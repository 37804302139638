import { FlexContainer, FlexItem, Show } from '@pluralsight/react-ng';
import React, { useEffect, useState } from 'react';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import avatar from '../../assets/images/avatar.png';

import './welcome.scss';

const Welcome = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslations();

  useEffect(() => {
    const isFirstVisit = localStorage.getItem('isFirstVisit');

    setIsVisible(isFirstVisit !== 'false');
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    localStorage.setItem('isFirstVisit', 'false');
  };

  return (
    <Show when={isVisible}>
      <FlexContainer
        className="welcome"
        style={{
          padding: '25px',
          borderRadius: '8px',
          background:
            'radial-gradient(1179.3% 405.82% at -15.74% 438.97%, rgba(119, 14, 247, 0.24) 0%, rgba(187, 18, 182, 0.35) 21.5%, rgba(246, 137, 34, 0.78) 100%)',
        }}
      >
        <FlexContainer align="center" gap="30px">
          <FlexItem>
            <img src={avatar} alt="Avatar" />
          </FlexItem>
          <FlexContainer direction="col">
            <FlexItem>
              <span
                style={{
                  color:
                    'var(--neutral-text, var(--neutral-text-initial, #D1D2E6))',
                  fontFamily: "var(--font-family-body, 'PS TT Commons')",
                  fontSize: 'var(--font-size-xs, 12px)',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: '150%',
                }}
              >
                {t('welcome.welcomeLabel')}
              </span>
            </FlexItem>
            <FlexItem>
              <span
                style={{
                  color:
                    'var(--neutral-text-strong, var(--neutral-text-300, #F3F3F8))',
                  fontFamily: "var(--font-family-title, 'PS TT Commons')",
                  fontSize: 'var(--font-size-size-3xl, 32px)',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '125%',
                }}
              >
                {t('welcome.letsStartYourJourney')}
              </span>
            </FlexItem>
          </FlexContainer>
        </FlexContainer>
        <FlexItem>
          <span className="welcome__close" onClick={handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M15.5855 5.00026L14.9998 4.41452C14.8371 4.2518 14.5732 4.2518 14.4105 4.41452L9.99978 8.82524L5.58904 4.41452C5.42632 4.2518 5.1625 4.2518 4.99978 4.41452L4.41404 5.00026C4.25132 5.16298 4.25132 5.4268 4.41404 5.58952L8.82478 10.0002L4.41404 14.411C4.25132 14.5737 4.25132 14.8376 4.41404 15.0003L4.99978 15.586C5.1625 15.7487 5.42632 15.7487 5.58904 15.586L9.99978 11.1752L14.4105 15.586C14.5732 15.7487 14.8371 15.7487 14.9998 15.586L15.5855 15.0002C15.7482 14.8375 15.7482 14.5737 15.5855 14.411L11.1748 10.0002L15.5855 5.58952C15.7482 5.4268 15.7482 5.16298 15.5855 5.00026Z"
                fill="#E1DDF7"
              />
            </svg>
          </span>
        </FlexItem>
      </FlexContainer>
    </Show>
  );
};

export default Welcome;
