// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-search-results-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--pando-colors-neutral-surface-100);
  padding: var(--pando-spacing-8);
  border-radius: var(--pando-radii-lg);
}
.no-search-results-container__title {
  font-size: var(--pando-font-sizes-2xl);
  font-weight: var(--pando-font-weights-semibold);
  line-height: var(--pando-line-heights-tight);
}
.no-search-results-container__image {
  width: 150px;
}`, "",{"version":3,"sources":["webpack://./src/features/teams/components/no-results/no-results.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,yDAAA;EACA,+BAAA;EACA,oCAAA;AAAF;AAEE;EACE,sCAAA;EACA,+CAAA;EACA,4CAAA;AAAJ;AAGE;EACE,YAAA;AADJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.no-search-results-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  background-color: var(--pando-colors-neutral-surface-100);\n  padding: var(--pando-spacing-8);\n  border-radius: var(--pando-radii-lg);\n\n  &__title {\n    font-size: var(--pando-font-sizes-2xl);\n    font-weight: var(--pando-font-weights-semibold);\n    line-height: var(--pando-line-heights-tight);\n  }\n\n  &__image {\n    width: 150px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
