// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirmation-step__team-name {
  margin: var(--pando-spacing-4) 0;
}
.confirmation-step__users {
  margin-bottom: var(--pando-spacing-2);
}
.confirmation-step__title {
  color: var(--pando-colors-neutral-text-300);
  /* Text/s bold */
  font-family: var(--font-family-body, "PS TT Commons");
  font-size: var(--font-size-sm, 14px);
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 21px */
}
.confirmation-step__title .team-name {
  margin-bottom: var(--pando-spacing-2);
}
.confirmation-step__length {
  color: var(--pando-inky-blue-neutral-text-initial-dark);
  /* Text/s */
  font-family: var(--font-family-body, "PS TT Commons");
  font-size: var(--font-size-sm, 14px);
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
}`, "",{"version":3,"sources":["webpack://./src/features/create-team/components/confirmation-step/confirmation-step.scss"],"names":[],"mappings":"AAEE;EACE,gCAAA;AADJ;AAIE;EACE,qCAAA;AAFJ;AAKE;EACE,2CAAA;EAEA,gBAAA;EACA,qDAAA;EACA,oCAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;AAJJ;AAMI;EACE,qCAAA;AAJN;AAQE;EACE,uDAAA;EAEA,WAAA;EACA,qDAAA;EACA,oCAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;AAPJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.confirmation-step {\n  &__team-name {\n    margin: var(--pando-spacing-4) 0;\n  }\n\n  &__users {\n    margin-bottom: var(--pando-spacing-2);\n  }\n\n  &__title {\n    color: var(--pando-colors-neutral-text-300);\n\n    /* Text/s bold */\n    font-family: var(--font-family-body, \"PS TT Commons\");\n    font-size: var(--font-size-sm, 14px);\n    font-style: normal;\n    font-weight: 700;\n    line-height: 150%; /* 21px */\n\n    .team-name {\n      margin-bottom: var(--pando-spacing-2);\n    }\n  }\n\n  &__length {\n    color: var(--pando-inky-blue-neutral-text-initial-dark);\n\n    /* Text/s */\n    font-family: var(--font-family-body, \"PS TT Commons\");\n    font-size: var(--font-size-sm, 14px);\n    font-style: normal;\n    font-weight: 500;\n    line-height: 150%; /* 21px */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
