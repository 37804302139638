import { ArrowRightIcon } from '@pluralsight/icons';
import { Button, FlexContainer } from '@pluralsight/react-ng';
import React from 'react';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import useScreenWidth from '../../../../shared/hooks/useScreenWidth';
import useDialogStep from '../../../../shared/dialog/useDialogStep';

interface IActionButtonsProps {
  closeModal: () => void;
}

const UpdateRoleStepActionButtons: React.FC<IActionButtonsProps> = ({
  closeModal,
}) => {
  const { t } = useTranslations();
  const { isXS } = useScreenWidth();
  const { setNextStep } = useDialogStep();

  return (
    <FlexContainer justify="spaceBetween">
      <FlexContainer
        align="center"
        direction="row"
        justify="flexEnd"
        wrap="nowrap"
      >
        <Button
          className="action-buttons__button"
          size="lg"
          palette="action"
          usage="text"
          role="button"
          aria-label={t('common.actionButtons.cancel')}
          onClick={closeModal}
        >
          {t('common.actionButtons.cancel')}
        </Button>
        <Button
          size="lg"
          palette="action"
          usage="filled"
          role="button"
          aria-label={t('common.actionButtons.next')}
          onClick={setNextStep}
        >
          {!isXS && t('common.actionButtons.next')}
          <ArrowRightIcon aria-hidden="true" width={20} />
        </Button>
      </FlexContainer>
    </FlexContainer>
  );
};

export default UpdateRoleStepActionButtons;
