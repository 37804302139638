import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';

import { selectUserPlanId } from '../../user/state/selectors';

import { TeamPendingInvitesService } from './services';
import {
  fetchTeamPendingInvites,
  fetchTeamPendingInvitesFailure,
  fetchTeamPendingInvitesSuccess,
} from './slice';

import type { Invite } from '../../people/state/interfaces';
import type { AxiosInstance } from 'axios';

function* fetchTeamPendingInvitesHandler({
  payload: teamId,
}: {
  payload: string;
}) {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');
    const service = new TeamPendingInvitesService(httpClient);

    const planId: string | null = yield select(selectUserPlanId);

    if (!planId) throw new Error('No planId provided');

    const invites: Invite[] = yield call(
      service.fetchTeamPendingInvites,
      planId,
      teamId,
    );

    yield put(fetchTeamPendingInvitesSuccess(invites));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetchTeamPendingInvitesFailure(error.message));
    } else {
      yield put(fetchTeamPendingInvitesFailure('An unknown error occurred'));
    }
  }
}

export default function* teamPendingInvitesSagas() {
  yield takeLatest(fetchTeamPendingInvites, fetchTeamPendingInvitesHandler);
}
