import { Button } from '@pluralsight/react-ng';
import './delete-user-dialog.scss';
import React, { forwardRef } from 'react';
import { useDispatch } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import CustomDialog from '../../../../components/custom-dialog/custom-dialog';
import { deleteUser } from '../../state/slice';

import type { UsersTableItem } from '../../../../shared/interfaces';
import type {
  ContentType,
  HeaderType,
} from '../../../../components/custom-dialog/custom-dialog';

interface DeleteUserDialogProps {
  currentUser: UsersTableItem | null;
  closeModal: () => void;
}

const DeleteUserDialog = forwardRef<
  HTMLDialogElement | null,
  DeleteUserDialogProps
>(({ currentUser, closeModal }, ref) => {
  const dispatch = useDispatch();
  const { t } = useTranslations();

  const handleAction = () => {
    if (!currentUser) return;

    dispatch(deleteUser(currentUser));
    closeModal();
  };

  const dialogHeader: HeaderType = {
    1: t('people.deleteUser.deleteUserHeader', {
      user: currentUser?.name,
    }),
  };

  const dialogContent: ContentType = {
    1: <span>{t('people.deleteUser.deleteUserDescription')}</span>,
  };

  const getActionButtons = (step: number): React.JSX.Element => {
    switch (step) {
      case 1:
        return (
          <>
            <Button
              size="lg"
              palette="action"
              usage="text"
              onClick={closeModal}
            >
              {t('common.actionButtons.cancel')}
            </Button>
            <Button
              size="lg"
              palette="danger"
              usage="filled"
              onClick={handleAction}
            >
              {t('people.deleteUser.delete')}
            </Button>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <CustomDialog
      id="delete-user"
      ref={ref}
      dialogHeader={dialogHeader}
      dialogContent={dialogContent}
      actionButtons={getActionButtons}
      multipleSteps={false}
      isWarning={true}
      closeModal={closeModal}
    />
  );
});

export default DeleteUserDialog;
