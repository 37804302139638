import { Button } from '@pluralsight/react-ng';
import './resend-invite-dialog.scss';
import React, { forwardRef } from 'react';
import { useDispatch } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import CustomDialog from '../../../../components/custom-dialog/custom-dialog';
import { resendInvitation } from '../../state/slice';

import type { Invite } from '../../../people/state/interfaces';
import type {
  ContentType,
  HeaderType,
} from '../../../../components/custom-dialog/custom-dialog';

interface IResendInviteDialogProps {
  invite?: Invite | null;
  closeModal: () => void;
}

const ResendInviteDialog = forwardRef<
  HTMLDialogElement | null,
  IResendInviteDialogProps
>(({ invite, closeModal }, ref) => {
  const dispatch = useDispatch();
  const { t } = useTranslations();

  const handleAction = () => {
    if (!invite) return;

    dispatch(resendInvitation(invite));
    closeModal();
  };

  const dialogHeader: HeaderType = {
    1: `${t('invitation.resendInviteDialog.resendInviteTo')} ${invite?.email}`,
  };

  const dialogContent: ContentType = {
    1: <span>{t('invitation.resendInviteDialog.dialogContent')}</span>,
  };

  const getActionButtons = (step: number): React.JSX.Element => {
    switch (step) {
      case 1:
        return (
          <>
            <Button
              size="lg"
              palette="action"
              usage="text"
              onClick={closeModal}
            >
              {t('common.actionButtons.cancel')}
            </Button>
            <Button
              size="lg"
              palette="action"
              usage="filled"
              onClick={handleAction}
            >
              {t('invitation.resendInviteDialog.resendInvite')}
            </Button>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <CustomDialog
      id="resend-invite"
      ref={ref}
      dialogHeader={dialogHeader}
      dialogContent={dialogContent}
      actionButtons={getActionButtons}
      multipleSteps={false}
      isWarning={false}
      closeModal={closeModal}
    />
  );
});

export default ResendInviteDialog;
