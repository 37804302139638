import i18n from 'i18next';
import {
  call,
  getContext,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';

import { selectUserPlanId } from '../../user/state/selectors';
import { setToast } from '../../toast/state/slice';

import { TeamsService } from './services';
import {
  fetchTeams,
  fetchTeamsFailure,
  fetchTeamsSuccess,
  fetchUploadTeamsUrl,
  fetchUploadTeamsUrlFailure,
  fetchUploadTeamsUrlSuccess,
  importTeamsFile,
  importTeamsFileFailure,
  importTeamsFileSuccess,
} from './slice';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { ImportUploadUrlResponse } from './interfaces';
import type { Team, TeamResponseItem } from '../../team/state/interfaces';
import type { AxiosInstance } from 'axios';

function* fetchTeamsHandler() {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');
    const teamsService = new TeamsService(httpClient);

    const planId: string | null = yield select(selectUserPlanId);

    if (!planId) throw new Error('No planId provided');

    const teamsResponse: TeamResponseItem[] = yield call(
      teamsService.fetchTeams,
      planId,
    );

    yield put(
      fetchTeamsSuccess(
        teamsResponse.map(
          (team: TeamResponseItem): Team => ({
            id: team.id,
            name: team.name,
            usersCount: team.usersCount,
            allUsersCount: team.allUsersCount,
            managersCount: team.managersCount,
            managerIds: team.managerIds || [],
            nestedTeams: 0,
          }),
        ),
      ),
    );
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetchTeamsFailure(error.message));
    } else {
      yield put(fetchTeamsFailure('An unknown error occurred'));
    }
  }
}

function* fetchUploadImportTeamsUrlHandler() {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');

    const planId: string | null = yield select(selectUserPlanId);

    if (!planId) throw new Error('No planId provided');

    const teamsService = new TeamsService(httpClient);

    const { url }: ImportUploadUrlResponse = yield call(
      teamsService.fetchUploadImporTeamstUrl,
      planId,
    );

    yield put(fetchUploadTeamsUrlSuccess({ url }));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetchUploadTeamsUrlFailure(error.message));
    } else {
      yield put(fetchUploadTeamsUrlFailure('An error occurred'));
    }
  }
}

function* importTeamsHandler(action: PayloadAction<{ file: File }>) {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');
    const planId: string | null = yield select(selectUserPlanId);

    if (!planId) throw new Error('No planId provided');

    const teamsService = new TeamsService(httpClient);

    yield put(fetchUploadTeamsUrl());

    const { payload } = yield take(fetchUploadTeamsUrlSuccess.type);

    const response: string = yield call(
      teamsService.uploadImportTeamsFile,
      payload.url,
      action.payload.file,
    );

    yield put(importTeamsFileSuccess(response));

    yield put(
      setToast({
        heading: i18n.t('teams.importTeams.importSuccessHeader'),
        palette: 'success',
        text: i18n.t('teams.importTeams.importSuccessText'),
      }),
    );
  } catch (error) {
    if (error instanceof Error) {
      yield put(importTeamsFileFailure(error.message));
    } else {
      yield put(importTeamsFileFailure('An error occurred'));
    }

    yield put(
      setToast({
        heading: i18n.t('teams.importTeams.importErrorHeader'),
        palette: 'danger',
        text: i18n.t('teams.importTeams.importErrorText'),
      }),
    );
  }
}

export default function* teamsSagas() {
  yield takeLatest(fetchTeams, fetchTeamsHandler);
  yield takeLatest(fetchUploadTeamsUrl.type, fetchUploadImportTeamsUrlHandler);
  yield takeLatest(importTeamsFile.type, importTeamsHandler);
}
