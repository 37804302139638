import {
  FlexContainer,
  IconButton,
  TabContent,
  TabsList,
  TabsRoot,
  TabTrigger,
  useToast,
} from '@pluralsight/react-ng';
import { PersonAddIcon, UploadIcon } from '@pluralsight/icons';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import RoleRestrictedComponent from '../../../../components/role-restricted-component/role-restricted-component';
import { USER_ROLE } from '../../../../shared/constants';
import AddUsersDialog from '../../../invitation/components/add-users-dialog/add-users-dialog';
import ExistingAccountDialog from '../../../invitation/components/existing-account-dialog/existing-account-dialog';
import ResendInviteDialog from '../../../invitation/components/resend-invite-dialog/resend-invite-dialog';
import RevokeInviteDialog from '../../../invitation/components/revoke-invite-dialog/revoke-invite-dialog';
import { selectExistingAccountEmails } from '../../../invitation/state/selectors';
import {
  clearAssignedLicenses,
  clearAssignedRoles,
  clearAssignedTeams,
  clearEmails,
  clearExistingAccountEmails,
} from '../../../invitation/state/slice';
import { selectToast } from '../../../toast/state/selectors';
import InvitesTableContainer from '../invites-table-container/invites-table-container';
import UsersTableContainer from '../users-table-container/users-table-container';
import { fetchProducts } from '../../../products/state/slice';
import ProductsUsageContainer from '../products-usage-container/products-usage-container';
import useScreenWidth from '../../../../shared/hooks/useScreenWidth';
import ImportUsersDialog from '../impor-users-dialog/import-users-dialog';
import { setImportActiveTab, setImportFile } from '../../state/slice';
import { ImportTabs } from '../../../../shared/interfaces';
import { fetchRoles } from '../../../roles/state/slice';
import { selectOrgId } from '../../../plans/state/selectors';
import { setDialogStep } from '../../../../shared/dialog/state/slice';

import type { Invite } from '../../state/interfaces';
import type { PeopleLocationState } from '../../types/navigation';
import './people-container.scss';

enum PeopleTabs {
  Users = 'users',
  Invites = 'invites',
}

enum KeyboardEventPeoplePage {
  ArrowRight = 'ArrowRight',
  ArrowLeft = 'ArrowLeft',
}

const PeopleContainer: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { show } = useToast();
  const { t, language: currentLanguage } = useTranslations();
  const toast = useSelector(selectToast);
  const existingAccountEmails = useSelector(selectExistingAccountEmails);
  const orgId = useSelector(selectOrgId);
  const [inviteSelected, setInviteSelected] = useState<Invite | null>(null);

  const [activeTab, setActiveTab] = useState<PeopleTabs>(PeopleTabs.Users);
  const { isXS, isSM } = useScreenWidth();

  useEffect(() => {
    if (toast) {
      show({
        heading: toast.heading,
        palette: toast.palette,
        text: toast.text,
        onClose: () => {},
      });
    }
  }, [show, toast]);

  const dialogRef = useRef<HTMLDialogElement>(null);
  const importDialogRef = useRef<HTMLDialogElement>(null);
  const resendDialogRef = useRef<HTMLDialogElement>(null);
  const revokeDialogRef = useRef<HTMLDialogElement>(null);
  const existingAccountDialogRef = useRef<HTMLDialogElement>(null);
  const tabRefs = useRef<HTMLButtonElement[]>([]);

  useEffect(() => {
    const state = location.state as PeopleLocationState | null;

    if (state?.shouldOpenInviteModal) {
      openDialog();
      navigate(location.pathname, { replace: true });
    }
  }, [location, navigate]);

  useEffect(() => {
    const openExistingAccountDialog = () => {
      existingAccountDialogRef.current?.showModal();
      dispatch(setDialogStep(1));
    };

    if (existingAccountEmails.length) {
      openExistingAccountDialog();
    }
  }, [dispatch, existingAccountEmails]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (tabRefs.current.length === 0) return;

    if (
      event.code === KeyboardEventPeoplePage.ArrowRight ||
      event.code === KeyboardEventPeoplePage.ArrowLeft
    ) {
      event.preventDefault();

      const newActiveTab =
        event.key === KeyboardEventPeoplePage.ArrowRight
          ? PeopleTabs.Invites
          : PeopleTabs.Users;

      setActiveTab(newActiveTab);
    }
  };

  useEffect(() => {
    if (activeTab === PeopleTabs.Users) {
      tabRefs.current[0]?.focus();

      return;
    }

    if (activeTab === PeopleTabs.Invites) {
      tabRefs.current[1]?.focus();

      return;
    }
  }, [activeTab]);

  const closeExistingAccountDialog = () => {
    existingAccountDialogRef.current?.close();
    dispatch(setDialogStep(0));
    dispatch(clearExistingAccountEmails());
  };

  const openDialog = () => {
    dialogRef.current?.showModal();
    dispatch(setDialogStep(1));
    dispatch(fetchProducts());
    dispatch(fetchRoles(orgId));
  };

  const closeDialog = () => {
    dialogRef.current?.close();
    dispatch(setDialogStep(0));
    dispatch(clearEmails());
    dispatch(clearAssignedRoles());
    dispatch(clearAssignedTeams());
    dispatch(clearAssignedLicenses());
  };

  const openResendDialog = (invite?: Invite) => {
    resendDialogRef.current?.showModal();
    dispatch(setDialogStep(1));
    setInviteSelected(invite ?? null);
  };

  const closeResendDialog = () => {
    resendDialogRef.current?.close();
    setInviteSelected(null);
    dispatch(setDialogStep(0));
  };

  const openRevokeDialog = (invite?: Invite) => {
    revokeDialogRef.current?.showModal();
    dispatch(setDialogStep(1));
    setInviteSelected(invite ?? null);
  };

  const closeRevokeDialog = () => {
    revokeDialogRef.current?.close();
    setInviteSelected(null);
    dispatch(setDialogStep(0));
  };

  const openImportDialog = () => {
    importDialogRef.current?.showModal();
    dispatch(setDialogStep(1));
  };

  const closeImportDialog = () => {
    importDialogRef.current?.close();
    dispatch(setDialogStep(0));
    dispatch(setImportFile(null));
    dispatch(setImportActiveTab(ImportTabs.Import));
  };

  const evaluateWidthAddUsers = useCallback(
    () =>
      currentLanguage === 'fr' || currentLanguage === 'ge' ? '200px' : '150px',
    [currentLanguage],
  );

  return (
    <>
      <div className="people-container">
        <div className="people-container__header">
          <h1 className="people-container__header-title">
            {t('people.peopleContainer.peopleLabel')}
          </h1>
          <FlexContainer gap="var(--pando-gap-md)" justify="flexEnd">
            <RoleRestrictedComponent
              restrictedTo={[
                {
                  roleName: USER_ROLE.SUPER_ADMIN,
                  scope: 'organizationScope',
                },
                {
                  roleName: USER_ROLE.SYSTEM_ADMIN,
                  scope: 'organizationScope',
                },
                {
                  roleName: USER_ROLE.PLAN_ADMIN,
                  scope: 'planScope',
                },
              ]}
            >
              <IconButton
                style={{ width: evaluateWidthAddUsers() }}
                size="lg"
                palette="action"
                usage="outline"
                onClick={openImportDialog}
                ariaLabel={t('people.peopleContainer.import')}
              >
                <UploadIcon />
                {!(isXS || isSM) && t('people.peopleContainer.import')}
              </IconButton>
            </RoleRestrictedComponent>
            <RoleRestrictedComponent
              restrictedTo={[
                {
                  roleName: USER_ROLE.SUPER_ADMIN,
                  scope: 'organizationScope',
                },
                {
                  roleName: USER_ROLE.SYSTEM_ADMIN,
                  scope: 'organizationScope',
                },
                {
                  roleName: USER_ROLE.PLAN_ADMIN,
                  scope: 'planScope',
                },
                {
                  roleName: USER_ROLE.MANAGER_FULL,
                  scope: 'planScope',
                },
              ]}
            >
              <IconButton
                style={{ width: evaluateWidthAddUsers() }}
                size="lg"
                palette="action"
                usage="filled"
                onClick={openDialog}
                ariaLabel={t('people.peopleContainer.addUsers')}
              >
                {!(isXS || isSM) ? (
                  t('people.peopleContainer.addUsers')
                ) : (
                  <PersonAddIcon />
                )}
              </IconButton>
            </RoleRestrictedComponent>
          </FlexContainer>
        </div>
        <RoleRestrictedComponent
          restrictedTo={[
            {
              roleName: USER_ROLE.SUPER_ADMIN,
              scope: 'organizationScope',
            },
            {
              roleName: USER_ROLE.PLAN_ADMIN,
              scope: 'planScope',
            },
          ]}
        >
          <ProductsUsageContainer />
        </RoleRestrictedComponent>
        <div className="people-container__tabs">
          <TabsRoot
            value={activeTab}
            onValueChange={(event) => setActiveTab(event.value as PeopleTabs)}
          >
            <TabsList>
              <TabTrigger
                value={PeopleTabs.Users}
                id="users-tab"
                ref={(element) =>
                  (tabRefs.current[0] = element as HTMLButtonElement)
                }
              >
                {t('people.peopleContainer.users')}
              </TabTrigger>
              <TabTrigger
                value={PeopleTabs.Invites}
                id="invites-tab"
                ref={(element) =>
                  (tabRefs.current[1] = element as HTMLButtonElement)
                }
              >
                {t('people.peopleContainer.invites')}
              </TabTrigger>
            </TabsList>
            <TabContent
              aria-labelledby={t('people.peopleContainer.usersTab')}
              value="users"
            >
              <UsersTableContainer openDialog={openDialog} />
            </TabContent>
            <TabContent
              aria-labelledby={t('people.peopleContainer.invitesTab')}
              value="invites"
            >
              <InvitesTableContainer
                openDialog={openDialog}
                openRevokeDialog={openRevokeDialog}
                openResendDialog={openResendDialog}
              />
            </TabContent>
          </TabsRoot>
        </div>
      </div>
      <AddUsersDialog ref={dialogRef} closeModal={closeDialog} />
      <ImportUsersDialog ref={importDialogRef} closeModal={closeImportDialog} />
      <ExistingAccountDialog
        ref={existingAccountDialogRef}
        closeModal={closeExistingAccountDialog}
      />
      <ResendInviteDialog
        ref={resendDialogRef}
        closeModal={closeResendDialog}
        invite={inviteSelected}
      />
      <RevokeInviteDialog
        ref={revokeDialogRef}
        closeModal={closeRevokeDialog}
        invite={inviteSelected}
      />
    </>
  );
};

export default PeopleContainer;
