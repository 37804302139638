import { Button, FlexContainer, Show } from '@pluralsight/react-ng';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import { handleMenuKeyBoardNavigation } from '../../../../../src/shared/helpers/handle-menu-keyboard-navigation';
import RoleRestrictedComponent from '../../../../components/role-restricted-component/role-restricted-component';
import PeopleIcon from '../../../../assets/people.svg';
import Placeholder from '../../../../components/placeholder/placeholder';
import UsersTable from '../../../../components/users-table/users-table';
import {
  deleteUsersRestrictedTo,
  USER_ROLE,
} from '../../../../shared/constants';
import { calculatePrivilegedRole } from '../../../../shared/role-validator-utils';
import {
  assignUser,
  clearAssignedMembers,
} from '../../../create-team/state/slice';
import { selectUser } from '../../../user/state/selectors';
import { selectTeamUsersList } from '../../state/selectors';
import { clearTeamSelections, fetchTeamUsers } from '../../state/slice';
import { teamMemberTableColumns } from '../team-member-columns';
import AddTeamMembersDialog from '../add-team-members-dialog/add-team-members-dialog';
import RemoveTeamMembersDialog from '../remove-team-members-dialog/remove-team-members-dialog';
import MoveTeamMembersDialog from '../move-team-members-dialog/move-team-members-dialog';
import { setDialogStep } from '../../../../shared/dialog/state/slice';
import './team-users-container.scss';
import useMapUsers from '../../../people/hooks/useMapUsers';
import DeleteUserDialog from '../../../people/components/delete-user-dialog/delete-user-dialog';

import type { KeyBoardActions } from '../../../../../src/shared/helpers/handle-menu-keyboard-navigation';
import type {
  ExpectedRole,
  Member,
  UsersTableItem,
} from '../../../../shared/interfaces';

interface TeamUsersContainerProps {
  teamId: string | null;
}

const TeamUsersContainer: React.FC<TeamUsersContainerProps> = ({ teamId }) => {
  const [teamUsers, setTeamUsers] = useState<UsersTableItem[]>([]);
  const { t } = useTranslations();
  const { toUserTableItems } = useMapUsers();
  const dialogRef = useRef<HTMLDialogElement>(null);
  const removeTeamMembersDialogRef = useRef<HTMLDialogElement>(null);
  const moveTeamMembersDialogRef = useRef<HTMLDialogElement>(null);
  const deleteUserDialogRef = useRef<HTMLDialogElement>(null);
  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  const [currentUser, setCurrentUser] = useState<UsersTableItem | null>(null);

  useEffect(() => {
    if (teamId) {
      dispatch(fetchTeamUsers(teamId));
    }
  }, [dispatch, teamId, user]);

  const users: Member[] = useSelector(selectTeamUsersList);

  const showDialog = () => {
    dialogRef.current?.showModal();
    dispatch(setDialogStep(1));
  };
  const closeDialog = () => {
    dialogRef.current?.close();
    dispatch(setDialogStep(0));
    dispatch(clearAssignedMembers());
  };

  const showRemoveTeamMembersDialog = (member: UsersTableItem) => {
    dispatch(assignUser(member));
    dispatch(setDialogStep(1));
    removeTeamMembersDialogRef.current?.showModal();
  };
  const closeRemoveTeamMembersDialog = () => {
    removeTeamMembersDialogRef.current?.close();
    dispatch(setDialogStep(0));
    dispatch(clearAssignedMembers());
  };

  const showMoveTeamMembersDialog = (member: UsersTableItem) => {
    dispatch(assignUser(member));
    dispatch(setDialogStep(1));
    moveTeamMembersDialogRef.current?.showModal();
  };
  const closeMoveTeamMembersDialog = () => {
    moveTeamMembersDialogRef.current?.close();
    dispatch(setDialogStep(0));
    dispatch(clearAssignedMembers());
    dispatch(clearTeamSelections());
  };

  const showDeleteUserDialog = (member: UsersTableItem) => {
    setCurrentUser(member);
    dispatch(setDialogStep(1));
    deleteUserDialogRef.current?.showModal();
  };

  const onDeleteUserDialogClose = () => {
    setCurrentUser(null);
    dispatch(setDialogStep(0));
    deleteUserDialogRef.current?.close();
  };

  const addUsersRestrictedTo: ExpectedRole[] = [
    {
      roleName: USER_ROLE.SUPER_ADMIN,
      scope: 'organizationScope',
    },
    {
      roleName: USER_ROLE.SYSTEM_ADMIN,
      scope: 'organizationScope',
    },
    {
      roleName: USER_ROLE.PLAN_ADMIN,
      scope: 'planScope',
    },
    {
      resourceId: teamId || '',
      roleName: USER_ROLE.MANAGER_FULL,
      scope: 'teamScope',
    },
    {
      resourceId: teamId || '',
      roleName: USER_ROLE.MANAGER_LIMITED,
      scope: 'teamScope',
    },
  ];

  const renderDropdownOptions = useCallback(
    (member: UsersTableItem, actions: KeyBoardActions) => {
      const isPrivilegedRole = calculatePrivilegedRole(
        user,
        member.roleNames?.map((r) => r as USER_ROLE) || null,
      );

      return (
        <div
          role="menu"
          onKeyDown={handleMenuKeyBoardNavigation(
            'common-dropdown-option',
            actions,
          )}
          tabIndex={0}
        >
          <div
            className="common-dropdown-option"
            onClick={() => []}
            role="menuitem"
            tabIndex={0}
            ref={(el) => el?.focus()}
          >
            <span className="dropdown-option-span">
              {t('teamUsers.tableOptions.editEmail')}
            </span>
          </div>
          <div
            className="common-dropdown-option"
            onClick={() => []}
            role="menuitem"
            tabIndex={0}
          >
            <span className="dropdown-option-span">
              {t('teamUsers.tableOptions.editNote')}
            </span>
          </div>
          <div
            className="common-dropdown-option"
            onClick={() => showMoveTeamMembersDialog(member)}
            role="menuitem"
            tabIndex={0}
          >
            <span className="dropdown-option-span">
              {t('teamUsers.tableOptions.moveTeams')}
            </span>
            <span className="dropdown-option-description">
              {t('teamUsers.tableOptions.moveTeamsDescription')}
            </span>
          </div>
          <div
            className="common-dropdown-option"
            onClick={() => showRemoveTeamMembersDialog(member)}
            role="menuitem"
            tabIndex={0}
          >
            <span className="dropdown-option-span">
              {t('teamUsers.tableOptions.removeTeam')}
            </span>
            <span className="dropdown-option-description">
              {t('teamUsers.tableOptions.removeTeamDescription')}
            </span>
          </div>

          <RoleRestrictedComponent
            restrictedTo={isPrivilegedRole ? deleteUsersRestrictedTo : []}
          >
            <div
              className="common-dropdown-option"
              onClick={() => showDeleteUserDialog(member)}
              role="menuItem"
              tabIndex={0}
            >
              <span className="dropdown-option-span">
                {t('people.optionsDropdown.deleteUser')}
              </span>
              <span className="dropdown-option-description">
                {t('people.optionsDropdown.deleteUserDescription')}
              </span>
            </div>
          </RoleRestrictedComponent>
        </div>
      );
    },
    [t, showRemoveTeamMembersDialog],
  );

  useEffect(() => {
    const mappedUsers = toUserTableItems(users);

    setTeamUsers(mappedUsers);
  }, [users]);

  return (
    <>
      <FlexContainer justify="end">
        <RoleRestrictedComponent restrictedTo={addUsersRestrictedTo}>
          <Button
            size="lg"
            palette="action"
            usage="filled"
            onClick={showDialog}
          >
            {t('teamUsers.addUsers')}
          </Button>
        </RoleRestrictedComponent>
        <AddTeamMembersDialog ref={dialogRef} closeModal={closeDialog} />
      </FlexContainer>
      <Show
        when={!!teamUsers.length}
        fallback={
          <div className="team-users-container__placeholder">
            <Placeholder icon={PeopleIcon}>
              <div className="team-users-container__placeholder-message">
                {t('teamUsers.noTeamUsers')}
              </div>
              <FlexContainer justify="center">
                <RoleRestrictedComponent restrictedTo={addUsersRestrictedTo}>
                  <button
                    className="team-users-container__placeholder-button"
                    aria-label="Add team users"
                    onClick={showDialog}
                  >
                    {t('teamUsers.addUsers')}
                  </button>
                </RoleRestrictedComponent>
              </FlexContainer>
            </Placeholder>
          </div>
        }
      >
        <UsersTable
          columns={teamMemberTableColumns}
          people={teamUsers}
          dropDownOptions={renderDropdownOptions}
        />
        <MoveTeamMembersDialog
          ref={moveTeamMembersDialogRef}
          closeModal={closeMoveTeamMembersDialog}
        />
        <RemoveTeamMembersDialog
          ref={removeTeamMembersDialogRef}
          closeModal={closeRemoveTeamMembersDialog}
          teamIdSelected={teamId ?? ''}
          singleRemove={true}
        />

        <DeleteUserDialog
          ref={deleteUserDialogRef}
          closeModal={onDeleteUserDialogClose}
          currentUser={currentUser}
        />
      </Show>
    </>
  );
};

export default TeamUsersContainer;
