/* eslint-disable no-console */
import { format, parseISO, isValid } from 'date-fns';
import i18n from 'i18next';

export const formatDate = (dateString: string, fallback?: string): string => {
  try {
    const date = parseISO(dateString);

    if (!isValid(date)) {
      if (!fallback) console.error('Invalid date:', dateString);

      return fallback ?? i18n.t('common.invalidDate', 'Invalid date');
    }

    return format(date, 'MM/dd/yyyy');
  } catch (error) {
    if (!fallback) console.error('Error formatting date:', error);

    return fallback ?? i18n.t('common.invalidDate', 'Invalid date');
  }
};
