import React, { useEffect, useState } from 'react';
import { FlexContainer, IconButton, Show } from '@pluralsight/react-ng';
import { useDispatch, useSelector } from 'react-redux';
import { DownloadIcon } from '@pluralsight/icons';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import PeopleIcon from '../../../../assets/people.svg';
import Placeholder from '../../../../components/placeholder/placeholder';
import RoleRestrictedComponent from '../../../../components/role-restricted-component/role-restricted-component';
import {
  TEAM_TABLE_KEYS,
  TEMPLATE_COLUMNS,
  USER_ROLE,
} from '../../../../shared/constants';
import { selectUser } from '../../../user/state/selectors';
import { selectTeamsList } from '../../state/selectors';
import { fetchTeams } from '../../state/slice';
import TeamsTable, { toTeamsTableItems } from '../teams-table/teams-table';
import NoResults from '../no-results/no-results';
import SearchInput from '../search-input/search-input';
import { generateCSV } from '../../../../shared/export-csv-data';
import useScreenWidth from '../../../../shared/hooks/useScreenWidth';

import type { TeamsTableItem } from '../teams-table/teams-table';
import type { FC } from 'react';
import type { ExpectedRole } from '../../../../shared/interfaces';

import './teams-table-container.scss';

interface ITeamsTableContainerProps {
  openDialog: () => void;
}

const TEAM_SEARCH_MIN_KEY_STROKES = 2;

const exportColumnMap = new Map([
  [TEAM_TABLE_KEYS.ID, TEMPLATE_COLUMNS.ID],
  [TEAM_TABLE_KEYS.NAME, TEMPLATE_COLUMNS.TEAM_NAME],
  [
    TEMPLATE_COLUMNS.PARENT_TEAM_NAME as string,
    TEMPLATE_COLUMNS.PARENT_TEAM_NAME,
  ],
  [TEMPLATE_COLUMNS.ACTION as string, TEMPLATE_COLUMNS.ACTION],
]);

const exportRestrictedTo: ExpectedRole[] = [
  {
    roleName: USER_ROLE.SUPER_ADMIN,
    scope: 'organizationScope',
  },
  {
    roleName: USER_ROLE.SYSTEM_ADMIN,
    scope: 'organizationScope',
  },
  {
    roleName: USER_ROLE.PLAN_ADMIN,
    scope: 'planScope',
  },
];

const TeamsTableContainer: FC<ITeamsTableContainerProps> = ({ openDialog }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [mappedTeams, setMappedTeams] = useState<TeamsTableItem[]>([]);
  const [teamsResult, setTeamsResult] = useState<TeamsTableItem[]>([]);
  const { t } = useTranslations();
  const { isXS, isSM } = useScreenWidth();
  const dispatch = useDispatch();

  const teams = useSelector(selectTeamsList);
  const user = useSelector(selectUser);

  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const onTeamsExport = () => {
    generateCSV(
      exportColumnMap,
      teamsResult as unknown as Record<string, string | null>[],
      'teams_export.csv',
    );
  };

  useEffect(() => {
    dispatch(fetchTeams());
  }, [dispatch, user]);

  useEffect(() => {
    if (searchTerm.length < TEAM_SEARCH_MIN_KEY_STROKES) {
      return setTeamsResult(mappedTeams);
    }

    const filtered = mappedTeams.filter((teamItem: TeamsTableItem) =>
      teamItem?.name?.toLowerCase().includes(searchTerm.toLowerCase()),
    );

    setTeamsResult(filtered);
  }, [mappedTeams, searchTerm]);

  useEffect(() => {
    if (teams.length) {
      const mappedTeams = toTeamsTableItems(teams);

      setMappedTeams(mappedTeams);
      setTeamsResult(mappedTeams);
    }
  }, [teams]);

  return (
    <Show
      when={!!teams.length}
      fallback={
        <div className="teams-table-container__placeholder">
          <Placeholder icon={PeopleIcon}>
            <div className="teams-table-container__placeholder-message">
              {t('teams.looksLikeLabel')}
            </div>
            <FlexContainer justify="center">
              <RoleRestrictedComponent
                restrictedTo={[
                  {
                    roleName: USER_ROLE.SUPER_ADMIN,
                    scope: 'organizationScope',
                  },
                  {
                    roleName: USER_ROLE.SYSTEM_ADMIN,
                    scope: 'organizationScope',
                  },
                  {
                    roleName: USER_ROLE.PLAN_ADMIN,
                    scope: 'planScope',
                  },
                ]}
              >
                <button
                  className="teams-table-container__placeholder-button"
                  aria-label="Add team"
                  onClick={openDialog}
                >
                  {t('teams.createTeam')}
                </button>
              </RoleRestrictedComponent>
            </FlexContainer>
          </Placeholder>
        </div>
      }
    >
      <FlexContainer
        className="teams-table-container__actions"
        justify="spaceBetween"
      >
        <SearchInput onSearch={handleSearch} />
        <RoleRestrictedComponent restrictedTo={exportRestrictedTo}>
          <IconButton
            className="teams-table-container__actions__export"
            size="lg"
            type="button"
            palette="action"
            usage="text"
            onClick={onTeamsExport}
            ariaLabel={t('teams.export')}
          >
            <DownloadIcon aria-hidden="true" />
            {!(isXS || isSM) && t('teams.export')}
          </IconButton>
        </RoleRestrictedComponent>
      </FlexContainer>
      <Show when={!!teamsResult.length} fallback={<NoResults />}>
        <TeamsTable teams={teamsResult} searchFilter={searchTerm} />
      </Show>
    </Show>
  );
};

export default TeamsTableContainer;
