import { useState } from 'react';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import './field-validation-provider.scss';

interface IFieldValidationProps<T> {
  validate?: (value: T) => string | undefined;
  children: (fieldProps: {
    error?: string;
    validateField: (value: T) => void;
  }) => React.ReactNode;
}

const FieldValidationProvider = <T,>({
  validate,
  children,
}: IFieldValidationProps<T>) => {
  const [error, setError] = useState<string | undefined>();
  const { t } = useTranslations();

  const validateField = (value: T) => {
    if (validate) {
      setError(validate(value));
    }
  };

  return (
    <>
      {children({ error, validateField })}
      {error && <p className="field-validation-error-message">{t(error)}</p>}
    </>
  );
};

export default FieldValidationProvider;
