import type { TableColumn, UsersTableItem } from '../../../shared/interfaces';

export const teamMemberTableColumns: TableColumn<UsersTableItem>[] = [
  { header: 'teamUsers.headerName', mapsTo: 'name', useAvatar: true },
  { header: 'teamUsers.headerEmail', mapsTo: 'email' },
  { header: 'teamUsers.headerLicenses', mapsTo: 'licenses' },
  { header: 'teamUsers.headerTeams', mapsTo: 'teams' },
  { header: 'teamUsers.headerLastLogin', mapsTo: 'lastLogin' },
  { header: 'teamUsers.headerNote', mapsTo: 'note' },
];
