import {
  FlexContainer,
  FormControlProvider,
  Input,
} from '@pluralsight/react-ng';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { SearchIcon } from '@pluralsight/icons';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import type { ChangeEvent } from 'react';
import './search-input.scss';

interface SearchInputProps {
  onSearch: (term: string) => void;
}

const SearchInput = ({ onSearch }: SearchInputProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { t } = useTranslations();

  const debouncedSearch = useCallback(
    debounce((term: string) => onSearch(term), 300),
    [searchTerm],
  );

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) =>
    setSearchTerm(e.target.value);

  useEffect(() => {
    debouncedSearch(searchTerm);

    return () => debouncedSearch.cancel();
  }, [searchTerm, debouncedSearch]);

  return (
    <FlexContainer className="search-input-container">
      <FormControlProvider>
        <Input
          name="team_search_input"
          type="text"
          placeholder={t('team.searchPlaceholder')}
          className="search-input-container__input"
          value={searchTerm}
          startIcon={<SearchIcon className="search__icon" />}
          onChange={onInputChange}
        />
      </FormControlProvider>
    </FlexContainer>
  );
};

export default SearchInput;
