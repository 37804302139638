import type { AxiosInstance } from 'axios';
import type { Team } from './interfaces';

const PLANS_PATH = '/v1/plans';

export class TeamService {
  private httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  createTeam = async (planId: string, team: Team): Promise<Team> => {
    const data = {
      name: team.name,
      description: 'Description',
      assignedMembers: team.assignedMembers.map((user) => user.id),
    };

    try {
      const response = await this.httpClient.post<Team>(
        `${PLANS_PATH}/${planId}/teams`,
        data,
      );

      return response.data;
    } catch (error) {
      console.error('Error creating team:', error);

      throw error;
    }
  };
}
