import {
  FieldMessage,
  FormControlProvider,
  Input,
  Show,
} from '@pluralsight/react-ng';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import { setTeamName } from '../../features/create-team/state/slice';
import { selectTeamItem } from '../../features/team/state/selectors';
import { selectTeamsList } from '../../features/teams/state/selectors';
import { selectTeam } from '../../features/create-team/state/selectors';

import type { Team } from '../../features/team/state/interfaces';

const TEAM_NAME_MAX_LENGTH = 200;

const TeamNameInput = () => {
  const dispatch = useDispatch();
  const teams = useSelector(selectTeamsList);
  const team: Team | null = useSelector(selectTeamItem);
  const { name } = useSelector(selectTeam);

  const [enteredName, setEnteredName] = useState(name);
  const [isValid, setIsValid] = useState(true);
  const { t } = useTranslations();

  useEffect(() => {
    if (team) setEnteredName(team.name);

    setIsValid(true);
  }, [team]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isExistingTeam = teams.find(
      (team) => team.name === event.target.value,
    );

    setIsValid(!isExistingTeam);
    setEnteredName(event.target.value);
    dispatch(setTeamName(!isExistingTeam ? event.target.value.trim() : ''));
  };

  return (
    <FormControlProvider invalid={!isValid}>
      <Input
        id="team-name"
        name="team-name"
        placeholder={t('team.enterTeamName')}
        size="md"
        value={enteredName}
        maxLength={TEAM_NAME_MAX_LENGTH}
        onChange={handleInputChange}
      />

      <Show
        when={isValid}
        children={
          <FieldMessage id="team-name">{`${enteredName.trim().length}/${TEAM_NAME_MAX_LENGTH}`}</FieldMessage>
        }
        fallback={
          <FieldMessage id="team-name">
            {t('team.anotherTeamExists')}
          </FieldMessage>
        }
      />
    </FormControlProvider>
  );
};

export default TeamNameInput;
