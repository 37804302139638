import { put, takeLatest } from 'redux-saga/effects';

import { resetToast, setToast } from './slice';

function* setToastHandler() {
  yield put(resetToast());
}

export default function* toastSaga() {
  yield takeLatest(setToast, setToastHandler);
}
