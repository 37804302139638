import { ArrowLeftIcon } from '@pluralsight/icons';
import { Button, FlexContainer, FlexItem, Show } from '@pluralsight/react-ng';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import { updateLicenses } from '../../state/slice';
import useUpdateLicenses from '../../hooks/useUpdateLicenses';
import useDialogStep from '../../../../shared/dialog/useDialogStep';
import { selectProducts } from '../../../products/state/selectors';

interface IActionButtonsProps {
  closeModal: () => void;
}

const ConfirmUpdatesActionButtons: React.FC<IActionButtonsProps> = ({
  closeModal,
}) => {
  const { t } = useTranslations();
  const dispatch = useDispatch();
  const products = useSelector(selectProducts);
  const { assigned, revoked } = useUpdateLicenses();

  const { setStepBack } = useDialogStep();

  const isActive = assigned.length || revoked.length;

  const updateLicensesHandler = () => {
    dispatch(
      updateLicenses({
        productsToAdd: assigned,
        productsToRemove: revoked,
      }),
    );
    closeModal();
  };

  return (
    <FlexContainer justify="spaceBetween">
      <Show when={products.length !== 1}>
        <FlexItem>
          <Button
            className="action-buttons__button"
            size="lg"
            palette="neutral"
            usage="filled"
            aria-label={t('licenseDialog.ariaLabel.back')}
            onClick={setStepBack}
          >
            <ArrowLeftIcon width={20} />
            {t('common.actionButtons.back')}
          </Button>
        </FlexItem>
      </Show>

      <FlexContainer
        align="center"
        direction="row"
        justify="flexEnd"
        wrap="nowrap"
      >
        <Button
          className="action-buttons__button"
          size="lg"
          palette="action"
          usage="text"
          aria-label={t('licenseDialog.ariaLabel.cancel')}
          onClick={closeModal}
        >
          {t('common.actionButtons.cancel')}
        </Button>
        <Button
          size="lg"
          usage="filled"
          palette={isActive ? 'action' : 'neutral'}
          disabled={!isActive}
          onClick={updateLicensesHandler}
        >
          {t('licenseDialog.applyUpdates')}
        </Button>
      </FlexContainer>
    </FlexContainer>
  );
};

export default ConfirmUpdatesActionButtons;
