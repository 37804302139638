import type { AxiosInstance } from 'axios';
import type { Invite } from '../../people/state/interfaces';

const PLANS_PATH = '/v1/plans';

export class TeamPendingInvitesService {
  private httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  fetchTeamPendingInvites = async (
    planId: string,
    teamId: string,
  ): Promise<Invite[]> => {
    try {
      const response = await this.httpClient.get<Invite[]>(
        `${PLANS_PATH}/${planId}/invites`,
        {
          params: {
            teamId,
          },
        },
      );

      return response.data;
    } catch (error) {
      console.error('Error fetching team user invites:', error);

      throw error;
    }
  };
}
