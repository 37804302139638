import { FlexContainer, TextLink } from '@pluralsight/react-ng';
import { DownloadIcon } from '@pluralsight/icons';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import { generateCSV } from '../../../../shared/export-csv-data';

import './export-users-container.scss';

const templateColumns = [
  'PlanUserId',
  'Email',
  'Note',
  'Teams',
  'IsAdmin',
  'IsManager',
  'ManagerPermission',
  'TeamsManaged',
  'HasLicense',
  'IsPending',
  'FirstName',
  'LastName',
  'MemberStartDate',
];

const ExportUsersContainer = () => {
  const { t } = useTranslations();

  const handleDownload = () => {
    generateCSV(templateColumns, [], 'CSV blank file.csv');
  };

  return (
    <FlexContainer direction="col" className="export-users-container">
      <FlexContainer>
        <p>
          {t('people.exportCsvFile.exportCsvInstructions')}{' '}
          <TextLink
            href="https://help.pluralsight.com/"
            className="export-users-container__link"
            aria-label={t('people.importUsers.helpCenter')}
          >
            {t('people.importUsers.helpCenter')}
          </TextLink>
        </p>
      </FlexContainer>
      <FlexContainer
        direction="col"
        className="export-users-container__section"
      >
        <h2 className="export-users-container__subtitle">
          {t('people.exportCsvFile.exportOptions')}
        </h2>
        <FlexContainer direction="col">
          <div className="export-users-container__action">
            <DownloadIcon
              width={20}
              height={20}
              className="export-users-container__icon"
            />
            <TextLink
              onClick={() => handleDownload()}
              className="export-users-container__link"
            >
              {t('people.exportCsvFile.blankTemplate')}
            </TextLink>
          </div>
          <div className="export-users-container__action">
            <DownloadIcon
              width={20}
              height={20}
              className="export-users-container__icon"
            />
            <span className="export-users-container__text">
              <TextLink className="export-users-container__link">
                {t('people.exportCsvFile.listOfUsers')}
              </TextLink>{' '}
              {t('people.exportCsvFile.listOfUsersDescription')}
            </span>
          </div>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default ExportUsersContainer;
