// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-input-container {
  margin-bottom: var(--pando-spacing-8);
}
.search-input-container__input {
  width: 70%;
}
.search-input-container .pando-input__root {
  margin-top: 0;
}
@media screen and (min-width: 992px) {
  .search-input-container__input {
    width: 40%;
  }
}`, "",{"version":3,"sources":["webpack://./src/features/teams/components/search-input/search-input.scss"],"names":[],"mappings":"AACA;EACE,qCAAA;AAAF;AAEE;EACE,UAAA;AAAJ;AAGE;EACE,aAAA;AADJ;AAIE;EACE;IACE,UAAA;EAFJ;AACF","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.search-input-container {\n  margin-bottom: var(--pando-spacing-8);\n\n  &__input {\n    width: 70%;\n  }\n\n  .pando-input__root{\n    margin-top: 0;\n  }\n\n  @media screen and (min-width: 992px) {\n    &__input {\n      width: 40%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
