import { FlexContainer, IconButton, useToast } from '@pluralsight/react-ng';
import { PeopleIcon, UploadIcon } from '@pluralsight/icons';
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import RoleRestrictedComponent from '../../../../components/role-restricted-component/role-restricted-component';
import { USER_ROLE } from '../../../../shared/constants';
import CreateTeamDialog from '../../../create-team/components/create-team-dialog/create-team-dialog';
import {
  clearAssignedMembers,
  clearTeamName,
} from '../../../create-team/state/slice';
import { resetTeamDetails } from '../../../team/state/slice';
import { selectToast } from '../../../toast/state/selectors';
import TeamsTableContainer from '../teams-table-container/teams-table-container';
import './teams-container.scss';
import { setDialogStep } from '../../../../shared/dialog/state/slice';
import useScreenWidth from '../../../../shared/hooks/useScreenWidth';
import ImportTeamsDialog from '../impor-teams-dialog/import-teams-dialog';
import { ImportTabs } from '../../../../shared/interfaces';
import { setImportActiveTab, setImportFile } from '../../state/slice';

const TeamsContainer: React.FC = () => {
  const dispatch = useDispatch();
  const dialogRef = useRef<HTMLDialogElement>(null);
  const importDialogRef = useRef<HTMLDialogElement>(null);
  const toast = useSelector(selectToast);
  const { show } = useToast();
  const { t, language: currentLanguage } = useTranslations();
  const { isXS, isSM } = useScreenWidth();

  useEffect(() => {
    if (toast) {
      show({
        heading: toast.heading,
        palette: toast.palette,
        text: toast.text,
        onClose: () => {},
      });
    }
  }, [show, toast]);

  const showModal = () => {
    dispatch(resetTeamDetails());
    dispatch(setDialogStep(1));
    dialogRef.current?.showModal();
  };
  const closeModal = () => {
    dialogRef.current?.close();
    dispatch(setDialogStep(0));
    dispatch(clearTeamName());
    dispatch(clearAssignedMembers());
  };

  const openImportDialog = () => {
    dispatch(setDialogStep(1));
    importDialogRef.current?.showModal();
  };

  const closeImportDialog = () => {
    importDialogRef.current?.close();
    dispatch(setDialogStep(0));
    dispatch(setImportFile(null));
    dispatch(setImportActiveTab(ImportTabs.Import));
  };

  const evaluateWidthAddUsers = useCallback(
    () =>
      currentLanguage === 'fr' || currentLanguage === 'ge' ? '200px' : '150px',
    [currentLanguage],
  );

  return (
    <div className="teams-container">
      <div className="teams-container__header">
        <h1 className="teams-container__header-title">
          {t('teams.teamsLabel')}
        </h1>
        <RoleRestrictedComponent
          restrictedTo={[
            {
              roleName: USER_ROLE.SUPER_ADMIN,
              scope: 'organizationScope',
            },
            {
              roleName: USER_ROLE.SYSTEM_ADMIN,
              scope: 'organizationScope',
            },
            {
              roleName: USER_ROLE.PLAN_ADMIN,
              scope: 'planScope',
            },
          ]}
        >
          <FlexContainer gap="var(--pando-gap-md)" justify="flexEnd">
            <RoleRestrictedComponent
              restrictedTo={[
                {
                  roleName: USER_ROLE.SUPER_ADMIN,
                  scope: 'organizationScope',
                },
                {
                  roleName: USER_ROLE.SYSTEM_ADMIN,
                  scope: 'organizationScope',
                },
                {
                  roleName: USER_ROLE.PLAN_ADMIN,
                  scope: 'planScope',
                },
              ]}
            >
              <IconButton
                style={{ width: evaluateWidthAddUsers() }}
                size="lg"
                palette="action"
                usage="outline"
                onClick={openImportDialog}
                ariaLabel={t('people.peopleContainer.import')}
              >
                <UploadIcon />
                {!(isXS || isSM) && t('people.peopleContainer.import')}
              </IconButton>
            </RoleRestrictedComponent>
            <IconButton
              style={{ width: evaluateWidthAddUsers() }}
              size="lg"
              palette="action"
              usage="filled"
              onClick={showModal}
              ariaLabel={t('teams.createTeam')}
            >
              {!(isXS || isSM) ? t('teams.createTeam') : <PeopleIcon />}
            </IconButton>
          </FlexContainer>
        </RoleRestrictedComponent>
        <CreateTeamDialog ref={dialogRef} closeModal={closeModal} />
      </div>
      <TeamsTableContainer openDialog={showModal} />
      <ImportTeamsDialog ref={importDialogRef} closeModal={closeImportDialog} />
    </div>
  );
};

export default TeamsContainer;
