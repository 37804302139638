import { InviteStatus } from '../../people/state/interfaces';

import type { AxiosInstance } from 'axios';
import type { Invite } from '../../people/state/interfaces';
import type {
  ActionInvitationResponse,
  InvitationRequest,
  InvitationResponse,
  ResendInvitationRequest,
} from './interfaces';

const PLANS_PATH = '/v1/plans';

export class InvitationService {
  private httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  sendInvitation = async (
    planId: string,
    { emails, teamIds, roleIds, productIds }: InvitationRequest,
  ): Promise<InvitationResponse> => {
    const data = emails.map((email) => ({
      email,
      teamIds: teamIds.length ? teamIds : undefined,
      roleIds,
      productIds,
    }));

    try {
      const response = await this.httpClient.post<InvitationResponse>(
        `${PLANS_PATH}/${planId}/invites`,
        data,
      );

      return response.data;
    } catch (error) {
      console.error('Error sending invitations:', error);

      throw error;
    }
  };

  resendInvitation = async (
    planId: string,
    inviteId: string,
  ): Promise<Invite> => {
    try {
      const data: ResendInvitationRequest[] = [
        {
          id: inviteId,
          status: InviteStatus.Pending,
        },
      ];

      const response = await this.httpClient.patch<Invite>(
        `${PLANS_PATH}/${planId}/invites`,
        data,
      );

      return response.data;
    } catch (error) {
      console.error('Error re-sending invitation:', error);

      throw error;
    }
  };

  revokeInvitation = async (
    planId: string,
    inviteIds: string[],
  ): Promise<ActionInvitationResponse> => {
    try {
      const response = await this.httpClient.delete<ActionInvitationResponse>(
        `${PLANS_PATH}/${planId}/invites?ids=${inviteIds.join(',')}`,
      );

      return response.data;
    } catch (error) {
      console.error('Error revoking invitation:', error);

      throw error;
    }
  };
}
