import i18n from 'i18next';
import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';

import { setToast } from '../../toast/state/slice';
import { selectUserPlanId } from '../../user/state/selectors';
import { fetchInvites } from '../../people/state/slice';

import { InvitationService } from './services';
import {
  resendInvitation,
  resendInvitationFailure,
  resendInvitationSuccess,
  revokeInvitation,
  revokeInvitationFailure,
  revokeInvitationSuccess,
  sendInvitation,
  sendInvitationFailure,
  sendInvitationSuccess,
} from './slice';

import type {
  ActionInvitationResponse,
  InvitationRequest,
  InvitationResponse,
} from './interfaces';
import type { Invite } from '../../people/state/interfaces';
import type { AxiosInstance } from 'axios';
import type { PayloadAction } from '@reduxjs/toolkit';

function* sendInvitationHandler(action: PayloadAction<InvitationRequest>) {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');
    const invitationService = new InvitationService(httpClient);

    const planId: string | null = yield select(selectUserPlanId);

    if (!planId) throw new Error('No planId provided');

    const response: InvitationResponse = yield call(
      invitationService.sendInvitation,
      planId,
      action.payload,
    );

    if (response.failed.length) {
      yield put(
        sendInvitationFailure({
          response,
          error: i18n.t('invitation.toastMessages.invitationError'),
        }),
      );
    } else {
      yield put(sendInvitationSuccess(response));

      yield put(
        setToast({
          heading: i18n.t('invitation.toastMessages.emailSent', {
            count: action.payload.emails.length,
          }),
          palette: 'success',
          text: i18n.t('invitation.toastMessages.reviewInvitation'),
        }),
      );
    }

    yield put(fetchInvites());
  } catch (error) {
    if (error instanceof Error) {
      yield put(sendInvitationFailure({ error: error.message }));
    } else {
      yield put(
        sendInvitationFailure({
          error: i18n.t('invitation.toastMessages.invitationError'),
        }),
      );
    }

    yield put(
      setToast({
        heading: i18n.t('invitation.toastMessages.invitationUnsuccessful'),
        palette: 'danger',
        text: i18n.t('invitation.toastMessages.invitationError'),
      }),
    );
  }
}

function* resendInvitationHandler(action: PayloadAction<Invite>) {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');
    const invitationService = new InvitationService(httpClient);

    const planId: string | null = yield select(selectUserPlanId);

    if (!planId) throw new Error('No planId provided');

    const { id } = action.payload;
    const response: InvitationResponse = yield call(
      invitationService.resendInvitation,
      planId,
      id,
    );

    yield put(resendInvitationSuccess(response));

    // TODO (ferney-pena): This should be updated to reflect the number of emails sent once bulk functionality is implemented
    const count = 1;

    yield put(
      setToast({
        heading: i18n.t('invitation.toastMessages.resendSuccess', { count }),
        palette: 'success',
        text: ' ',
      }),
    );

    yield put(fetchInvites());
  } catch (error) {
    if (error instanceof Error) {
      yield put(resendInvitationFailure(error.message));
    } else {
      yield put(
        resendInvitationFailure(i18n.t('invitation.toastMessages.resendError')),
      );
    }

    yield put(
      setToast({
        heading: i18n.t('invitation.toastMessages.resendUnsuccessful'),
        palette: 'danger',
        text: i18n.t('invitation.toastMessages.resendError'),
      }),
    );
  }
}

function* revokeInvitationHandler(action: PayloadAction<Invite>) {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');
    const invitationService = new InvitationService(httpClient);

    const planId: string | null = yield select(selectUserPlanId);

    if (!planId) throw new Error('No planId provided');

    const revokeResponse: ActionInvitationResponse = yield call(
      invitationService.revokeInvitation,
      planId,
      [action.payload.id],
    );

    yield put(revokeInvitationSuccess(revokeResponse));

    // TODO (ferney-pena): This should be updated to reflect the number of emails revoked once bulk functionality is implemented
    const count = [action.payload.id].length;

    yield put(
      setToast({
        heading: i18n.t('invitation.toastMessages.revokeSuccess', { count }),
        palette: 'success',
        text: ' ',
      }),
    );

    yield put(fetchInvites());
  } catch (error) {
    if (error instanceof Error) {
      yield put(revokeInvitationFailure(error.message));
    } else {
      yield put(
        revokeInvitationFailure(i18n.t('invitation.toastMessages.revokeError')),
      );
    }

    yield put(
      setToast({
        heading: i18n.t('invitation.toastMessages.revokeUnsuccessful'),
        palette: 'danger',
        text: i18n.t('invitation.toastMessages.revokeError'),
      }),
    );
  }
}

export default function* invitationSagas() {
  yield takeLatest(sendInvitation, sendInvitationHandler);
  yield takeLatest(resendInvitation, resendInvitationHandler);
  yield takeLatest(revokeInvitation, revokeInvitationHandler);
}
