// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-menu {
  display: inline-block;
  margin-top: var(--pando-spacing-4);
  position: relative;
}
.custom-menu__select {
  border: 1px solid var(--pando-inky-blue-action-border-initial-dark);
  border-radius: 5px;
  height: 48px;
  margin-top: 8px;
  padding: var(--pando-spacing-4);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.custom-menu__options {
  border: 1px solid var(--pando-inky-blue-neutral-border-initial-dark);
  border-radius: 5px;
  margin-top: 2px;
  padding: 4px;
}
.custom-menu__option {
  border-radius: 5px;
  padding: 8px;
  display: flex;
  align-items: center;
}
.custom-menu__option:hover {
  background-color: var(--pando-inky-blue-neutral-background-hover-dark);
  cursor: pointer;
}
.custom-menu__label {
  color: var(--pando-inky-blue-neutral-text-initial-dark);
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  overflow: hidden;
}
.custom-menu__permissions {
  color: var(--pando-inky-blue-neutral-text-200-dark);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/custom-menu/custom-menu.scss"],"names":[],"mappings":"AACA;EACE,qBAAA;EACA,kCAAA;EACA,kBAAA;AAAF;AAEE;EACE,mEAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;EACA,+BAAA;EACA,WAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AAAJ;AAGE;EACE,oEAAA;EACA,kBAAA;EACA,eAAA;EACA,YAAA;AADJ;AAIE;EACE,kBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;AAFJ;AAII;EACE,sEAAA;EACA,eAAA;AAFN;AAME;EACE,uDAAA;EACA,uBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AAJJ;AAOE;EACE,mDAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AALJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.custom-menu {\n  display: inline-block;\n  margin-top: var(--pando-spacing-4);\n  position: relative;\n\n  &__select {\n    border: 1px solid var(--pando-inky-blue-action-border-initial-dark);\n    border-radius: 5px;\n    height: 48px;\n    margin-top: 8px;\n    padding: var(--pando-spacing-4);\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n\n  &__options {\n    border: 1px solid var(--pando-inky-blue-neutral-border-initial-dark);\n    border-radius: 5px;\n    margin-top: 2px;\n    padding: 4px;\n  }\n\n  &__option {\n    border-radius: 5px;\n    padding: 8px;\n    display: flex;\n    align-items: center;\n\n    &:hover {\n      background-color: var(--pando-inky-blue-neutral-background-hover-dark);\n      cursor: pointer;\n    }\n  }\n\n  &__label {\n    color: var(--pando-inky-blue-neutral-text-initial-dark);\n    text-overflow: ellipsis;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 150%;\n    overflow: hidden;\n  }\n\n  &__permissions {\n    color: var(--pando-inky-blue-neutral-text-200-dark);\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
