// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nested-team-step__checkbox {
  margin: 12px 0;
}
.nested-team-step .pando-radio__container {
  border-color: var(--pando-inky-blue-action-border-initial-dark);
}
.nested-team-step__radio-container {
  margin: 16px 0;
  border-top: 1px solid var(--pando-inky-blue-neutral-border-100-dark);
}
.nested-team-step__radio {
  height: 40px;
  border-bottom: 1px solid var(--pando-inky-blue-neutral-border-100-dark);
}
.nested-team-step__team {
  color: var(--pando-colors-neutral-text-300);
  font-family: var(--font-family-body, "PS TT Commons");
  opacity: 0.5;
}
.nested-team-step__team-search {
  font-style: italic;
  color: var(--pando-inky-blue-action-border-initial-dark);
}`, "",{"version":3,"sources":["webpack://./src/features/create-team/components/nested-team-step/nested-team-step.scss"],"names":[],"mappings":"AAEE;EACE,cAAA;AADJ;AAKI;EACE,+DAAA;AAHN;AAOE;EACE,cAAA;EACA,oEAAA;AALJ;AAQE;EACE,YAAA;EACA,uEAAA;AANJ;AASE;EACE,2CAAA;EACA,qDAAA;EACA,YAAA;AAPJ;AASI;EACE,kBAAA;EACA,wDAAA;AAPN","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.nested-team-step {\n  &__checkbox {\n    margin: 12px 0;\n  }\n\n  .pando-radio {\n    &__container {\n      border-color: var(--pando-inky-blue-action-border-initial-dark);\n    }\n  }\n\n  &__radio-container {\n    margin: 16px 0;\n    border-top: 1px solid var(--pando-inky-blue-neutral-border-100-dark);\n  }\n\n  &__radio {\n    height: 40px;\n    border-bottom: 1px solid var(--pando-inky-blue-neutral-border-100-dark);\n  }\n\n  &__team {\n    color: var(--pando-colors-neutral-text-300);\n    font-family: var(--font-family-body, \"PS TT Commons\");\n    opacity: 0.5;\n\n    &-search {\n      font-style: italic;\n      color: var(--pando-inky-blue-action-border-initial-dark);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
