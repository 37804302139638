import { call, getContext, put, takeLatest } from 'redux-saga/effects';

import { PlanService } from './services';
import { fetchPlan, fetchPlanFailure, fetchPlanSuccess } from './slice';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { AxiosInstance } from 'axios';
import type { IPlan, IPlanId } from './interfaces';

function* fetchPlanHandler(action: PayloadAction<IPlanId>) {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');

    if (!httpClient) {
      throw new Error('HTTP client is not available');
    }

    const planService = new PlanService(httpClient);
    const response: IPlan = yield call(planService.fetchPlan, action.payload);

    yield put(fetchPlanSuccess(response));
  } catch (error: any) {
    yield put(fetchPlanFailure(error.message || 'Unknown error'));
  }
}

export default function* planSagas() {
  yield takeLatest(fetchPlan.type, fetchPlanHandler);
}
