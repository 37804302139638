import { useEffect } from 'react';
import { FlexContainer } from '@pluralsight/react-ng';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import useRoleOptions from '../../../../shared/hooks/useRoleOptions';
import { selectOrgId } from '../../../plans/state/selectors';
import { fetchRoles } from '../../../roles/state/slice';
import { selectEditUser, selectUserRoles } from '../../state/selectors';
import './role-confirmation-step.scss';
import ConfirmationCard from '../confirmation-card/confirmation-card';

const RoleConfirmationStep = () => {
  const { t } = useTranslations();
  const dispatch = useDispatch();
  const orgId = useSelector(selectOrgId);
  const assignedRoles = useSelector(selectUserRoles);
  const user = useSelector(selectEditUser);

  const { defaultManagerOption, defaultAdminOption, optionsByPermissions } =
    useRoleOptions();

  const { managerOptions, adminOptions } = optionsByPermissions();

  const assignedManager =
    managerOptions.find((option) => assignedRoles.includes(option.value)) ||
    defaultManagerOption;

  const assignedAdmin =
    adminOptions.find((option) => assignedRoles.includes(option.value)) ||
    defaultAdminOption;

  const currentRoles = user?.roles.map((role) => role.id);

  const currentManager = managerOptions.find((option) =>
    currentRoles?.includes(option.value),
  );
  const currentAdmin = adminOptions.find((option) =>
    currentRoles?.includes(option.value),
  );

  const renderManagerChange = () => {
    if (
      currentManager &&
      currentManager?.value !== assignedManager.value &&
      assignedManager.value === defaultManagerOption.value
    ) {
      return t('people.updateRolesDialog.confirmationStep.managerWarn');
    }

    return null;
  };

  const renderAdminChange = () => {
    if (
      currentAdmin &&
      currentAdmin?.value !== assignedAdmin.value &&
      assignedAdmin.value === defaultAdminOption.value
    ) {
      return t('people.updateRolesDialog.confirmationStep.adminWarn');
    }

    return null;
  };

  const assignedManagerDescription = `${
    assignedManager.value !== defaultManagerOption.value
      ? t('common.roleStep.userCan') + assignedManager.permissions.toLowerCase()
      : assignedManager.permissions
  }`;

  useEffect(() => {
    dispatch(fetchRoles(orgId));
  }, [orgId, dispatch]);

  return (
    <FlexContainer
      direction="col"
      gap="var(--pando-gap-lg)"
      className="role-confirmation-step__container"
    >
      <ConfirmationCard
        title={t('common.roleStep.standardRoleLabel')}
        role={t('common.roleStep.standardRole')}
        description={t('common.roleStep.standardRolePermissions')}
      />
      <ConfirmationCard
        title={t('common.roleStep.managerRoleLabel')}
        role={assignedManager.name}
        description={assignedManagerDescription}
        warn={renderManagerChange()}
      />
      <ConfirmationCard
        title={t('common.roleStep.adminRoleLabel')}
        role={assignedAdmin.name}
        description={assignedAdmin.permissions}
        warn={renderAdminChange()}
      />
    </FlexContainer>
  );
};

export default RoleConfirmationStep;
