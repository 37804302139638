import i18n from 'i18next';
import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';

import { setToast } from '../../toast/state/slice';
import { selectUserPlanId } from '../../user/state/selectors';
import { fetchTeams } from '../../teams/state/slice';

import { TeamService } from './services';
import {
  fetchTeam,
  fetchTeamFailure,
  fetchTeamSuccess,
  patchTeam,
  patchTeamFailure,
  patchTeamSuccess,
} from './slice';

import type { Team, TeamPatchAction, TeamResponseItem } from './interfaces';
import type { AxiosInstance } from 'axios';
import type { PayloadAction } from '@reduxjs/toolkit';

type TeamManager = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  psId: string;
};

// TODO (NGP-5435): review and delete custom type in favor of `TeamResponseItem`
type TeamResponseCustom = TeamResponseItem & {
  managers: TeamManager[];
};

function* fetchTeamHandler(action: PayloadAction<string>) {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');
    const teamService = new TeamService(httpClient);

    const planId: string | null = yield select(selectUserPlanId);

    if (!planId) throw new Error('No planId provided');

    const teamId = action.payload;
    const team: TeamResponseCustom = yield call(
      teamService.fetchTeam,
      planId,
      teamId,
    );

    yield put(
      fetchTeamSuccess({
        id: team.id,
        name: team.name,
        usersCount: team.usersCount,
        allUsersCount: team.allUsersCount,
        managersCount: team.managersCount,
        // TODO (NGP-5435): should directly use `managerIds` from the response
        managerIds: team.managers
          ? team.managers.map((manager: TeamManager) => manager.psId)
          : [],
        nestedTeams: 0,
      }),
    );
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetchTeamFailure(error.message));
    } else {
      yield put(fetchTeamFailure('An unknown error occurred'));
    }
  }
}

function* patchTeamHandler(action: PayloadAction<TeamPatchAction>) {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');
    const teamService = new TeamService(httpClient);

    const planId: string | null = yield select(selectUserPlanId);

    if (!planId) throw new Error('No planId provided');

    const teamId = action.payload.id;
    const patch = { name: action.payload.name };
    const team: Team = yield call(teamService.patchTeam, planId, teamId, patch);

    yield put(
      patchTeamSuccess({
        id: team.id,
        name: team.name,
        usersCount: team.usersCount,
        allUsersCount: team.allUsersCount,
        managersCount: team.managersCount,
        managerIds: [],
        nestedTeams: 0,
      }),
    );
    yield put(
      setToast({
        heading: '',
        palette: 'success',
        text: i18n.t('team.toastMessages.updateTeamNameSuccessText'),
      }),
    );

    if (action.payload.shouldRefetchTeams) {
      yield put(fetchTeams());
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(patchTeamFailure(error.message));
      yield put(
        setToast({
          heading: i18n.t('team.toastMessages.updateTeamNameFailure'),
          palette: 'danger',
          text: i18n.t('team.toastMessages.updateTeamNameFailureText'),
        }),
      );
    } else {
      yield put(patchTeamFailure('An unknown error occurred'));

      yield put(
        setToast({
          heading: i18n.t('team.toastMessages.updateTeamNameFailure'),
          palette: 'danger',
          text: i18n.t('team.toastMessages.updateTeamNameFailureText'),
        }),
      );
    }
  }
}

export default function* teamSagas() {
  yield takeLatest(fetchTeam.type, fetchTeamHandler);
  yield takeLatest(patchTeam.type, patchTeamHandler);
}
