import { createSelector } from 'reselect';

import { InviteStatus } from '../../people/state/interfaces';

import type { Invite } from '../../people/state/interfaces';
import type { AppState } from '../../../store';
import type { TeamPendingInvitesState } from './interfaces';

const selectTeamPendingInvitesState = (state: AppState) =>
  state.teamPendingInvites;

export const selectTeamPendingInvitesList = createSelector(
  [selectTeamPendingInvitesState],
  (teamPendingInvites: TeamPendingInvitesState): Invite[] =>
    teamPendingInvites.list.filter(
      (invite) => invite.status === InviteStatus.Pending,
    ),
);
