// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subscription-item {
  border: 1px solid var(--pando-inky-blue-neutral-border-100-dark, #2d2d55);
  background: var(--pando-inky-blue-neutral-surface-200-dark, #242145);
  border-radius: var(--pando-radii-md);
  padding: var(--pando-spacing-5) var(--pando-spacing-4);
}
.subscription-item__header {
  margin-bottom: var(--pando-spacing-4);
}
.subscription-item__title {
  font-size: var(--pando-font-sizes-2xl);
  line-height: 150%;
  font-weight: 600;
}
.subscription-item__details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--pando-spacing-4);
  width: 60%;
}
@media (max-width: 767.98px) {
  .subscription-item__details {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }
}
.subscription-item__field {
  display: flex;
  flex-direction: column;
}
.subscription-item__label {
  color: var(--pando-colors-neutral-text-300, #f3f3f8);
  font-size: var(--pando-font-sizes-sm);
  font-weight: 500;
  line-height: 150%;
}
.subscription-item__value {
  color: var(--pando-colors-neutral-text-200, #a5aacf);
  font-size: var(--pando-font-sizes-lg);
  font-weight: 700;
  line-height: 150%;
}`, "",{"version":3,"sources":["webpack://./src/features/plan-settings/components/subscriptions/subscription-item/subscription-item.scss"],"names":[],"mappings":"AACA;EACE,yEAAA;EACA,oEAAA;EACA,oCAAA;EACA,sDAAA;AAAF;AAEE;EACE,qCAAA;AAAJ;AAGE;EACE,sCAAA;EACA,iBAAA;EACA,gBAAA;AADJ;AAIE;EACE,aAAA;EACA,qCAAA;EACA,2BAAA;EACA,UAAA;AAFJ;AAKI;EAPF;IAQI,qCAAA;IACA,WAAA;EAFJ;AACF;AAKE;EACE,aAAA;EACA,sBAAA;AAHJ;AAME;EACE,oDAAA;EACA,qCAAA;EACA,gBAAA;EACA,iBAAA;AAJJ;AAOE;EACE,oDAAA;EACA,qCAAA;EACA,gBAAA;EACA,iBAAA;AALJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.subscription-item {\n  border: 1px solid var(--pando-inky-blue-neutral-border-100-dark, #2d2d55);\n  background: var(--pando-inky-blue-neutral-surface-200-dark, #242145);\n  border-radius: var(--pando-radii-md);\n  padding: var(--pando-spacing-5) var(--pando-spacing-4);\n\n  &__header {\n    margin-bottom: var(--pando-spacing-4);\n  }\n\n  &__title {\n    font-size: var(--pando-font-sizes-2xl);\n    line-height: 150%;\n    font-weight: 600;\n  }\n\n  &__details {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    gap: var(--pando-spacing-4);\n    width: 60%;\n\n    // small device breakpoint\n    @media (max-width: 767.98px) {\n      grid-template-columns: repeat(1, 1fr);\n      width: 100%;\n    }\n  }\n\n  &__field {\n    display: flex;\n    flex-direction: column;\n  }\n\n  &__label {\n    color: var(--pando-colors-neutral-text-300, #f3f3f8);\n    font-size: var(--pando-font-sizes-sm);\n    font-weight: 500;\n    line-height: 150%;\n  }\n\n  &__value {\n    color: var(--pando-colors-neutral-text-200, #a5aacf);\n    font-size: var(--pando-font-sizes-lg);\n    font-weight: 700;\n    line-height: 150%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
