import './sso-configuration.scss';
import { FlexContainer, useToast } from '@pluralsight/react-ng';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';
import { ROUTES } from '@ps-fe-plan-management/shared/constants';

import {
  selectSingleSsoConnectionClientId,
  selectSingleSsoConnectionError,
  selectSingleSsoConnectionName,
  selectSingleSsoConnectionOidcMetadata,
  selectSingleSsoConnectionSigningCert,
} from '../sso-conections/state/selectors';
import {
  fetchSsoConnection,
  resetSsoConnection,
} from '../sso-conections/state/slice';
import { selectOrgId } from '../../../plans/state/selectors';
import { Tabs } from '../../../../pages/account-settings/account-settings';

import BasicInformationForm from './basic-information-form/basic-information-form.component';
import SsoConfigurationForm from './sso-configuration-form/sso-configuration-form';
import { CLIENT_SECRET } from './state/interfaces';
import {
  selectSsoConfigurationError,
  selectSsoConfigurationSuccess,
} from './state/selectors';
import {
  resetSsoConfiguration,
  submitSsoConfiguration,
  updateSsoConfiguration,
} from './state/slice';

import type {
  IOidcConfigurationPayload,
  ISamlConfigurationPayload,
  TSsoConfigurationPayload,
} from './state/interfaces';
import type { FormEvent } from 'react';

const SsoConfiguration = () => {
  const { connectionId } = useParams();
  const navigate = useNavigate();
  const success = useSelector(selectSsoConfigurationSuccess);
  const error = useSelector(selectSsoConfigurationError);
  const connectionName = useSelector(selectSingleSsoConnectionName);
  const clientId = useSelector(selectSingleSsoConnectionClientId);
  const oidcMetadata = useSelector(selectSingleSsoConnectionOidcMetadata);
  const signingCert = useSelector(selectSingleSsoConnectionSigningCert);
  const singleSsoConnectionError = useSelector(selectSingleSsoConnectionError);
  const orgId = useSelector(selectOrgId);
  const dispatch = useDispatch();
  const formRef = useRef<HTMLFormElement>(null);
  const { show } = useToast();
  const { t } = useTranslations();

  useEffect(() => {
    if (connectionId && !connectionName && !singleSsoConnectionError) {
      dispatch(fetchSsoConnection({ id: connectionId }));
    }

    return () => {
      if (connectionName) {
        dispatch(resetSsoConnection());
      }
    };
  }, [singleSsoConnectionError, connectionName, connectionId, dispatch]);

  useEffect(() => {
    if (success || error) {
      const text = success
        ? t('sso.ssoToastMessages.ssoConnectionToastDeleteSuccess')
        : (error ?? t('sso.ssoToastMessages.ssoConnectionToastError'));

      show({
        text,
        palette: success ? 'success' : 'danger',
        onClose: () => {},
      });

      if (success) {
        navigate(ROUTES.ACCOUNT_SETTINGS, {
          state: { currentTab: Tabs.SSO },
          replace: true,
        });
        dispatch(resetSsoConfiguration());
      }
    }
  }, [success, error, show, dispatch, navigate, t]);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (formRef.current) {
      const formData = new FormData(formRef.current);
      const ssoConfigurationPayload = {
        id: connectionId,
        connectionName,
        orgId,
      } as TSsoConfigurationPayload;

      formData.forEach((value, field) => {
        const key = field as keyof TSsoConfigurationPayload;

        ssoConfigurationPayload[key] =
          value as TSsoConfigurationPayload[keyof TSsoConfigurationPayload];
      });

      /* This condition is true when the user is editing the OIDC connection and has not uploaded a different metadata file.
      In this case, we keep the previous value. */
      if (oidcMetadata && !('metadata' in ssoConfigurationPayload)) {
        (ssoConfigurationPayload as IOidcConfigurationPayload).metadata =
          oidcMetadata;
      } else if (
        /* And this condition is true when the user is editing the SAMLP connection
        and has not uploaded a different signing certificate file.  In this case, we keep the previous value. */
        signingCert &&
        !('signingCert' in ssoConfigurationPayload)
      ) {
        (
          ssoConfigurationPayload as unknown as ISamlConfigurationPayload
        ).signingCert = signingCert;
      }

      if (ssoConfigurationPayload) {
        return connectionId && connectionName
          ? dispatch(updateSsoConfiguration(ssoConfigurationPayload))
          : dispatch(submitSsoConfiguration(ssoConfigurationPayload));
      }
    }
  };

  return (
    <FlexContainer direction="col" className="sso-config">
      <FlexContainer
        direction="col"
        gap="var(--pando-gap-xs)"
        className="sso-config__header"
      >
        <h1 className="sso-config__title">
          {t('sso.ssoConfiguration.ssoConfiguration')}
        </h1>
        <p className="sso-config__subtitle">
          {t('sso.ssoConfiguration.customizeYourExperience')}
        </p>
      </FlexContainer>
      <form className="sso-config__form" ref={formRef} onSubmit={handleSubmit}>
        <BasicInformationForm
          connectionName={connectionName}
          clientId={clientId}
          clientSecret={CLIENT_SECRET}
        />
        <SsoConfigurationForm />
      </form>
    </FlexContainer>
  );
};

export default SsoConfiguration;
