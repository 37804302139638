import { USER_ROLE } from './constants';

import type { User } from '../features/user/state/interfaces';

const getActingUserRoleNames = (actingUser: User): USER_ROLE[] | null => {
  const permissionScope = actingUser.permissionScope?.[0];

  if (
    !permissionScope ||
    !permissionScope.plans?.length ||
    !permissionScope.plans[0].roles?.length
  ) {
    return null;
  }

  return permissionScope.plans[0].roles.map(
    (role) => role.roleName as USER_ROLE,
  );
};

const getHighestPrivilegeRole = (
  actingUserRoleNames: USER_ROLE[],
): USER_ROLE | null => {
  // Define the hierarchical order of privileged roles.
  const privilegedRoleHierarchy: USER_ROLE[] = [
    USER_ROLE.SYSTEM_ADMIN,
    USER_ROLE.SUPER_ADMIN,
    USER_ROLE.PLAN_ADMIN,
    USER_ROLE.MANAGER_FULL,
    USER_ROLE.MANAGER_LIMITED,
    USER_ROLE.MANAGER_BASIC,
    USER_ROLE.SSO_ADMIN,
    USER_ROLE.API_DEVELOPER,
    USER_ROLE.B2B_LEARNER,
    USER_ROLE.B2C_LEARNER,
  ];

  for (const role of privilegedRoleHierarchy) {
    if (actingUserRoleNames.includes(role)) {
      return role;
    }
  }

  return null;
};

/**
 * Determines whether the acting user has the privileges to update the target user based on their roles.
 */
export const calculatePrivilegedRole = (
  actingUser: User | null,
  targetUserRoles: USER_ROLE[] | null,
): boolean => {
  // Map each privileged role to the roles they are permitted to update.
  // A value of null indicates no restrictions; an empty array indicates no privileges.
  const roleUpdatePermissions: Record<USER_ROLE, USER_ROLE[] | null> = {
    [USER_ROLE.SYSTEM_ADMIN]: null,
    [USER_ROLE.SUPER_ADMIN]: null,
    [USER_ROLE.PLAN_ADMIN]: [
      USER_ROLE.PLAN_ADMIN,
      USER_ROLE.MANAGER_FULL,
      USER_ROLE.MANAGER_LIMITED,
      USER_ROLE.MANAGER_BASIC,
      USER_ROLE.API_DEVELOPER,
      USER_ROLE.B2B_LEARNER,
      USER_ROLE.B2C_LEARNER,
      USER_ROLE.SSO_ADMIN,
    ],
    [USER_ROLE.MANAGER_FULL]: [],
    [USER_ROLE.MANAGER_LIMITED]: [],
    [USER_ROLE.MANAGER_BASIC]: [],
    [USER_ROLE.SSO_ADMIN]: [],
    [USER_ROLE.API_DEVELOPER]: [],
    [USER_ROLE.B2B_LEARNER]: [],
    [USER_ROLE.B2C_LEARNER]: [],
  };

  if (!actingUser || !targetUserRoles || targetUserRoles.length === 0) {
    return false;
  }

  const actingUserRoleNames = getActingUserRoleNames(actingUser);

  if (!actingUserRoleNames) {
    return false;
  }

  const highestPrivilegeRole = getHighestPrivilegeRole(actingUserRoleNames);

  if (!highestPrivilegeRole) {
    return false; // Acting user has no permissions.
  }

  const allowedRoles = roleUpdatePermissions[highestPrivilegeRole];

  if (allowedRoles === null) {
    return true; // No restrictions apply.
  }

  if (allowedRoles.length === 0) {
    return false; // Acting user has no privileges.
  }

  // The acting user has privilege if all of the target user's roles are within the allowed roles.
  return targetUserRoles.every((role) => allowedRoles.includes(role));
};
