import { ArrowLeftIcon } from '@pluralsight/icons';
import { Button, FlexContainer, FlexItem } from '@pluralsight/react-ng';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import { USER_ROLE } from '../../../../shared/constants';
import { selectMappedRoles } from '../../../roles/state/selectors';
import {
  selectAssignedProducts,
  selectEmailsList,
  selectRoles,
  selectTeamIds,
} from '../../state/selectors';
import { sendInvitation } from '../../state/slice';
import useProducts from '../../../products/hooks/useProducts';
import useDialogStep from '../../../../shared/dialog/useDialogStep';
import './action-buttons.scss';

interface IActionButtonsProps {
  closeModal: () => void;
}

const ConfirmStepActionButtons: React.FC<IActionButtonsProps> = ({
  closeModal,
}) => {
  const { t } = useTranslations();
  const dispatch = useDispatch();
  const emails = useSelector(selectEmailsList);
  const teamIds = useSelector(selectTeamIds);
  const roleIds = useSelector(selectRoles);
  const roles = useSelector(selectMappedRoles);
  const productIds = useSelector(selectAssignedProducts);
  const isBaseProductSelected = useProducts(productIds);

  const learnerId = roles.get(USER_ROLE.B2B_LEARNER);

  const inviteRoles = learnerId ? [learnerId, ...roleIds] : roleIds;

  const inviteProducts = isBaseProductSelected ? productIds : [];

  const { setStepBack } = useDialogStep();

  const submitEmails = () => {
    dispatch(
      sendInvitation({
        emails,
        teamIds,
        roleIds: inviteRoles,
        productIds: inviteProducts,
      }),
    );
    closeModal();
  };

  return (
    <FlexContainer justify="spaceBetween">
      <FlexItem>
        <Button
          className="action-buttons__button"
          size="lg"
          palette="neutral"
          usage="filled"
          onClick={setStepBack}
        >
          <ArrowLeftIcon width={20} />
          {t('common.actionButtons.back')}
        </Button>
      </FlexItem>

      <FlexContainer
        align="center"
        direction="row"
        justify="flexEnd"
        wrap="nowrap"
      >
        <Button
          className="action-buttons__button"
          size="lg"
          palette="action"
          usage="text"
          onClick={closeModal}
        >
          {t('common.actionButtons.cancel')}
        </Button>
        <Button
          size="lg"
          palette="action"
          usage="filled"
          onClick={submitEmails}
        >
          {t('invitation.actionButtons.sendInvitations')}
        </Button>
      </FlexContainer>
    </FlexContainer>
  );
};

export default ConfirmStepActionButtons;
