import { USER_ROLE } from '../../../../shared/constants';

interface Permission {
  roleName: USER_ROLE;
  scope: 'organizationScope' | 'planScope' | 'teamScope';
}

export const updateLicenseRestrictedTo: Permission[] = [
  {
    roleName: USER_ROLE.SYSTEM_ADMIN,
    scope: 'organizationScope',
  },
  {
    roleName: USER_ROLE.SUPER_ADMIN,
    scope: 'organizationScope',
  },
  {
    roleName: USER_ROLE.PLAN_ADMIN,
    scope: 'planScope',
  },
  {
    roleName: USER_ROLE.MANAGER_FULL,
    scope: 'planScope',
  },
];

export const updateRolesRestrictedTo: Permission[] = [
  {
    roleName: USER_ROLE.SYSTEM_ADMIN,
    scope: 'organizationScope',
  },
  {
    roleName: USER_ROLE.SUPER_ADMIN,
    scope: 'planScope',
  },
  {
    roleName: USER_ROLE.SUPER_ADMIN,
    scope: 'organizationScope',
  },
  {
    roleName: USER_ROLE.PLAN_ADMIN,
    scope: 'planScope',
  },
];
