import { createSlice } from '@reduxjs/toolkit';

import { EMAIL_PATTERN } from '../../../shared/constants';

import type {
  InvitationRequest,
  InvitationResponse,
  InvitationState,
} from './interfaces';
import type { PayloadAction } from '@reduxjs/toolkit';

const INITIAL_STATE: InvitationState = {
  actionComplete: false,
  emails: [],
  existingAccountEmails: [],
  invalidEmails: [],
  roleIds: [],
  teamIds: [],
  productIds: [],
  loading: false,
  error: null,
};

const invitationSlice = createSlice({
  name: 'invitation',
  initialState: INITIAL_STATE,
  reducers: {
    sendInvitation: (state, _action: PayloadAction<InvitationRequest>) => {
      state.error = null;
      state.loading = true;
    },
    sendInvitationSuccess: (
      state,
      _action: PayloadAction<InvitationResponse>,
    ) => {
      state.loading = false;
    },
    sendInvitationFailure: (
      state,
      action: PayloadAction<{ response?: InvitationResponse; error: string }>,
    ) => {
      state.loading = false;
      state.error = action.payload.error;
      state.existingAccountEmails = action.payload?.response?.failed || [];
    },
    clearEmails: (state) => {
      state.emails = [];
      state.invalidEmails = [];
    },
    clearExistingAccountEmails: (state) => {
      state.existingAccountEmails = [];
    },
    addValidEmails: (state, action: PayloadAction<string[]>) => {
      state.emails = [...action.payload, ...state.emails];
    },
    addInvalidEmails: (state, action: PayloadAction<string[]>) => {
      state.invalidEmails = [...action.payload, ...state.invalidEmails];
    },
    deleteEmail: (state, action: PayloadAction<string>) => {
      if (EMAIL_PATTERN.test(action.payload)) {
        state.emails = state.emails.filter((value) => value !== action.payload);
      } else {
        state.invalidEmails = state.invalidEmails.filter(
          (value) => value !== action.payload,
        );
      }
    },

    assignRole: (state, action: PayloadAction<string[]>) => {
      state.roleIds = action.payload.filter(Boolean);
    },
    clearAssignedRoles: (state) => {
      state.roleIds = [];
    },

    assignTeams: (state, action: PayloadAction<string>) => {
      const index = state.teamIds.indexOf(action.payload);

      state.teamIds = state.teamIds.includes(action.payload)
        ? [...state.teamIds.slice(0, index), ...state.teamIds.slice(index + 1)]
        : [...state.teamIds, action.payload];
    },
    clearAssignedTeams: (state) => {
      state.teamIds = [];
    },

    assignLicenses: (state, action: PayloadAction<string>) => {
      const index = state.productIds.indexOf(action.payload);

      state.productIds = state.productIds.includes(action.payload)
        ? [
            ...state.productIds.slice(0, index),
            ...state.productIds.slice(index + 1),
          ]
        : [...state.productIds, action.payload];
    },
    clearAssignedLicenses: (state) => {
      state.productIds = [];
    },

    resendInvitation: (state) => {
      state.actionComplete = false;
      state.error = null;
      state.loading = true;
    },
    resendInvitationSuccess: (
      state,
      _action: PayloadAction<InvitationResponse>,
    ) => {
      state.actionComplete = true;
      state.loading = false;
    },
    resendInvitationFailure: (state, action: PayloadAction<string>) => {
      state.actionComplete = false;
      state.error = action.payload;
      state.loading = false;
    },
    revokeInvitation: (state) => {
      state.actionComplete = false;
      state.error = null;
      state.loading = true;
    },
    revokeInvitationSuccess: (state) => {
      state.actionComplete = true;
      state.loading = false;
    },
    revokeInvitationFailure: (state, action: PayloadAction<string>) => {
      state.actionComplete = false;
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  sendInvitation,
  sendInvitationSuccess,
  sendInvitationFailure,
  clearEmails,
  clearExistingAccountEmails,
  addValidEmails,
  addInvalidEmails,
  deleteEmail,
  assignRole,
  clearAssignedRoles,
  assignTeams,
  clearAssignedTeams,
  assignLicenses,
  clearAssignedLicenses,
  resendInvitation,
  resendInvitationSuccess,
  resendInvitationFailure,
  revokeInvitation,
  revokeInvitationSuccess,
  revokeInvitationFailure,
} = invitationSlice.actions;

export default invitationSlice.reducer;
