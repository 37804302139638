import './users-table.scss';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisVerticalIcon,
} from '@pluralsight/icons';
import {
  Avatar,
  Caption,
  Checkbox,
  FlexContainer,
  For,
  FormControlProvider,
  IconButton,
  Option,
  Select,
  Show,
  Table,
  TBody,
  TD,
  TH,
  THead,
  TR,
} from '@pluralsight/react-ng';
import React, { useEffect, useRef, useState } from 'react';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import { TABLES, USER_ROLE } from '../../shared/constants';
import { SortingDirection } from '../../shared/interfaces';
import { createPaginationOptions, sortArray } from '../../shared/utils';
import SortingIconButton from '../table/sorting-icon-button';
import HintPopover from '../../components/hint-popover/hint-popover';
import RoleRestrictedComponent from '../role-restricted-component/role-restricted-component';
import OptionsDropdown from '../options-dropdown/options-dropdown';
import useIndividualMenuStatuses from '../../shared/hooks/useIndividualMenuStatuses';
import TextHighlight from '../../features/teams/components/text-highlight/text-highlight';

import type { OptionsDropdownRef } from '../options-dropdown/options-dropdown';
import type { KeyBoardActions } from '../../shared/helpers/handle-menu-keyboard-navigation';
import type {
  Sorting,
  TableColumn,
  UsersTableItem,
} from '../../shared/interfaces';

interface UsersTableProps {
  columns: TableColumn<UsersTableItem>[];
  people: UsersTableItem[];
  searchFilter?: string;
  dropDownOptions: (
    member: UsersTableItem,
    actions: KeyBoardActions,
  ) => JSX.Element;
}

// TODO (przem-wierzbicki): REFACTOR!!! Use generic Customizable Table Component instead.
const UsersTable: React.FC<UsersTableProps> = React.memo(
  ({ columns, people, searchFilter, dropDownOptions }) => {
    const { t } = useTranslations();
    const optionsDropdownRefs = useRef<
      Record<string, OptionsDropdownRef | null>
    >({});

    const [pagination, setPagination] = useState({
      currPage: 0,
      pageSize: TABLES.PAGINATION_OPTIONS[0],
    });

    const [sorting, setSorting] = useState<Sorting<UsersTableItem>>({
      column: 'name',
      direction: SortingDirection.DESC,
    });

    const paginated = (users: UsersTableItem[]) => {
      return users.slice(
        pagination.currPage * pagination.pageSize,
        (pagination.currPage + 1) * pagination.pageSize,
      );
    };

    const [_users, _setUsers] = useState<UsersTableItem[]>(() =>
      paginated(sortArray<UsersTableItem>([...people], sorting)),
    );

    const onChangeSorting = (column: keyof UsersTableItem) => {
      const isSortingDirectionDesc =
        sorting.column === column &&
        sorting.direction === SortingDirection.DESC;
      const direction = isSortingDirectionDesc
        ? SortingDirection.ASC
        : SortingDirection.DESC;

      setSorting({ column, direction });
    };

    const onChangePageSize = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const currPage = 0;
      const pageSize = parseInt(e.target.value);

      setPagination({ ...pagination, currPage, pageSize });
    };

    const onChangePage = (pageChange: number) => {
      const currPage = pagination.currPage + pageChange;

      if (currPage < 0 || currPage > people.length / pagination.pageSize)
        return;

      setPagination({ ...pagination, currPage });
    };

    const handleDropdownClose = (userId: string) => {
      const dropdown = optionsDropdownRefs.current[userId];

      dropdown?.onClose();
      dropdown?.onMoreActionsButtonFocus();
    };

    useEffect(() => {
      _setUsers(paginated(sortArray<UsersTableItem>([...people], sorting)));
    }, [people, sorting, pagination]);

    const currFrom = pagination.currPage * pagination.pageSize + 1;
    const currTo =
      (pagination.currPage + 1) * pagination.pageSize <= people.length
        ? (pagination.currPage + 1) * pagination.pageSize
        : people.length;

    const isPaginationDisabled = people.length <= TABLES.PAGINATION_OPTIONS[0];
    const paginationOptions = createPaginationOptions(people.length); // TODO (okyry) get count of users from the API

    const { individualMenuStatuses, toggleMenuStatuses } =
      useIndividualMenuStatuses(people);

    return (
      <div className="users-table">
        <Caption type="base" className="users-table__caption">
          {t('usersTable.clickingOnATableCell')}
        </Caption>
        <FormControlProvider>
          <Table className="users-table__table" hover="highlight" type="base">
            <THead className="users-table__head" hover="highlight" type="base">
              <TR header hover="highlight" type="base">
                <TH hover="highlight" type="condensed" />
                <For
                  each={columns}
                  children={(column) => {
                    return (
                      <TH hover="highlight" type="base">
                        <FlexContainer className="users-table__th">
                          <span>{t(column.header)}</span>
                          <span className="users-table__th-separator" />
                          <SortingIconButton<UsersTableItem>
                            column={column.mapsTo}
                            sorting={sorting}
                            onChangeSorting={onChangeSorting}
                          />
                        </FlexContainer>
                      </TH>
                    );
                  }}
                />
                <TH hover="highlight" type="condensed" />
              </TR>
            </THead>
            <TBody hover="highlight" type="base" style={{ textAlign: 'left' }}>
              <For
                each={_users}
                children={(user: UsersTableItem) => {
                  return (
                    <TR key={user.id} hover="highlight" type="base">
                      <TD hover="highlight" type="base" tabIndex={0}>
                        <Checkbox id={user.id} name={user.id} disabled />
                      </TD>
                      <For
                        each={columns}
                        children={(column, index) => {
                          return (
                            <TD
                              hover="highlight"
                              type="base"
                              tabIndex={0}
                              id={`${user.id}-${index}`}
                            >
                              <FlexContainer className="users-table__td">
                                <Show when={!!column.useAvatar}>
                                  <Avatar
                                    label={user[column.mapsTo] || user.id}
                                    size="xs"
                                  />
                                  <span className="users-table__td-separator" />
                                </Show>
                                <HintPopover
                                  body={user[column.mapsTo] || ''}
                                  id={`${user.id}-${index}`}
                                >
                                  <Show
                                    when={!!column.searchColumn}
                                    fallback={
                                      <span>
                                        {(user[column.mapsTo] || '-') as string}
                                      </span>
                                    }
                                  >
                                    <TextHighlight
                                      text={
                                        (user[column.mapsTo] || '-') as string
                                      }
                                      highlight={searchFilter as string}
                                    />
                                  </Show>
                                </HintPopover>
                              </FlexContainer>
                            </TD>
                          );
                        }}
                      />
                      <TD hover="highlight" type="base">
                        <RoleRestrictedComponent
                          restrictedTo={[
                            {
                              roleName: USER_ROLE.SUPER_ADMIN,
                              scope: 'organizationScope',
                            },
                            {
                              roleName: USER_ROLE.SUPER_ADMIN,
                              scope: 'planScope',
                            },
                            {
                              roleName: USER_ROLE.SYSTEM_ADMIN,
                              scope: 'organizationScope',
                            },
                            {
                              roleName: USER_ROLE.PLAN_ADMIN,
                              scope: 'planScope',
                            },
                            {
                              roleName: USER_ROLE.MANAGER_FULL,
                              scope: 'planScope',
                            },
                            {
                              roleName: USER_ROLE.MANAGER_LIMITED,
                              scope: 'planScope',
                            },
                          ]}
                        >
                          <OptionsDropdown
                            ref={(el) => {
                              optionsDropdownRefs.current[user.id] = el;
                            }}
                            button={
                              <EllipsisVerticalIcon
                                aria-label={t(
                                  'usersTable.elipsisMessageForUser',
                                  {
                                    value: user.name,
                                  },
                                )}
                              />
                            }
                            isOpen={individualMenuStatuses[user.id]}
                            options={dropDownOptions(user, {
                              Escape: () => handleDropdownClose(user.id),
                              Tab: () => handleDropdownClose(user.id),
                              ShiftTab: () => handleDropdownClose(user.id),
                            })}
                            setIsOpen={() => {
                              toggleMenuStatuses(user.id);
                            }}
                          />
                        </RoleRestrictedComponent>
                      </TD>
                    </TR>
                  );
                }}
              />
            </TBody>
          </Table>
          <div className="users-table__pagination">
            <FlexContainer align="center" justify="flexEnd">
              <span className="users-table__pagination-status">
                <span className="emphasis">
                  {currFrom}-{currTo}{' '}
                </span>
                {t('usersTable.of')} {people.length}
              </span>
            </FlexContainer>
            <FlexContainer align="center" justify="center">
              <Select
                aria-label={t('pagination.itemsPerPage')}
                id="name"
                name="name"
                size="md"
                onChange={onChangePageSize}
                disabled={isPaginationDisabled}
              >
                <React.Fragment key=".0">
                  <For
                    each={paginationOptions}
                    children={(paginationOption) => {
                      return (
                        <Option
                          name={paginationOption.toString()}
                          value={paginationOption.toString()}
                        >
                          {paginationOption}
                        </Option>
                      );
                    }}
                  />
                </React.Fragment>
              </Select>
            </FlexContainer>
            <FlexContainer align="center" justify="flexStart">
              <IconButton
                ariaLabel={t('pagination.pageDown')}
                className="users-table__pagination-icon"
                palette="action"
                usage="text"
                size="sm"
                disabled={pagination.currPage <= 0 || isPaginationDisabled}
                onClick={() => onChangePage(-1)}
              >
                <ChevronLeftIcon />
              </IconButton>
              <IconButton
                ariaLabel={t('pagination.pageUp')}
                className="users-table__pagination-icon"
                palette="action"
                usage="text"
                size="sm"
                disabled={
                  pagination.currPage >=
                    people.length / pagination.pageSize - 1 ||
                  isPaginationDisabled
                }
                onClick={() => onChangePage(1)}
              >
                <ChevronRightIcon />
              </IconButton>
            </FlexContainer>
          </div>
        </FormControlProvider>
      </div>
    );
  },
);

export default UsersTable;
