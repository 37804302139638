import { ArrowRightIcon } from '@pluralsight/icons';
import { Button, FlexContainer } from '@pluralsight/react-ng';
import React from 'react';
import { useSelector } from 'react-redux';

import './action-buttons.scss';
import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import { selectEmailsList } from '../../state/selectors';
import useDialogStep from '../../../../shared/dialog/useDialogStep';

interface IActionButtonsProps {
  closeModal: () => void;
}

const EmailStepActionButtons: React.FC<IActionButtonsProps> = ({
  closeModal,
}) => {
  const { t } = useTranslations();
  const emails = useSelector(selectEmailsList);

  const { setNextStep } = useDialogStep();

  const isActive = !!emails.length;

  return (
    <FlexContainer
      align="center"
      direction="row"
      justify="flexEnd"
      wrap="nowrap"
    >
      <Button
        className="action-buttons__button"
        size="lg"
        palette="action"
        usage="text"
        onClick={closeModal}
      >
        {t('common.actionButtons.cancel')}
      </Button>
      <Button
        size="lg"
        palette={isActive ? 'action' : 'neutral'}
        usage="filled"
        disabled={!isActive}
        onClick={setNextStep}
      >
        {t('common.actionButtons.next')}
        <ArrowRightIcon width={20} />
      </Button>
    </FlexContainer>
  );
};

export default EmailStepActionButtons;
