import { createSelector } from 'reselect';

import type { DialogState } from './interfaces';
import type { AppState } from '../../../store';

const selectDialogState = (state: AppState) => state.dialog;

export const selectDialogStep = createSelector(
  [selectDialogState],
  (dialog: DialogState) => dialog.step,
);
