// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resend-invite-dialog {
  max-width: 726px;
  padding: 0 var(--pando-spacing-6) var(--pando-spacing-6);
  position: relative;
  width: 726px;
}
.resend-invite-dialog__status-section {
  width: fit-content;
  height: var(--pando-spacing-10);
  position: absolute;
  top: 0;
}`, "",{"version":3,"sources":["webpack://./src/features/invitation/components/resend-invite-dialog/resend-invite-dialog.scss"],"names":[],"mappings":"AACA;EACE,gBAAA;EACA,wDAAA;EACA,kBAAA;EACA,YAAA;AAAF;AAEE;EACE,kBAAA;EACA,+BAAA;EACA,kBAAA;EACA,MAAA;AAAJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.resend-invite-dialog {\n  max-width: 726px;\n  padding: 0 var(--pando-spacing-6) var(--pando-spacing-6);\n  position: relative;\n  width: 726px;\n\n  &__status-section {\n    width: fit-content;\n    height: var(--pando-spacing-10);\n    position: absolute;\n    top: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
