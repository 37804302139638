import type { AxiosInstance } from 'axios';
import type { UsersResponseItem } from '../../people/state/interfaces';
import type { User, UserResponseItem } from './interfaces';

const USERS_PATH = '/v1/users';

export class UserService {
  private httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  fetchUser = async (userId: string): Promise<UserResponseItem> => {
    try {
      const response = await this.httpClient.get<UserResponseItem>(
        `${USERS_PATH}/${userId}`,
      );

      return response.data;
    } catch (error) {
      console.error('Error fetching user:', error);

      throw error;
    }
  };

  patchUser = async (
    userId: string,
    patch: Partial<User>,
  ): Promise<UsersResponseItem> => {
    try {
      const response = await this.httpClient.patch<UsersResponseItem>(
        `${USERS_PATH}/${userId}`,
        patch,
      );

      return response.data;
    } catch (error) {
      console.error('Error patching user:', error);

      throw error;
    }
  };
}
