import { forwardRef } from 'react';
import { Button } from '@pluralsight/react-ng';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import CustomDialog from '../../../../components/custom-dialog/custom-dialog';
import { selectTeam } from '../../../create-team/state/selectors';
import { moveTeamMembers } from '../../state/slice';
import AssignTeam from '../assing-team/assign-team';
import {
  selectTeamsToAddIds,
  selectTeamsToRemoveIds,
} from '../../state/selectors';

import type { UsersTableItem } from '../../../../shared/interfaces';
import type { TFunction } from 'i18next';
import type {
  ContentType,
  HeaderType,
} from '../../../../components/custom-dialog/custom-dialog';

interface MoveTeamMembersDialogProps {
  closeModal: () => void;
  shouldFetchUsers?: boolean;
}

const dialogHeader = (
  t: TFunction<'translation', undefined>,
  assignedMembers: UsersTableItem[],
): HeaderType => ({
  1: (
    <>
      {t('teamUsers.moveTeams.title', {
        count: assignedMembers.length,
        user: `${assignedMembers[0]?.name || ''}`,
      })}
    </>
  ),
});

const MoveTeamMemberDialog = forwardRef<
  HTMLDialogElement | null,
  MoveTeamMembersDialogProps
>(({ closeModal, shouldFetchUsers = false }, ref) => {
  const dispatch = useDispatch();
  const { assignedMembers } = useSelector(selectTeam);
  const teamsToAddIds = useSelector(selectTeamsToAddIds);
  const teamsToRemoveIds = useSelector(selectTeamsToRemoveIds);
  const { t } = useTranslations();

  const dialogContent: ContentType = {
    1: <AssignTeam />,
  };

  const moveUsers = () => {
    const payload = {
      users: assignedMembers.map((member) => member.id),
      teamsToAddIds,
      teamsToRemoveIds,
      shouldFetchUsers,
    };

    dispatch(moveTeamMembers(payload));
    closeModal();
  };

  const actionButtons = () => (
    <>
      <Button size="lg" palette="action" usage="text" onClick={closeModal}>
        {t('common.actionButtons.cancel')}
      </Button>
      <Button
        size="lg"
        disabled={!assignedMembers.length || !teamsToAddIds.length}
        palette={
          assignedMembers.length && teamsToAddIds.length ? 'action' : 'neutral'
        }
        usage="filled"
        onClick={moveUsers}
      >
        {t('teamUsers.moveTeams.moveTeamsButton')}
      </Button>
    </>
  );

  return (
    <CustomDialog
      id="move-users"
      ref={ref}
      dialogHeader={dialogHeader(t, assignedMembers as UsersTableItem[])}
      dialogContent={dialogContent}
      actionButtons={actionButtons}
      multipleSteps={false}
      isWarning={false}
      closeModal={closeModal}
    />
  );
});

export default MoveTeamMemberDialog;
