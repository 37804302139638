import type { AxiosInstance } from 'axios';
import type { Role } from './interfaces';

const ORGANIZATION_PATH = '/v1/organizations';

export class RolesService {
  private httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  fetchRoles = async (orgId: string): Promise<Role[]> => {
    try {
      const response = await this.httpClient.get<Role[]>(
        `${ORGANIZATION_PATH}/${orgId}/roles`,
      );

      return response.data;
    } catch (error) {
      console.error('Error fetching roles:', error);

      throw error;
    }
  };
}
