import { ChevronRightIcon, SearchIcon } from '@pluralsight/icons';
import {
  Checkbox,
  FlexContainer,
  For,
  FormControlProvider,
  Input,
} from '@pluralsight/react-ng';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import { selectTeamsList } from '../../../teams/state/selectors';
import { fetchTeams } from '../../../teams/state/slice';
import './assign-team.scss';
import { selectTeam } from '../../../create-team/state/selectors';
import { updateTeamSelection } from '../../state/slice';
import { selectTeamsToAddIds } from '../../state/selectors';

import type {
  TeamSummary,
  UsersTableItem,
} from '../../../../shared/interfaces';

const AssignTeam = () => {
  const dispatch = useDispatch();
  const teams = useSelector(selectTeamsList);
  const teamsToAddIds = useSelector(selectTeamsToAddIds);
  const { assignedMembers } = useSelector(selectTeam);
  const [searchTeam, setSearchTeam] = useState('');
  const { t } = useTranslations();

  useEffect(() => {
    setSearchTeam('');
  }, [assignedMembers]);

  useEffect(() => {
    const assignedTeamIds = assignedMembers.flatMap(
      (member) =>
        ((member as UsersTableItem).teamsSummary as TeamSummary[])?.map(
          (team) => team.id,
        ) || [],
    );

    assignedTeamIds.forEach((teamId) => {
      dispatch(updateTeamSelection({ teamId, wasPreviouslyAssigned: false }));
    });
  }, [assignedMembers, dispatch]);

  const filteredTeams = teams.filter((team) =>
    team?.name?.toLowerCase().includes(searchTeam.toLowerCase()),
  );

  const getOptions = () => {
    if (!filteredTeams.length) {
      return [];
    }

    return searchTeam.length > 1 && filteredTeams.length
      ? filteredTeams
      : teams;
  };

  useEffect(() => {
    dispatch(fetchTeams());
  }, [dispatch]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const teamId = event.target.value;

    const wasPreviouslyAssigned = assignedMembers.some((member) =>
      ((member as UsersTableItem).teamsSummary as TeamSummary[])?.some(
        (team) => team.id === teamId,
      ),
    );

    dispatch(updateTeamSelection({ teamId, wasPreviouslyAssigned }));
  };

  return (
    <FlexContainer direction="col" className="assign-team">
      <div className="assign-team__content">
        {t('teamUsers.moveTeams.description', {
          count: assignedMembers.length,
        })}
      </div>
      <FormControlProvider>
        <Input
          name="assign-team"
          value={searchTeam}
          placeholder={t('invitation.assignTeamStep.searchTeamPlaceholder')}
          startIcon={
            <SearchIcon aria-hidden height="1.25rem" width="1.25rem" />
          }
          onChange={(e) => setSearchTeam(e.target.value)}
        />
      </FormControlProvider>

      <FlexContainer align="center" className="assign-team__tooltip">
        {t('teamUsers.moveTeams.teamSelected', {
          count: teamsToAddIds.length,
        })}
      </FlexContainer>

      <FormControlProvider>
        <FlexContainer className="assign-team__list-container" direction="col">
          <For
            each={getOptions()}
            children={(team) => (
              <FlexContainer
                className={`assign-team__row ${
                  teamsToAddIds.includes(team.id) ? 'selected' : ''
                }`}
                align="center"
              >
                <ChevronRightIcon />
                <Checkbox
                  id={team.id}
                  value={team.id}
                  name={`assigned-team-${team.id}`}
                  checked={teamsToAddIds.includes(team.id)}
                  onChange={handleOnChange}
                >
                  <div className="assign-team__team">{team.name}</div>
                </Checkbox>
              </FlexContainer>
            )}
          />
        </FlexContainer>
      </FormControlProvider>
    </FlexContainer>
  );
};

export default AssignTeam;
