// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hint-popover__trigger {
  max-width: var(--pando-spacing-60);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.hint-popover__content {
  color: var(--pando-colors-neutral-text-inverse);
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background-color: var(--pando-colors-neutral-surface-inverse);
}
.hint-popover__content:focus {
  outline: none;
}
.hint-popover__content > .pando-popover__content {
  font-size: 12px;
  font-weight: 400;
  overflow-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
  min-width: 120px;
  max-width: 300px;
}`, "",{"version":3,"sources":["webpack://./src/components/hint-popover/hint-popover.scss"],"names":[],"mappings":"AAEE;EACE,kCAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;AADJ;AAIE;EACE,+CAAA;EACA,YAAA;EACA,kBAAA;EACA,wCAAA;EACA,6DAAA;AAFJ;AAII;EACE,aAAA;AAFN;AAKI;EACE,eAAA;EACA,gBAAA;EACA,yBAAA;EACA,sBAAA;EACA,aAAA;EACA,gBAAA;EACA,gBAAA;AAHN","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.hint-popover {\n  &__trigger {\n    max-width: var(--pando-spacing-60);\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }\n\n  &__content {\n    color: var(--pando-colors-neutral-text-inverse);\n    padding: 8px;\n    border-radius: 4px;\n    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);\n    background-color: var(--pando-colors-neutral-surface-inverse);\n\n    &:focus {\n      outline: none;\n    }\n\n    & > .pando-popover__content {\n      font-size: 12px;\n      font-weight: 400;\n      overflow-wrap: break-word;\n      word-break: break-word;\n      hyphens: auto;\n      min-width: 120px;\n      max-width: 300px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
