import { createSelector } from 'reselect';

import type { AppState } from '../../../store';
import type { TeamState } from './interfaces';

const selectTeamState = (state: AppState) => state.team;

export const selectTeamItem = createSelector(
  [selectTeamState],
  (teamState: TeamState) => teamState.item,
);
