import {
  FlexContainer,
  InlineCode,
  SwitchInput,
  SwitchInputLabel,
} from '@pluralsight/react-ng';
import { memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import Divider from '../../../shared/divider/divider';
import { selectSingleSsoConnectionConfigurations } from '../../../sso-conections/state/selectors';
import { OIDCFormFields } from '../../state/interfaces';
import { useFieldTracker } from '../hooks/useFieldTracker';

import CommunicationChannelField from './communication-channel/communication-channel-field';
import OpenIdConnectField from './open-id-connect/open-id-connect-field';

import type { ISsoConfigurationOIDC } from '../../../sso-conections/state/interfaces';

const initProfileSyncEnabled = true;

const Oidc = () => {
  const {
    discoveryUrl,
    oidcMetadata,
    type: communicationChannel,
    clientId,
    clientSecret,
  } = useSelector(
    selectSingleSsoConnectionConfigurations,
  ) as ISsoConfigurationOIDC;

  const [isProfileSyncEnabled, setIsProfileSyncEnabled] = useState(
    initProfileSyncEnabled,
  );
  const { t } = useTranslations();

  useFieldTracker(initProfileSyncEnabled, isProfileSyncEnabled);

  const handleProfileSyncToggle = useCallback(() => {
    setIsProfileSyncEnabled((prev) => !prev);
  }, []);

  return (
    <>
      <OpenIdConnectField discoveryUrl={discoveryUrl} metadata={oidcMetadata} />
      <CommunicationChannelField
        communicationChannel={communicationChannel}
        clientId={clientId}
        clientSecret={clientSecret}
      />
      <FlexContainer
        direction="col"
        gap="var(--pando-gap-sm)"
        className="fieldset__field"
      >
        <h4 className="fieldset__label">
          {t('sso.ssoConfiguration.callbackURL')}
        </h4>
        <p className="fieldset__note">
          {t('sso.ssoConfiguration.openIdConnectType')}
        </p>
        <InlineCode className="fieldset__code">
          https://dev-yzfbhwn6h7f@pk28.eu.auth0.com/login/callback
        </InlineCode>
      </FlexContainer>
      <FlexContainer direction="col">
        <h3>{t('sso.ssoConfiguration.advanced')}</h3>
        <Divider />
      </FlexContainer>
      <FlexContainer direction="col">
        <SwitchInput
          className="fieldset__switch"
          labelPosition="start"
          switchSize="sm"
          onChange={handleProfileSyncToggle}
          checked={isProfileSyncEnabled}
          name={OIDCFormFields.IS_PROFILE_SYNC_ENABLED}
        >
          <SwitchInputLabel
            className="fieldset__switch-label"
            aria-label={t('sso.ssoConfiguration.syncUserProfile')}
          >
            {t('sso.ssoConfiguration.syncUserProfile')}
          </SwitchInputLabel>
        </SwitchInput>
      </FlexContainer>
    </>
  );
};

export default memo(Oidc);
