import React from 'react';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import { formatDate } from '../../../shared/utils/date-utils';

import type { Subscription } from '../../../../products/state/interfaces';
import './subscription-item.scss';

interface SubscriptionItemProps {
  subscription: Subscription;
}

const SubscriptionItem: React.FC<SubscriptionItemProps> = ({
  subscription,
}) => {
  const { t } = useTranslations();

  return (
    <div className="subscription-item">
      <div className="subscription-item__header">
        <h4 className="subscription-item__title">{subscription.productName}</h4>
      </div>
      <div className="subscription-item__details">
        <div className="subscription-item__field">
          <span className="subscription-item__label">
            {t('planSettings.subscriptions.licenses')}
          </span>
          <span className="subscription-item__value">
            {subscription.totalLicenses}
          </span>
        </div>
        <div className="subscription-item__field">
          <span className="subscription-item__label">
            {t('planSettings.subscriptions.startDate')}
          </span>
          <span className="subscription-item__value">
            {formatDate(subscription.validFrom)}
          </span>
        </div>
        <div className="subscription-item__field">
          <span className="subscription-item__label">
            {t('planSettings.subscriptions.renewalDate')}
          </span>
          <span className="subscription-item__value">
            {formatDate(subscription.validTo)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionItem;
