// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.copy-button {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/features/sso/components/shared/copy-button/copy-button.scss"],"names":[],"mappings":"AACA;EACE,eAAA;AAAF","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.copy-button {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
