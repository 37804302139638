import {
  all,
  call,
  fork,
  getContext,
  put,
  takeLatest,
} from 'redux-saga/effects';

import {
  fetchOrganization,
  fetchOrganizationFailure,
  fetchOrganizationSuccess,
  updateOrganization,
  updateOrganizationFailure,
  updateOrganizationSuccess,
} from './slice';
import { OrganizationService } from './services';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { AxiosInstance } from 'axios';
import type { IOrganization } from './interfaces';

function* fetchOrganizationHandler(action: PayloadAction<{ orgId: string }>) {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');

    if (!httpClient) {
      throw new Error('HTTP client is not available');
    }

    const { orgId } = action.payload;

    const organizationService = new OrganizationService(httpClient);

    const organization: IOrganization = yield call(
      organizationService.fetchOrganization,
      orgId,
    );

    yield put(fetchOrganizationSuccess(organization));
  } catch (error: any) {
    yield put(fetchOrganizationFailure(error.message || 'Unknown error'));
  }
}

function* updateOrganizationHandler(action: PayloadAction<IOrganization>) {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');

    if (!httpClient) {
      throw new Error('HTTP client is not available');
    }

    const organizationService = new OrganizationService(httpClient);

    const org = action.payload;

    const organization: IOrganization = yield call(
      organizationService.updateOrganization,
      org,
    );

    yield put(updateOrganizationSuccess(organization));
  } catch (error: any) {
    yield put(updateOrganizationFailure(error.message || 'Unknown error'));

    throw error;
  }
}

function* watchFetchOrganization() {
  yield takeLatest(fetchOrganization.type, fetchOrganizationHandler);
}

function* watchUpdateOrganization() {
  yield takeLatest(updateOrganization.type, updateOrganizationHandler);
}

export default function* organizationSagas() {
  yield all([fork(watchFetchOrganization), fork(watchUpdateOrganization)]);
}
