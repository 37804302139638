import { FlexContainer, TextLink, useFileUpload } from '@pluralsight/react-ng';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import { useFileChangeHandler } from '../../../../shared/hooks/useFileChangeHandler';
import UploadFileField from '../../../../components/upload-file-field/upload-file-field';
import './import-teams-container.scss';
import { validateCsvFile } from '../../../../shared/validate-csv-file';
import { setImportActiveTab, setImportFile } from '../../state/slice';
import { selectImportErrors, selectImportFile } from '../../state/selectors';
import { ImportTabs } from '../../../../shared/interfaces';

import type { ImportFile } from '../../../../shared/interfaces';
import type { FileUploadFileChangeDetails } from '@pluralsight/react-ng';

const initialFormState = {
  importFile: { invalid: false, required: true, isTouched: false },
};

export type formStateType = typeof initialFormState;

const initialFormFields: ImportFile = {
  id: null,
  file: null,
  objectKey: null,
  importErrors: null,
};

const fileUploadConfig = {
  accept: { 'application/*': ['.csv'] },
  maxFiles: 1,
  maxFileSize: 1024 * 1024 * 5, // 5MB
};

const requiredColumns = ['ID', 'TeamName', 'ParentTeamName', 'Remove/Add/Edit'];

const ImportTeamsContainer = () => {
  const dispatch = useDispatch();
  const { t } = useTranslations();
  const [formState, setFormState] = useState(initialFormState);
  const [formFields, setFormFields] = useState<ImportFile>(initialFormFields);
  const importFile = useSelector(selectImportFile);
  const importErrors = useSelector(selectImportErrors);

  const clearRejectedFiles = () => {
    fileUpload.value.clearRejectedFiles();
  };
  const { handleFileChange } = useFileChangeHandler(clearRejectedFiles);

  const handleFileValidation = async (file: File): Promise<void> => {
    try {
      const response = await validateCsvFile(file, requiredColumns, 50000);

      dispatch(
        setImportFile({
          id: null,
          file,
          importErrors: response,
          objectKey: null,
        }),
      );
    } catch (error) {
      dispatch(
        setImportFile({
          id: null,
          file: null,
          importErrors: [error as string],
          objectKey: null,
        }),
      );
      setFormState((prev) => ({
        ...prev,
        importFile: { ...prev.importFile, invalid: true },
      }));
    }
  };

  const fileUpload = {
    name: 'importFile',
    value: useFileUpload({
      ...fileUploadConfig,
      onFileChange: (details?: FileUploadFileChangeDetails) => {
        if (details?.acceptedFiles[0])
          void handleFileValidation(details?.acceptedFiles[0]);

        handleFileChange(details);
      },
    }),
  };

  const handleFormChange = (
    name: string,
    value: string,
    markTouched?: boolean,
  ) => {
    updateFormValue(name, value);
    updateFormStatus(name, value, markTouched);
  };

  const updateFormValue = (name: string, value: string) =>
    setFormFields((prev) => ({
      ...prev,
      [name]: value,
    }));

  const updateFormStatus = (
    name: string,
    value: string,
    markTouched?: boolean,
  ) => {
    const fieldName = name as keyof typeof formState;

    setFormState((prev) => ({
      ...prev,
      [name]: {
        ...prev[fieldName],
        invalid: setIsInvalid(fieldName, value, markTouched),
      },
    }));
  };

  const setIsInvalid = (
    fieldName: keyof typeof formState,
    value: string,
    markTouched?: boolean,
  ): boolean => {
    if (
      formState[fieldName].required &&
      (markTouched || formState[fieldName].isTouched)
    ) {
      if (Array.isArray(value)) {
        return !value.length;
      }

      return value.trim() === '';
    }

    return false;
  };

  const onFileDelete = () => {
    setFormState((prev) => ({
      ...prev,
      importFile: {
        ...prev['importFile'],
        isTouched: true,
      },
    }));

    clearRejectedFiles();
  };

  useEffect(() => {
    if (fileUpload?.value.acceptedFiles) {
      handleFormChange(fileUpload.name, fileUpload.value.acceptedFiles);
    }
  }, [fileUpload?.value.acceptedFiles, fileUpload.value.rejectedFiles]);

  useEffect(() => {
    if (!importFile) {
      setFormState(initialFormState);
      fileUpload.value.clearFiles();
    }
  }, [importFile]);

  return (
    <FlexContainer direction="col" className="import-teams-container">
      <FlexContainer>
        <p>
          {t('teams.importTeams.importInstructions')}{' '}
          <TextLink
            className="import-teams-container__link"
            aria-label={t('teams.importTeams.exportCsvTemplate')}
            onClick={() => dispatch(setImportActiveTab(ImportTabs.Export))}
          >
            {t('teams.importTeams.exportCsvTemplate')}
          </TextLink>{' '}
          {t('teams.importTeams.learnMore')}{' '}
          <TextLink
            href="https://help.pluralsight.com/"
            className="import-teams-container__link"
            aria-label={t('teams.importTeams.helpCenter')}
          >
            {t('teams.importTeams.helpCenter')}
          </TextLink>
        </p>
      </FlexContainer>
      <FlexContainer
        direction="col"
        className="import-teams-container__section"
      >
        <h2 className="import-teams-container__subtitle">
          {t('teams.importTeams.chooseCsv')}
        </h2>
        <p>{t('teams.importTeams.maximunSize')}</p>
      </FlexContainer>
      <FlexContainer className="import-teams-container__form">
        <UploadFileField
          objectKey={formFields?.objectKey}
          formState={formState}
          fileUpload={fileUpload}
          importErrors={importErrors}
          onFileDelete={onFileDelete}
        />
      </FlexContainer>
    </FlexContainer>
  );
};

export default ImportTeamsContainer;
