import { useSelector } from 'react-redux';

import {
  selectMemberProducts,
  selectProductIds,
  selectProductsToAdd,
  selectProductsToRemove,
} from '../state/selectors';
import { selectAddOnProducts } from '../../products/state/selectors';
import useProducts from '../../products/hooks/useProducts';

const useUpdateLicenses = () => {
  const addOnProducts = useSelector(selectAddOnProducts);
  const currentMemberProducts = useSelector(selectMemberProducts);
  const productsToAdd = useSelector(selectProductsToAdd);
  const productsToRemove = useSelector(selectProductsToRemove);
  const productIds = useSelector(selectProductIds);

  const isBaseSelected = useProducts(productIds);

  const filterAddOns = addOnProducts
    .filter(
      ({ productId }) =>
        productIds.includes(productId) &&
        currentMemberProducts.includes(productId),
    )
    .map(({ productId }) => productId);

  const assigned = isBaseSelected ? productsToAdd : [];

  const revoked =
    isBaseSelected || !currentMemberProducts.length
      ? productsToRemove
      : [...productsToRemove, ...filterAddOns];

  return { revoked, assigned };
};

export default useUpdateLicenses;
