import { createSelector } from 'reselect';

import type { RootState } from '../../../store';
import type { IPlan, IPlanState } from './interfaces';

const selectPlanState = (state: RootState) => state.plan;

export const selectPlan = createSelector(
  [selectPlanState],
  (planState: IPlanState): IPlan => planState.plan,
);

export const selectPlanLoading = createSelector(
  [selectPlanState],
  (planState: IPlanState) => planState.fetch.loading,
);

export const selectPlanError = createSelector(
  [selectPlanState],
  (planState: IPlanState) => planState.fetch.error,
);

export const selectPlanSuccess = createSelector(
  [selectPlanState],
  (planState: IPlanState) => planState.fetch.success,
);

export const selectOrgId = createSelector(
  [selectPlan],
  (plan: IPlan) => plan.orgId,
);
