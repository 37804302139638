import { FlexContainer } from '@pluralsight/react-ng';
import React from 'react';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import SubscriptionItem from './subscription-item/subscription-item';

import './subscriptions.scss';
import type { Subscription } from '@ps-fe-plan-management/features/products/state/interfaces';

interface SubscriptionsProps {
  subscriptionData: Subscription[];
  isLoading: boolean;
}

const Subscriptions: React.FC<SubscriptionsProps> = ({
  subscriptionData,
  isLoading,
}) => {
  const { t } = useTranslations();

  if (isLoading) {
    return <div>{t('common.loading')}</div>;
  }

  return (
    <div className="subscriptions">
      <div className="subscriptions__header">
        <h3 className="subscriptions__title">
          {t('planSettings.subscriptions.title')}
        </h3>
      </div>
      <FlexContainer direction="col" gap="var(--pando-spacing-4)">
        {subscriptionData.map((subscription) => (
          <SubscriptionItem
            key={subscription.productId}
            subscription={subscription}
          />
        ))}
      </FlexContainer>
    </div>
  );
};

export default Subscriptions;
