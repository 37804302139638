import React from 'react';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import PeopleSelect from '../../../../components/people-select/people-select';

const SelectTeamMembers = () => {
  const { t } = useTranslations();

  return (
    <PeopleSelect
      placeholder={t('invitation.selectTeamMembers.placeholder')}
      title={t('invitation.selectTeamMembers.title')}
    />
  );
};

export default SelectTeamMembers;
