import { useSelector } from 'react-redux';
import { GridCell, GridContainer } from '@pluralsight/react-ng';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import { fetchProducts } from '../../../products/state/slice';
import { selectProductsSortedByGroup } from '../../../products/state/selectors';
import ProductUsageWidget from '../product-usage-widget/product-usage-widget';
import useDispatchPolling from '../../hooks/useDispatchPolling';

import './products-usage-container.scss';

const PRODUCT_USAGE_POLLING_INTERVAL = 30000;

const ProductsUsageContainer = () => {
  const products = useSelector(selectProductsSortedByGroup);
  const { t } = useTranslations();

  useDispatchPolling(fetchProducts, PRODUCT_USAGE_POLLING_INTERVAL);

  return (
    <section
      className="products-usage-container"
      aria-label={t('productUsageWidget.productUsageOverview')}
    >
      <GridContainer className="grid-container">
        {products.map((product) => (
          <GridCell key={product.productId} medium="12" large="6">
            <ProductUsageWidget product={product} />
          </GridCell>
        ))}
      </GridContainer>
    </section>
  );
};

export default ProductsUsageContainer;
