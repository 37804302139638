// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.teams-table-container__placeholder {
  background-color: var(--pando-colors-neutral-surface-200);
  border-radius: var(--pando-radii-lg-border-radius);
}
.teams-table-container__placeholder-message {
  color: var(--neutral-text-300, #f3f3f8);
  font-size: var(--pando-font-size-2xl-font-size, 24px);
  font-weight: 600;
  line-height: 125%;
  margin-bottom: var(--pando-spacing-4);
  text-align: center;
}
.teams-table-container__placeholder-button {
  color: var(--pando-inky-blue-action-text-100-dark);
  cursor: pointer;
  font-size: var(--pando-font-sizes-sm, 14px);
  font-weight: 500;
  line-height: 150%;
}
.teams-table-container__actions__export {
  width: 100px;
}
@media screen and (min-width: 768px) {
  .teams-table-container__actions__export {
    width: 150px;
  }
}`, "",{"version":3,"sources":["webpack://./src/features/teams/components/teams-table-container/teams-table-container.scss"],"names":[],"mappings":"AAEE;EACE,yDAAA;EACA,kDAAA;AADJ;AAIE;EACE,uCAAA;EACA,qDAAA;EACA,gBAAA;EACA,iBAAA;EACA,qCAAA;EACA,kBAAA;AAFJ;AAKE;EACE,kDAAA;EACA,eAAA;EACA,2CAAA;EACA,gBAAA;EACA,iBAAA;AAHJ;AAME;EACE,YAAA;AAJJ;AAOE;EACE;IACE,YAAA;EALJ;AACF","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.teams-table-container {\n  &__placeholder {\n    background-color: var(--pando-colors-neutral-surface-200);\n    border-radius: var(--pando-radii-lg-border-radius);\n  }\n\n  &__placeholder-message {\n    color: var(--neutral-text-300, #f3f3f8);\n    font-size: var(--pando-font-size-2xl-font-size, 24px);\n    font-weight: 600;\n    line-height: 125%;\n    margin-bottom: var(--pando-spacing-4);\n    text-align: center;\n  }\n\n  &__placeholder-button {\n    color: var(--pando-inky-blue-action-text-100-dark);\n    cursor: pointer;\n    font-size: var(--pando-font-sizes-sm, 14px);\n    font-weight: 500;\n    line-height: 150%;\n  }\n\n  &__actions__export {\n    width: 100px;\n  }\n\n  @media screen and (min-width: 768px) {\n    &__actions__export {\n      width: 150px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
