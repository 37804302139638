import { call, getContext, put, takeLatest } from 'redux-saga/effects';

import { groupPermissionsByScope } from '../../../shared/utils';

import { UserService } from './services';
import {
  fetchUser,
  fetchUserFailure,
  fetchUserSuccess,
  patchUser,
  patchUserFailure,
  patchUserSuccess,
} from './slice';

import type { User, UserResponseItem } from './interfaces';
import type { AxiosInstance } from 'axios';
import type { PayloadAction } from '@reduxjs/toolkit';

function* fetchUserHandler(action: PayloadAction<string>) {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');
    const userService = new UserService(httpClient);
    const userId = action.payload;
    const userResponse: UserResponseItem = yield call(
      userService.fetchUser,
      userId,
    );

    const permissionsByScope = groupPermissionsByScope(
      userResponse.permissionScope,
    );

    yield put(fetchUserSuccess({ ...userResponse, permissionsByScope }));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetchUserFailure(error.message));
    } else {
      yield put(fetchUserFailure('An unknown error occurred'));
    }
  }
}

function* patchUserHandler(action: PayloadAction<Partial<User>>) {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');
    const userService = new UserService(httpClient);
    const userId: string | undefined = action.payload.id;

    if (!userId) throw new Error('No userId provided');

    const user: User = yield call(userService.patchUser, userId, {
      ...action.payload,
    });

    yield put(patchUserSuccess(user));
  } catch (error) {
    if (error instanceof Error) {
      yield put(patchUserFailure(error.message));
    } else {
      yield put(patchUserFailure('An unknown error occurred'));
    }
  }
}

export default function* userSagas() {
  yield takeLatest(fetchUser, fetchUserHandler);
  yield takeLatest(patchUser, patchUserHandler);
}
