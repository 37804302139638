import { createSelector } from 'reselect';

import type { RootState } from '../../../../../store';
import type { ISsoConfigurationState } from './interfaces';

const selectSsoConfigurationState = (state: RootState) =>
  state.ssoConfiguration;

export const selectSsoConfigurationLoading = createSelector(
  [selectSsoConfigurationState],
  (ssoConfig: ISsoConfigurationState) => ssoConfig.loading,
);

export const selectSsoConfigurationError = createSelector(
  [selectSsoConfigurationState],
  (ssoConfig: ISsoConfigurationState) => ssoConfig.error,
);

export const selectSsoConfigurationSuccess = createSelector(
  [selectSsoConfigurationState],
  (ssoConfig: ISsoConfigurationState) => ssoConfig.success,
);

export const selectIsFormModifiedInEdit = createSelector(
  [selectSsoConfigurationState],
  (ssoConfig: ISsoConfigurationState) => ssoConfig.isFormModifiedInEdit,
);
