import { call, getContext, put, takeLatest } from 'redux-saga/effects';

import { RolesService } from './services';
import { fetchRoles, fetchRolesFailure, fetchRolesSuccess } from './slice';

import type { Role } from './interfaces';
import type { AxiosInstance } from 'axios';

function* fetchRolesHandler({ payload: orgId }: { payload: string }) {
  if (!orgId) throw new Error('orgId not found');

  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');
    const service = new RolesService(httpClient);
    const roles: Role[] = yield call(service.fetchRoles, orgId);

    yield put(fetchRolesSuccess(roles));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetchRolesFailure(error.message));
    } else {
      yield put(fetchRolesFailure('An unknown error occurred'));
    }
  }
}

export default function* rolesSagas() {
  yield takeLatest(fetchRoles, fetchRolesHandler);
}
