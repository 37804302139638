export type KeyBoardActions = Record<string, VoidFunction>;

export const handleMenuKeyBoardNavigation =
  (optionClass: string, extraActions?: KeyBoardActions) =>
  (event: React.KeyboardEvent<HTMLElement>) => {
    event.preventDefault();

    const options = Array.from(
      event.currentTarget.querySelectorAll<HTMLElement>(`.${optionClass}`),
    );

    if (!options.length) return;

    if (event.shiftKey && event.key === 'Tab') event.key = 'ShiftTab';

    const currentIndex = options.indexOf(document.activeElement as HTMLElement);

    const length = options.length;

    const getNextIndex = (offset: number) =>
      (currentIndex + offset + length) % length;

    const actions: KeyBoardActions = {
      ArrowDown: () => options[getNextIndex(1)]?.focus(),
      ArrowUp: () => options[getNextIndex(-1)]?.focus(),
      Home: () => options[0]?.focus(),
      End: () => options.at(-1)?.focus(),
      Enter: () => options[currentIndex]?.click(),
      ...extraActions,
    };

    actions[event.key]?.();
  };
