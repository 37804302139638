// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard {
  padding: 25px 40px;
  height: 100%;
}
.dashboard__header {
  margin-bottom: 32px;
}
.dashboard__container {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 40px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/dashboard.scss"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,YAAA;AAAF;AAEE;EACE,mBAAA;AAAJ;AAGE;EACE,aAAA;EACA,8BAAA;EACA,yBAAA;AADJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.dashboard {\n  padding: 25px 40px;\n  height: 100%;\n\n  &__header {\n    margin-bottom: 32px;\n  }\n\n  &__container {\n    display: grid;\n    grid-template-columns: 3fr 1fr;\n    grid-gap: 40px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
