import './sso-settings-section.scss';
import { FlexContainer, Show } from '@pluralsight/react-ng';
import React from 'react';

interface ISsoSettingsSectionProps {
  title: string;
  subtitle: string;
  children?: React.ReactNode;
  headerButton?: React.ReactNode;
}

const SsoSettingsSection = ({
  title,
  subtitle,
  children,
  headerButton,
}: ISsoSettingsSectionProps) => {
  return (
    <FlexContainer
      direction="col"
      gap="var(--pando-gap-xl)"
      className="general-sso-section"
    >
      <FlexContainer
        justify="spaceBetween"
        className="general-sso-section__header"
      >
        <FlexContainer direction="col" gap="var(--pando-gap-xs)">
          <h1 className="general-sso-section__title">{title}</h1>
          <p className="general-sso-section__subtitle">{subtitle}</p>
        </FlexContainer>
        <Show when={!!headerButton}>
          <FlexContainer className="general-sso-section__header-button">
            {headerButton}
          </FlexContainer>
        </Show>
      </FlexContainer>
      {children}
    </FlexContainer>
  );
};

export default SsoSettingsSection;
