import type { Language } from '../../translations/interfaces';

export interface DataCourse {
  type: string;
  title: string;
  tags: string[];
  videoLength: string;
}

export const POPULAR_COURSES_EN: DataCourse[] = [
  {
    type: 'COURSE',
    title: 'The title of something that is two lines long',
    tags: ['Meta', 'Meta'],
    videoLength: '5m',
  },
  {
    type: 'COURSE',
    title: 'The title of something that is two lines long',
    tags: ['Meta', 'Meta'],
    videoLength: '5m',
  },
  {
    type: 'COURSE',
    title: 'The title of something that is two lines long',
    tags: ['Meta', 'Meta'],
    videoLength: '5m',
  },
];

export const POPULAR_COURSES_ES: DataCourse[] = [
  {
    type: 'CURSO',
    title: 'El título de algo que ocupa dos líneas',
    tags: ['Meta', 'Meta'],
    videoLength: '5 min',
  },
  {
    type: 'CURSO',
    title: 'El título de algo que ocupa dos líneas',
    tags: ['Meta', 'Meta'],
    videoLength: '5 min',
  },
  {
    type: 'CURSO',
    title: 'El título de algo que ocupa dos líneas',
    tags: ['Meta', 'Meta'],
    videoLength: '5 min',
  },
];

export const POPULAR_COURSES_FR: DataCourse[] = [
  {
    type: 'COURS',
    title: 'Le titre de quelque chose qui tient en deux lignes',
    tags: ['Meta', 'Meta'],
    videoLength: '5 min',
  },
  {
    type: 'COURS',
    title: 'Le titre de quelque chose qui tient en deux lignes',
    tags: ['Meta', 'Meta'],
    videoLength: '5 min',
  },
  {
    type: 'COURS',
    title: 'Le titre de quelque chose qui tient en deux lignes',
    tags: ['Meta', 'Meta'],
    videoLength: '5 min',
  },
];

export const POPULAR_COURSES_GE: DataCourse[] = [
  {
    type: 'KURS',
    title: 'Der Titel von etwas, das zwei Zeilen lang ist',
    tags: ['Meta', 'Meta'],
    videoLength: '5 Min.',
  },
  {
    type: 'KURS',
    title: 'Der Titel von etwas, das zwei Zeilen lang ist',
    tags: ['Meta', 'Meta'],
    videoLength: '5 Min.',
  },
  {
    type: 'KURS',
    title: 'Der Titel von etwas, das zwei Zeilen lang ist',
    tags: ['Meta', 'Meta'],
    videoLength: '5 Min.',
  },
];

export const POPULAR_COURSES_PT: DataCourse[] = [
  {
    type: 'CURSO',
    title: 'O título de algo que ocupa duas linhas',
    tags: ['Meta', 'Meta'],
    videoLength: '5 min',
  },
  {
    type: 'CURSO',
    title: 'O título de algo que ocupa duas linhas',
    tags: ['Meta', 'Meta'],
    videoLength: '5 min',
  },
  {
    type: 'CURSO',
    title: 'O título de algo que ocupa duas linhas',
    tags: ['Meta', 'Meta'],
    videoLength: '5 min',
  },
];

export const POPULAR_COURSES_JP: DataCourse[] = [
  {
    type: 'コース',
    title: '2行にわたる何かのタイトル',
    tags: ['Meta', 'Meta'],
    videoLength: '5分',
  },
  {
    type: 'コース',
    title: '2行にわたる何かのタイトル',
    tags: ['Meta', 'Meta'],
    videoLength: '5分',
  },
  {
    type: 'コース',
    title: '2行にわたる何かのタイトル',
    tags: ['Meta', 'Meta'],
    videoLength: '5分',
  },
];

export const POPULAR_COURSES: Record<Language, DataCourse[]> = {
  en: POPULAR_COURSES_EN,
  es: POPULAR_COURSES_ES,
  fr: POPULAR_COURSES_FR,
  jp: POPULAR_COURSES_JP,
  ge: POPULAR_COURSES_GE,
  pt: POPULAR_COURSES_PT,
};
