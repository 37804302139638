import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FlexContainer } from '@pluralsight/react-ng';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import {
  selectAddOnProducts,
  selectProductsLoading,
} from '../../../products/state/selectors';
import { assignLicenses } from '../../state/slice';
import SelectLicenses from '../../../../components/select-licenses/select-licenses';
import { selectAssignedProducts } from '../../state/selectors';
import './licenses-step.scss';

const LicensesStep = () => {
  const { t } = useTranslations();
  const dispatch = useDispatch();
  const addOnProducts = useSelector(selectAddOnProducts);
  const productIds = useSelector(selectAssignedProducts);

  const isProductsLoading = useSelector(selectProductsLoading);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(assignLicenses(event.target.value));
  };

  return (
    <FlexContainer direction="col" className="licenses-step__conteiner">
      <div className="licenses-step__title">
        {t('invitation.licensesStep.title', { count: addOnProducts.length })}
      </div>

      <div className="licenses-step__licenses-container">
        {isProductsLoading ? (
          <div className="licenses-step__loading">{t('common.loading')}</div>
        ) : null}

        <SelectLicenses productIds={productIds} onChange={onChange} />
      </div>
    </FlexContainer>
  );
};

export default LicensesStep;
