// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.teams-container {
  display: flex;
  flex-direction: column;
  padding: var(--pando-spacing-6, 1rem);
}
.teams-container__header {
  align-items: center;
  display: flex;
  height: 44px;
  justify-content: space-between;
  margin-bottom: var(--pando-spacing-8);
}
.teams-container__header-title {
  font-size: var(--pando-font-sizes-4xl);
  font-weight: 600;
}
.teams-container__tabs {
  flex-direction: column;
  margin: var(--pando-spacing-6) 0;
}`, "",{"version":3,"sources":["webpack://./src/features/teams/components/teams-container/teams-container.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,sBAAA;EACA,qCAAA;AAAF;AAEE;EACE,mBAAA;EACA,aAAA;EACA,YAAA;EACA,8BAAA;EACA,qCAAA;AAAJ;AAEI;EACE,sCAAA;EACA,gBAAA;AAAN;AAIE;EACE,sBAAA;EACA,gCAAA;AAFJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.teams-container {\n  display: flex;\n  flex-direction: column;\n  padding: var(--pando-spacing-6, 1rem);\n\n  &__header {\n    align-items: center;\n    display: flex;\n    height: 44px;\n    justify-content: space-between;\n    margin-bottom: var(--pando-spacing-8);\n\n    &-title {\n      font-size: var(--pando-font-sizes-4xl);\n      font-weight: 600;\n    }\n  }\n\n  &__tabs {\n    flex-direction: column;\n    margin: var(--pando-spacing-6) 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
