import type { AxiosInstance } from 'axios';
import type { LicensedProductWithUsage } from './interfaces';

const PLANS_PATH = '/v1/plans';

export class ProductsService {
  private httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  fetchProductsUsage = async (
    planId: string,
  ): Promise<LicensedProductWithUsage[]> => {
    try {
      const response = await this.httpClient.get<LicensedProductWithUsage[]>(
        `${PLANS_PATH}/${planId}/products/usage`,
      );

      return response.data;
    } catch (error) {
      console.error('Error fetching products usage:', error);

      throw error;
    }
  };
}
