import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';

import { selectUserPlanId } from '../../user/state/selectors';

import {
  fetchProducts,
  fetchProductsSuccess,
  fetchProductsFailure,
} from './slice';
import { ProductsService } from './services';

import type { AxiosInstance } from 'axios';
import type { LicensedProductWithUsage } from './interfaces';

function* fetchProductsHandler() {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');

    if (!httpClient) {
      throw new Error('HTTP client is not available');
    }

    const planId: string | null = yield select(selectUserPlanId);

    if (!planId) throw new Error('No planId provided');

    const productsService = new ProductsService(httpClient);

    const productsWithUsage: LicensedProductWithUsage[] = yield call(
      productsService.fetchProductsUsage,
      planId,
    );

    yield put(fetchProductsSuccess(productsWithUsage));
  } catch (error: unknown) {
    const errorMessage =
      error instanceof Error ? error.message : 'An unknown error occurred';

    yield put(fetchProductsFailure(errorMessage));
  }
}

export default function* productsSaga() {
  yield takeLatest(fetchProducts.type, fetchProductsHandler);
}
