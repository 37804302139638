import type {
  UpdateLicensesRequest,
  UpdateLicensesResponse,
} from './interfaces';
import type { AxiosInstance } from 'axios';

const PLANS_PATH = '/v1/plans';

export class UpdateLicensesService {
  private httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  updateLicenses = async (
    planId: string,
    { productsToAdd, productsToRemove }: UpdateLicensesRequest,
  ): Promise<UpdateLicensesResponse> => {
    try {
      const response = await this.httpClient.post<UpdateLicensesResponse>(
        `${PLANS_PATH}/${planId}/members/products`,
        { productsToAdd, productsToRemove },
      );

      return response.data;
    } catch (error) {
      console.error('Error updating licenses:', error);

      throw error;
    }
  };
}
