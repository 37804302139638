// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.plan-settings-container__product-header {
  margin-bottom: var(--pando-spacing-4);
}
.plan-settings-container__product-header__title {
  height: 39px;
}
.plan-settings-container__product-header__legend {
  height: 29px;
}
.plan-settings-container__product-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: var(--pando-spacing-6);
  border-radius: var(--pando-radii-lg-border-radius);
  background: var(---pando-inky-blue-neutral-surface-100-dark, #1b1834);
}`, "",{"version":3,"sources":["webpack://./src/features/plan-settings/components/plan-settings-container/plan-settings-container.scss"],"names":[],"mappings":"AAEE;EACE,qCAAA;AADJ;AAGI;EACE,YAAA;AADN;AAII;EACE,YAAA;AAFN;AAME;EACE,aAAA;EACA,eAAA;EACA,8BAAA;EACA,qCAAA;EACA,kDAAA;EACA,qEAAA;AAJJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.plan-settings-container {\n  &__product-header {\n    margin-bottom: var(--pando-spacing-4);\n\n    &__title {\n      height: 39px;\n    }\n\n    &__legend {\n      height: 29px;\n    }\n  }\n\n  &__product-content {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    margin-bottom: var(--pando-spacing-6);\n    border-radius: var(--pando-radii-lg-border-radius);\n    background: var(---pando-inky-blue-neutral-surface-100-dark, #1b1834);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
