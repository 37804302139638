import { ArrowLeftIcon, ArrowRightIcon } from '@pluralsight/icons';
import { Button, FlexContainer, FlexItem } from '@pluralsight/react-ng';
import React from 'react';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import useDialogStep from '../../../../shared/dialog/useDialogStep';

import './action-buttons.scss';

interface IActionButtonsProps {
  closeModal: () => void;
}

const TeamStepActionButtons: React.FC<IActionButtonsProps> = ({
  closeModal,
}) => {
  const { t } = useTranslations();

  const { setStepBack, setNextStep } = useDialogStep();

  return (
    <FlexContainer justify="spaceBetween">
      <FlexItem>
        <Button
          className="action-buttons__button"
          size="lg"
          palette="neutral"
          usage="filled"
          onClick={setStepBack}
        >
          <ArrowLeftIcon width={20} />
          {t('common.actionButtons.back')}
        </Button>
      </FlexItem>

      <FlexContainer
        align="center"
        direction="row"
        justify="flexEnd"
        wrap="nowrap"
      >
        <Button
          className="action-buttons__button"
          size="lg"
          palette="action"
          usage="text"
          onClick={closeModal}
        >
          {t('common.actionButtons.cancel')}
        </Button>
        <Button size="lg" palette="action" usage="filled" onClick={setNextStep}>
          {t('common.actionButtons.next')}
          <ArrowRightIcon width={20} />
        </Button>
      </FlexContainer>
    </FlexContainer>
  );
};

export default TeamStepActionButtons;
