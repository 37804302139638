// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-licenses__admonition {
  align-items: center;
  border-radius: var(--pando-radii-lg);
  display: flex;
  margin: var(--pando-spacing-4) 0;
  padding-right: var(--pando-spacing-2);
}
.select-licenses__link {
  text-decoration: underline;
}
.select-licenses__base-container {
  margin-top: var(--pando-spacing-6);
}
.select-licenses__label {
  margin-bottom: var(--pando-spacing-2);
}
.select-licenses__control {
  padding-left: 5px;
}
.select-licenses__license {
  margin-bottom: var(--pando-spacing-4);
}
.select-licenses__license.disabled label {
  opacity: 50%;
}`, "",{"version":3,"sources":["webpack://./src/components/select-licenses/select-licenses.scss"],"names":[],"mappings":"AAEE;EACE,mBAAA;EACA,oCAAA;EACA,aAAA;EACA,gCAAA;EACA,qCAAA;AADJ;AAIE;EACE,0BAAA;AAFJ;AAKE;EACE,kCAAA;AAHJ;AAME;EACE,qCAAA;AAJJ;AAOE;EACE,iBAAA;AALJ;AAQE;EACE,qCAAA;AANJ;AASE;EACE,YAAA;AAPJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.select-licenses {\n  &__admonition {\n    align-items: center;\n    border-radius: var(--pando-radii-lg);\n    display: flex;\n    margin: var(--pando-spacing-4) 0;\n    padding-right: var(--pando-spacing-2);\n  }\n\n  &__link {\n    text-decoration: underline;\n  }\n\n  &__base-container {\n    margin-top: var(--pando-spacing-6);\n  }\n\n  &__label {\n    margin-bottom: var(--pando-spacing-2);\n  }\n\n  &__control {\n    padding-left: 5px;\n  }\n\n  &__license {\n    margin-bottom: var(--pando-spacing-4);\n  }\n\n  &__license.disabled label {\n    opacity: 50%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
