import type { AxiosInstance } from 'axios';
import type { IOrganization } from './interfaces';

const ORGANIZATION_PATH = '/v1/organizations';

export class OrganizationService {
  private httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  fetchOrganization = async (orgId: string): Promise<IOrganization> => {
    try {
      const response = await this.httpClient.get<IOrganization>(
        `${ORGANIZATION_PATH}/${orgId}`,
      );

      return response.data;
    } catch (error) {
      console.error('Error fetching Organization', error);

      throw error;
    }
  };

  updateOrganization = async (org: IOrganization): Promise<IOrganization> => {
    try {
      const orgPayload = { ...org, id: undefined };

      const response = await this.httpClient.patch<IOrganization>(
        `${ORGANIZATION_PATH}/${org.id}`,
        orgPayload,
      );

      return response.data;
    } catch (error) {
      console.error(`Error updating Organization with ID ${org.id}:`, error);

      throw error;
    }
  };
}
