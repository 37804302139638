import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

import createTeamReducer from '../features/create-team/state/slice';
import invitationReducer from '../features/invitation/state/slice';
import usersReducer, {
  importUsersFile,
  setImportFile as setImportUsersFile,
} from '../features/people/state/slice';
import planReducer from '../features/plans/state/slice';
import productsReducer from '../features/products/state/slice';
import rolesReducer from '../features/roles/state/slice';
import teamPendingInvitesReducer from '../features/team-pending-invites/state/slice';
import teamUsersReducer from '../features/team-users/state/slice';
import teamReducer from '../features/team/state/slice';
import teamsReducer, {
  importTeamsFile,
  setImportFile as setImportTeamsFile,
} from '../features/teams/state/slice';
import toastReducer from '../features/toast/state/slice';
import userReducer from '../features/user/state/slice';
import memberLicensesReducer from '../features/update-licenses/state/slice';
import createDialogReducer from '../shared/dialog/state/slice';
import organizationReducer from '../features/organization/state/slice';
import ssoConfigurationReducer, {
  submitSsoConfiguration,
  updateSsoConfiguration,
} from '../features/sso/components/sso-configuration/state/slice';
import ssoConnectionsReducer from '../features/sso/components/sso-conections/state/slice';

import rootSaga from './sagas';

import type { DialogState } from '../shared/dialog/state/interfaces';
import type { ProductsState } from '../features/products/state/interfaces';
import type { UserState } from '../features/user/state/interfaces';
import type { ToastState } from '../features/toast/state/interfaces';
import type { TeamsState } from '../features/teams/state/interfaces';
import type { TeamState } from '../features/team/state/interfaces';
import type { TeamUsersState } from '../features/team-users/state/interfaces';
import type { TeamPendingInvitesState } from '../features/team-pending-invites/state/interfaces';
import type { RolesState } from '../features/roles/state/interfaces';
import type { IPlanState } from '../features/plans/state/interfaces';
import type { PeopleState } from '../features/people/state/interfaces';
import type { InvitationState } from '../features/invitation/state/interfaces';
import type { CreateTeamState } from '../features/create-team/state/interfaces';
import type { AxiosInstance } from 'axios';
import type { EnhancedStore } from '@reduxjs/toolkit';
import type { LicensesState } from '../features/update-licenses/state/interfaces';
import type { IOrganizationState } from '../features/organization/state/interfaces';
import type { ISsoConfigurationState } from '../features/sso/components/sso-configuration/state/interfaces';
import type { ISsoConnectionsState } from '../features/sso/components/sso-conections/state/interfaces';

export const sagaMiddleware = createSagaMiddleware({
  context: {
    httpClient: null as AxiosInstance | null,
  },
});

type StoreReducers = {
  createTeam: CreateTeamState;
  invitations: InvitationState;
  people: PeopleState;
  plan: IPlanState;
  products: ProductsState;
  roles: RolesState;
  team: TeamState;
  teamPendingInvites: TeamPendingInvitesState;
  teamUsers: TeamUsersState;
  teams: TeamsState;
  toast: ToastState;
  user: UserState;
  memberLicenses: LicensesState;
  dialog: DialogState;
  organization: IOrganizationState;
  ssoConfiguration: ISsoConfigurationState;
  ssoConnections: ISsoConnectionsState;
};

const rootReducer = combineReducers({
  createTeam: createTeamReducer,
  invitations: invitationReducer,
  people: usersReducer,
  plan: planReducer,
  products: productsReducer,
  roles: rolesReducer,
  team: teamReducer,
  teamPendingInvites: teamPendingInvitesReducer,
  teamUsers: teamUsersReducer,
  teams: teamsReducer,
  toast: toastReducer,
  user: userReducer,
  memberLicenses: memberLicensesReducer,
  dialog: createDialogReducer,
  organization: organizationReducer,
  ssoConfiguration: ssoConfigurationReducer,
  ssoConnections: ssoConnectionsReducer,
});

const persistedReducer = persistReducer<StoreReducers>(
  {
    key: 'root',
    storage,
    timeout: undefined,
    transforms: [],
    whitelist: [],
  },
  rootReducer,
);

const store: EnhancedStore<Partial<StoreReducers>> = configureStore({
  devTools: {
    name: 'Dashboard Store',
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
      serializableCheck: {
        ignoredActions: [
          submitSsoConfiguration.type,
          updateSsoConfiguration.type,
          setImportUsersFile.type,
          importUsersFile.type,
          setImportTeamsFile.type,
          importTeamsFile.type,
          'persist/PERSIST',
        ],
        ignoredPaths: ['people.importFile.file', 'teams.importFile.file'],
      },
    }).concat([sagaMiddleware]),
  reducer: persistedReducer,
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof rootReducer>;
export type AppState = ReturnType<typeof store.dispatch>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);

export default store;
