// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.basic-information__eye-icon {
  padding-inline-start: unset;
  padding-inline-end: unset;
}
.basic-information .client-secret-field .fieldset__input {
  padding-right: 5rem;
}
.basic-information .client-secret-field .pando-input__startIcon {
  width: unset;
}`, "",{"version":3,"sources":["webpack://./src/features/sso/components/sso-configuration/basic-information-form/basic-information-form.style.scss"],"names":[],"mappings":"AAEE;EACE,2BAAA;EACA,yBAAA;AADJ;AAKI;EACE,mBAAA;AAHN;AAMI;EACE,YAAA;AAJN","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.basic-information {\n  &__eye-icon {\n    padding-inline-start: unset;\n    padding-inline-end: unset;\n  }\n\n  .client-secret-field {\n    .fieldset__input {\n      padding-right: 5rem;\n    }\n\n    .pando-input__startIcon {\n      width: unset;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
