import { GridContainer, GridCell } from '@pluralsight/react-ng';
import React from 'react';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import AddOnItem from './add-on-item/add-on-item';

import type { AddOn } from '../../../products/state/interfaces';

import './add-ons.scss';

interface AddOnsProps {
  addOnData: AddOn[];
  isLoading: boolean;
  onEditAddOn?: (addon: AddOn) => void;
}

const AddOns: React.FC<AddOnsProps> = ({
  addOnData,
  isLoading,
  onEditAddOn,
}) => {
  const { t } = useTranslations();

  if (isLoading) {
    return <div>{t('common.loading')}</div>;
  }

  return (
    <div className="add-ons">
      <div className="add-ons__header">
        <h3 className="add-ons__title">{t('planSettings.addOns.title')}</h3>
      </div>
      <GridContainer spacing="0" style={{ gap: 'var(--pando-spacing-6)' }}>
        {addOnData.map((addon) => (
          <GridCell
            key={addon.id}
            xsmall="12"
            small="12"
            medium="6"
            large="4"
            positionContentY="stretch"
            style={{ height: '100%' }}
          >
            <AddOnItem addon={addon} onEdit={onEditAddOn} />
          </GridCell>
        ))}
      </GridContainer>
    </div>
  );
};

export default AddOns;
