import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';
import type {
  ISsoConfigurationState,
  IStatusPayload,
  TSsoConfigurationPayload,
} from './interfaces';

const INITIAL_STATE: ISsoConfigurationState = {
  isFormModifiedInEdit: false,
  loading: false,
  success: null,
  error: null,
};

const ssoConfigurationSlice = createSlice({
  name: 'ssoConfiguration',
  initialState: INITIAL_STATE,
  reducers: {
    submitSsoConfiguration: (
      state,
      _action: PayloadAction<TSsoConfigurationPayload>,
    ) => {
      state.loading = true;
      state.success = null;
      state.error = null;
    },
    submitSsoConfigurationSuccess: (state) => {
      state.success = true;
      state.loading = false;
      state.error = null;
    },
    submitSsoConfigurationFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
    resetSsoConfiguration: () => ({ ...INITIAL_STATE }),
    updateSsoConnectionStatus: (
      state,
      _action: PayloadAction<IStatusPayload>,
    ) => {
      state.loading = true;
      state.success = null;
      state.error = null;
    },
    updateSsoConnectionStatusSuccess: () => {},
    updateSsoConnectionStatusFailure: () => {},
    updateSsoConfiguration: (
      state,
      _action: PayloadAction<TSsoConfigurationPayload>,
    ) => {
      state.loading = true;
      state.success = null;
      state.error = null;
    },
    updateSsoConfigurationSuccess: (state) => {
      state.loading = false;
      state.success = true;
      state.error = null;
    },
    updateSsoConfigurationFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    updateFormModifiedStatus: (state, action: PayloadAction<boolean>) => {
      state.isFormModifiedInEdit = action.payload;
    },
  },
});

export const {
  submitSsoConfiguration,
  submitSsoConfigurationSuccess,
  submitSsoConfigurationFailure,
  resetSsoConfiguration,
  updateSsoConfiguration,
  updateSsoConfigurationSuccess,
  updateSsoConfigurationFailure,
  updateSsoConnectionStatus,
  updateSsoConnectionStatusSuccess,
  updateSsoConnectionStatusFailure,
  updateFormModifiedStatus,
} = ssoConfigurationSlice.actions;

export default ssoConfigurationSlice.reducer;
