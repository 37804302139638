import { createSelector } from 'reselect';

import { hasExpectedRoleInScope } from '../../../shared/utils';
import { USER_ROLE } from '../../../shared/constants';

import type { AppState } from '../../../store';
import type { User, UserState } from './interfaces';

const selectUserState = (state: AppState) => state.user;

export const selectUser = createSelector(
  [selectUserState],
  (userState: UserState): User | null => userState.item,
);

export const selectAccess = createSelector(
  [selectUserState],
  ({ item }: UserState): boolean =>
    !item ||
    !hasExpectedRoleInScope(item, [
      {
        roleName: USER_ROLE.SUPER_ADMIN,
        scope: 'organizationScope',
      },
      {
        roleName: USER_ROLE.SYSTEM_ADMIN,
        scope: 'organizationScope',
      },
      {
        roleName: USER_ROLE.PLAN_ADMIN,
        scope: 'planScope',
      },
    ]),
);

export const selectUserPlanId = createSelector(
  [selectUser],
  (user: User | null) => {
    const plans = user?.permissionScope[0].plans;
    let planId = plans?.length ? plans[0].planId : null;

    if (
      !planId &&
      user &&
      // Super admin can access all plans
      hasExpectedRoleInScope(user, [
        {
          roleName: USER_ROLE.SUPER_ADMIN,
          scope: 'organizationScope',
        },
      ])
    ) {
      planId = user?.currentPlanId ?? null;
    }

    return planId;
  },
);
