import { createSlice } from '@reduxjs/toolkit';

import type { DialogState } from './interfaces';
import type { PayloadAction } from '@reduxjs/toolkit';

const INITIAL_STATE: DialogState = {
  step: 0,
};

const dialogSlice = createSlice({
  name: 'dialog',
  initialState: INITIAL_STATE,
  reducers: {
    setDialogStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    setDialogNextStep: (state) => {
      state.step = state.step + 1;
    },
    setDialogBackStep: (state) => {
      state.step = state.step - 1;
    },
  },
});

export const { setDialogStep, setDialogNextStep, setDialogBackStep } =
  dialogSlice.actions;

export default dialogSlice.reducer;
