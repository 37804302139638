// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-settings {
  width: 1000px;
  display: flex;
  flex-direction: column;
  padding: var(--page-margin-top, 56px) var(--page-margin-right, 56px) var(--page-margin-bottom, 56px) var(--page-margin-left, 56px);
}
@media (max-width: 1200px) {
  .account-settings {
    width: 100%;
  }
}
.account-settings__header {
  align-items: center;
  display: flex;
  height: 44px;
  justify-content: space-between;
}
.account-settings__header-title {
  font-size: var(--pando-font-sizes-3xl);
  font-weight: 600;
  line-height: 125%;
}
.account-settings__subheader {
  margin-bottom: var(--pando-spacing-6);
}
.account-settings__subheader-span {
  color: var(--pando-colors-neutral-text-200, #a5aacf);
  font-size: var(--pando-font-sizes-md);
  line-height: 150%;
}`, "",{"version":3,"sources":["webpack://./src/pages/account-settings/account-settings.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,aAAA;EACA,sBAAA;EACA,kIAAA;AAAF;AAGE;EAPF;IAQI,WAAA;EAAF;AACF;AAEE;EACE,mBAAA;EACA,aAAA;EACA,YAAA;EACA,8BAAA;AAAJ;AAEI;EACE,sCAAA;EACA,gBAAA;EACA,iBAAA;AAAN;AAIE;EACE,qCAAA;AAFJ;AAII;EACE,oDAAA;EACA,qCAAA;EACA,iBAAA;AAFN","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.account-settings {\n  width: 1000px;\n  display: flex;\n  flex-direction: column;\n  padding: var(--page-margin-top, 56px) var(--page-margin-right, 56px)\n    var(--page-margin-bottom, 56px) var(--page-margin-left, 56px);\n\n  @media (max-width: 1200px) {\n    width: 100%;\n  }\n\n  &__header {\n    align-items: center;\n    display: flex;\n    height: 44px;\n    justify-content: space-between;\n\n    &-title {\n      font-size: var(--pando-font-sizes-3xl);\n      font-weight: 600;\n      line-height: 125%;\n    }\n  }\n\n  &__subheader {\n    margin-bottom: var(--pando-spacing-6);\n\n    &-span {\n      color: var(--pando-colors-neutral-text-200, #a5aacf);\n      font-size: var(--pando-font-sizes-md);\n      line-height: 150%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
