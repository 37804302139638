import { Button, FlexContainer } from '@pluralsight/react-ng';
import React from 'react';
import { useSelector } from 'react-redux';
import { ArrowRightIcon } from '@pluralsight/icons';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import {
  selectMemberProducts,
  selectProductIds,
  selectProductsToAdd,
  selectProductsToRemove,
} from '../../state/selectors';
import useProducts from '../../../products/hooks/useProducts';
import useDialogStep from '../../../../shared/dialog/useDialogStep';

interface SelectLicensesActionButtonsProps {
  closeModal: () => void;
}

const SelectLicensesActionButtons: React.FC<
  SelectLicensesActionButtonsProps
> = ({ closeModal }) => {
  const { t } = useTranslations();
  const productsToAdd = useSelector(selectProductsToAdd);
  const productsToRemove = useSelector(selectProductsToRemove);
  const productIds = useSelector(selectProductIds);
  const currentMemberProducts = useSelector(selectMemberProducts);
  const isBaseSelected = useProducts(productIds);

  const { setNextStep } = useDialogStep();

  const isActive =
    (isBaseSelected || currentMemberProducts.length) &&
    (productsToAdd.length || productsToRemove.length);

  return (
    <FlexContainer
      align="center"
      direction="row"
      justify="flexEnd"
      wrap="nowrap"
    >
      <Button
        className="action-buttons__button"
        size="lg"
        palette="action"
        usage="text"
        aria-label={t('licenseDialog.ariaLabel.cancel')}
        onClick={closeModal}
      >
        {t('common.actionButtons.cancel')}
      </Button>
      <Button
        size="lg"
        palette={isActive ? 'action' : 'neutral'}
        usage="filled"
        disabled={!isActive}
        aria-label={t('licenseDialog.ariaLabel.reviewUpdates')}
        onClick={setNextStep}
      >
        {t('licenseDialog.reviewUpdates')}
        <ArrowRightIcon width={20} />
      </Button>
    </FlexContainer>
  );
};

export default SelectLicensesActionButtons;
