import { Button } from '@pluralsight/react-ng';
import './revoke-invite-dialog.scss';
import React, { forwardRef } from 'react';
import { useDispatch } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import CustomDialog from '../../../../components/custom-dialog/custom-dialog';
import { revokeInvitation } from '../../state/slice';

import { dialogHeaderTranslations } from './translations';

import type { Invite } from '../../../people/state/interfaces';
import type { Language } from '../../../../translations/interfaces';
import type {
  ContentType,
  HeaderType,
} from '../../../../components/custom-dialog/custom-dialog';

interface IRevokeInviteDialogProps {
  invite?: Invite | null;
  closeModal: () => void;
}

const RevokeInviteDialog = forwardRef<
  HTMLDialogElement | null,
  IRevokeInviteDialogProps
>(({ invite, closeModal }, ref) => {
  const dispatch = useDispatch();
  const { t, language: currentLanguage } = useTranslations();

  const handleAction = () => {
    if (!invite) return;

    dispatch(revokeInvitation(invite));
    closeModal();
  };

  const dialogHeader: HeaderType = {
    1: invite?.email
      ? dialogHeaderTranslations(invite.email)[currentLanguage as Language]
      : t('invitation.revokeInvite.deleteInviteFromPlan'),
  };

  const dialogContent: ContentType = {
    1: <span>{t('invitation.revokeInvite.deletingThisInvite')}</span>,
  };

  const getActionButtons = (step: number): React.JSX.Element => {
    switch (step) {
      case 1:
        return (
          <>
            <Button
              size="lg"
              palette="action"
              usage="text"
              onClick={closeModal}
            >
              {t('common.actionButtons.cancel')}
            </Button>
            <Button
              size="lg"
              palette="danger"
              usage="filled"
              onClick={handleAction}
            >
              {t('invitation.revokeInvite.deleteInvite')}
            </Button>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <CustomDialog
      id="revoke-invite"
      ref={ref}
      dialogHeader={dialogHeader}
      dialogContent={dialogContent}
      actionButtons={getActionButtons}
      multipleSteps={false}
      isWarning={true}
      closeModal={closeModal}
    />
  );
});

export default RevokeInviteDialog;
