import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import type { UnknownAction } from '@reduxjs/toolkit';

/**
 * A hook that dispatches an action at regular intervals
 *
 * @param actionCreator - A function that returns the action to dispatch
 * @param intervalMs - The interval in milliseconds between dispatches
 */
const useDispatchPolling = <T extends (...args: any[]) => UnknownAction>(
  actionCreator: T,
  intervalMs: number,
  ...args: Parameters<T>
) => {
  const dispatch = useDispatch();
  const actionCreatorRef = useRef(actionCreator);
  const argsRef = useRef(args);

  // Update refs when dependencies change
  useEffect(() => {
    actionCreatorRef.current = actionCreator;
    argsRef.current = args;
  }, [actionCreator, args]);

  const fetchData = useCallback(() => {
    dispatch(actionCreatorRef.current(...argsRef.current));
  }, [dispatch]);

  useEffect(() => {
    fetchData();

    const intervalId = setInterval(fetchData, intervalMs || 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, [fetchData, intervalMs]);
};

export default useDispatchPolling;
