import { Button, FlexContainer, Show } from '@pluralsight/react-ng';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import { handleMenuKeyBoardNavigation } from '../../../../shared/helpers/handle-menu-keyboard-navigation';
import UsersTable from '../../../../components/users-table/users-table';
import PeopleIcon from '../../../../assets/people.svg';
import Placeholder from '../../../../components/placeholder/placeholder';
import { selectUser } from '../../../user/state/selectors';
import { selectTeamUsersList } from '../../state/selectors';
import {
  clearAssignedManagers,
  clearTeamSelections,
  fetchTeamUsers,
} from '../../state/slice';
import AddManagersDialog from '../add-team-managers-dialog/add-managers-dialog';
import { teamMemberTableColumns } from '../team-member-columns';
import './team-managers-container.scss';
import { selectTeamItem } from '../../../team/state/selectors';
import {
  assignUser,
  clearAssignedMembers,
} from '../../../create-team/state/slice';
import RemoveTeamMembersDialog from '../remove-team-members-dialog/remove-team-members-dialog';
import MoveTeamMembersDialog from '../move-team-members-dialog/move-team-members-dialog';
import useMapUsers from '../../../people/hooks/useMapUsers';
import { setDialogStep } from '../../../../shared/dialog/state/slice';

import type { KeyBoardActions } from '../../../../../src/shared/helpers/handle-menu-keyboard-navigation';
import type { Member, UsersTableItem } from '../../../../shared/interfaces';
import type { Team } from '../../../team/state/interfaces';

interface TeamManagersContainerProps {
  teamId: string | null;
}

const TeamManagersContainer: React.FC<TeamManagersContainerProps> = ({
  teamId,
}) => {
  const [teamManagers, setTeamManagers] = useState<UsersTableItem[]>([]);
  const { t } = useTranslations();
  const { toUserTableItems } = useMapUsers();
  const dialogRef = useRef<HTMLDialogElement>(null);
  const removeTeamMembersDialogRef = useRef<HTMLDialogElement>(null);
  const moveTeamMembersDialogRef = useRef<HTMLDialogElement>(null);
  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  const team: Team | null = useSelector(selectTeamItem);
  const teamMembers: Member[] = useSelector(selectTeamUsersList);

  useEffect(() => {
    if (teamId) {
      dispatch(fetchTeamUsers(teamId));
    }
  }, [dispatch, teamId, user]);

  const showDialog = () => {
    dialogRef.current?.showModal();
    dispatch(setDialogStep(1));
  };
  const closeDialog = () => {
    dialogRef.current?.close();
    dispatch(setDialogStep(0));
    dispatch(clearAssignedManagers());
  };

  const showRemoveTeamMembersDialog = (member: UsersTableItem) => {
    dispatch(assignUser(member));
    removeTeamMembersDialogRef.current?.showModal();
  };
  const closeRemoveTeamMembersDialog = () => {
    removeTeamMembersDialogRef.current?.close();
    dispatch(clearAssignedMembers());
  };

  const showMoveTeamMembersDialog = (member: UsersTableItem) => {
    dispatch(assignUser(member));
    moveTeamMembersDialogRef.current?.showModal();
  };
  const closeMoveTeamMembersDialog = () => {
    moveTeamMembersDialogRef.current?.close();
    dispatch(clearAssignedMembers());
    dispatch(clearTeamSelections());
  };

  useEffect(() => {
    const managerIds = team?.managerIds || [];
    const teamManagers: Member[] = teamMembers.filter((member) =>
      managerIds.includes(member.id),
    );
    const mappedUsers = toUserTableItems(teamManagers);

    setTeamManagers(mappedUsers);
  }, [teamMembers, team]);

  const renderDropdownOptions = useCallback(
    (member: UsersTableItem, actions: KeyBoardActions) => {
      return (
        <div
          role="menu"
          onKeyDown={handleMenuKeyBoardNavigation(
            'common-dropdown-option',
            actions,
          )}
          tabIndex={0}
        >
          <div
            className="common-dropdown-option"
            onClick={() => []}
            role="menuitem"
            tabIndex={0}
            ref={(el) => el?.focus()}
          >
            <span className="dropdown-option-span">
              {t('teamUsers.tableOptions.editEmail')}
            </span>
          </div>
          <div
            className="common-dropdown-option"
            onClick={() => []}
            role="menuitem"
            tabIndex={0}
          >
            <span className="dropdown-option-span">
              {t('teamUsers.tableOptions.editNote')}
            </span>
          </div>
          <div
            className="common-dropdown-option"
            onClick={() => showMoveTeamMembersDialog(member)}
            role="menuitem"
            tabIndex={0}
          >
            <span className="dropdown-option-span">
              {t('teamUsers.tableOptions.moveTeams')}
            </span>
            <span className="dropdown-option-description">
              {t('teamUsers.tableOptions.moveTeamsDescription')}
            </span>
          </div>
          <div
            className="common-dropdown-option"
            onClick={() => showRemoveTeamMembersDialog(member)}
            role="menuitem"
            tabIndex={0}
          >
            <span className="dropdown-option-span">
              {t('teamUsers.tableOptions.removeTeam')}
            </span>
            <span className="dropdown-option-description">
              {t('teamUsers.tableOptions.removeTeamDescription')}
            </span>
          </div>
        </div>
      );
    },
    [t, showRemoveTeamMembersDialog],
  );

  return (
    <>
      <FlexContainer justify="end">
        <Button size="lg" palette="action" usage="filled" onClick={showDialog}>
          {t('teamUsers.addManagers')}
        </Button>
        <AddManagersDialog ref={dialogRef} closeModal={closeDialog} />
      </FlexContainer>
      <Show
        when={!!teamManagers.length}
        fallback={
          <div className="team-managers-container__placeholder">
            <Placeholder icon={PeopleIcon}>
              <div className="team-managers-container__placeholder-message">
                {t('teamUsers.noTeamManagers')}
              </div>

              <FlexContainer justify="center">
                <button
                  className="team-managers-container__placeholder-button"
                  aria-label="Add team managers"
                  onClick={showDialog}
                >
                  {t('teamUsers.addManagers')}
                </button>
              </FlexContainer>
            </Placeholder>
          </div>
        }
      >
        <UsersTable
          columns={teamMemberTableColumns}
          people={teamManagers}
          dropDownOptions={renderDropdownOptions}
        />
        <MoveTeamMembersDialog
          ref={moveTeamMembersDialogRef}
          closeModal={closeMoveTeamMembersDialog}
        />
        <RemoveTeamMembersDialog
          ref={removeTeamMembersDialogRef}
          closeModal={closeRemoveTeamMembersDialog}
          teamIdSelected={teamId ?? ''}
          singleRemove={true}
        />
      </Show>
    </>
  );
};

export default TeamManagersContainer;
