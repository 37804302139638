// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.licenses-step__conteiner {
  min-height: 503px;
}
.licenses-step__licenses-container {
  height: 400px;
  overflow: auto;
}
.licenses-step__loading {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/features/invitation/components/licenses-step/licenses-step.scss"],"names":[],"mappings":"AAEE;EACE,iBAAA;AADJ;AAIE;EACE,aAAA;EACA,cAAA;AAFJ;AAKE;EACE,aAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAHJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.licenses-step {\n  &__conteiner {\n    min-height: 503px;\n  }\n\n  &__licenses-container {\n    height: 400px;\n    overflow: auto;\n  }\n\n  &__loading {\n    height: 400px;\n    display: flex;\n    align-items: center;\n    justify-content: center;;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
