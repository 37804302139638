import i18n from 'i18next';
import {
  call,
  getContext,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';

import { PlansService } from '../../../shared/services';
import { sendInvitationSuccess } from '../../invitation/state/slice';
import { selectUserPlanId } from '../../user/state/selectors';
import { setToast } from '../../toast/state/slice';
import { getDateFormat } from '../../../shared/utils';

import {
  deleteUser,
  deleteUserFailure,
  deleteUserSuccess,
  fetchInvites,
  fetchInvitesFailure,
  fetchInvitesSuccess,
  fetchPeople,
  fetchPeopleFailure,
  fetchPeopleSuccess,
  fetchUploadUsersUrl,
  fetchUploadUsersUrlFailure,
  fetchUploadUsersUrlSuccess,
  importUsersFile,
  importUsersFileFailure,
  importUsersFileSuccess,
  updateInviteNoteFailure,
  updateInviteNoteSuccess,
  updateMemberNoteFailure,
  updateMemberNoteSuccess,
  updateNote,
  updateUserRoles,
  updateUserRolesFailure,
  updateUserRolesSuccess,
} from './slice';
import { NOTE_TYPE_ENUM } from './interfaces';
import { PeopleService } from './services';

import type { Team } from '../../team/state/interfaces';
import type {
  ImportUploadUrlResponse,
  Invite,
  InviteStatus,
  NoteType,
  UpdateRole,
} from './interfaces';
import type {
  Member,
  PlanInvitesResponseItem,
  UsersTableItem,
} from '../../../shared/interfaces';
import type { AxiosInstance } from 'axios';
import type { PayloadAction } from '@reduxjs/toolkit';

function* fetchPeopleHandler() {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');

    const planId: string | null = yield select(selectUserPlanId);

    if (!planId) throw new Error('No planId provided');

    const plansService = new PlansService(httpClient);
    const planMembers: Member[] = yield call(
      plansService.fetchPlanMembers,
      planId,
    );

    const people: Member[] = planMembers.map((planMember) => ({
      id: planMember.id,
      active: true,
      username: '',
      firstName: planMember.firstName,
      lastName: planMember.lastName,
      email: planMember.email,
      roles: planMember.roles,
      products: planMember.products,
      teams: planMember.teams,
      lastSignIn: getDateFormat(planMember.lastSignIn),
      note: planMember.note,
    }));

    yield put(fetchPeopleSuccess(people));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetchPeopleFailure(error.message));
    } else {
      yield put(fetchPeopleFailure('An unknown error occurred'));
    }
  }
}

function* fetchInvitesHandler() {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');

    const planId: string | null = yield select(selectUserPlanId);

    if (!planId) throw new Error('No planId provided');

    const plansService = new PlansService(httpClient);
    const planInvites: PlanInvitesResponseItem[] = yield call(
      plansService.fetchPlanInvites,
      planId,
    );

    const invites: Invite[] = planInvites.map((planInvite) => ({
      id: planInvite.id,
      psUserId: planInvite.psUserId,
      email: planInvite.email,
      note: planInvite.note,
      status: planInvite.status as InviteStatus,
      roles: planInvite.roles,
      products: planInvite.products,
      teams: planInvite.teams as Team[],
      inviteRedemptionLink: planInvite.inviteRedemptionLink,
      expiresOn: planInvite.expiresOn,
      sendDate: planInvite.sendDate,
    }));

    yield put(fetchInvitesSuccess(invites));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetchInvitesFailure(error.message));
    } else {
      yield put(fetchInvitesFailure('An unknown error occurred'));
    }
  }
}

function* updateNoteHandler(
  action: PayloadAction<{
    id: string;
    note: string;
    type: NoteType;
  }>,
) {
  try {
    const planId: string | null = yield select(selectUserPlanId);

    if (!planId) throw new Error('No planId provided');

    const httpClient: AxiosInstance = yield getContext('httpClient');
    const plansService = new PlansService(httpClient);
    const isUser = action.payload.type === NOTE_TYPE_ENUM.MEMBER;
    const successMsg = i18n.t('noteDialog.noteSuccess', {
      type: isUser
        ? i18n.t('noteDialog.user.possessive')
        : i18n.t('noteDialog.invite.possessive'),
    });
    const service = isUser
      ? plansService.updateMemberNote
      : plansService.updateInviteNote;

    const patchData: Member | Invite = yield call(
      service,
      action.payload.id,
      planId,
      action.payload.note,
    );

    yield put(
      setToast({
        heading: '',
        palette: 'success',
        text: successMsg,
      }),
    );

    const actionSuccess = isUser
      ? updateMemberNoteSuccess
      : updateInviteNoteSuccess;

    yield put(
      actionSuccess({
        id: action.payload.id,
        note: patchData.note as string,
      }),
    );
  } catch (error) {
    const isUser = action.payload.type === NOTE_TYPE_ENUM.MEMBER;
    const actionFailure = isUser
      ? updateMemberNoteFailure
      : updateInviteNoteFailure;

    if (error instanceof Error) {
      yield put(actionFailure(error.message));
    } else {
      yield put(actionFailure('An unknown error occurred'));
    }

    yield put(
      setToast({
        heading: i18n.t('noteDialog.errorHeader'),
        palette: 'danger',
        text: i18n.t('noteDialog.errorBody', {
          type: isUser
            ? i18n.t('noteDialog.user.possessive')
            : i18n.t('noteDialog.invite.possessive'),
        }),
      }),
    );
  }
}

function* fetchUploadImportUsersUrlHandler() {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');

    const planId: string | null = yield select(selectUserPlanId);

    if (!planId) throw new Error('No planId provided');

    const peopleService = new PeopleService(httpClient);

    const { url }: ImportUploadUrlResponse = yield call(
      peopleService.fetchUploadImporUserstUrl,
      planId,
    );

    yield put(fetchUploadUsersUrlSuccess({ url }));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetchUploadUsersUrlFailure(error.message));
    } else {
      yield put(fetchUploadUsersUrlFailure('An error occurred'));
    }
  }
}

function* importUserHandler(action: PayloadAction<{ file: File }>) {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');
    const planId: string | null = yield select(selectUserPlanId);

    if (!planId) throw new Error('No planId provided');

    const peopleService = new PeopleService(httpClient);

    yield put(fetchUploadUsersUrl());

    const { payload } = yield take(fetchUploadUsersUrlSuccess.type);

    const response: string = yield call(
      peopleService.uploadImportUsersFile,
      payload.url,
      action.payload.file,
    );

    yield put(importUsersFileSuccess(response));

    yield put(
      setToast({
        heading: i18n.t('people.importUsers.importSuccessHeader'),
        palette: 'success',
        text: i18n.t('people.importUsers.importSuccessText'),
      }),
    );
  } catch (error) {
    if (error instanceof Error) {
      yield put(importUsersFileFailure(error.message));
    } else {
      yield put(importUsersFileFailure('An error occurred'));
    }

    yield put(
      setToast({
        heading: i18n.t('people.importUsers.importErrorHeader'),
        palette: 'danger',
        text: i18n.t('people.importUsers.importErrorText'),
      }),
    );
  }
}

function* updateUserRolesHandler(action: PayloadAction<UpdateRole>) {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');
    const planId: string | null = yield select(selectUserPlanId);

    if (!planId) throw new Error('No planId provided');

    const plansService = new PeopleService(httpClient);
    const {
      rolesToAdd,
      rolesToRemove,
      newUserRoles: roles,
      user,
    } = action.payload;

    yield call(plansService.updateUserRoles, planId, {
      rolesToAdd,
      rolesToRemove,
    });

    yield put(updateUserRolesSuccess({ userId: user.id, roles }));

    yield put(
      setToast({
        heading: i18n.t('people.updateRolesDialog.successTitle', {
          user: `${user.firstName} ${user.lastName}`,
        }),
        palette: 'success',
        text: i18n.t('people.updateRolesDialog.successDescription'),
      }),
    );
  } catch (error) {
    yield put(
      setToast({
        heading: i18n.t('people.updateRolesDialog.errorTitle'),
        palette: 'danger',
        text: i18n.t('people.updateRolesDialog.errorDescription'),
      }),
    );

    if (error instanceof Error) {
      yield put(updateUserRolesFailure(error.message));
    } else {
      yield put(updateUserRolesFailure('An unknown error occurred'));
    }
  }
}

function* deleteUserHandler(action: PayloadAction<UsersTableItem>) {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');
    const planId: string | null = yield select(selectUserPlanId);

    if (!planId) throw new Error('No planId provided');

    const plansService = new PeopleService(httpClient);
    const { id } = action.payload;

    yield call(plansService.deleteUser, planId, id);

    yield put(deleteUserSuccess(id));

    yield put(
      setToast({
        heading: '',
        palette: 'success',
        text: i18n.t('people.deleteUser.successDescription'),
      }),
    );
  } catch (error) {
    yield put(
      setToast({
        heading: i18n.t('people.deleteUser.errorTitle'),
        palette: 'danger',
        text: i18n.t('people.deleteUser.errorDescription'),
      }),
    );

    if (error instanceof Error) {
      yield put(deleteUserFailure(error.message));
    } else {
      yield put(deleteUserFailure('An unknown error occurred'));
    }
  }
}

export default function* peopleSagas() {
  yield takeLatest(fetchPeople, fetchPeopleHandler);
  yield takeLatest(sendInvitationSuccess, fetchPeopleHandler);
  yield takeLatest(fetchInvites, fetchInvitesHandler);
  yield takeLatest(updateNote.type, updateNoteHandler);
  yield takeLatest(fetchUploadUsersUrl.type, fetchUploadImportUsersUrlHandler);
  yield takeLatest(importUsersFile.type, importUserHandler);
  yield takeLatest(updateUserRoles.type, updateUserRolesHandler);
  yield takeLatest(deleteUser.type, deleteUserHandler);
}
