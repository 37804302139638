import i18n from 'i18next';
import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';

import { setToast } from '../../toast/state/slice';
import { selectUserPlanId } from '../../user/state/selectors';
import { fetchTeams } from '../../teams/state/slice';

import { TeamService } from './services';
import { createTeam, createTeamFailure, createTeamSuccess } from './slice';

import type { Team } from './interfaces';
import type { AxiosInstance } from 'axios';
import type { PayloadAction } from '@reduxjs/toolkit';

function* createTeamHandler(action: PayloadAction<Team>) {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');
    const createTeamService = new TeamService(httpClient);

    const planId: string | null = yield select(selectUserPlanId);

    if (!planId) throw new Error('No planId provided');

    const response: undefined = yield call(
      createTeamService.createTeam,
      planId,
      action.payload,
    );

    yield put(createTeamSuccess(response));

    yield put(
      setToast({
        heading: i18n.t('toasts.headingTeamCreated'),
        palette: 'success',
        text: i18n.t('toasts.goToTeamDetailsCreated'),
      }),
    );

    yield put(fetchTeams());
  } catch (error) {
    if (error instanceof Error) {
      yield put(createTeamFailure(error.message));
    } else {
      yield put(
        createTeamFailure(
          'Oops! An error occurred while trying to create this team. Please try again.',
        ),
      );
    }

    yield put(
      setToast({
        heading: i18n.t('toasts.headingTeamNotCreated'),
        palette: 'danger',
        text: i18n.t('toasts.goToTeamDetailsNotCreated'),
      }),
    );
  }
}

export default function* createTeamSagas() {
  yield takeLatest(createTeam.type, createTeamHandler);
}
