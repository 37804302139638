// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-usage-widget {
  border-radius: var(--pando-radii-md);
  overflow: hidden;
}
.product-usage-widget__header {
  background-color: var(--pando-inky-blue-neutral-surface-300-dark);
  padding: var(--pando-spacing-5);
}
.product-usage-widget__header-title {
  font-weight: 700;
  font-size: var(--pando-font-sizes-md);
  margin: 0;
}
.product-usage-widget__header-value {
  font-size: var(--pando-font-sizes-sm);
  white-space: nowrap;
}
.product-usage-widget__body-progress-bar {
  margin-bottom: var(--pando-spacing-4);
}
.product-usage-widget__body {
  background-color: var(--pando-inky-blue-neutral-surface-200-dark);
  padding: var(--pando-spacing-5);
  height: 100%;
}
.product-usage-widget__body-usage-info-item {
  font-size: var(--pando-font-sizes-sm);
}
.product-usage-widget__body-usage-info-item .icon-redeemed {
  color: var(--pando-inky-blue-action-text-200-dark);
}
.product-usage-widget__body-usage-info-item .icon-pending {
  color: var(--pando-inky-blue-action-text-initial-dark);
}
.product-usage-widget__body-usage-info-item .icon-available {
  color: var(--pando-inky-blue-neutral-border-200-dark);
}
.product-usage-widget__body-usage-info-item_value, .product-usage-widget__body-usage-info-item_label {
  margin-left: var(--pando-spacing-2);
}`, "",{"version":3,"sources":["webpack://./src/features/people/components/product-usage-widget/product-usage-widget.scss"],"names":[],"mappings":"AACA;EACE,oCAAA;EACA,gBAAA;AAAF;AAEE;EACE,iEAAA;EACA,+BAAA;AAAJ;AAGE;EACE,gBAAA;EACA,qCAAA;EACA,SAAA;AADJ;AAIE;EACE,qCAAA;EACA,mBAAA;AAFJ;AAKE;EACE,qCAAA;AAHJ;AAME;EACE,iEAAA;EACA,+BAAA;EACA,YAAA;AAJJ;AAOE;EACE,qCAAA;AALJ;AAOI;EACE,kDAAA;AALN;AAQI;EACE,sDAAA;AANN;AASI;EACE,qDAAA;AAPN;AAUI;EAEE,mCAAA;AATN","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.product-usage-widget {\n  border-radius: var(--pando-radii-md);\n  overflow: hidden;\n\n  &__header {\n    background-color: var(--pando-inky-blue-neutral-surface-300-dark);\n    padding: var(--pando-spacing-5);\n  }\n\n  &__header-title {\n    font-weight: 700;\n    font-size: var(--pando-font-sizes-md);\n    margin: 0;\n  }\n\n  &__header-value {\n    font-size: var(--pando-font-sizes-sm);\n    white-space: nowrap;\n  }\n\n  &__body-progress-bar {\n    margin-bottom: var(--pando-spacing-4);\n  }\n\n  &__body {\n    background-color: var(--pando-inky-blue-neutral-surface-200-dark);\n    padding: var(--pando-spacing-5);\n    height: 100%;\n  }\n\n  &__body-usage-info-item {\n    font-size: var(--pando-font-sizes-sm);\n\n    & .icon-redeemed {\n      color: var(--pando-inky-blue-action-text-200-dark);\n    }\n\n    & .icon-pending {\n      color: var(--pando-inky-blue-action-text-initial-dark);\n    }\n\n    & .icon-available {\n      color: var(--pando-inky-blue-neutral-border-200-dark);\n    }\n\n    &_value,\n    &_label {\n      margin-left: var(--pando-spacing-2);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
