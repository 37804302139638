import {
  all,
  call,
  delay,
  fork,
  getContext,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';

import {
  selectActiveSsoConnections,
  selectSingleSsoConnection,
} from '../../sso-conections/state/selectors';
import { updateSingleSsoConnection } from '../../sso-conections/state/slice';
import { fetchOrganization } from '../../../../organization/state/slice';
import { selectOrgId } from '../../../../plans/state/selectors';

import { SsoConfigurationService } from './services';
import {
  submitSsoConfiguration,
  submitSsoConfigurationFailure,
  submitSsoConfigurationSuccess,
  updateSsoConfiguration,
  updateSsoConfigurationFailure,
  updateSsoConfigurationSuccess,
  updateSsoConnectionStatus,
  updateSsoConnectionStatusFailure,
  updateSsoConnectionStatusSuccess,
} from './slice';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { AxiosInstance } from 'axios';
import type { ISsoConnection } from '../../sso-conections/state/interfaces';
import type { IStatusPayload, TSsoConfigurationPayload } from './interfaces';

function* submitSsoConfigurationHandler(
  action: PayloadAction<TSsoConfigurationPayload>,
) {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');

    if (!httpClient) {
      throw new Error('HTTP client is not available');
    }

    const ssoConfigService = new SsoConfigurationService(httpClient);

    const ssoConfigData: TSsoConfigurationPayload = action.payload;

    yield call(ssoConfigService.submitSsoConfiguration, ssoConfigData);

    yield put(submitSsoConfigurationSuccess());
  } catch (error) {
    const errorMessage =
      typeof error === 'string'
        ? error
        : error instanceof Error
          ? error.message
          : 'Unknown error';

    yield put(submitSsoConfigurationFailure(errorMessage));
  }
}

function* updateSsoConfigurationHandler(
  action: PayloadAction<TSsoConfigurationPayload>,
) {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');

    if (!httpClient) {
      throw new Error('HTTP client is not available');
    }

    const ssoConfigService = new SsoConfigurationService(httpClient);

    const ssoConfigData: TSsoConfigurationPayload = action.payload;

    const currentSsoConnection: ISsoConnection | undefined = yield select(
      selectSingleSsoConnection,
    );

    const newStatus = ssoConfigData.status ? 'ACTIVE' : 'INACTIVE';

    yield call(ssoConfigService.updateSsoConnection, ssoConfigData);

    if (currentSsoConnection?.status !== newStatus) {
      const statusPayload = {
        status: newStatus,
        id: ssoConfigData.id,
      } as IStatusPayload;

      yield put(updateSsoConnectionStatus(statusPayload));

      const { success, failure, timeout } = yield race({
        success: take(updateSsoConnectionStatusSuccess.type),
        failure: take(updateSsoConnectionStatusFailure.type),
        timeout: delay(5000),
      });

      if (success) {
        yield put(updateSsoConfigurationSuccess());
      } else if (failure) {
        yield put(
          updateSsoConfigurationFailure(
            'Failed to update SSO connection status',
          ),
        );
      } else if (timeout) {
        yield put(updateSsoConfigurationFailure('Request timed out'));
      }
    } else {
      yield put(updateSsoConfigurationSuccess());
    }
  } catch (error: any) {
    yield put(updateSsoConfigurationFailure(error.message || 'Unknown error'));
  }
}

function* updateSsoConnectionStatusHandler(
  action: PayloadAction<IStatusPayload>,
) {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');

    if (!httpClient) {
      throw new Error('HTTP client is not available');
    }

    const ssoConfigService = new SsoConfigurationService(httpClient);

    const statusPayload = action.payload;

    yield call(ssoConfigService.updateSsoConnectionStatus, statusPayload);
    yield put(updateSsoConnectionStatusSuccess());
    yield put(updateSingleSsoConnection(statusPayload));

    // If disabling SSO and this was the last active connection, fetch organization
    if (statusPayload.status === 'INACTIVE') {
      const activeSsoConnections: ISsoConnection[] = yield select(
        selectActiveSsoConnections,
      );

      if (activeSsoConnections.length === 0) {
        const orgId: string | undefined = yield select(selectOrgId);

        if (orgId) {
          yield put(fetchOrganization({ orgId }));
        }
      }
    }
  } catch (error) {
    yield put(updateSsoConnectionStatusFailure());

    throw error;
  }
}

function* watchSubmitSsoConfiguration() {
  yield takeLatest(submitSsoConfiguration.type, submitSsoConfigurationHandler);
}

function* watchUpdateSsoConfiguration() {
  yield takeLatest(updateSsoConfiguration.type, updateSsoConfigurationHandler);
}

function* watchUpdateSsoConnectionStatus() {
  yield takeLatest(
    updateSsoConnectionStatus.type,
    updateSsoConnectionStatusHandler,
  );
}

export default function* ssoConfigurationSagas() {
  yield all([
    fork(watchSubmitSsoConfiguration),
    fork(watchUpdateSsoConfiguration),
    fork(watchUpdateSsoConnectionStatus),
  ]);
}
