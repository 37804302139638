import {
  TabContent,
  FlexContainer,
  TabsList,
  TabsRoot,
  TabTrigger,
} from '@pluralsight/react-ng';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import PlanSettingsContainer from '../../features/plan-settings/components/plan-settings-container/plan-settings-container';
import RoleRestrictedComponent from '../../components/role-restricted-component/role-restricted-component';
import SsoConnections from '../../features/sso/components/sso-conections/sso-connections';
import { USER_ROLE } from '../../shared/constants';

import './account-settings.scss';

export enum Tabs {
  PLAN = 'plan',
  INTEGRATIONS = 'integrations',
  PAYMENT = 'payment',
  CONTACT = 'contact',
  SSO = 'sso',
}

enum ActiveState {
  SELECTED = 'selected',
  DESELECTED = 'deselected',
}

interface IRoleDefinition {
  roleName: USER_ROLE;
  scope: 'organizationScope' | 'planScope';
}

interface RoleGuardProps {
  allowedRoles: IRoleDefinition[];
  children: React.ReactNode;
}

const RoleGuard: React.FC<RoleGuardProps> = ({ allowedRoles, children }) => {
  return (
    <RoleRestrictedComponent restrictedTo={allowedRoles}>
      {children}
    </RoleRestrictedComponent>
  );
};

const planRoles: IRoleDefinition[] = [
  { roleName: USER_ROLE.SUPER_ADMIN, scope: 'organizationScope' },
  { roleName: USER_ROLE.PLAN_ADMIN, scope: 'planScope' },
];
const ssoAdminRoles: IRoleDefinition[] = [
  { roleName: USER_ROLE.SUPER_ADMIN, scope: 'organizationScope' },
  { roleName: USER_ROLE.SSO_ADMIN, scope: 'organizationScope' },
];

const AccountSettings = () => {
  const { t } = useTranslations();
  const location = useLocation();
  const initialTab = location.state?.currentTab || Tabs.PLAN;
  const [selectedTab, setSelectedTab] = useState(initialTab);

  const getAriaLabel = (tabValue: string, tabName: string) => {
    const state =
      selectedTab === tabValue ? ActiveState.SELECTED : ActiveState.DESELECTED;

    return `${tabName} ${t(`accountSettings.tabs.${state}`)}`;
  };

  useEffect(() => {
    if (
      location.state?.currentTab &&
      location.state.currentTab !== selectedTab
    ) {
      setSelectedTab(location.state.currentTab);
    }
  }, [location.state?.currentTab]);

  return (
    <FlexContainer direction="col" align="center">
      <main className="account-settings">
        <div className="account-settings__header">
          <h1 className="account-settings__header-title">
            {t('accountSettings.title')}
          </h1>
        </div>
        <div className="account-settings__subheader">
          <span className="account-settings__subheader-span">
            {t('accountSettings.subheader')}
          </span>
        </div>
        <TabsRoot
          defaultValue={selectedTab}
          onValueChange={(details: { value: string }) =>
            setSelectedTab(details.value as Tabs)
          }
        >
          <TabsList>
            <RoleGuard allowedRoles={planRoles}>
              <TabTrigger
                value={Tabs.PLAN}
                id="plan-tab"
                tabIndex={0}
                aria-label={getAriaLabel(
                  Tabs.PLAN,
                  t('accountSettings.tabs.plan'),
                )}
              >
                {t('accountSettings.tabs.plan')}
              </TabTrigger>
            </RoleGuard>
            <TabTrigger
              value={Tabs.INTEGRATIONS}
              id="integrations-tab"
              tabIndex={0}
              aria-label={getAriaLabel(
                Tabs.INTEGRATIONS,
                t('accountSettings.tabs.integrations'),
              )}
            >
              {t('accountSettings.tabs.integrations')}
            </TabTrigger>
            <TabTrigger
              value={Tabs.PAYMENT}
              id="payment-tab"
              tabIndex={0}
              aria-label={getAriaLabel(
                Tabs.PAYMENT,
                t('accountSettings.tabs.payment'),
              )}
            >
              {t('accountSettings.tabs.payment')}
            </TabTrigger>
            <TabTrigger
              value={Tabs.CONTACT}
              id="contact-tab"
              tabIndex={0}
              aria-label={getAriaLabel(
                Tabs.CONTACT,
                t('accountSettings.tabs.contact'),
              )}
            >
              {t('accountSettings.tabs.contact')}
            </TabTrigger>
            <RoleGuard allowedRoles={ssoAdminRoles}>
              <TabTrigger
                value="sso"
                id="sso-tab"
                tabIndex={0}
                aria-label={getAriaLabel(
                  Tabs.SSO,
                  t('accountSettings.tabs.sso'),
                )}
              >
                {t('accountSettings.tabs.sso')}
              </TabTrigger>
            </RoleGuard>
          </TabsList>

          <RoleGuard allowedRoles={planRoles}>
            <TabContent value={Tabs.PLAN} aria-labelledby="plan-tab">
              <PlanSettingsContainer />
            </TabContent>
          </RoleGuard>

          <TabContent
            value={Tabs.INTEGRATIONS}
            aria-labelledby="integrations-tab"
          >
            {/* TODO: Implement integrations content */}
          </TabContent>

          <TabContent value={Tabs.PAYMENT} aria-labelledby="payment-tab">
            {/* TODO: Implement payment content */}
          </TabContent>

          <TabContent value={Tabs.CONTACT} aria-labelledby="contact-tab">
            {/* TODO: Implement contact content */}
          </TabContent>

          <RoleGuard allowedRoles={ssoAdminRoles}>
            <TabContent value={Tabs.SSO} aria-labelledby="sso-tab">
              <SsoConnections />
            </TabContent>
          </RoleGuard>
        </TabsRoot>
      </main>
    </FlexContainer>
  );
};

export default AccountSettings;
