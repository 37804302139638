import { ChevronRightIcon, SearchIcon } from '@pluralsight/icons';
import {
  Button,
  Checkbox,
  FlexContainer,
  For,
  FormControlProvider,
  Input,
  Show,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@pluralsight/react-ng';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import useScreenWidth from '../../../../shared/hooks/useScreenWidth';
import { selectTeamsList } from '../../../teams/state/selectors';
import { selectTeamIds } from '../../state/selectors';
import { fetchTeams } from '../../../teams/state/slice';
import { assignTeams } from '../../state/slice';
import './assign-team-step.scss';

const AssignTeamStep = () => {
  const dispatch = useDispatch();
  const { isXS } = useScreenWidth();
  const teams = useSelector(selectTeamsList);
  const teamIds = useSelector(selectTeamIds);
  const [searchTeam, setSearchTeam] = useState('');
  const { t } = useTranslations();

  useEffect(() => {
    dispatch(fetchTeams());
  }, [dispatch]);

  const assignedTeams = teams
    .filter((team) => teamIds.includes(team.id))
    .map((team) => team.name)
    .join(', ');

  const filteredTeams = teams.filter((team) =>
    team?.name?.toLowerCase().includes(searchTeam.toLowerCase()),
  );

  const getOptions = () => {
    if (!filteredTeams.length) {
      return [];
    }

    return searchTeam.length > 1 && filteredTeams.length
      ? filteredTeams
      : teams;
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(assignTeams(event.target.value));
  };

  return (
    <FlexContainer direction="col" className="assign-team-step">
      <Show
        when={!isXS}
        children={
          <div className="assign-team-step__content">
            {t('invitation.assignTeamStep.selectTeamsLabel')}
          </div>
        }
        fallback={
          <div className="assign-team-step__xs-content">
            {t('invitation.assignTeamStep.allInvitedUsersLabel')}
          </div>
        }
      />
      <FormControlProvider>
        <Input
          name="assign-team"
          value={searchTeam}
          placeholder={t('invitation.assignTeamStep.searchTeamPlaceholder')}
          startIcon={
            <SearchIcon aria-hidden height="1.25rem" width="1.25rem" />
          }
          onChange={(e) => setSearchTeam(e.target.value)}
        />
      </FormControlProvider>

      <FlexContainer align="center" className="assign-team-step__tooltip">
        {t('invitation.assignTeamStep.teamSelected', {
          count: teamIds.length,
        })}
        <Show
          when={!!teamIds.length}
          children={
            <Tooltip placement="top">
              <TooltipTrigger>
                <Button size="md" palette="neutral" usage="text">
                  {t('invitation.assignTeamStep.viewListLabel')}
                </Button>
              </TooltipTrigger>
              <TooltipContent parentId="add-users" text={assignedTeams} />
            </Tooltip>
          }
        />
      </FlexContainer>

      <FormControlProvider>
        <FlexContainer
          className="assign-team-step__list-container"
          direction="col"
        >
          <For
            each={getOptions()}
            children={(team) => (
              <FlexContainer
                className={`assign-team-step__row ${
                  teamIds.includes(team.id) ? 'selected' : ''
                }`}
                align="center"
              >
                <ChevronRightIcon />
                <Checkbox
                  id={team.id}
                  value={team.id}
                  name={`assigned-team-${team.id}`}
                  checked={teamIds.includes(team.id)}
                  onChange={handleOnChange}
                >
                  <div className="assign-team-step__team">{team.name}</div>
                </Checkbox>
              </FlexContainer>
            )}
          />
        </FlexContainer>
      </FormControlProvider>
    </FlexContainer>
  );
};

export default AssignTeamStep;
