import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { Person } from '../../people/state/interfaces';
import type { CreateTeamState } from './interfaces';
import type { UsersTableItem } from '../../../shared/interfaces';

const INITIAL_STATE: CreateTeamState = {
  team: {
    name: '',
    assignedMembers: [],
  },
  loading: false,
  error: null,
};

const createTeamSlice = createSlice({
  name: 'createTeam',
  initialState: INITIAL_STATE,
  reducers: {
    createTeam: (state) => {
      state.error = null;
      state.loading = true;
    },
    createTeamSuccess: (state) => {
      state.loading = false;
    },
    createTeamFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    setTeamName: (state, action: PayloadAction<string>) => {
      state.team.name = action.payload;
    },
    clearTeamName: (state) => {
      state.team.name = '';
    },
    assignUser: (state, action: PayloadAction<Person | UsersTableItem>) => {
      state.team.assignedMembers = [
        action.payload,
        ...state.team.assignedMembers,
      ];
    },
    clearAssignedMembers: (state) => {
      state.team.assignedMembers = [];
    },
    removeAssignedUser: (state, action: PayloadAction<string>) => {
      state.team.assignedMembers = state.team.assignedMembers.filter(
        (user) => user.id !== action.payload,
      );
    },
  },
});

export const {
  createTeam,
  createTeamSuccess,
  createTeamFailure,
  setTeamName,
  clearTeamName,
  assignUser,
  clearAssignedMembers,
  removeAssignedUser,
} = createTeamSlice.actions;

export default createTeamSlice.reducer;
