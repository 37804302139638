import { FlexContainer, Show } from '@pluralsight/react-ng';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import { handleMenuKeyBoardNavigation } from '../../../../../src/shared/helpers/handle-menu-keyboard-navigation';
import { calculatePrivilegedRole } from '../../../../shared/role-validator-utils';
import PeopleIcon from '../../../../assets/people.svg';
import Placeholder from '../../../../components/placeholder/placeholder';
import RoleRestrictedComponent from '../../../../components/role-restricted-component/role-restricted-component';
import UsersTable from '../../../../components/users-table/users-table';
import {
  deleteUsersRestrictedTo,
  USER_ROLE,
} from '../../../../shared/constants';
import { selectPeopleList } from '../../state/selectors';
import { clearCurrentMember } from '../../../update-licenses/state/slice';
import UpdateLicensesDialog from '../../../update-licenses/components/update-licenses-dialog';
import { fetchProducts } from '../../../products/state/slice';
import { selectUser } from '../../../user/state/selectors';
import { clearEditUser, fetchPeople, setEditUser } from '../../state/slice';
import { NOTE_TYPE_ENUM } from '../../state/interfaces';
import UpdateNoteDialog from '../../../team-users/components/update-note-dialog/update-note-dialog';
import UpdateRolesDialog from '../update-roles-dialog/update-roles-dialog';
import { setDialogStep } from '../../../../shared/dialog/state/slice';
import SearchInput from '../../../teams/components/search-input/search-input';
import NoResults from '../../../teams/components/no-results/no-results';
import useSearchFilter from '../../hooks/useSearchFilter';
import useMapUsers from '../../hooks/useMapUsers';
import { selectOrgId } from '../../../plans/state/selectors';
import { fetchRoles } from '../../../roles/state/slice';
import DeleteUserDialog from '../delete-user-dialog/delete-user-dialog';

import { peopleTableColumns } from './people-table-columns';
import {
  updateLicenseRestrictedTo,
  updateRolesRestrictedTo,
} from './constants';

import type { KeyBoardActions } from '../../../../../src/shared/helpers/handle-menu-keyboard-navigation';
import type { UsersTableItem } from '../../../../shared/interfaces';

import './users-table-container.scss';

interface UsersTableContainerProps {
  openDialog: () => void;
}

const USER_SEARCH_MIN_KEY_STROKES = 2;

const UsersTableContainer: React.FC<UsersTableContainerProps> = ({
  openDialog,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [userResults, setUserResults] = useState<UsersTableItem[]>([]);
  const [mappedUsers, setMappedUsers] = useState<UsersTableItem[]>([]);
  const { filterData } = useSearchFilter();
  const { toUserTableItems } = useMapUsers();
  const { t } = useTranslations();
  const dispatch = useDispatch();

  const [currentUser, setCurrentUser] = useState<UsersTableItem | null>(null);
  const updateNoteDialogRef = useRef<HTMLDialogElement>(null);
  const updateLicensesDialogRef = useRef<HTMLDialogElement>(null);
  const updateRolesDialogRef = useRef<HTMLDialogElement>(null);
  const deleteUserDialogRef = useRef<HTMLDialogElement>(null);

  const user = useSelector(selectUser);
  const users = useSelector(selectPeopleList);
  const orgId = useSelector(selectOrgId);

  const showUpdateNoteDialog = (member: UsersTableItem) => {
    setCurrentUser(member);
    dispatch(setDialogStep(1));
    updateNoteDialogRef.current?.showModal();
  };

  const onNoteModalClose = () => {
    setCurrentUser(null);
    dispatch(setDialogStep(0));
    updateNoteDialogRef.current?.close();
  };

  const onUpdateRolesDialogClose = () => {
    dispatch(clearEditUser());
    updateRolesDialogRef.current?.close();
    dispatch(setDialogStep(0));
  };
  const showUpdateRolesDialog = useCallback(
    (member: UsersTableItem) => {
      dispatch(setEditUser(member.id));
      updateRolesDialogRef.current?.showModal();
      dispatch(setDialogStep(1));
      dispatch(fetchRoles(orgId));
    },
    [orgId],
  );

  const showDeleteUserDialog = (member: UsersTableItem) => {
    setCurrentUser(member);
    dispatch(setDialogStep(1));
    deleteUserDialogRef.current?.showModal();
  };

  const onDeleteUserDialogClose = () => {
    setCurrentUser(null);
    dispatch(setDialogStep(0));
    deleteUserDialogRef.current?.close();
  };

  useEffect(() => {
    dispatch(fetchPeople());
  }, [dispatch, user]);

  const showUpdateLicensesDialog = (member: UsersTableItem) => {
    setCurrentUser(member);
    dispatch(fetchProducts());
    dispatch(setDialogStep(1));
    updateLicensesDialogRef.current?.showModal();
  };

  const closeUpdateLicensesDialog = () => {
    setCurrentUser(null);
    dispatch(setDialogStep(0));
    dispatch(clearCurrentMember());
    updateLicensesDialogRef.current?.close();
  };

  const renderDropdownOptions = useCallback(
    (member: UsersTableItem, actions: KeyBoardActions) => {
      const isPrivilegedRole = calculatePrivilegedRole(
        user,
        member.roleNames?.map((r) => r as USER_ROLE) || null,
      );

      return (
        <div
          role="menu"
          onKeyDown={handleMenuKeyBoardNavigation(
            'common-dropdown-option',
            actions,
          )}
          tabIndex={0}
        >
          <div
            className="common-dropdown-option"
            onClick={() => showUpdateNoteDialog(member)}
            role="menuItem"
            tabIndex={0}
            ref={(el) => el?.focus()}
          >
            <span className="dropdown-option-span">
              {t('usersTable.optionsDropdown.editNote')}
            </span>
          </div>

          <RoleRestrictedComponent restrictedTo={updateLicenseRestrictedTo}>
            <div
              className="common-dropdown-option"
              onClick={() => showUpdateLicensesDialog(member)}
              role="menuItem"
              tabIndex={0}
            >
              <span className="dropdown-option-span">
                {t('usersTable.optionsDropdown.updateLicenses')}
              </span>
              <span className="dropdown-option-description">
                {t('usersTable.optionsDropdown.updateLicensesDescription')}
              </span>
            </div>
          </RoleRestrictedComponent>

          <RoleRestrictedComponent
            restrictedTo={isPrivilegedRole ? updateRolesRestrictedTo : []}
          >
            <div
              className="common-dropdown-option"
              onClick={() => showUpdateRolesDialog(member)}
              role="menuItem"
              tabIndex={0}
            >
              <span className="dropdown-option-span">
                {t('people.optionsDropdown.updateRoles')}
              </span>
              <span className="dropdown-option-description">
                {t('people.optionsDropdown.updateRolesDescription')}
              </span>
            </div>
          </RoleRestrictedComponent>

          <RoleRestrictedComponent
            restrictedTo={isPrivilegedRole ? deleteUsersRestrictedTo : []}
          >
            <div
              className="common-dropdown-option"
              onClick={() => showDeleteUserDialog(member)}
              role="menuItem"
              tabIndex={0}
            >
              <span className="dropdown-option-span">
                {t('people.optionsDropdown.deleteUser')}
              </span>
              <span className="dropdown-option-description">
                {t('people.optionsDropdown.deleteUserDescription')}
              </span>
            </div>
          </RoleRestrictedComponent>
        </div>
      );
    },
    [showUpdateRolesDialog],
  );

  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  useEffect(() => {
    if (searchTerm.length < USER_SEARCH_MIN_KEY_STROKES) {
      return setUserResults(mappedUsers);
    }

    const filtered = filterData(mappedUsers, searchTerm, peopleTableColumns);

    setUserResults(filtered);
  }, [mappedUsers, searchTerm]);

  useEffect(() => {
    const mappedUsers = toUserTableItems(users);

    setMappedUsers(mappedUsers);
    setUserResults(mappedUsers);
  }, [users]);

  return (
    <Show
      when={!!mappedUsers.length}
      fallback={
        <div className="users-table-container__placeholder">
          <Placeholder icon={PeopleIcon}>
            <div className="users-table-container__placeholder-message">
              {t('people.usersTableContainer.looksLikeLabel')}
            </div>
            <FlexContainer justify="center">
              <RoleRestrictedComponent
                restrictedTo={[
                  {
                    roleName: USER_ROLE.SUPER_ADMIN,
                    scope: 'organizationScope',
                  },
                  {
                    roleName: USER_ROLE.SYSTEM_ADMIN,
                    scope: 'organizationScope',
                  },
                  {
                    roleName: USER_ROLE.PLAN_ADMIN,
                    scope: 'planScope',
                  },
                  {
                    roleName: USER_ROLE.MANAGER_FULL,
                    scope: 'planScope',
                  },
                ]}
              >
                <button
                  className="users-table-container__placeholder-button"
                  onClick={openDialog}
                >
                  {t('people.usersTableContainer.addUsers')}
                </button>
              </RoleRestrictedComponent>
            </FlexContainer>
          </Placeholder>
        </div>
      }
    >
      <SearchInput onSearch={handleSearch} />
      <Show when={!!userResults.length} fallback={<NoResults />}>
        <UsersTable
          people={userResults}
          searchFilter={searchTerm}
          columns={peopleTableColumns}
          dropDownOptions={renderDropdownOptions}
        />
      </Show>

      <UpdateNoteDialog
        ref={updateNoteDialogRef}
        noteUser={currentUser as UsersTableItem}
        closeModal={onNoteModalClose}
        type={NOTE_TYPE_ENUM.MEMBER}
      />

      <UpdateLicensesDialog
        ref={updateLicensesDialogRef}
        currentUserId={currentUser?.id || ''}
        closeModal={closeUpdateLicensesDialog}
      />
      <UpdateRolesDialog
        ref={updateRolesDialogRef}
        closeModal={onUpdateRolesDialogClose}
      />

      <DeleteUserDialog
        ref={deleteUserDialogRef}
        closeModal={onDeleteUserDialogClose}
        currentUser={currentUser}
      />
    </Show>
  );
};

export default UsersTableContainer;
