import './sso-connections.scss';
import { Button, FlexContainer } from '@pluralsight/react-ng';
import { useNavigate } from 'react-router-dom';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';
import { ROUTES } from '@ps-fe-plan-management/shared/constants';

import SsoSettingsSection from '../shared/sso-settings-section/sso-settings-section';

import ConnectionList from './connection-list/connection-list';
import GeneralSsoSettings from './general-sso-settings/general-sso-settings';

const SsoConnections = () => {
  const { t } = useTranslations();
  const navigate = useNavigate();

  return (
    <FlexContainer
      direction="col"
      gap="var(--pando-gap-xl)"
      className="sso-connections"
    >
      <SsoSettingsSection
        title={t('sso.ssoSettings.title')}
        subtitle={t('sso.ssoSettings.subtitle')}
      >
        <GeneralSsoSettings />
      </SsoSettingsSection>
      <SsoSettingsSection
        title={t('sso.ssoConnections.ssoConnectionsTitle')}
        subtitle={t('sso.ssoConnections.viewDetails')}
        headerButton={
          <Button
            palette="action"
            size="md"
            usage="filled"
            onClick={() => navigate(ROUTES.SSO_CONFIGURATION)}
            role="link"
          >
            {t('sso.ssoConnections.ssoConnectionLink')}
          </Button>
        }
      >
        <ConnectionList />
      </SsoSettingsSection>
    </FlexContainer>
  );
};

export default SsoConnections;
