import { createSelector } from 'reselect';

import type { Person } from '../../people/state/interfaces';
import type { AppState } from '../../../store';
import type { CreateTeamState } from './interfaces';

const selectCreateTeamState = (state: AppState) => state.createTeam;

export const selectTeam = createSelector(
  [selectCreateTeamState],
  (createTeam: CreateTeamState) => createTeam.team,
);

export const selectAssignedMembers = createSelector(
  [selectCreateTeamState],
  (createTeam: CreateTeamState) => createTeam.team.assignedMembers,
);

export const selectAssignedUsers = createSelector(
  [selectCreateTeamState],
  (createTeam: CreateTeamState) =>
    createTeam.team.assignedMembers
      .filter((user): user is Person => (user as Person).active)
      .sort((a, b) => {
        if (a.firstName && b.firstName) {
          return a.firstName.localeCompare(b.firstName);
        }

        return 0;
      }),
);

export const selectAssignedInvites = createSelector(
  [selectCreateTeamState],
  (createTeam: CreateTeamState) =>
    createTeam.team.assignedMembers
      .filter((user): user is Person => !(user as Person).active)
      .sort((a, b) => a.email.localeCompare(b.email)),
);

export const selectCreateTeamLoading = createSelector(
  [selectCreateTeamState],
  (createTeam: CreateTeamState) => createTeam.loading,
);

export const selectCreateTeamError = createSelector(
  [selectCreateTeamState],
  (createTeam: CreateTeamState) => createTeam.error,
);
