import {
  Admonition,
  AdmonitionText,
  Checkbox,
  For,
  FormControlProvider,
  Label,
  Show,
} from '@pluralsight/react-ng';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ExternalLinkIcon } from '@pluralsight/icons';
import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import { hasExpectedRole } from '../../shared/utils';
import { USER_ROLE } from '../../shared/constants';
import {
  selectProductsSortedByGroup,
  selectAddOnProducts,
  selectBaseProducts,
} from '../../features/products/state/selectors';
import { selectUser } from '../../features/user/state/selectors';
import './select-licenses.scss';
import { selectMemberProducts } from '../../features/update-licenses/state/selectors';
import useProducts from '../../features/products/hooks/useProducts';

interface SelectLicensesProps {
  productIds: string[];
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SelectLicenses: React.FC<SelectLicensesProps> = ({
  productIds,
  onChange,
}) => {
  const { t } = useTranslations();
  const user = useSelector(selectUser);
  const products = useSelector(selectProductsSortedByGroup);
  const baseProducts = useSelector(selectBaseProducts);
  const addOnProducts = useSelector(selectAddOnProducts);
  const currentMemberProducts = useSelector(selectMemberProducts);

  const isBaseProductSelected = useProducts(productIds);

  const isFullManager = user && hasExpectedRole(user, [USER_ROLE.MANAGER_FULL]);

  const isAdmonition = products.some(
    (value) =>
      value.remainingLicenses === 0 &&
      !currentMemberProducts.includes(value.productId),
  );

  const isAvailable = (id: string) => {
    if (currentMemberProducts.includes(id)) return true;

    const usageId = products.find((product) => product.productId === id);

    if (!usageId) {
      return false;
    }

    return usageId.remainingLicenses > 0;
  };

  const noAvailableProducts = products
    .filter(
      (use) =>
        use.remainingLicenses === 0 &&
        !currentMemberProducts.includes(use.productId),
    )
    .map(
      (use) =>
        products.find((product) => product.productId === use.productId)
          ?.productName,
    );

  const noAvailableProductsString =
    noAvailableProducts.length === 1
      ? noAvailableProducts.join(', ')
      : noAvailableProducts.slice(0, -1).join(', ');

  const noAvailableProductLast =
    noAvailableProducts[noAvailableProducts.length - 1];

  const assignedAddOn = addOnProducts
    .filter((product) => currentMemberProducts.includes(product.productId))
    .map((product) => product.productName);

  const isWarning =
    !isBaseProductSelected && assignedAddOn.length && productIds.length;

  const addOnString = assignedAddOn.join(', ');
  const addOnManyString = assignedAddOn.slice(0, -1).join(', ');
  const lastAddOnString = assignedAddOn[assignedAddOn.length - 1];

  return (
    <>
      {isAdmonition ? (
        <Admonition className="select-licenses__admonition">
          <AdmonitionText>
            <Trans
              i18nKey={
                isFullManager
                  ? 'invitation.licensesStep.admonition'
                  : 'invitation.licensesStep.admonitionWithLink'
              }
              values={{
                count: noAvailableProducts.length,
                product: noAvailableProductsString,
                productLast: noAvailableProductLast,
              }}
              components={[
                <Link
                  key="link"
                  to="/account-settings"
                  className="select-licenses__link"
                >
                  Account page
                  <ExternalLinkIcon />,
                </Link>,
              ]}
            />
          </AdmonitionText>
        </Admonition>
      ) : null}

      {isWarning ? (
        <Admonition
          className="select-licenses__admonition warning"
          palette="warning"
        >
          <AdmonitionText>
            <Trans
              i18nKey={
                assignedAddOn.length === 1
                  ? 'licenseDialog.warning_one'
                  : 'licenseDialog.warning_other'
              }
              values={{
                addOnOne: addOnString,
                addOnMany: addOnManyString,
                last: lastAddOnString,
              }}
              components={[<strong key="strong" />]}
            />
          </AdmonitionText>
        </Admonition>
      ) : null}

      <Show when={baseProducts.length > 0}>
        <FormControlProvider>
          <div className="select-licenses__base-container">
            <Show when={addOnProducts.length > 0}>
              <Label htmlFor="base-licenses" className="select-licenses__label">
                {t('invitation.licensesStep.baseLicenses')}
              </Label>
            </Show>
            <div className="select-licenses__control">
              <For
                each={baseProducts}
                children={(product) => {
                  const isProductAvailable = isAvailable(product.productId);

                  return (
                    <div
                      className={cn('select-licenses__license', {
                        disabled: !isProductAvailable,
                      })}
                    >
                      <Checkbox
                        id={product.productId}
                        value={product.productId}
                        name={product.productName}
                        disabled={!isProductAvailable}
                        checked={productIds.includes(product.productId)}
                        onChange={onChange}
                      >
                        {product.productName}
                      </Checkbox>
                    </div>
                  );
                }}
              />
            </div>
          </div>

          <Show when={addOnProducts.length > 0}>
            <Label htmlFor="addon-licenses" className="select-licenses__label">
              {t('invitation.licensesStep.addOnLicenses')}
            </Label>
            <div className="select-licenses__control">
              <For
                each={addOnProducts}
                children={(product) => {
                  const isProductAvailable = isAvailable(product.productId);

                  return (
                    <div
                      className={cn('select-licenses__license', {
                        disabled: !isBaseProductSelected || !isProductAvailable,
                      })}
                    >
                      <Checkbox
                        id={product.productId}
                        value={product.productId}
                        name={product.productName}
                        checked={
                          isBaseProductSelected &&
                          productIds.includes(product.productId)
                        }
                        disabled={!isBaseProductSelected || !isProductAvailable}
                        onChange={onChange}
                      >
                        {product.productName}
                      </Checkbox>
                    </div>
                  );
                }}
              />
            </div>
          </Show>
        </FormControlProvider>
      </Show>
    </>
  );
};

export default SelectLicenses;
