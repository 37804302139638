import { useSelector } from 'react-redux';

import { selectBaseProducts } from '../state/selectors';

const useProducts = (productIds: string[]) => {
  const baseProducts = useSelector(selectBaseProducts);

  return baseProducts.some((product) => productIds.includes(product.productId));
};

export default useProducts;
