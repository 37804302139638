import React from 'react';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import PeopleSelect from '../../../../components/people-select/people-select';

const SelectTeamManagers = () => {
  const { t } = useTranslations();

  return (
    <PeopleSelect
      isManagersFlow
      placeholder={t('invitation.selectTeamManagers.placeholder')}
      title={t('invitation.selectTeamManagers.title')}
      hint={t('invitation.selectTeamManagers.hint')}
    />
  );
};

export default SelectTeamManagers;
