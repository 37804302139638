import { call, getContext, put, takeLatest } from 'redux-saga/effects';

import { SsoConnectionsService } from './services';
import {
  deleteSsoConnection,
  deleteSsoConnectionSuccess,
  fetchSsoConnection,
  fetchSsoConnectionFailure,
  fetchSsoConnections,
  fetchSsoConnectionsFailure,
  fetchSsoConnectionsSuccess,
  fetchSsoConnectionSuccess,
} from './slice';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { AxiosInstance } from 'axios';
import type { ISsoConnection, ISsoConnectionId } from './interfaces';

function* fetchSsoConnectionsHandler(action: PayloadAction<{ orgId: string }>) {
  const { orgId } = action.payload;

  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');

    if (!httpClient) {
      throw new Error('HTTP client is not available');
    }

    const ssoConnectionsService = new SsoConnectionsService(httpClient);
    const ssoConnections: ISsoConnection[] = yield call(
      ssoConnectionsService.fetchSsoConnections,
      orgId,
    );

    yield put(fetchSsoConnectionsSuccess(ssoConnections ?? []));
  } catch (error: any) {
    yield put(fetchSsoConnectionsFailure(error.message || 'Unknown error'));
  }
}

function* fetchSsoConnectionHandler(action: PayloadAction<ISsoConnectionId>) {
  const { id } = action.payload;

  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');

    if (!httpClient) {
      throw new Error('HTTP client is not available');
    }

    const ssoConnectionsService = new SsoConnectionsService(httpClient);
    const response: ISsoConnection = yield call(
      ssoConnectionsService.fetchSsoConnection,
      id,
    );

    yield put(fetchSsoConnectionSuccess(response));
  } catch (error: any) {
    yield put(fetchSsoConnectionFailure(error.message || 'Unknown error'));
  }
}

function* deleteSsoConnectionHandler(action: PayloadAction<ISsoConnectionId>) {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');

    if (!httpClient) {
      throw new Error('HTTP client is not available');
    }

    const ssoConnectionsService = new SsoConnectionsService(httpClient);

    yield call(ssoConnectionsService.deleteSsoConnection, action.payload);

    yield put(deleteSsoConnectionSuccess(action.payload));
  } catch (error: any) {
    yield put(fetchSsoConnectionsFailure(error.message || 'Unknown error'));
  }
}

export default function* ssoConnectionsSagas() {
  yield takeLatest(fetchSsoConnections.type, fetchSsoConnectionsHandler);
  yield takeLatest(deleteSsoConnection.type, deleteSsoConnectionHandler);
  yield takeLatest(fetchSsoConnection.type, fetchSsoConnectionHandler);
}
