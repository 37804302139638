import React from 'react';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import videoPlaceholder from '../../assets/images/vide-placeholder.png';

import { POPULAR_COURSES } from './fixtures';

import type { Language } from '../../translations/interfaces';
import type { DataCourse } from './fixtures';

const PopularCourses = () => {
  const { language: currentLanguage, t } = useTranslations();
  const data = POPULAR_COURSES[currentLanguage as Language] as DataCourse[];

  const showCourses = () => {
    return data.map((item, index) => {
      return (
        <div
          key={index}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '17px',
            flex: '1 0 0',
            borderRadius: '4px',
            background: 'var(--neutral-surface-300, #2A2753)',
            boxShadow:
              '0px 10px 15px -3px rgba(0, 0, 0, 0.10), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)',
          }}
        >
          <img src={videoPlaceholder} alt="Content type" />
          <div
            style={{
              padding: '15px',
              gap: '10px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <p
              style={{
                color:
                  'var(--neutral-text-weak, var(--neutral-text-100, #8B90C1))',
                fontVariantNumeric: 'lining-nums tabular-nums',
                fontFeatureSettings: "'liga' off",
                fontFamily: '"PS TT Commons"',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '16px',
                letterSpacing: '0.96px',
                textTransform: 'uppercase',
              }}
            >
              {item.type}
            </p>
            <h3
              style={{
                color:
                  'var(--neutral-text-strong, var(--neutral-text-300, #F3F3F8))',
                fontFamily: 'var(--font-family-title, "PS TT Commons")',
                fontSize: 'var(--font-size-md, 16px)',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '150%',
              }}
            >
              {item.title}
            </h3>
            <p
              style={{
                color:
                  'var(--neutral-text-weak, var(--neutral-text-100, #8B90C1))',
                fontFamily: 'var(--font-family-body, "PS TT Commons")',
                fontSize: 'var(--font-size-xs, 12px)',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '150%',
              }}
            >
              {item.tags.join('• ')}
            </p>
          </div>
        </div>
      );
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        padding: 'var(--pando-button-padding, 24px)',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '16px',
        alignSelf: 'stretch',
        borderRadius: '8px',
        background: 'var(--neutral-surface-100, #1B1834)',
      }}
    >
      <h1
        style={{
          color: 'var(--neutral-text-strong, var(--neutral-text-300, #F3F3F8))',
          fontFamily: 'var(--font-family-title, "PS TT Commons")',
          fontSize: 'var(--font-size-2xl, 24px)',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '125%',
        }}
      >
        {t('popularCourses.title')}
      </h1>
      <p
        style={{
          color: 'var(--neutral-text-medium, var(--neutral-text-200, #A5AACF))',
          fontFamily: 'var(--font-family-body, "PS TT Commons")',
          fontSize: 'var(--font-size-md, 16px)',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '150%',
        }}
      >
        {t('popularCourses.notSureToStart')}
      </p>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
          alignSelf: 'stretch',
        }}
      >
        {showCourses()}
      </div>
    </div>
  );
};

export default PopularCourses;
