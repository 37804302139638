export const validateCsvFile = (
  file: File,
  requiredColumns: string[],
  maxRows = 50000,
): Promise<string[]> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (typeof reader.result !== 'string') {
        return reject('FILE_PARSE_ERROR');
      }

      const text = reader.result;

      const lines = text.split(/\r?\n/).filter(Boolean);

      if (lines.length === 0) {
        return reject('FILE_INVALID_COLUMNS');
      }

      const headerLine = lines[0];
      const columns = headerLine
        .split(',')
        .map((col) => col.trim().toLowerCase());

      const hasAllColumns = requiredColumns.every((required) =>
        columns.includes(required.toLowerCase()),
      );

      if (!hasAllColumns) {
        return reject('FILE_INVALID_COLUMNS');
      }

      const dataRowsCount = lines.length - 1;

      if (dataRowsCount > maxRows) {
        return reject('FILE_TOO_MANY_ROWS');
      }

      resolve([]);
    };

    reader.onerror = () => {
      reject('FILE_PARSE_ERROR');
    };

    reader.readAsText(file);
  });
