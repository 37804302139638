// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.import-teams-step .pando-radio__container {
  border-color: var(--pando-inky-blue-action-border-initial-dark);
}
.import-teams-step__xs-content {
  font-size: var(--font-size-sm, 14px);
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.import-teams-step__content {
  font-size: var(--font-size-md, 16px);
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.import-teams-step__list-container {
  width: 676px;
  height: 319px;
  overflow: auto;
  margin: 16px 0;
  border-top: 1px solid var(--pando-inky-blue-neutral-border-100-dark);
}
.import-teams-step__tooltip {
  margin-top: var(--pando-spacing-6);
  padding-left: var(--pando-spacing-4);
}
.import-teams-step__tooltip button {
  height: 21px;
  color: var(--pando-colors-action-text-100);
}
.import-teams-step__row {
  min-height: 40px;
  border-bottom: 1px solid var(--pando-inky-blue-neutral-border-100-dark);
}
.import-teams-step__row.selected {
  background-color: var(--pando-inky-blue-neutral-background-active-dark);
}
.import-teams-step__row:hover {
  background-color: var(--pando-inky-blue-neutral-background-active-dark);
}
.import-teams-step__team {
  color: var(--pando-colors-neutral-text-300);
  font-family: var(--font-family-body, "PS TT Commons");
  opacity: 0.5;
}
.import-teams-step input:hover {
  background-color: unset;
}`, "",{"version":3,"sources":["webpack://./src/features/teams/components/import-teams-step/import-teams-step.scss"],"names":[],"mappings":"AAGI;EACE,+DAAA;AAFN;AAME;EACE,oCAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAJJ;AAOE;EACE,oCAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AALJ;AAQE;EACE,YAAA;EACA,aAAA;EACA,cAAA;EACA,cAAA;EACA,oEAAA;AANJ;AASE;EACE,kCAAA;EACA,oCAAA;AAPJ;AASI;EACE,YAAA;EACA,0CAAA;AAPN;AAWE;EACE,gBAAA;EACA,uEAAA;AATJ;AAWI;EACE,uEAAA;AATN;AAaE;EACE,uEAAA;AAXJ;AAcE;EACE,2CAAA;EACA,qDAAA;EACA,YAAA;AAZJ;AAeE;EACE,uBAAA;AAbJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.import-teams-step {\n  .pando-radio {\n    &__container {\n      border-color: var(--pando-inky-blue-action-border-initial-dark);\n    }\n  }\n\n  &__xs-content {\n    font-size: var(--font-size-sm, 14px);\n    font-style: normal;\n    font-weight: 500;\n    line-height: 150%;\n  }\n\n  &__content {\n    font-size: var(--font-size-md, 16px);\n    font-style: normal;\n    font-weight: 500;\n    line-height: 150%;\n  }\n\n  &__list-container {\n    width: 676px;\n    height: 319px;\n    overflow: auto;\n    margin: 16px 0;\n    border-top: 1px solid var(--pando-inky-blue-neutral-border-100-dark);\n  }\n\n  &__tooltip {\n    margin-top: var(--pando-spacing-6);\n    padding-left: var(--pando-spacing-4);\n\n    button {\n      height: 21px;\n      color: var(--pando-colors-action-text-100);\n    }\n  }\n\n  &__row {\n    min-height: 40px;\n    border-bottom: 1px solid var(--pando-inky-blue-neutral-border-100-dark);\n\n    &.selected {\n      background-color: var(--pando-inky-blue-neutral-background-active-dark);\n    }\n  }\n\n  &__row:hover {\n    background-color: var(--pando-inky-blue-neutral-background-active-dark);\n  }\n\n  &__team {\n    color: var(--pando-colors-neutral-text-300);\n    font-family: var(--font-family-body, 'PS TT Commons');\n    opacity: 0.5;\n  }\n\n  input:hover {\n    background-color: unset;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
