import { useEffect, useState } from 'react';

const useScreenWidth = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const isXS = screenWidth < 480;
  const isSM = screenWidth > 480 && screenWidth < 768;
  const isMD = screenWidth > 767 && screenWidth < 1024;
  const isXL = screenWidth > 1024;

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { isXS, isSM, isMD, isXL, screenWidth };
};

export default useScreenWidth;
