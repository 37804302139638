// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.emails-list__scroll {
  width: 676px;
  height: 310px;
  overflow: auto;
}
.emails-list__row {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid;
  border-color: var(--pando-colors-neutral-border-100);
}
.emails-list__row td {
  border: none;
}
.emails-list__row.invalid {
  color: var(--pando-inky-blue-danger-text-200-dark);
  font-size: var(--font-size-md);
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
}
.emails-list__warning-triangle {
  margin-right: var(--pando-spacing-3);
}`, "",{"version":3,"sources":["webpack://./src/features/invitation/components/emails-list/emails-list.scss"],"names":[],"mappings":"AAEE;EACE,YAAA;EACA,aAAA;EACA,cAAA;AADJ;AAIE;EACE,aAAA;EACA,8BAAA;EACA,wBAAA;EACA,oDAAA;AAFJ;AAII;EACE,YAAA;AAFN;AAME;EACE,kDAAA;EAEA,8BAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;AALJ;AAQE;EACE,oCAAA;AANJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.emails-list {\n  &__scroll {\n    width: 676px;\n    height: 310px;\n    overflow: auto;\n  }\n\n  &__row {\n    display: flex;\n    justify-content: space-between;\n    border-bottom: 1px solid;\n    border-color: var(--pando-colors-neutral-border-100);\n\n    td {\n      border: none;\n    }\n  }\n\n  &__row.invalid {\n    color: var(--pando-inky-blue-danger-text-200-dark);\n\n    font-size: var(--font-size-md);\n    font-style: normal;\n    font-weight: 500;\n    line-height: 150%; /* 24px */\n  }\n\n  &__warning-triangle {\n    margin-right: var(--pando-spacing-3);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
