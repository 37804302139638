// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.people-container {
  display: flex;
  flex-direction: column;
  padding: var(--pando-spacing-6, 1rem);
}
.people-container__header {
  align-items: center;
  display: flex;
  height: 44px;
  justify-content: space-between;
  margin-bottom: var(--pando-spacing-6);
}
.people-container__header-title {
  font-size: var(--pando-font-sizes-4xl);
  font-weight: 600;
}
.people-container__tabs {
  flex-direction: column;
  margin-bottom: var(--pando-spacing-6);
}`, "",{"version":3,"sources":["webpack://./src/features/people/components/people-container/people-container.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,sBAAA;EACA,qCAAA;AAAF;AAEE;EACE,mBAAA;EACA,aAAA;EACA,YAAA;EACA,8BAAA;EACA,qCAAA;AAAJ;AAEI;EACE,sCAAA;EACA,gBAAA;AAAN;AAIE;EACE,sBAAA;EACA,qCAAA;AAFJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.people-container {\n  display: flex;\n  flex-direction: column;\n  padding: var(--pando-spacing-6, 1rem);\n\n  &__header {\n    align-items: center;\n    display: flex;\n    height: 44px;\n    justify-content: space-between;\n    margin-bottom: var(--pando-spacing-6);\n\n    &-title {\n      font-size: var(--pando-font-sizes-4xl);\n      font-weight: 600;\n    }\n  }\n\n  &__tabs {\n    flex-direction: column;\n    margin-bottom: var(--pando-spacing-6);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
