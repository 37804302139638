import i18n from 'i18next';
import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';

import { setToast } from '../../toast/state/slice';
import { selectUserPlanId } from '../../user/state/selectors';
import { fetchPeople } from '../../people/state/slice';

import { UpdateLicensesService } from './services';
import {
  updateLicenses,
  updateLicensesFailure,
  updateLicensesSuccess,
} from './slice';
import { selectMemberIds, selectMemberName } from './selectors';

import type { UpdateLicensesResponse } from './interfaces';
import type { AxiosInstance } from 'axios';
import type { PayloadAction } from '@reduxjs/toolkit';

function* updateLicensesHandler(
  action: PayloadAction<{
    productsToAdd: string[];
    productsToRemove: string[];
  }>,
) {
  const currentMemberName: string = yield select(selectMemberName);

  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');
    const updateLicensesService = new UpdateLicensesService(httpClient);

    const planId: string | null = yield select(selectUserPlanId);
    const memberIds: string[] = yield select(selectMemberIds);

    if (!planId) throw new Error('No planId provided');

    const { productsToAdd, productsToRemove } = action.payload;
    const payloadData = {
      ...(productsToAdd.length && {
        productsToAdd: { memberIds, productIds: productsToAdd },
      }),
      ...(productsToRemove.length && {
        productsToRemove: { memberIds, productIds: productsToRemove },
      }),
    };
    const response: UpdateLicensesResponse = yield call(
      updateLicensesService.updateLicenses,
      planId,
      payloadData,
    );

    yield put(updateLicensesSuccess(response));

    yield put(
      setToast({
        heading: i18n.t('licenseDialog.toastMessages.success', {
          memberName: currentMemberName,
        }),
        palette: 'success',
        text: ' ',
      }),
    );

    yield put(fetchPeople());
  } catch (error) {
    yield put(
      setToast({
        heading: i18n.t('licenseDialog.toastMessages.error', {
          memberName: currentMemberName,
        }),
        palette: 'danger',
        text: ' ',
      }),
    );

    if (error instanceof Error) {
      yield put(updateLicensesFailure({ error: error.message }));
    } else {
      yield put(
        updateLicensesFailure({
          error: i18n.t('licenseDialog.toastMessages.error'),
        }),
      );
    }
  }
}

export default function* updateLicensesSagas() {
  yield takeLatest(updateLicenses, updateLicensesHandler);
}
