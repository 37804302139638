import i18n from 'i18next';
import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';

import { setToast } from '../../toast/state/slice';
import { fetchTeams } from '../../teams/state/slice';
import { selectUserPlanId } from '../../user/state/selectors';
import { getDateFormat } from '../../../shared/utils';

import {
  addTeamMembers,
  addTeamMembersFailure,
  addTeamMembersSuccess,
  fetchTeamUsers,
  fetchTeamUsersFailure,
  fetchTeamUsersSuccess,
  moveTeamMembers,
  removeTeamMembers,
  removeTeamMembersFailure,
  removeTeamMembersSuccess,
} from './slice';
import { TeamMembersService } from './services';

import type { AxiosInstance } from 'axios';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { Member } from '../../../shared/interfaces';

function* fetchTeamUsersHandler({ payload: teamId }: { payload: string }) {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');
    const service = new TeamMembersService(httpClient);

    const planId: string | null = yield select(selectUserPlanId);

    if (!planId) throw new Error('No planId provided');

    const teamMembers: Member[] = yield call(
      service.fetchTeamMembers,
      planId,
      teamId,
    );

    const people = teamMembers.map((member) => ({
      ...member,
      lastSignIn: getDateFormat(member.lastSignIn),
    }));

    yield put(fetchTeamUsersSuccess(people));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetchTeamUsersFailure(error.message));
    } else {
      yield put(fetchTeamUsersFailure('An unknown error occurred'));
    }
  }
}

function* addTeamMembersHandler(
  action: PayloadAction<{
    users: string[];
    teamId: string;
    shouldFetchTeams?: boolean;
  }>,
) {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');
    const createTeamService = new TeamMembersService(httpClient);

    const planId: string | null = yield select(selectUserPlanId);

    if (!planId) throw new Error('No planId provided');

    const { users, teamId, shouldFetchTeams } = action.payload;
    const successMsg = i18n.t('toasts.usersAddedToTeam', {
      count: users.length,
    });

    const response: undefined = yield call(
      createTeamService.addTeamMembers,
      users,
      teamId,
      planId,
    );

    yield put(addTeamMembersSuccess(response));

    yield put(
      setToast({
        heading: '',
        palette: 'success',
        text: successMsg,
      }),
    );

    if (shouldFetchTeams) {
      yield put(fetchTeams());
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(addTeamMembersFailure(error.message));
    } else {
      yield put(
        addTeamMembersFailure(
          'Oops! An error occurred while trying to add team members. Please try again.',
        ),
      );
    }

    yield put(
      setToast({
        heading: i18n.t('toasts.headingAddTeamMembersUnsuccessful'),
        palette: 'danger',
        text: i18n.t('toasts.bodyAddTeamMembersUnsuccessful'),
      }),
    );
  }
}

function* moveTeamMembersHandler(
  action: PayloadAction<{
    users: string[];
    teamsToAddIds: string[];
    teamsToRemoveIds: string[];
    shouldFetchTeams?: boolean;
  }>,
) {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');
    const createTeamService = new TeamMembersService(httpClient);

    const planId: string | null = yield select(selectUserPlanId);

    if (!planId) throw new Error('No planId provided');

    const { users, teamsToAddIds, teamsToRemoveIds, shouldFetchTeams } =
      action.payload;
    const successMsg = i18n.t('toasts.userMovedFromTeams', {
      count: users.length,
      teamsCount: teamsToAddIds.length,
    });

    const response: undefined = yield call(
      createTeamService.moveTeamMembers,
      users,
      teamsToAddIds,
      teamsToRemoveIds,
      planId,
    );

    yield put(removeTeamMembersSuccess(response));

    yield put(
      setToast({
        heading: '',
        palette: 'success',
        text: successMsg,
      }),
    );

    if (shouldFetchTeams) {
      yield put(fetchTeams());
    }
  } catch (error) {
    const { users, teamsToAddIds } = action.payload;

    const errorMessage = i18n.t('toasts.headingMoveTeamMembersUnsuccessful', {
      count: users.length,
      teamCount: teamsToAddIds.length,
    });

    if (error instanceof Error) {
      yield put(removeTeamMembersFailure(error.message));
    } else {
      yield put(removeTeamMembersFailure(errorMessage));
    }

    yield put(
      setToast({
        heading: errorMessage,
        palette: 'danger',
        text: ' ',
      }),
    );
  }
}

function* removeTeamMembersHandler(
  action: PayloadAction<{
    users: string[];
    teamId: string;
    teamName: string;
    shouldFetchTeams?: boolean;
  }>,
) {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');
    const createTeamService = new TeamMembersService(httpClient);

    const planId: string | null = yield select(selectUserPlanId);

    if (!planId) throw new Error('No planId provided');

    const { users, teamId, shouldFetchTeams, teamName } = action.payload;
    const successMsg = i18n.t('toasts.usersRemovedFromTeam', {
      count: users.length,
      team: teamName,
    });

    const response: undefined = yield call(
      createTeamService.removeTeamMembers,
      users,
      teamId,
      planId,
    );

    yield put(removeTeamMembersSuccess(response));

    yield put(
      setToast({
        heading: '',
        palette: 'success',
        text: successMsg,
      }),
    );

    if (shouldFetchTeams) {
      yield put(fetchTeams());
    }
  } catch (error) {
    const { users, teamName } = action.payload;

    const errorMessage = i18n.t('toasts.headingRemoveTeamMembersUnsuccessful', {
      count: users.length,
      team: teamName,
    });

    if (error instanceof Error) {
      yield put(removeTeamMembersFailure(error.message));
    } else {
      yield put(removeTeamMembersFailure(errorMessage));
    }

    yield put(
      setToast({
        heading: errorMessage,
        palette: 'danger',
        text: ' ',
      }),
    );
  }
}

export default function* teamUsersSagas() {
  yield takeLatest(fetchTeamUsers, fetchTeamUsersHandler);
  yield takeLatest(addTeamMembers.type, addTeamMembersHandler);
  yield takeLatest(moveTeamMembers.type, moveTeamMembersHandler);
  yield takeLatest(removeTeamMembers.type, removeTeamMembersHandler);
}
