import type { ImportUploadUrlResponse } from './interfaces';
import type { AxiosInstance, AxiosResponse } from 'axios';
import type { ApiResponse } from '../../../shared/interfaces';
import type { TeamResponseItem } from '../../team/state/interfaces';

const PLANS_PATH = '/v1/plans';

export class TeamsService {
  private httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  fetchTeams = async (planId: string) => {
    try {
      const response = await this.httpClient.get<
        ApiResponse<AxiosResponse<TeamResponseItem[]>>
      >(`${PLANS_PATH}/${planId}/teams`);

      return response.data;
    } catch (error) {
      console.error('Error fetching teams:', error);

      throw error;
    }
  };

  fetchUploadImporTeamstUrl = async (
    planId: string,
  ): Promise<ImportUploadUrlResponse> => {
    try {
      const response = await this.httpClient.get(
        `${PLANS_PATH}/${planId}/teams/upload/url`,
      );

      return response.data;
    } catch (error) {
      console.error('Error getting upload URL', error);

      throw error;
    }
  };

  uploadImportTeamsFile = async (
    url: string,
    file: File,
  ): Promise<{ id: string }> => {
    try {
      const response = await this.httpClient.put(url, file, {
        headers: { 'Content-Type': file.type },
        transformRequest: [
          (data, headers) => {
            delete headers.Authorization;

            return data;
          },
        ],
      });

      return response.data;
    } catch (error) {
      console.error('Error uploading the file', error);

      throw error;
    }
  };
}
