import { forwardRef } from 'react';
import { Button } from '@pluralsight/react-ng';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import CustomDialog from '../../../../components/custom-dialog/custom-dialog';
import { selectTeam } from '../../../create-team/state/selectors';
import { removeTeamMembers } from '../../state/slice';
import { selectTeamItem } from '../../../team/state/selectors';

import type { UsersTableItem } from '../../../../shared/interfaces';
import type { TFunction } from 'i18next';
import type {
  ContentType,
  HeaderType,
} from '../../../../components/custom-dialog/custom-dialog';
import type { Team } from '../../../team/state/interfaces';

interface RemoveTeamMembersDialogProps {
  teamIdSelected: string;
  closeModal: () => void;
  shouldFetchUsers?: boolean;
  singleRemove?: boolean;
}

const dialogHeader = (
  t: TFunction<'translation', undefined>,
  singleRemove: boolean,
  assignedMembers: UsersTableItem[],
  team: Team | null,
): HeaderType => ({
  1: (
    <>
      {t(singleRemove ? 'teamUsers.removeTeamUsers.singleRemove' : '', {
        count: assignedMembers.length,
        user: `${assignedMembers[0]?.name || ''}`,
        team: team?.name,
      })}
    </>
  ),
});

const RemoveTeamMemberDialog = forwardRef<
  HTMLDialogElement | null,
  RemoveTeamMembersDialogProps
>(
  (
    {
      teamIdSelected,
      closeModal,
      shouldFetchUsers = false,
      singleRemove = false,
    },
    ref,
  ) => {
    const dispatch = useDispatch();
    const { assignedMembers } = useSelector(selectTeam);
    const team: Team | null = useSelector(selectTeamItem);
    const { teamId } = useParams();
    const { t } = useTranslations();

    const dialogContent: ContentType = {
      1: <span>{t('teamUsers.removeTeamUsers.singleRemoveDescription')}</span>,
    };

    const removeUsers = () => {
      const payload = {
        users: assignedMembers.map((member) => member.id),
        teamId: teamIdSelected || teamId || '',
        teamName: team?.name || '',
        shouldFetchUsers,
      };

      dispatch(removeTeamMembers(payload));
      closeModal();
    };

    const actionButtons = () => (
      <>
        <Button size="lg" palette="action" usage="text" onClick={closeModal}>
          {t('common.actionButtons.cancel')}
        </Button>
        <Button
          size="lg"
          disabled={!assignedMembers.length}
          palette={assignedMembers.length ? 'action' : 'neutral'}
          usage="filled"
          onClick={removeUsers}
        >
          {t('teamUsers.removeTeamUsers.removeTeamButton')}
        </Button>
      </>
    );

    return (
      <CustomDialog
        id="remove-users"
        ref={ref}
        dialogHeader={dialogHeader(
          t,
          singleRemove,
          assignedMembers as UsersTableItem[],
          team,
        )}
        dialogContent={dialogContent}
        actionButtons={actionButtons}
        multipleSteps={false}
        isWarning={false}
        closeModal={closeModal}
      />
    );
  },
);

export default RemoveTeamMemberDialog;
