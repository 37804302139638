import { FlexContainer } from '@pluralsight/react-ng';
import React from 'react';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import { widgetsByLanguage } from './widgets';

import type { Language } from '@ps-fe-plan-management/types';
import type { Widget } from './widgets';

const DashboardSidebar = () => {
  const { language: currentLanguage } = useTranslations();

  const widgets = widgetsByLanguage[currentLanguage as Language] as Widget[];
  const content = widgets.map((widget, _index) => {
    return (
      <div
        key={widget.title}
        className="dashboard-sidebar-widget"
        style={{
          padding: '16px',
          borderRadius: '4px',
          background: 'var(--neutral-surface-100, #1B1834)',
          gap: '16px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignSelf: 'stretch',
        }}
      >
        <h3
          style={{
            color: '#F3F3F8',
            fontFamily: 'var(--font-family-title, "PS TT Commons")',
            fontSize: 'var(--font-size-lg, 18px)',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '125%',
          }}
        >
          {widget.title}
        </h3>
        <p
          style={{
            color:
              'var(--neutral-text-medium, var(--neutral-text-200, #A5AACF))',
            fontFamily: '"PS TT Commons"',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '150%',
          }}
        >
          {widget.description}
        </p>
        {widget.content && (
          <ul
            style={{
              gap: '16px',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            {widget.content.map((item, _index) => {
              return (
                <li
                  key={item.title}
                  style={{
                    borderRadius: '4px',
                    background: 'var(--neutral-surface-300, #2A2753)',
                    display: 'flex',
                    padding: '12px',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '16px',
                    alignSelf: 'stretch',
                  }}
                >
                  <h4
                    style={{
                      color:
                        'var(--neutral-text-weak, var(--neutral-text-100, #8B90C1))',
                      fontVariantNumeric: 'lining-nums tabular-nums',
                      fontFeatureSettings: "'liga' off",
                      fontFamily: '"PS TT Commons"',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      lineHeight: '16px',
                      letterSpacing: '0.96px',
                      textTransform: 'uppercase',
                    }}
                  >
                    {item.title}
                  </h4>
                  <p
                    style={{
                      color:
                        'var(--neutral-text-strong, var(--neutral-text-300, #F3F3F8))',
                      fontFamily: 'var(--font-family-title, "PS TT Commons")',
                      fontSize: 'var(--font-size-md, 16px)',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      lineHeight: '150%',
                    }}
                  >
                    {item.description}
                  </p>
                  <p
                    style={{
                      color:
                        'var(--neutral-text-weak, var(--neutral-text-100, #8B90C1))',
                      fontFamily: 'var(--font-family-body, "PS TT Commons")',
                      fontSize: 'var(--font-size-xs, 12px)',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '150%',
                    }}
                  >
                    {item.channels}
                  </p>
                </li>
              );
            })}
          </ul>
        )}
        {widget.linkText && (
          <a
            href="#"
            style={{
              color: 'var(--action-navigation-initial, #3895FF)',
              fontFamily: 'var(--font-family-body, "PS TT Commons")',
              fontSize: 'var(--font-size-sm, 14px)',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '150%',
              textDecorationLine: 'underline',
              textDecorationStyle: 'solid',
              textDecorationSkipInk: 'none',
              textDecorationThickness: 'auto',
              textUnderlineOffset: 'auto',
              textUnderlinePosition: 'from-font',
              textAlign: 'right',
            }}
          >
            {widget.linkText}
          </a>
        )}
        {widget.btnText && (
          <button
            style={{
              padding: '8px 16px',
              borderRadius: '4px',
              background: 'var(--neutral-surface-300, #2A2753)',
              color:
                'var(--neutral-text-strong, var(--neutral-text-300, #F3F3F8))',
              fontFamily: 'var(--font-family-title, "PS TT Commons")',
              fontSize: 'var(--font-size-md, 16px)',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '150%',
              marginTop: '16px',
              cursor: 'pointer',
            }}
          >
            {widget.btnText}
          </button>
        )}
      </div>
    );
  });

  return (
    <FlexContainer className="dashboard-sidebar" direction="col" gap="16px">
      {content}
    </FlexContainer>
  );
};

export default DashboardSidebar;
