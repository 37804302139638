import React, { forwardRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Show } from '@pluralsight/react-ng';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import { selectMemberName } from '../state/selectors';
import CustomDialog from '../../../components/custom-dialog/custom-dialog';
import { selectPeopleList } from '../../people/state/selectors';
import { setCurrentMember } from '../state/slice';
import {
  selectAddOnProducts,
  selectProducts,
} from '../../products/state/selectors';

import SelectLicensesActionButtons from './action-buttons/select-licenses-action-buttons';
import ConfirmUpdatesActionButtons from './action-buttons/confirm-updates-action-buttons';
import UpdateLicensesStep from './steps/update-licenses-step';
import ConfirmStep from './steps/confirm-step';

import type {
  ContentType,
  HeaderType,
} from '../../../components/custom-dialog/custom-dialog';

interface UpdateLicensesDialogProps {
  currentUserId: string;
  closeModal: () => void;
}
const UpdateLicensesDialog = forwardRef<
  HTMLDialogElement | null,
  UpdateLicensesDialogProps
>(({ currentUserId, closeModal }, ref) => {
  const { t } = useTranslations();
  const dispatch = useDispatch();
  const users = useSelector(selectPeopleList);
  const currentMemberName = useSelector(selectMemberName);
  const products = useSelector(selectProducts);
  const addOnProducts = useSelector(selectAddOnProducts);

  const currentMember = users.find((user) => user.id === currentUserId);

  useEffect(() => {
    if (currentMember) {
      dispatch(setCurrentMember(currentMember));
    }
  }, [currentMember]);

  const dialogHeader: HeaderType = {
    1: (
      <h4>
        {t('licenseDialog.headerSelect', { memberName: currentMemberName })}
      </h4>
    ),
    2: (
      <h4>
        {t('licenseDialog.headerConfirm', {
          count: addOnProducts.length,
          memberName: currentMemberName,
        })}
      </h4>
    ),
  };

  const dialogContent: ContentType = {
    1: <UpdateLicensesStep />,
    2: <ConfirmStep />,
  };

  const getActionButtons = (step: number): React.JSX.Element => {
    switch (step) {
      case 1:
        return <SelectLicensesActionButtons closeModal={closeModal} />;

      case 2:
        return <ConfirmUpdatesActionButtons closeModal={closeModal} />;

      default:
        return <></>;
    }
  };

  return (
    <Show
      when={products.length === 1}
      children={
        <CustomDialog
          id="update-licenses"
          ref={ref}
          isWarning={false}
          multipleSteps={false}
          dialogHeader={dialogHeader}
          dialogContent={dialogContent}
          actionButtons={() => (
            <ConfirmUpdatesActionButtons closeModal={closeModal} />
          )}
          closeModal={closeModal}
        />
      }
      fallback={
        <CustomDialog
          id="update-licenses"
          ref={ref}
          isWarning={false}
          multipleSteps={2}
          dialogHeader={dialogHeader}
          dialogContent={dialogContent}
          actionButtons={getActionButtons}
          closeModal={closeModal}
        />
      }
    />
  );
});

export default UpdateLicensesDialog;
