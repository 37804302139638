import type { Role } from '../../roles/state/interfaces';
import type {
  AssignedLicenseSummary,
  ImportFile,
  ImportTabs,
  Member,
  RoleSummary,
  TeamSummary,
} from '../../../shared/interfaces';

export enum InviteStatus {
  Pending = 'Pending',
  Accepted = 'Accepted',
  Expired = 'Expired',
}

export enum NOTE_TYPE_ENUM {
  MEMBER = 'MEMBER',
  INVITE = 'INVITE',
}

export type NoteType = NOTE_TYPE_ENUM.MEMBER | NOTE_TYPE_ENUM.INVITE;

export interface Invite {
  id: string;
  psUserId: string;
  email: string;
  note: string | null;
  status: InviteStatus;
  invitationStatus?: string;
  roles: RoleSummary[];
  products?: AssignedLicenseSummary[] | null;
  teams: TeamSummary[];
  inviteRedemptionLink: string;
  expiresOn: Date;
  sendDate: Date;
}

export interface Person {
  id: string;
  active: boolean;
  email: string;
  username: string;
  firstName: string | null;
  lastName: string | null;
  roles: RoleSummary[] | string[];
  licenses: string | null;
  teams: TeamSummary[];
  lastLogin: string | null;
  note: string | null;
  invitationStatus: string | null;
  name?: string | null;
  createdAt?: Date | null;
  updatedAt?: Date | null;
}

export interface PeopleState {
  error: string | null;
  list: Member[];
  inviteList: Invite[];
  editUser: Member | null;
  roles: string[];
  loading: boolean;
  importFile: ImportFile | null;
  importActiveTab: ImportTabs;
}

export interface UsersResponseItem {
  id: string;
  active: boolean;
  age: number | null;
  country: string | null;
  email: string;
  firstName: string | null;
  lastName: string | null;
  phoneNumber: string | null;
  psId: string;
  username: string | null;
}

export interface ImportUploadUrlResponse {
  url: string;
}

export interface UpdateRole extends UpdateRolePayload {
  user: Member;
  newUserRoles: Role[];
}

export interface UpdateRolePayload {
  rolesToAdd: RoleItem[];
  rolesToRemove: RoleItem[];
}

interface RoleItem {
  psUserId: string;
  roleId: string;
}
