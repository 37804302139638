import React, { useEffect, useRef, useState } from 'react';
import './teams-table.scss';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisVerticalIcon,
} from '@pluralsight/icons';
import {
  Avatar,
  Caption,
  Checkbox,
  FlexContainer,
  For,
  FormControlProvider,
  IconButton,
  Option,
  Select,
  Show,
  Table,
  TBody,
  TD,
  TH,
  THead,
  TR,
} from '@pluralsight/react-ng';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import { handleMenuKeyBoardNavigation } from '../../../../../src/shared/helpers/handle-menu-keyboard-navigation';
import OptionsDropdown from '../../../../components/options-dropdown/options-dropdown';
import RoleRestrictedComponent from '../../../../components/role-restricted-component/role-restricted-component';
import SortingIconButton from '../../../../components/table/sorting-icon-button';
import {
  TABLES,
  TEAM_TABLE_KEYS,
  USER_ROLE,
} from '../../../../shared/constants';
import { SortingDirection } from '../../../../shared/interfaces';
import {
  convertCamelCaseToSnakeCase,
  createPaginationOptions,
  sortArray,
} from '../../../../shared/utils';
import {
  clearAssignedMembers,
  clearTeamName,
} from '../../../create-team/state/slice';
import AddTeamMembersDialog from '../../../team-users/components/add-team-members-dialog/add-team-members-dialog';
import TeamNameDialog from '../../../team/components/team-name-dialog/team-name-dialog';
import { fetchTeam } from '../../../team/state/slice';
import useIndividualMenuStatuses from '../../../../shared/hooks/useIndividualMenuStatuses';
import TextHighlight from '../text-highlight/text-highlight';
import { setDialogStep } from '../../../../shared/dialog/state/slice';

import { columnsTeamTable } from './columns';

import type { OptionsDropdownRef } from '../../../../components/options-dropdown/options-dropdown';
import type { ExpectedRole, Sorting } from '../../../../shared/interfaces';
import type { Team } from '../../../team/state/interfaces';

export interface TeamsTableItem {
  id: string;
  name: string | null;
  managersCount: string;
  users: string;
  nestedTeams: string;
  nestedUsers: string;
}

interface TeamsTableProps {
  teams: TeamsTableItem[];
  searchFilter: string;
}

export const toTeamsTableItems = (teams: Team[]): TeamsTableItem[] =>
  teams.map((team) => ({
    [TEAM_TABLE_KEYS.ID]: team.id,
    [TEAM_TABLE_KEYS.NAME]: team.name,
    [TEAM_TABLE_KEYS.MANAGERS_COUNT]: String(team.managersCount),
    [TEAM_TABLE_KEYS.USERS]: String(team.usersCount),
    [TEAM_TABLE_KEYS.NESTED_TEAMS]: '0',
    [TEAM_TABLE_KEYS.NESTED_USERS]: String(team.allUsersCount),
  }));

// TODO (przem-wierzbicki): REFACTOR!!! Use generic Customizable Table Component instead.
const TeamsTable: React.FC<TeamsTableProps> = ({ teams, searchFilter }) => {
  const OPTIONS_DROPDOWN_RESTRICTED_TO: ExpectedRole[] = [
    {
      roleName: USER_ROLE.SUPER_ADMIN,
      scope: 'organizationScope',
    },
    {
      roleName: USER_ROLE.SYSTEM_ADMIN,
      scope: 'organizationScope',
    },
    {
      roleName: USER_ROLE.PLAN_ADMIN,
      scope: 'planScope',
    },
    {
      roleName: USER_ROLE.MANAGER_FULL,
      scope: 'teamScope',
    },
    {
      roleName: USER_ROLE.MANAGER_LIMITED,
      scope: 'teamScope',
    },
  ];

  const addUsersDialogRef = useRef<HTMLDialogElement>(null);
  const renameTeamDialogRef = useRef<HTMLDialogElement>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [teamIdSelected, setTeamIdSelected] = useState<string>('');
  const { t } = useTranslations();
  const optionsDropdownRefs = useRef<Record<string, OptionsDropdownRef | null>>(
    {},
  );
  const [pagination, setPagination] = useState({
    currPage: 0,
    pageSize: TABLES.PAGINATION_OPTIONS[0],
  });

  const { individualMenuStatuses, toggleMenuStatuses } =
    useIndividualMenuStatuses(teams);

  const [sorting, setSorting] = useState<Sorting<TeamsTableItem>>({
    column: 'name',
    direction: SortingDirection.DESC,
  });

  const paginated = (teams: TeamsTableItem[]) => {
    return teams.slice(
      pagination.currPage * pagination.pageSize,
      (pagination.currPage + 1) * pagination.pageSize,
    );
  };

  const [_teams, _setTeams] = useState<TeamsTableItem[]>(() =>
    paginated(sortArray<TeamsTableItem>([...teams], sorting)),
  );

  const onChangeSorting = (column: keyof TeamsTableItem) => {
    const isSortingDirectionDesc =
      sorting.column === column && sorting.direction === SortingDirection.DESC;
    const direction = isSortingDirectionDesc
      ? SortingDirection.ASC
      : SortingDirection.DESC;

    setSorting({ column, direction });
  };

  const onChangePageSize = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const currPage = 0;
    const pageSize = parseInt(e.target.value);

    setPagination({ ...pagination, currPage, pageSize });
  };

  const onChangePage = (pageChange: number) => {
    const currPage = pagination.currPage + pageChange;

    if (currPage < 0 || currPage > teams.length / pagination.pageSize) return;

    setPagination({ ...pagination, currPage });
  };

  useEffect(() => {
    _setTeams(paginated(sortArray<TeamsTableItem>([...teams], sorting)));
  }, [teams, sorting, pagination]);

  const currFrom = pagination.currPage * pagination.pageSize + 1;
  const currTo =
    (pagination.currPage + 1) * pagination.pageSize <= teams.length
      ? (pagination.currPage + 1) * pagination.pageSize
      : teams.length;

  const isPaginationDisabled = teams.length <= 100;
  const paginationOptions = createPaginationOptions(teams.length);

  const showEditTeamNameDialog = (teamId: string) => {
    dispatch(fetchTeam(teamId));
    setTeamIdSelected(teamId);
    renameTeamDialogRef.current?.showModal();
  };
  const closeEditTeamNameDialog = () => {
    renameTeamDialogRef.current?.close();
    setTeamIdSelected('');
    dispatch(clearTeamName());
  };

  const showAddUsersDialog = (teamId: string) => {
    setTeamIdSelected(teamId);
    dispatch(setDialogStep(1));
    addUsersDialogRef.current?.showModal();
  };
  const closeAddUsersDialog = () => {
    addUsersDialogRef.current?.close();
    dispatch(setDialogStep(0));
    dispatch(clearAssignedMembers());
    setTeamIdSelected('');
  };
  const navigateToManageTeam = (teamId: string) => {
    navigate(`/teams/${teamId}`);
  };
  const handleEditTeamNameDialog = (
    teamId: string,
    e: React.MouseEvent<HTMLDivElement>,
  ) => {
    e.stopPropagation();
    toggleMenuStatuses(teamId);
    showEditTeamNameDialog(teamId);
  };
  const handleAddUserDialog = (
    teamId: string,
    e: React.MouseEvent<HTMLDivElement>,
  ) => {
    e.stopPropagation();
    toggleMenuStatuses(teamId);
    showAddUsersDialog(teamId);
  };
  const handleManageTeam = (teamId: string) => {
    toggleMenuStatuses(teamId);
    navigateToManageTeam(teamId);
  };

  const handleDropdownClose = (teamId: string) => {
    const dropdown = optionsDropdownRefs.current[teamId];

    dropdown?.onClose();
    dropdown?.onMoreActionsButtonFocus();
  };

  return (
    <div className="teams-table">
      <Caption type="base" className="teams-table__caption" />
      <FormControlProvider>
        <Table className="teams-table__table" hover="highlight" type="base">
          <THead className="teams-table__head" hover="highlight" type="base">
            <TR header hover="highlight" type="base">
              <TH hover="highlight" type="condensed" />
              <For
                each={columnsTeamTable}
                children={(column) => {
                  return (
                    <TH
                      hover="highlight"
                      type="base"
                      data-column={convertCamelCaseToSnakeCase(column.mapsTo)}
                      className={'teams-table__th'}
                    >
                      <FlexContainer className="users-table__th">
                        <span>{t(column.header)}</span>
                        <span className="users-table__th-separator" />
                        <SortingIconButton<TeamsTableItem>
                          column={column.mapsTo}
                          sorting={sorting}
                          onChangeSorting={onChangeSorting}
                        />
                      </FlexContainer>
                    </TH>
                  );
                }}
              />
              <RoleRestrictedComponent
                restrictedTo={OPTIONS_DROPDOWN_RESTRICTED_TO}
              >
                <TH hover="highlight" type="condensed" />
              </RoleRestrictedComponent>
            </TR>
          </THead>
          <TBody hover="highlight" type="base" style={{ textAlign: 'left' }}>
            <For
              each={_teams}
              children={(team: TeamsTableItem) => {
                return (
                  <TR
                    key={team.id}
                    hover="highlight"
                    className={'teams-table__tr'}
                    type="base"
                    onClick={() => navigateToManageTeam(team.id)}
                  >
                    <TD hover="highlight" type="base">
                      <Checkbox id={team.id} name={team.id} disabled />
                    </TD>
                    <For
                      each={columnsTeamTable}
                      children={(column) => {
                        return (
                          <TD
                            hover="highlight"
                            type="base"
                            data-column={convertCamelCaseToSnakeCase(
                              column.mapsTo,
                            )}
                            className={'teams-table__td'}
                          >
                            <FlexContainer className="users-table__td">
                              <Show when={!!column.useAvatar}>
                                <Avatar
                                  label={team[column.mapsTo] || team.id}
                                  size="xs"
                                />
                                <span className="users-table__td-separator" />
                              </Show>

                              <button
                                onClick={() => navigateToManageTeam(team.id)}
                                tabIndex={column.focusable ? 0 : -1}
                                aria-label={team[column.mapsTo] || '-'}
                              >
                                <Show
                                  when={!!column.searchColumn}
                                  fallback={
                                    <span>{team[column.mapsTo] || '-'}</span>
                                  }
                                >
                                  <TextHighlight
                                    text={team[column.mapsTo] || '-'}
                                    highlight={searchFilter}
                                  />
                                </Show>
                              </button>
                            </FlexContainer>
                          </TD>
                        );
                      }}
                    />
                    <RoleRestrictedComponent
                      restrictedTo={OPTIONS_DROPDOWN_RESTRICTED_TO}
                    >
                      <TD hover="highlight" type="base">
                        <OptionsDropdown
                          ref={(el) => {
                            optionsDropdownRefs.current[team.id] = el;
                          }}
                          button={<EllipsisVerticalIcon />}
                          isOpen={individualMenuStatuses[team.id]}
                          options={
                            <div
                              role="menu"
                              onKeyDown={handleMenuKeyBoardNavigation(
                                'teams-table-dropdown-option',
                                {
                                  Escape: () => handleDropdownClose(team.id),
                                  Tab: () => handleDropdownClose(team.id),
                                  ShiftTab: () => handleDropdownClose(team.id),
                                },
                              )}
                              tabIndex={0}
                            >
                              <div
                                className="teams-table-dropdown-option"
                                onClick={(event) =>
                                  handleEditTeamNameDialog(team.id, event)
                                }
                                role="menuitem"
                                tabIndex={0}
                                ref={(el) => el?.focus()}
                              >
                                <span className="dropdown-option-span">
                                  {t('team.editTeamName')}
                                </span>
                              </div>
                              <div
                                className="teams-table-dropdown-option"
                                onClick={(event) =>
                                  handleAddUserDialog(team.id, event)
                                }
                                role="menuitem"
                                tabIndex={0}
                              >
                                <span className="dropdown-option-span">
                                  {t(
                                    'teams.teamsTable.optionsDropdown.addUsers',
                                  )}
                                </span>
                                <span className="dropdown-option-description">
                                  {t(
                                    'teams.teamsTable.optionsDropdown.addUsersDescription',
                                  )}
                                </span>
                              </div>
                              <div
                                className="teams-table-dropdown-option"
                                onClick={() => handleManageTeam(team.id)}
                                role="menuitem"
                                tabIndex={0}
                              >
                                <span className="dropdown-option-span">
                                  {t(
                                    'teams.teamsTable.optionsDropdown.manageTeam',
                                  )}
                                </span>
                                <span className="dropdown-option-description">
                                  {t(
                                    'teams.teamsTable.optionsDropdown.manageTeamDescription',
                                  )}
                                </span>
                              </div>
                            </div>
                          }
                          setIsOpen={() => {
                            toggleMenuStatuses(team.id);
                          }}
                        />
                      </TD>
                    </RoleRestrictedComponent>
                  </TR>
                );
              }}
            />
          </TBody>
        </Table>
        <div className="teams-table__pagination">
          <FlexContainer align="center" justify="flexEnd">
            <span className="teams-table__pagination-status">
              <span className="emphasis">
                {currFrom}-{currTo}{' '}
              </span>
              {t('teams.of')} {teams.length}
            </span>
          </FlexContainer>
          <FlexContainer align="center" justify="center">
            <Select
              aria-label={t('pagination.itemsPerPage')}
              id="name"
              name="name"
              size="md"
              onChange={onChangePageSize}
              disabled={isPaginationDisabled}
            >
              <React.Fragment key=".0">
                <For
                  each={paginationOptions}
                  children={(paginationOption) => {
                    return (
                      <Option
                        name={paginationOption.toString()}
                        value={paginationOption.toString()}
                      >
                        {paginationOption}
                      </Option>
                    );
                  }}
                />
              </React.Fragment>
            </Select>
          </FlexContainer>
          <FlexContainer align="center" justify="flexStart">
            <IconButton
              ariaLabel={t('pagination.pageDown')}
              className="teams-table__pagination-icon"
              palette="action"
              usage="text"
              size="sm"
              disabled={pagination.currPage <= 0 || isPaginationDisabled}
              onClick={() => onChangePage(-1)}
            >
              <ChevronLeftIcon />
            </IconButton>
            <IconButton
              ariaLabel={t('pagination.pageUp')}
              className="teams-table__pagination-icon"
              palette="action"
              usage="text"
              size="sm"
              disabled={
                pagination.currPage >= teams.length / pagination.pageSize - 1 ||
                isPaginationDisabled
              }
              onClick={() => onChangePage(1)}
            >
              <ChevronRightIcon />
            </IconButton>
          </FlexContainer>
        </div>
      </FormControlProvider>
      <AddTeamMembersDialog
        ref={addUsersDialogRef}
        closeModal={closeAddUsersDialog}
        teamIdSelected={teamIdSelected}
        shouldFetchTeams
      />
      <TeamNameDialog
        ref={renameTeamDialogRef}
        id={teamIdSelected}
        closeModal={closeEditTeamNameDialog}
        shouldRefetchTeams
      />
    </div>
  );
};

export default TeamsTable;
