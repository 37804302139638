// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.users-list__scroll {
  width: 676px;
  height: 282px;
  overflow: auto;
}
.users-list__row {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid;
  border-color: var(--pando-colors-neutral-border-100);
}
.users-list__row td {
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/components/users-list/users-list.scss"],"names":[],"mappings":"AAEE;EACE,YAAA;EACA,aAAA;EACA,cAAA;AADJ;AAIE;EACE,aAAA;EACA,8BAAA;EACA,wBAAA;EACA,oDAAA;AAFJ;AAII;EACE,YAAA;AAFN","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.users-list {\n  &__scroll {\n    width: 676px;\n    height: 282px;\n    overflow: auto;\n  }\n\n  &__row {\n    display: flex;\n    justify-content: space-between;\n    border-bottom: 1px solid;\n    border-color: var(--pando-colors-neutral-border-100);\n\n    td {\n      border: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
