import { Button } from '@pluralsight/react-ng';
import React, { forwardRef } from 'react';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import CustomDialog from '../../../../components/custom-dialog/custom-dialog';
import SelectTeamManagers from '../../../invitation/components/select-team-managers/select-team-managers';

import type {
  ContentType,
  HeaderType,
} from '../../../../components/custom-dialog/custom-dialog';

interface IAddManagersDialogProps {
  closeModal: () => void;
}

const dialogHeader = (t: any): HeaderType => ({
  1: t('teamUsers.addTeamManagers'),
});

const AddManagersDialog = forwardRef<
  HTMLDialogElement | null,
  IAddManagersDialogProps
>(({ closeModal }, ref) => {
  const { t } = useTranslations();
  const dialogContent: ContentType = {
    1: <SelectTeamManagers />,
  };

  const addManagers = () => {
    closeModal();
  };

  const actionButtons = () => (
    <>
      <Button size="lg" palette="action" usage="text" onClick={closeModal}>
        {t('common.actionButtons.cancel')}
      </Button>
      <Button size="lg" palette="action" usage="filled" onClick={addManagers}>
        {t('teamUsers.addManagers')}
      </Button>
    </>
  );

  return (
    <CustomDialog
      id="add-managers"
      ref={ref}
      dialogHeader={dialogHeader(t)}
      dialogContent={dialogContent}
      actionButtons={actionButtons}
      multipleSteps={false}
      isWarning={false}
      closeModal={closeModal}
    />
  );
});

export default AddManagersDialog;
