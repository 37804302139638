import type { Subscription } from 'rxjs';
import type { Role } from '../features/roles/state/interfaces';
import type { USER_ROLE } from './constants';
import type { formStateType } from '../features/people/components/import-users-container/import-users-container';
import type { FileUploadFileChangeDetails } from '@pluralsight/react-ng';

export interface ApiResponse<T> {
  data: T;
  meta: {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    itemCount: number;
    page: number;
    pageCount: number;
    size: number;
  };
}

type EventPayload = Record<string, unknown>;

export interface EventBus {
  publish(event: string, payload: EventPayload | null): void;

  subscribe(
    event: string,
    callback: (payload: EventPayload | null) => void,
  ): Subscription;

  getValue(): { event: string; payload: EventPayload | null };
}

export interface ExpectedRole {
  resourceId?: string;
  roleName: USER_ROLE;
  scope: 'organizationScope' | 'planScope' | 'teamScope';
}

export interface Sorting<T> {
  column: keyof T;
  direction: SortingDirection;
}

export enum SortingDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum ImportTabs {
  Import = 'IMPORT',
  Export = 'EXPORT',
}

export interface ImportFile {
  id: string | null;
  file: File | null;
  objectKey: string | null;
  importErrors: string[] | null;
}

export interface TableColumn<T> {
  header: string;
  mapsTo: keyof T;
  useAvatar?: boolean;
  searchColumn?: boolean;
  focusable?: boolean;
}

export type Summary = {
  id: string;
  name: string;
};

export type TeamSummary = Summary;
export type RoleSummary = Summary;
export type AssignedLicenseSummary = Summary;

type Product = {
  productId: string;
  productName: string;
  productType: string;
};

export interface Member {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  note: string;
  products: Product[];
  teams: TeamSummary[];
  roles: Role[];
  lastSignIn: string;
}

export interface PlanInvitesResponseItem {
  id: string;
  psUserId: string;
  email: string;
  note: string | null;
  status: string;
  teams: TeamSummary[];
  roles: RoleSummary[];
  products: AssignedLicenseSummary[];
  inviteRedemptionLink: string;
  expiresOn: Date;
  sendDate: Date;
}

export interface UsersTableItem {
  id: string;
  name: string | null;
  email: string;
  roles: string | null;
  licenses: string | null;
  teams: string | null;
  teamsSummary: TeamSummary[];
  lastLogin: string | null;
  note: string | null;
  roleNames: string[] | null;
}

export interface UploadFileValue {
  accept: {
    [key: string]: string[];
  };
  maxFiles: number;
  maxFileSize: number;
  acceptedFiles: FileUploadFileChangeDetails;
  rejectedFiles: FileUploadFileChangeDetails;
  onFileChange: () => void;
}

export interface UploadFile {
  objectKey?: string | null;
  formState: Partial<formStateType>;
  importErrors?: string[] | null;
  fileUpload: {
    name: string;
    value: UploadFileValue;
  };
  onFileDelete: () => void;
}

export interface RejectedFile {
  errors: string[];
  file: File;
}

export interface UploadItem {
  file: File | File[];
  error?: string | null;
  onFileDeleteHandler: () => void;
}
