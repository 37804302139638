import { CheckCircleIcon, TrashIcon } from '@pluralsight/icons';
import {
  Button,
  FileUploadContext,
  FileUploadDropzone,
  FileUploadHiddenInput,
  FileUploadItem,
  FileUploadItemDeleteTrigger,
  FileUploadItemGroup,
  FileUploadItemName,
  FileUploadItemPreview,
  FileUploadItemSizeText,
  FileUploadLabel,
  FileUploadRootProvider,
  FileUploadTrigger,
  FlexContainer,
  FlexItem,
  FormControlProvider,
  IconButton,
  Input,
  Label,
  Radio,
  Show,
  useFileUpload,
} from '@pluralsight/react-ng';
import {
  PandoGapSm,
  PandoInkyBlueDangerBorderInitialDark,
} from '@pluralsight/design-tokens';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import cs from 'classnames';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';
import { validateUrl } from '@ps-fe-plan-management/features/sso/components/sso-configuration/sso-configuration-form/utils/validation';
import FieldValidationProvider from '@ps-fe-plan-management/features/sso/components/shared/field-validation/field-validation-provider';
import { errorOIDCFileUploadKeys } from '@ps-fe-plan-management/features/sso/components/sso-configuration/sso-configuration-form/oidc/constants';

import {
  DISCOVERY_URL,
  OIDCFormFields,
  OpenIdConnectType,
} from '../../../state/interfaces';
import { useFileChangeHandler } from '../../hooks/useFileChangeHandler';
import { useFieldTracker } from '../../hooks/useFieldTracker';

import type { FileError, FileRejection } from '@zag-js/file-upload';
import type { ChangeEvent } from 'react';
import type { FileUploadFileChangeDetails } from '@pluralsight/react-ng';

interface IOpenIdConnectFieldProps {
  discoveryUrl?: string;
  metadata?: unknown;
}

const fileUploadConfig = {
  accept: { 'application/json': ['.json'] },
  maxFiles: 1,
  maxFileSize: 1024 * 1024, // 1MB
};

const OpenIdConnectField = ({
  discoveryUrl,
  metadata,
}: IOpenIdConnectFieldProps) => {
  const { connectionId } = useParams();
  const [openIdConnectType, setOpenIdConnectType] = useState<OpenIdConnectType>(
    OpenIdConnectType.DiscoveryUrl,
  );
  const [openIdConnectDiscUrl, setOpenIdConnectDiscUrl] =
    useState(DISCOVERY_URL);
  const [isEditMode, setIsEditMode] = useState<boolean>(!!connectionId);
  const { handleFileChange } = useFileChangeHandler();
  const { t } = useTranslations();

  const initialOpenIdConnectType = discoveryUrl
    ? OpenIdConnectType.DiscoveryUrl
    : !discoveryUrl && metadata
      ? OpenIdConnectType.MetadataFile
      : undefined;

  useFieldTracker(initialOpenIdConnectType, openIdConnectType);
  useFieldTracker(discoveryUrl, openIdConnectDiscUrl);

  const handleDiscoveryUrlChange = (
    e: ChangeEvent<HTMLInputElement>,
    validateField: (inputValue: string) => void,
  ): void => {
    validateField(e.target.value);
    setOpenIdConnectDiscUrl(e.target.value);
  };

  const fileUpload = useFileUpload({
    ...fileUploadConfig,
    onFileChange: handleFileChange,
  });

  const hasRejectedFiles = !!fileUpload?.rejectedFiles?.length;

  const getFileUploadErrorText = (error: FileError) =>
    t(
      errorOIDCFileUploadKeys[error] ||
        'sso.ssoConfiguration.anErrorOccurredWhileUploading',
    );

  useEffect(() => {
    if (discoveryUrl) {
      setOpenIdConnectDiscUrl(discoveryUrl);
      setOpenIdConnectType(OpenIdConnectType.DiscoveryUrl);
    }

    if (!discoveryUrl && metadata) {
      setOpenIdConnectType(OpenIdConnectType.MetadataFile);

      // Convert metadata object to a JSON file and set it
      const file = convertObjToJson(metadata);

      fileUpload.setFiles([file]);
    }
  }, [discoveryUrl, metadata]);

  return (
    <>
      <fieldset className="fieldset__field">
        <FormControlProvider>
          <legend className="fieldset__label fieldset__label--mb-sm">
            {t('sso.ssoConfiguration.openIdConnectType')}
          </legend>
          <FlexContainer direction="col" gap="var(--pando-gap-md)">
            <Radio
              checked={openIdConnectType === OpenIdConnectType.DiscoveryUrl}
              id="openid-connect-type-discovery-url"
              name={OIDCFormFields.OPENID_CONNECT_TYPE}
              value={OpenIdConnectType.DiscoveryUrl}
              onChange={() =>
                setOpenIdConnectType(OpenIdConnectType.DiscoveryUrl)
              }
            >
              {t('sso.ssoConfiguration.discoveryURL')}
            </Radio>
            <Radio
              checked={openIdConnectType === OpenIdConnectType.MetadataFile}
              id="openid-connect-type-metadata-file"
              name={OIDCFormFields.OPENID_CONNECT_TYPE}
              value={OpenIdConnectType.MetadataFile}
              onChange={() =>
                setOpenIdConnectType(OpenIdConnectType.MetadataFile)
              }
            >
              {t('sso.ssoConfiguration.metadataFile')}
            </Radio>
          </FlexContainer>
        </FormControlProvider>
      </fieldset>
      <FlexItem className="fieldset__field">
        <Show when={openIdConnectType === OpenIdConnectType.DiscoveryUrl}>
          <FieldValidationProvider validate={validateUrl}>
            {({ error, validateField }) => (
              <FormControlProvider required invalid={!!error}>
                <Label
                  htmlFor="openid-connect-disc-url"
                  className={cs('fieldset__label', {
                    'fieldset__label--error': !!error,
                  })}
                >
                  {t('sso.ssoConfiguration.openIdConnectDiscoveryURL')}
                </Label>
                <Input
                  id="openid-connect-disc-url"
                  name={OIDCFormFields.OPENID_CONNECT_DISC_URL}
                  value={openIdConnectDiscUrl}
                  size="lg"
                  className="fieldset__input"
                  onChange={(e) => handleDiscoveryUrlChange(e, validateField)}
                />
              </FormControlProvider>
            )}
          </FieldValidationProvider>
          <p className="fieldset__note">
            {t('sso.ssoConfiguration.provideTheWellKnownOpenID')}
          </p>
        </Show>
        <Show when={openIdConnectType === OpenIdConnectType.MetadataFile}>
          <FileUploadRootProvider
            value={fileUpload}
            style={{ gap: PandoGapSm }}
          >
            <Show when={!fileUpload?.acceptedFiles?.length}>
              <FileUploadDropzone
                style={{
                  borderColor: hasRejectedFiles
                    ? PandoInkyBlueDangerBorderInitialDark
                    : undefined,
                }}
              >
                <FileUploadTrigger asChild>
                  <Button palette="action" size="md">
                    {t('sso.ssoConfiguration.chooseFile')}
                  </Button>
                </FileUploadTrigger>
                <FileUploadLabel
                  onClick={(e: MouseEvent) => e.preventDefault()}
                >
                  {t('sso.ssoConfiguration.chooseAFileOrDrag')}
                </FileUploadLabel>
              </FileUploadDropzone>
            </Show>
            <FileUploadItemGroup>
              <FileUploadContext>
                {({ acceptedFiles }: FileUploadFileChangeDetails) =>
                  acceptedFiles.map((file: File) => (
                    <FileUploadItem
                      key={file.name}
                      file={file}
                      className="fieldset__description"
                      style={{
                        color:
                          'var(--pando-inky-blue-neutral-text-initial-dark)',
                      }}
                    >
                      <FileUploadItemPreview type=".*">
                        <FlexContainer gap="var(--pando-gap-md)" align="center">
                          <CheckCircleIcon
                            style={{
                              color:
                                'var(--pando-inky-blue-success-border-initial-dark)',
                            }}
                          />
                          <FlexContainer direction="col">
                            <FileUploadItemName />
                            <FileUploadItemSizeText />
                          </FlexContainer>
                        </FlexContainer>
                      </FileUploadItemPreview>
                      <FileUploadItemDeleteTrigger asChild>
                        <IconButton
                          ariaLabel="trash icon"
                          palette="danger"
                          size="sm"
                          usage="text"
                          style={{
                            color: 'var(--pando-colors-danger-border-initial)',
                          }}
                          onClick={() => setIsEditMode(false)} // after this action, upload is required
                        >
                          <TrashIcon />
                        </IconButton>
                      </FileUploadItemDeleteTrigger>
                    </FileUploadItem>
                  ))
                }
              </FileUploadContext>
            </FileUploadItemGroup>
            <FileUploadContext>
              {({ rejectedFiles }: FileUploadFileChangeDetails) =>
                rejectedFiles.map(({ errors }: FileRejection) => (
                  <p className="field-validation-error-message">
                    {getFileUploadErrorText(errors[0])}
                  </p>
                ))
              }
            </FileUploadContext>
            <FileUploadHiddenInput
              name={!isEditMode ? OIDCFormFields.METADATA : undefined}
              required={!isEditMode}
            />
          </FileUploadRootProvider>
          <p className="fieldset__note">
            {t('sso.ssoConfiguration.uploadTheOidc')}
          </p>
        </Show>
      </FlexItem>
    </>
  );
};

const convertObjToJson = (metadata: object): File => {
  // Convert metadata object to a JSON file and set it
  const fileContent = JSON.stringify(metadata);
  const blob = new Blob([fileContent], { type: 'application/json' });

  const file = new File([blob], 'metadata.json', {
    type: 'application/json',
  });

  Object.defineProperty(file, 'isDummy', {
    value: true,
  });

  return file;
};

export default OpenIdConnectField;
