import { createSelector } from 'reselect';

import type { AppState } from '../../../store';
import type { RolesState } from './interfaces';

const selectRolesState = (state: AppState) => state.roles;

export const selectAllRoles = createSelector(
  [selectRolesState],
  (roles: RolesState) => roles.list,
);

export const selectMappedRoles = createSelector(
  [selectRolesState],
  (roles: RolesState) =>
    new Map(roles.list.map((role) => [role.name, role.id])),
);
