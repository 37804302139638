// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.team-name-dialog {
  max-width: 726px;
  padding: 0 var(--pando-spacing-6) var(--pando-spacing-6);
  position: relative;
  width: 726px;
}
.team-name-dialog__instructions {
  margin-bottom: var(--pando-spacing-4);
}`, "",{"version":3,"sources":["webpack://./src/features/team/components/team-name-dialog/team-name-dialog.scss"],"names":[],"mappings":"AACA;EACE,gBAAA;EACA,wDAAA;EACA,kBAAA;EACA,YAAA;AAAF;AAEE;EACE,qCAAA;AAAJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.team-name-dialog {\n  max-width: 726px;\n  padding: 0 var(--pando-spacing-6) var(--pando-spacing-6);\n  position: relative;\n  width: 726px;\n\n  &__instructions {\n    margin-bottom: var(--pando-spacing-4);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
