import { useEffect, useState } from 'react';

type StatusMap = Record<string, boolean>;

interface ReturnHookProps {
  individualMenuStatuses: StatusMap;
  toggleMenuStatuses: (id: string) => void;
}

const mapStatuses = <T extends { id: string }>(items: T[]) => {
  return items.reduce<StatusMap>((statusMap, item: T) => {
    statusMap[item.id] = false;

    return statusMap;
  }, {});
};

const useIndividualMenuStatuses = <T extends { id: string }>(
  items: T[],
): ReturnHookProps => {
  const [individualMenuStatuses, setIndividualMenuStatuses] = useState(() =>
    mapStatuses(items),
  );

  const toggleMenuStatuses = (teamId: string) => {
    setIndividualMenuStatuses((prevMap) => {
      const updatedMap: StatusMap = {};

      Object.keys(prevMap).forEach((key) => {
        updatedMap[key] = key === teamId ? !prevMap[key] : false;
      });

      return updatedMap;
    });
  };

  useEffect(() => {
    setIndividualMenuStatuses(mapStatuses(items));
  }, [items]);

  return { individualMenuStatuses, toggleMenuStatuses };
};

export default useIndividualMenuStatuses;
