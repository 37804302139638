import {
  Caption,
  FlexContainer,
  FlexItem,
  For,
  Table,
  TBody,
  TD,
  TR,
} from '@pluralsight/react-ng';
import { useDispatch } from 'react-redux';
import { forwardRef, useImperativeHandle, useRef } from 'react';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import { assignUser } from '../../features/create-team/state/slice';
import { assignManagers } from '../../features/team-users/state/slice';

import type { Invite, Person } from '../../features/people/state/interfaces';
import './people-search-result.scss';

interface IPeopleSearchResultProps {
  isAddingManagers: boolean;
  users: Person[];
  filteredInvites: Invite[];
  cleanSearch: () => void;
}

export interface MethodsExport {
  focusFirstUser: () => void;
}

const PeopleSearchResultComponent = forwardRef<
  MethodsExport,
  IPeopleSearchResultProps
>(({ isAddingManagers, users, filteredInvites, cleanSearch }, ref) => {
  const dispatch = useDispatch();
  const { t } = useTranslations();
  const rowRefs = useRef<Array<HTMLTableRowElement | null>>([]);

  useImperativeHandle(ref, () => ({
    focusFirstUser: () => {
      if (rowRefs.current.length > 0 && rowRefs.current[0]) {
        rowRefs.current[0].focus();
      }
    },
  }));

  const sortedUsers = users.sort((a, b) => {
    if (a.firstName && b.firstName) {
      return a.firstName.localeCompare(b.firstName);
    }

    return 0;
  });

  const sortedInvites = filteredInvites.sort((a, b) =>
    a.email.localeCompare(b.email),
  );

  const handleClick = (user: Person | Invite) => {
    if ('inviteRedemptionLink' in user) {
      const userInvite: Person = {
        id: user.psUserId,
        firstName: '',
        lastName: '',
        email: user.email,
        active: false,
        username: '',
        roles: user.roles.map((role) => role.id),
        licenses: null,
        teams: user.teams,
        lastLogin: null,
        note: user.note,
        invitationStatus: user.status,
      };

      dispatch(
        isAddingManagers ? assignManagers(userInvite) : assignUser(userInvite),
      );
    } else {
      dispatch(isAddingManagers ? assignManagers(user) : assignUser(user));
    }

    cleanSearch();
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLTableRowElement>,
    index: number,
  ) => {
    const isArrowDown = event.key === 'ArrowDown';
    const isArrowUp = event.key === 'ArrowUp';
    const isEnterOrSpace = event.key === 'Enter' || event.key === ' ';

    if (isArrowDown || isArrowUp) {
      event.preventDefault();

      const nextIndex =
        (index + (isArrowDown ? 1 : -1) + rowRefs.current.length) %
        rowRefs.current.length;

      rowRefs.current[nextIndex]?.focus();
    } else if (isEnterOrSpace) {
      event.preventDefault();
      rowRefs.current[index]?.click();
    }
  };

  return (
    <Table hover="highlight" type="base">
      <Caption type="base" className="caption">
        {t('peopleSearchResult.title')}
      </Caption>
      <TBody type="base" className="tb">
        <FlexContainer
          direction="col"
          align="stretch"
          className="people-search__scroll"
        >
          <For
            each={sortedUsers}
            children={(user, index) => (
              <TR
                role="row"
                type="base"
                hover="highlight"
                onClick={() => handleClick(user)}
                ref={(el) => (rowRefs.current[index] = el)}
                tabIndex={index === 0 ? 0 : -1}
                aria-labelledby={`user-name-${index} user-email-${index}`}
                onKeyDown={(event) => handleKeyDown(event, index)}
              >
                <TD type="base">
                  <FlexContainer direction="col">
                    <FlexItem
                      id={`user-name-${index}`}
                      className="people-search__username"
                    >
                      {`${user.firstName} ${user.lastName}`}
                    </FlexItem>
                    <FlexItem
                      id={`user-email-${index}`}
                      className="people-search__email"
                    >
                      {user.email}
                    </FlexItem>
                  </FlexContainer>
                </TD>
              </TR>
            )}
          />

          <For
            each={sortedInvites}
            children={(invite, index) => (
              <TR
                role="row"
                type="base"
                hover="highlight"
                onClick={() => handleClick(invite)}
                tabIndex={0}
                aria-labelledby={`pending-invite-${index} invite-email-${index}`}
                ref={(el) => (rowRefs.current[sortedUsers.length + index] = el)}
                onKeyDown={(event) =>
                  handleKeyDown(event, sortedUsers.length + index)
                }
              >
                <TD type="base">
                  <FlexContainer direction="col">
                    <FlexItem
                      id={`pending-invite-${index}`}
                      className="people-search__pending-invite"
                    >
                      {t('peopleSearchResult.pendingInvite')}
                    </FlexItem>
                    <FlexItem
                      id={`invite-email-${index}`}
                      className="people-search__email"
                    >
                      {invite.email}
                    </FlexItem>
                  </FlexContainer>
                </TD>
              </TR>
            )}
          />
        </FlexContainer>
      </TBody>
    </Table>
  );
});

export default PeopleSearchResultComponent;
