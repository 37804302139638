import { Button } from '@pluralsight/react-ng';
import { forwardRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import CustomDialog from '../../../../components/custom-dialog/custom-dialog';
import UpdateNote from '../../../../components/update-note/update-note';
import { updateNote as updateNoteAction } from '../../../people/state/slice';

import type {
  ContentType,
  HeaderType,
} from '../../../../components/custom-dialog/custom-dialog';
import type { UsersTableItem } from '../../../../shared/interfaces';
import type { Invite, NoteType } from '../../../people/state/interfaces';

interface IUpdateNoteDialogProps {
  type: NoteType;
  noteUser: UsersTableItem | Invite;
  closeModal: () => void;
}

const UpdateNoteDialog = forwardRef<
  HTMLDialogElement | null,
  IUpdateNoteDialogProps
>(({ noteUser, closeModal, type }, ref) => {
  const [note, setNote] = useState<string | null>(noteUser?.note);
  const dispatch = useDispatch();
  const { t } = useTranslations();

  const updateNoteHandler = () => {
    dispatch(updateNoteAction({ id: noteUser.id, note: note ?? '', type }));
    closeModal();
  };

  const handleNoteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const value = e.target.value;

    setNote(value);
  };

  const dialogHeader = (): HeaderType => ({
    1: <h4>{t('noteDialog.edit')}</h4>,
  });

  const dialogContent: ContentType = {
    1: (
      <UpdateNote
        noteUser={noteUser}
        note={note as string}
        type={type}
        handleNoteChange={handleNoteChange}
      />
    ),
  };

  const actionButtons = () => (
    <>
      <Button size="lg" palette="action" usage="text" onClick={closeModal}>
        {t('common.actionButtons.cancel')}
      </Button>
      <Button
        size="lg"
        palette="action"
        usage="filled"
        onClick={updateNoteHandler}
      >
        {t('common.actionButtons.update')}
      </Button>
    </>
  );

  useEffect(() => {
    if (noteUser) {
      setNote(noteUser.note);
    }
  }, [noteUser]);

  return (
    <CustomDialog
      id="update-note"
      ref={ref}
      dialogHeader={dialogHeader()}
      dialogContent={dialogContent}
      actionButtons={actionButtons}
      multipleSteps={false}
      isWarning={false}
      closeModal={closeModal}
    />
  );
});

export default UpdateNoteDialog;
