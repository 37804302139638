// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.update-licenses__container {
  margin-top: var(--pando-spacing-2);
}
.update-licenses__container.scroll {
  max-height: 433px;
  overflow: auto;
}
.update-licenses__title {
  margin: var(--pando-spacing-4) 0 var(--pando-spacing-2);
}
.update-licenses__row {
  margin-bottom: var(--pando-spacing-2);
}
.update-licenses__check-icon {
  color: var(--pando-inky-blue-success-border-initial-light);
  margin-right: var(--pando-spacing-2);
}
.update-licenses__close-icon {
  color: var(--pando-inky-blue-danger-text-200-dark);
  margin-right: var(--pando-spacing-2);
}`, "",{"version":3,"sources":["webpack://./src/features/update-licenses/components/steps/update-licenses.scss"],"names":[],"mappings":"AAEE;EACE,kCAAA;AADJ;AAIE;EACE,iBAAA;EACA,cAAA;AAFJ;AAKE;EACE,uDAAA;AAHJ;AAME;EACE,qCAAA;AAJJ;AAOE;EACE,0DAAA;EACA,oCAAA;AALJ;AAQE;EACE,kDAAA;EACA,oCAAA;AANJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.update-licenses {\n  &__container {\n    margin-top: var(--pando-spacing-2);\n  }\n\n  &__container.scroll {\n    max-height: 433px;\n    overflow: auto;\n  }\n\n  &__title {\n    margin: var(--pando-spacing-4) 0 var(--pando-spacing-2);\n  }\n\n  &__row {\n    margin-bottom: var(--pando-spacing-2);\n  }\n\n  &__check-icon {\n    color: var(--pando-inky-blue-success-border-initial-light);\n    margin-right: var(--pando-spacing-2);\n  }\n\n  &__close-icon {\n    color: var(--pando-inky-blue-danger-text-200-dark);\n    margin-right: var(--pando-spacing-2);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
