// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.right-icon-input-container {
  position: relative;
}
.right-icon-input-container__icon {
  position: absolute;
  right: 1rem;
  transform: translateY(-50%);
  top: 50%;
}
.right-icon-input-container .pando-input__root {
  margin-top: 0;
}`, "",{"version":3,"sources":["webpack://./src/features/sso/components/shared/right-icon-input/right-icon-input.scss"],"names":[],"mappings":"AACA;EACE,kBAAA;AAAF;AAEE;EACE,kBAAA;EACA,WAAA;EACA,2BAAA;EACA,QAAA;AAAJ;AAGE;EACE,aAAA;AADJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.right-icon-input-container {\n  position: relative;\n\n  &__icon {\n    position: absolute;\n    right: 1rem;\n    transform: translateY(-50%);\n    top: 50%;\n  }\n\n  & .pando-input__root {\n    margin-top: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
