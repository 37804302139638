// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.email-step {
  /* pando overrides */
}
.email-step__input-label {
  margin-bottom: var(--pando-spacing-4);
}
.email-step__error-message {
  color: var(--pando-inky-blue-danger-text-200-dark);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}
.email-step__add-emails-button {
  margin: 0;
}
.email-step__table-container {
  margin-top: var(--pando-spacing-6);
}
.email-step__table-container .caption {
  display: none;
}
.email-step__table-container table {
  table-layout: fixed;
  width: 100%;
}
.email-step__table-container tr {
  background-color: unset;
}
.email-step__placeholder-container {
  box-sizing: content-box;
  margin: 44.5px 0 0;
  height: 265px;
}
.email-step__placeholder-message {
  color: var(--neutral-text-300, #f3f3f8);
  font-family: var(--font-family-title), "PS TT Commons", sans-serif;
  font-size: var(--pando-font-size-2xl-font-size, 24px);
  font-weight: 600;
  line-height: 125%;
  text-align: center;
}
.email-step__input-container .pando-input__root {
  margin-top: 0;
}`, "",{"version":3,"sources":["webpack://./src/features/invitation/components/email-step/email-step.scss"],"names":[],"mappings":"AACA;EAkDE,oBAAA;AAjDF;AAAE;EACE,qCAAA;AAEJ;AACE;EACE,kDAAA;EAEA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,aAAA;AAAJ;AAGE;EACE,SAAA;AADJ;AAIE;EAKE,kCAAA;AANJ;AAEI;EACE,aAAA;AAAN;AAME;EACE,mBAAA;EACA,WAAA;AAJJ;AAOE;EACE,uBAAA;AALJ;AAQE;EACE,uBAAA;EACA,kBAAA;EACA,aAAA;AANJ;AASE;EACE,uCAAA;EACA,kEAAA;EACA,qDAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AAPJ;AAWE;EACE,aAAA;AATJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.email-step {\n  &__input-label {\n    margin-bottom: var(--pando-spacing-4);\n  }\n\n  &__error-message {\n    color: var(--pando-inky-blue-danger-text-200-dark);\n\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 16px; /* 133.333% */\n  }\n\n  &__add-emails-button {\n    margin: 0;\n  }\n\n  &__table-container {\n    .caption {\n      display: none;\n    }\n\n    margin-top: var(--pando-spacing-6);\n  }\n\n  &__table-container table {\n    table-layout: fixed;\n    width: 100%;\n  }\n\n  &__table-container tr {\n    background-color: unset;\n  }\n\n  &__placeholder-container {\n    box-sizing: content-box;\n    margin: 44.5px 0 0;\n    height: 265px;\n  }\n\n  &__placeholder-message {\n    color: var(--neutral-text-300, #f3f3f8);\n    font-family: var(--font-family-title), \"PS TT Commons\", sans-serif;\n    font-size: var(--pando-font-size-2xl-font-size, 24px);\n    font-weight: 600;\n    line-height: 125%;\n    text-align: center;\n  }\n\n  /* pando overrides */\n  &__input-container .pando-input__root {\n    margin-top: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
