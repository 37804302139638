import { Show } from '@pluralsight/react-ng';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import { handleMenuKeyBoardNavigation } from '../../../../../src/shared/helpers/handle-menu-keyboard-navigation';
import PeopleIcon from '../../../../assets/people.svg';
import InvitesTable from '../../../../components/invites-table/invites-table';
import Placeholder from '../../../../components/placeholder/placeholder';
import './team-pending-invites-container.scss';
import { selectUser } from '../../../user/state/selectors';
import { selectTeamPendingInvitesList } from '../../state/selectors';
import { fetchTeamPendingInvites } from '../../state/slice';

import type { KeyBoardActions } from '../../../../../src/shared/helpers/handle-menu-keyboard-navigation';
import type { Invite } from '../../../people/state/interfaces';

interface TeamPendingInvitesContainerProps {
  teamId: string | null;
}

const TeamPendingInvitesContainer: React.FC<
  TeamPendingInvitesContainerProps
> = ({ teamId }) => {
  const { t } = useTranslations();
  const dispatch = useDispatch();

  const user = useSelector(selectUser);

  useEffect(() => {
    if (teamId) {
      dispatch(fetchTeamPendingInvites(teamId));
    }
  }, [dispatch, teamId, user]);

  const pendingInvites: Invite[] = useSelector(selectTeamPendingInvitesList);

  const handleResendInvite = (user: Invite) => {
    // eslint-disable-next-line no-console
    console.log('resend invite', user);
  };

  const handleRevokeInvite = (user: Invite) => {
    // eslint-disable-next-line no-console
    console.log('revoke invite', user);
  };

  const renderDropdownOptions = useCallback(
    (invite: Invite, actions: KeyBoardActions) => {
      return (
        <div
          role="menu"
          onKeyDown={handleMenuKeyBoardNavigation(
            'invite-dropdown-option',
            actions,
          )}
          tabIndex={0}
        >
          <div
            className="invite-dropdown-option"
            onClick={() => handleResendInvite(invite)}
            role="menuItem"
            tabIndex={0}
            ref={(el) => el?.focus()}
          >
            <span className="dropdown-option-span">
              {t('invitesTable.optionsDropdown.resendInvite')}
            </span>
            <span className="dropdown-option-description">
              {t('invitesTable.optionsDropdown.sentAnotherInvite')}
            </span>
          </div>
          <div
            className="invite-dropdown-option"
            onClick={() => handleRevokeInvite(invite)}
            role="menuItem"
            tabIndex={0}
          >
            <span className="dropdown-option-span">
              {t('invitesTable.optionsDropdown.deleteInvite')}
            </span>
            <span className="dropdown-option-description">
              {t('invitesTable.optionsDropdown.revokeTheAssignedLicense')}
            </span>
          </div>
        </div>
      );
    },
    [],
  );

  return (
    <Show
      when={!!pendingInvites.length}
      fallback={
        <div className="team-pending-invites-container__placeholder">
          <Placeholder icon={PeopleIcon}>
            <div className="team-pending-invites-container__placeholder-message">
              {t('teamPendingInvites.teamHasNoPendingInvites')}
            </div>
          </Placeholder>
        </div>
      }
    >
      <InvitesTable
        invites={pendingInvites}
        dropDownOptions={renderDropdownOptions}
      />
    </Show>
  );
};

export default TeamPendingInvitesContainer;
