// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.export-users-container__section {
  padding-top: var(--pando-spacing-4);
}
.export-users-container__subtitle {
  color: var(--pando-colors-neutral-text-300);
  font-size: var(--pando-font-sizes-md);
  font-weight: var(--pando-font-weights-bold);
  font-variation-settings: var(--pando-text-styles-display-md-font-variation-settings);
}
.export-users-container__action {
  display: flex;
  align-items: flex-start;
  gap: var(--pando-spacing-2);
  padding-top: var(--pando-spacing-4);
}
.export-users-container__icon {
  cursor: pointer;
  flex-shrink: 0;
  color: var(--pando-colors-action-navigation-initial);
  margin-top: 3px;
}
.export-users-container__text {
  display: inline;
  white-space: normal;
  max-width: 100%;
}
.export-users-container__link {
  cursor: pointer;
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/features/people/components/export-users-container/export-users-container.scss"],"names":[],"mappings":"AAEE;EACE,mCAAA;AADJ;AAIE;EACE,2CAAA;EACA,qCAAA;EACA,2CAAA;EACA,oFAAA;AAFJ;AAOE;EACE,aAAA;EACA,uBAAA;EACA,2BAAA;EACA,mCAAA;AALJ;AAQE;EACE,eAAA;EACA,cAAA;EACA,oDAAA;EACA,eAAA;AANJ;AASE;EACE,eAAA;EACA,mBAAA;EACA,eAAA;AAPJ;AAUE;EACE,eAAA;EACA,mBAAA;AARJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.export-users-container {\n  &__section {\n    padding-top: var(--pando-spacing-4);\n  }\n\n  &__subtitle {\n    color: var(--pando-colors-neutral-text-300);\n    font-size: var(--pando-font-sizes-md);\n    font-weight: var(--pando-font-weights-bold);\n    font-variation-settings: var(\n      --pando-text-styles-display-md-font-variation-settings\n    );\n  }\n\n  &__action {\n    display: flex;\n    align-items: flex-start;\n    gap: var(--pando-spacing-2);\n    padding-top: var(--pando-spacing-4);\n  }\n\n  &__icon {\n    cursor: pointer;\n    flex-shrink: 0;\n    color: var(--pando-colors-action-navigation-initial);\n    margin-top: 3px;\n  }\n\n  &__text {\n    display: inline;\n    white-space: normal;\n    max-width: 100%;\n  }\n\n  &__link {\n    cursor: pointer;\n    white-space: nowrap;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
