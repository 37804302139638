// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.role-confirmation-step__container {
  height: 503px;
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/features/people/components/role-confirmation-step/role-confirmation-step.scss"],"names":[],"mappings":"AAEE;EACE,aAAA;EACA,cAAA;AADJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.role-confirmation-step {\n  &__container {\n    height: 503px;\n    overflow: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
