import { createSelector } from 'reselect';

import type { RootState } from '../../../../../store';
import type {
  ISsoConfigurationOIDC,
  ISsoConfigurationSAML,
  ISsoConnection,
  ISsoConnectionsState,
} from './interfaces';

const selectSsoConnectionState = (state: RootState) => state.ssoConnections;

export const selectSsoConnections = createSelector(
  [selectSsoConnectionState],
  (ssoConnectionsState: ISsoConnectionsState) =>
    ssoConnectionsState.connections,
);

export const selectSsoConnectionsLoading = createSelector(
  [selectSsoConnectionState],
  (ssoConnectionsState: ISsoConnectionsState) =>
    ssoConnectionsState.fetch.loading,
);

export const selectSsoConnectionsError = createSelector(
  [selectSsoConnectionState],
  (ssoConnectionsState: ISsoConnectionsState) =>
    ssoConnectionsState.fetch.error,
);

export const selectSsoConnectionsSuccess = createSelector(
  [selectSsoConnectionState],
  (ssoConnectionsState: ISsoConnectionsState) =>
    ssoConnectionsState.fetch.success,
);

export const selectSsoConnectionDeleteError = createSelector(
  [selectSsoConnectionState],
  (ssoConnectionsState: ISsoConnectionsState) =>
    ssoConnectionsState.delete.error,
);

export const selectSsoConnectionDeleteSuccess = createSelector(
  [selectSsoConnectionState],
  (ssoConnectionsState: ISsoConnectionsState) =>
    ssoConnectionsState.delete.success,
);

export const selectSingleSsoConnection = createSelector(
  [selectSsoConnectionState],
  (ssoConnectionsState: ISsoConnectionsState): ISsoConnection =>
    ssoConnectionsState.singleConnection ?? ({} as ISsoConnection),
);

export const selectSingleSsoConnectionLoading = createSelector(
  [selectSsoConnectionState],
  (ssoConnectionsState: ISsoConnectionsState) =>
    ssoConnectionsState.singleFetch.loading,
);

export const selectSingleSsoConnectionError = createSelector(
  [selectSsoConnectionState],
  (ssoConnectionsState: ISsoConnectionsState) =>
    ssoConnectionsState.singleFetch.error,
);

export const selectSingleSsoConnectionSuccess = createSelector(
  [selectSsoConnectionState],
  (ssoConnectionsState: ISsoConnectionsState) =>
    ssoConnectionsState.singleFetch.success,
);

export const selectSingleSsoConnectionName = createSelector(
  [selectSingleSsoConnection],
  (singleSsoConnectionState: ISsoConnection) => singleSsoConnectionState?.name,
);

export const selectSingleSsoConnectionClientId = createSelector(
  [selectSingleSsoConnection],
  (singleSsoConnectionState: ISsoConnection) =>
    (singleSsoConnectionState?.configurations as ISsoConfigurationOIDC)
      ?.clientId,
);

export const selectSingleSsoConnectionConfigurations = createSelector(
  [selectSingleSsoConnection],
  (singleSsoConnectionState: ISsoConnection) =>
    singleSsoConnectionState?.configurations ?? {},
);

export const selectSingleSsoConnectionOidcMetadata = createSelector(
  [selectSingleSsoConnection],
  (singleSsoConnectionState: ISsoConnection) =>
    (singleSsoConnectionState?.configurations as ISsoConfigurationOIDC)
      ?.oidcMetadata,
);

export const selectSingleSsoConnectionSigningCert = createSelector(
  [selectSingleSsoConnection],
  (singleSsoConnectionState: ISsoConnection) =>
    (singleSsoConnectionState?.configurations as ISsoConfigurationSAML)
      ?.signingCert,
);

export const selectActiveSsoConnections = createSelector(
  [selectSsoConnections],
  (connections: ISsoConnection[]) =>
    connections.filter((connection) => connection.status === 'ACTIVE'),
);
