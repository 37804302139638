import React from 'react';
import './text-highlight.scss';

interface TextHighlightProps {
  text: string;
  highlight: string;
  minKeyStrokes?: number;
}

const TextHighlight = React.memo(
  ({ text, highlight, minKeyStrokes = 2 }: TextHighlightProps) => {
    if (!highlight.trim() || highlight.length < minKeyStrokes) {
      return <span>{text}</span>;
    }

    const regex = new RegExp(`(${highlight})`, 'gi');
    const parts = text.split(regex);

    const renderHighlight = () =>
      parts.map((part: string, i: number) =>
        regex.test(part) ? (
          <span key={i} className="highlight-container--highlighted">
            {part}
          </span>
        ) : (
          <span key={i}>{part}</span>
        ),
      );

    return <span className="highlight-container">{renderHighlight()}</span>;
  },
);

export default TextHighlight;
