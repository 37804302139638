import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import CustomDialog from '../../../../components/custom-dialog/custom-dialog';
import TeamNameStep from '../../../../components/team-name-input/team-name-input';
import ConfirmStepActionButtons from '../action-buttons/confirm-step-action-buttons';
import TeamNameActionButtons from '../action-buttons/team-name-action-buttons';
import TeamNestingStepActionButtons from '../action-buttons/team-nesting-action-buttons';
import TeamUsersActionButtons from '../action-buttons/team-users-action-buttons';
import AssignedUserStep from '../assign-user-step/assign-user-step';
import ConfirmationStep from '../confirmation-step/confirmation-step';
import TeamNestingStep from '../nested-team-step/nested-team-step';
import { selectDialogStep } from '../../../../shared/dialog/state/selectors';

import { translationsPerDialog } from './translations';

import type { Language } from '../../../../translations/interfaces';
import type { ContentType } from '../../../../components/custom-dialog/custom-dialog';

interface ICreateTeamDialogProps {
  closeModal: () => void;
}

const dialogContent: ContentType = {
  1: <TeamNameStep />,
  2: <TeamNestingStep />,
  3: <AssignedUserStep />,
  4: <ConfirmationStep />,
};

const CreateTeamDialog = forwardRef<
  HTMLDialogElement | null,
  ICreateTeamDialogProps
>(({ closeModal }, ref) => {
  const { language: currentLanguage } = useTranslations();
  const currentHeaderPerLanguage =
    translationsPerDialog[currentLanguage as Language];
  const step = useSelector(selectDialogStep);

  const getActionButtons = (): React.JSX.Element => {
    switch (step) {
      case 1:
        return <TeamNameActionButtons closeModal={closeModal} />;

      case 2:
        return <TeamNestingStepActionButtons closeModal={closeModal} />;

      case 3:
        return <TeamUsersActionButtons closeModal={closeModal} />;

      case 4:
        return <ConfirmStepActionButtons closeModal={closeModal} />;

      default:
        return <></>;
    }
  };

  return (
    <CustomDialog
      id="create-team"
      ref={ref}
      multipleSteps={4}
      actionButtons={getActionButtons}
      closeModal={closeModal}
      dialogContent={dialogContent}
      dialogHeader={currentHeaderPerLanguage}
    />
  );
});

export default CreateTeamDialog;
