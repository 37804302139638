import { createSelector } from '@reduxjs/toolkit';
import { max, min } from 'date-fns';

import { LicenseCategories } from './interfaces';

import type {
  AddOn,
  LicensedProductWithUsage,
  ProductAsset,
  Subscription,
} from './interfaces';
import type { RootState } from '../../../store';

const byProductName = (
  a: LicensedProductWithUsage,
  b: LicensedProductWithUsage,
) => a.productName.localeCompare(b.productName);

const selectProductsState = (state: RootState) => state.products;

export const selectProducts = createSelector(
  selectProductsState,
  (state): LicensedProductWithUsage[] => state.products,
);

export const selectBaseProducts = createSelector(
  selectProductsState,
  (state): LicensedProductWithUsage[] =>
    state.products
      .filter(
        (product) => product.productCategory === LicenseCategories.SUBSCRIPTION,
      )
      .sort(byProductName),
);

export const selectAddOnProducts = createSelector(
  selectProductsState,
  (state): LicensedProductWithUsage[] =>
    state.products
      .filter((product) => product.productCategory === LicenseCategories.ADD_ON)
      .sort(byProductName),
);

export const selectProductsSortedByGroup = createSelector(
  [selectBaseProducts, selectAddOnProducts],
  (baseProducts, addOneProducts) => {
    return [...baseProducts, ...addOneProducts];
  },
);

export const selectProductsLoading = createSelector(
  selectProductsState,
  (state): boolean => state.loading,
);

const sortProducts = <T extends { productName: string }>(products: T[]) =>
  products.sort((a, b) => a.productName.localeCompare(b.productName));

export const selectProductAssets = createSelector(
  selectProducts,
  (products): ProductAsset[] => {
    // Group unique products by type and category
    const productsByType = Array.from(products.values()).reduce(
      (acc, product) => {
        // Initialize type group if it doesn't exist
        if (!acc[product.productType]) {
          acc[product.productType] = {
            [LicenseCategories.SUBSCRIPTION]: [],
            [LicenseCategories.ADD_ON]: [],
          };
        }

        // Add product to appropriate category within its type group
        acc[product.productType][product.productCategory].push({
          ...product,
        });

        return acc;
      },
      {} as Record<
        string,
        Record<LicenseCategories, LicensedProductWithUsage[]>
      >,
    );

    // Convert the grouped data into the required ProductAsset[] format and sort subscriptions
    return Object.entries(productsByType).map(([type, categories]) => ({
      name: type,
      subscriptions: sortProducts(
        categories[LicenseCategories.SUBSCRIPTION] as Subscription[],
      ),
      addOns: sortProducts(categories[LicenseCategories.ADD_ON] as AddOn[]),
    }));
  },
);

// TODO (vladyslav-mashkin): deprecated and should be removed in scope of NGP-5661
export const selectProductDateBoundaries = createSelector(
  selectBaseProducts,
  (products): { startDate: string; expireDate: string } => {
    if (!products.length) {
      return {
        startDate: '',
        expireDate: '',
      };
    }

    const startDates = products.map((product) => new Date(product.validFrom));
    const expireDates = products.map((product) => new Date(product.validTo));

    return {
      startDate: min(startDates).toISOString(),
      expireDate: max(expireDates).toISOString(),
    };
  },
);
