import { CheckIcon, ChevronDownIcon } from '@pluralsight/icons';
import {
  FieldMessage,
  FlexContainer,
  FormControlProvider,
  Label,
  Show,
} from '@pluralsight/react-ng';
import { useRef, useState } from 'react';

import { handleMenuKeyBoardNavigation } from '../../shared/helpers/handle-menu-keyboard-navigation';

import type { ButtonHTMLAttributes, FC } from 'react';

import './custom-menu.scss';

export interface IOption {
  value: string;
  name: string;
  permissions: string;
}

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  options: IOption[];
  selectedOption: IOption;
  handleSelect: (option: IOption) => void;
  id: string;
}

export const CustomMenu: FC<IProps> = ({
  label,
  options,
  selectedOption,
  handleSelect,
  id,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const selectOption = (option: IOption) => {
    handleSelect(option);
    setIsOpen(false);
  };

  const handleCloseMenu = () => {
    toggleDropdown();
    buttonRef.current?.focus();
  };

  const handleKeyBoardNavigation = handleMenuKeyBoardNavigation(
    'custom-menu__option',
    {
      Escape: handleCloseMenu,
      Tab: handleCloseMenu,
      ShiftTab: handleCloseMenu,
    },
  );

  const handleOption = (option: IOption) => {
    selectOption(option);
    buttonRef.current?.focus();
  };

  return (
    <FlexContainer className="custom-menu">
      <FormControlProvider>
        <Label htmlFor={id}>{label}</Label>
        <button
          ref={buttonRef}
          className="custom-menu__select"
          onClick={toggleDropdown}
          role="combobox"
          id={id}
          aria-label={id}
          aria-expanded={isOpen}
          aria-activedescendant={id}
        >
          {selectedOption.name}

          <ChevronDownIcon />
        </button>

        <Show
          when={isOpen}
          children={
            <ul
              className="custom-menu__options"
              role="listbox"
              id={`${id}-listbox`}
              onKeyDown={handleKeyBoardNavigation}
            >
              {options.map((option, index) => (
                <li
                  ref={(e) => !index && e?.focus()}
                  className="custom-menu__option"
                  key={option.value}
                  role="option"
                  aria-label={option.name}
                  aria-selected={selectedOption?.value === option.value}
                  tabIndex={0}
                  onClick={() => handleOption(option)}
                >
                  <FlexContainer direction="col">
                    <div className="custom-menu__label">{option.name}</div>
                    <div className="custom-menu__permissions">
                      {option.permissions}
                    </div>
                  </FlexContainer>
                  {selectedOption.value === option.value && <CheckIcon />}
                </li>
              ))}
            </ul>
          }
          fallback={
            <FieldMessage id="role">{selectedOption.permissions}</FieldMessage>
          }
        />
      </FormControlProvider>
    </FlexContainer>
  );
};
