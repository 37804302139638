// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.role-step__container {
  height: 503px;
  overflow: auto;
}
.role-step__select-role {
  margin-top: var(--pando-spacing-4);
}`, "",{"version":3,"sources":["webpack://./src/components/role-step/role-step.scss"],"names":[],"mappings":"AAEE;EACE,aAAA;EACA,cAAA;AADJ;AAIE;EACE,kCAAA;AAFJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.role-step {\n  &__container {\n    height: 503px;\n    overflow: auto;\n  }\n\n  &__select-role {\n    margin-top: var(--pando-spacing-4);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
