import { TrashIcon, UploadIcon } from '@pluralsight/icons';
import {
  Button,
  FileUploadContext,
  FileUploadDropzone,
  FileUploadHiddenInput,
  FileUploadItemGroup,
  FileUploadLabel,
  FileUploadRootProvider,
  FileUploadTrigger,
  FlexContainer,
  FormControlProvider,
  IconButton,
  Show,
} from '@pluralsight/react-ng';
import cs from 'classnames';
import { useState } from 'react';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import UploadedItem from './uploaded-item';

import type { UploadFile } from '../../shared/interfaces';
import type { FileUploadFileChangeDetails } from '@pluralsight/react-ng';

import './upload-file-field.scss';

const UploadFileField = ({
  objectKey,
  formState,
  fileUpload,
  importErrors,
  onFileDelete,
}: UploadFile) => {
  const { t } = useTranslations();
  const [savedKey, setSavedKey] = useState(objectKey);
  const [isEditMode, setIsEditMode] = useState(false);

  const noFiles =
    !fileUpload.value?.acceptedFiles?.length &&
    !fileUpload.value?.rejectedFiles?.length &&
    !savedKey;
  const isValidFile =
    fileUpload?.value.acceptedFiles?.length && !formState.importFile?.invalid;

  const onFileDeleteHandler = () => {
    setIsEditMode(false);
    onFileDelete();
  };

  const onSavedDeleted = () => {
    setSavedKey(null);
    onFileDelete();
  };

  const renderSelectedFile = ({
    acceptedFiles,
    rejectedFiles,
  }: FileUploadFileChangeDetails) => {
    if (rejectedFiles.length) {
      return (
        <UploadedItem
          file={rejectedFiles[0].file}
          onFileDeleteHandler={onFileDeleteHandler}
          error={rejectedFiles[0].errors[0]}
        />
      );
    } else if (acceptedFiles.length) {
      return acceptedFiles.map((file: File) => (
        <UploadedItem
          file={file}
          onFileDeleteHandler={onFileDeleteHandler}
          error={importErrors ? importErrors[0] : null}
        />
      ));
    }
  };

  return (
    <FlexContainer
      className="import-file-container-form__field"
      direction="col"
    >
      <FormControlProvider {...formState.importFile}>
        <FileUploadRootProvider
          className="import-file-container-form__upload"
          value={fileUpload.value}
        >
          <Show when={noFiles}>
            <FileUploadDropzone
              className={cs('import-file-container-form__upload__field', {
                'import-file-container-form__upload__field--error':
                  formState.importFile?.invalid,
              })}
            >
              <FileUploadTrigger asChild>
                <Button
                  palette="action"
                  size="md"
                  startIcon={
                    <UploadIcon width={16} height={16} aria-hidden="true" />
                  }
                  aria-label={t('importFile.chooseFile')}
                >
                  {t('importFile.chooseFile')}
                </Button>
              </FileUploadTrigger>
              <FileUploadLabel className="import-file-container-form__upload__field__label__drag">
                {t('importFile.dragAndDrop')}
              </FileUploadLabel>
            </FileUploadDropzone>
          </Show>
          <Show when={!!savedKey}>
            <FlexContainer
              className="import-file-container-form__upload__field pando-file-upload__dropzone"
              justify="spaceBetween"
            >
              <span>{savedKey}</span>
              <IconButton
                className="fieldset__description__trash-icon"
                palette="danger"
                size="sm"
                usage="text"
                onClick={onSavedDeleted}
                ariaLabel={t('importFile.deleteUploadedFile')}
              >
                <TrashIcon />
              </IconButton>
            </FlexContainer>
          </Show>
          <Show when={!savedKey}>
            <FileUploadItemGroup
              className={cs('import-file-container-form__upload__item-group', {
                valid: isValidFile,
                'import-file-container-form__upload__item-group--error':
                  !isValidFile,
              })}
            >
              <FileUploadContext>{renderSelectedFile}</FileUploadContext>
            </FileUploadItemGroup>
          </Show>

          <FileUploadHiddenInput name="file-up" required={!isEditMode} />
        </FileUploadRootProvider>
      </FormControlProvider>
    </FlexContainer>
  );
};

export default UploadFileField;
