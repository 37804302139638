import type { Language } from '../../translations/interfaces';

export interface IContent {
  title: string;
  description: string;
  url: string;
  btnText: string;
}

export const HANDS_ON_CONTENT: Record<Language, IContent[]> = {
  en: [
    {
      title: 'Learn something new',
      description:
        'Helpful info about _______ would go right here. We should try to keep things like this under three lines long.',
      url: '/learn-something-new',
      btnText: 'Browse all Content',
    },
    {
      title: 'Assess your skills',
      description:
        'Helpful info about _______ would go right here. We should try to keep things like this under three lines long.',
      url: '/learn-something-new',
      btnText: 'Take a Skill IQ',
    },
    {
      title: 'Company Channels',
      description:
        'Helpful info about _______ would go right here. We should try to keep things like this under three lines long.',
      url: '/learn-something-new',
      btnText: 'View Channels',
    },
  ],
  es: [
    {
      title: 'Aprende algo nuevo',
      description:
        'Información útil sobre _______ iría aquí. Deberíamos intentar mantener cosas como esta en menos de tres líneas.',
      url: '/learn-something-new',
      btnText: 'Explorar todo el contenido',
    },
    {
      title: 'Evalúa tus habilidades',
      description:
        'Información útil sobre _______ iría aquí. Deberíamos intentar mantener cosas como esta en menos de tres líneas.',
      url: '/learn-something-new',
      btnText: 'Realiza un Skill IQ',
    },
    {
      title: 'Canales de la empresa',
      description:
        'Información útil sobre _______ iría aquí. Deberíamos intentar mantener cosas como esta en menos de tres líneas.',
      url: '/learn-something-new',
      btnText: 'Ver canales',
    },
  ],
  fr: [
    {
      title: 'Apprenez quelque chose de nouveau',
      description:
        'Des informations utiles sur _______ seraient ici. Nous devrions essayer de garder ce genre de choses en moins de trois lignes.',
      url: '/learn-something-new',
      btnText: 'Parcourir tout le contenu',
    },
    {
      title: 'Évaluez vos compétences',
      description:
        'Des informations utiles sur _______ seraient ici. Nous devrions essayer de garder ce genre de choses en moins de trois lignes.',
      url: '/learn-something-new',
      btnText: 'Passer un Skill IQ',
    },
    {
      title: "Chaînes de l'entreprise",
      description:
        'Des informations utiles sur _______ seraient ici. Nous devrions essayer de garder ce genre de choses en moins de trois lignes.',
      url: '/learn-something-new',
      btnText: 'Voir les chaînes',
    },
  ],
  ge: [
    {
      title: 'Lerne etwas Neues',
      description:
        'Hilfreiche Informationen über _______ würden hier stehen. Wir sollten versuchen, solche Dinge auf weniger als drei Zeilen zu halten.',
      url: '/learn-something-new',
      btnText: 'Alle Inhalte durchsuchen',
    },
    {
      title: 'Bewerten Sie Ihre Fähigkeiten',
      description:
        'Hilfreiche Informationen über _______ würden hier stehen. Wir sollten versuchen, solche Dinge auf weniger als drei Zeilen zu halten.',
      url: '/learn-something-new',
      btnText: 'Machen Sie einen Skill-IQ-Test',
    },
    {
      title: 'Unternehmenskanäle',
      description:
        'Hilfreiche Informationen über _______ würden hier stehen. Wir sollten versuchen, solche Dinge auf weniger als drei Zeilen zu halten.',
      url: '/learn-something-new',
      btnText: 'Kanäle anzeigen',
    },
  ],
  pt: [
    {
      title: 'Aprenda algo novo',
      description:
        'Informações úteis sobre _______ ficariam aqui. Devemos tentar manter coisas como essa com menos de três linhas.',
      url: '/learn-something-new',
      btnText: 'Explorar todo o conteúdo',
    },
    {
      title: 'Avalie suas habilidades',
      description:
        'Informações úteis sobre _______ ficariam aqui. Devemos tentar manter coisas como essa com menos de três linhas.',
      url: '/learn-something-new',
      btnText: 'Fazer um Skill IQ',
    },
    {
      title: 'Canais da empresa',
      description:
        'Informações úteis sobre _______ ficariam aqui. Devemos tentar manter coisas como essa com menos de três linhas.',
      url: '/learn-something-new',
      btnText: 'Ver canais',
    },
  ],
  jp: [
    {
      title: '新しいことを学ぶ',
      description:
        '_______ に関する役立つ情報はここに記載されます。このような内容は3行以内に収めるようにしましょう。',
      url: '/learn-something-new',
      btnText: 'すべてのコンテンツを見る',
    },
    {
      title: 'スキルを評価する',
      description:
        '_______ に関する役立つ情報はここに記載されます。このような内容は3行以内に収めるようにしましょう。',
      url: '/learn-something-new',
      btnText: 'スキルIQを受ける',
    },
    {
      title: '会社のチャンネル',
      description:
        '_______ に関する役立つ情報はここに記載されます。このような内容は3行以内に収めるようにしましょう。',
      url: '/learn-something-new',
      btnText: 'チャンネルを見る',
    },
  ],
};
