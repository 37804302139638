import { Button, GridCell, GridContainer } from '@pluralsight/react-ng';
import React from 'react';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import contentType from '../../assets/images/content-type.png';

import { HANDS_ON_CONTENT } from './fixtures';

import type { Language } from '@ps-fe-plan-management/types';

const HandsOnContent = () => {
  const { language: currentLanguage } = useTranslations();

  const currentDataWithLanguage = HANDS_ON_CONTENT[currentLanguage as Language];
  const content = currentDataWithLanguage.map((item, _index) => {
    return (
      <GridCell
        key={item.title}
        large="4"
        style={{
          borderRadius: '4px',
          padding: '32px 16px var(--pando-button-padding, 24px) 16px',
          background: 'var(--neutral-surface-300, #2A2753)',
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          alignItems: 'center',
        }}
      >
        <img src={contentType} alt="Content type" />
        <h3
          style={{
            color:
              'var(--neutral-text-strong, var(--neutral-text-300, #F3F3F8))',
            textAlign: 'center',
            fontFamily: 'var(--font-family-title, "PS TT Commons")',
            fontSize: 'var(--font-size-md, 16px)',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '150%',
          }}
        >
          {item.title}
        </h3>
        <p
          style={{
            color:
              'var(--neutral-text-medium, var(--neutral-text-200, #A5AACF))',
            textAlign: 'center',
            fontFamily: '"PS TT Commons"',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '150%',
          }}
        >
          {item.description}
        </p>
        <Button
          role="button"
          palette="action"
          size="lg"
          usage="filled"
          type="button"
          style={{
            marginTop: '32px',
            width: '100%',
            cursor: 'pointer',
          }}
        >
          {item.btnText}
        </Button>
      </GridCell>
    );
  });

  return (
    <GridContainer
      style={{
        gap: '16px',
        padding: 'var(--pando-button-padding, 24px)',
        borderRadius: '3px',
        background: 'var(--neutral-surface-100, #1B1834)',
      }}
    >
      {content}
    </GridContainer>
  );
};

export default HandsOnContent;
