import { FlexContainer, FlexItem, For, Show } from '@pluralsight/react-ng';
import React from 'react';
import { useSelector } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import bullet from '../../../../assets/list-bullet.svg';
import {
  selectAllRoles,
  selectMappedRoles,
} from '../../../roles/state/selectors';
import { selectTeamsList } from '../../../teams/state/selectors';
import {
  selectEmailsList,
  selectAssignedProducts,
  selectRoles,
  selectTeamIds,
} from '../../state/selectors';
import { USER_ROLE } from '../../../../shared/constants';
import {
  selectAddOnProducts,
  selectBaseProducts,
} from '../../../products/state/selectors';
import useProducts from '../../../products/hooks/useProducts';
import { selectAccess } from '../../../user/state/selectors';

import { roleNameMap } from './constants';

import type { Team } from '../../../team/state/interfaces';

import './confirm-step.scss';

const ConfirmStep = () => {
  const { t } = useTranslations();
  const isReducedAccess = useSelector(selectAccess);
  const teams = useSelector(selectTeamsList);
  const emails = useSelector(selectEmailsList);
  const allRoles = useSelector(selectAllRoles);
  const baseProducts = useSelector(selectBaseProducts);
  const addOnProducts = useSelector(selectAddOnProducts);
  const mappedRoles = useSelector(selectMappedRoles);
  const assignedRoles = useSelector(selectRoles);
  const teamIds = useSelector(selectTeamIds);
  const productIds = useSelector(selectAssignedProducts);

  const isBaseProductSelected = useProducts(productIds);

  const getRolesList = () => {
    return allRoles
      .filter((role) => assignedRoles.includes(role.id))
      .map((role) => roleNameMap[role.name]);
  };

  const learnerId = mappedRoles.get(USER_ROLE.B2B_LEARNER);
  const roles = learnerId
    ? [roleNameMap[USER_ROLE.B2B_LEARNER], ...getRolesList()]
    : getRolesList();

  const assignedTeams = teams.filter((team) => teamIds.includes(team.id));

  const assignedProducts = isBaseProductSelected
    ? [
        ...baseProducts.filter((product) =>
          productIds.includes(product.productId),
        ),
        ...addOnProducts.filter((product) =>
          productIds.includes(product.productId),
        ),
      ]
    : [
        ...baseProducts.filter((product) =>
          productIds.includes(product.productId),
        ),
      ];

  return (
    <>
      <FlexContainer
        direction="col"
        align="stretch"
        className="confirm-emails-list__scroll"
      >
        <Show when={!isReducedAccess}>
          <FlexItem className="confirm-emails-list__title">
            <h5>
              {t('invitation.dialogConfirmStep.rolesAssigned', {
                value: roles.length,
              })}
            </h5>
          </FlexItem>

          <For
            each={roles}
            children={(role: string) => (
              <FlexContainer gap={12}>
                <img src={bullet} alt="list-bullet icon" />
                <FlexItem className="confirm-emails-list__role">
                  {t(role)}
                </FlexItem>
              </FlexContainer>
            )}
          />
        </Show>

        <FlexItem className="confirm-emails-list__title">
          <h5>
            {t('invitation.dialogConfirmStep.teamsAssigned', {
              value: assignedTeams.length,
            })}
          </h5>
        </FlexItem>
        <For
          each={assignedTeams}
          children={(team: Team) => (
            <FlexContainer gap={12}>
              <img src={bullet} alt="list-bullet icon" />
              <FlexItem>{team.name}</FlexItem>
            </FlexContainer>
          )}
        />

        <FlexItem className="confirm-emails-list__title">
          <h5>
            {t('invitation.dialogConfirmStep.licensesAssigned', {
              value: assignedProducts.length,
            })}
          </h5>
        </FlexItem>
        <For
          each={assignedProducts}
          children={(product) => (
            <FlexContainer gap={12}>
              <img src={bullet} alt="list-bullet icon" />
              <FlexItem>{product.productName}</FlexItem>
            </FlexContainer>
          )}
        />

        <FlexItem className="confirm-emails-list__title">
          <h5>
            {`${t(
              'invitation.dialogConfirmStep.emailsToSendInvite',
            )} (${emails.length})`}
          </h5>
        </FlexItem>
        <For
          each={emails}
          children={(email: string) => (
            <FlexContainer gap={12}>
              <img src={bullet} alt="" />
              <FlexItem className="confirm-emails-list__email">
                {email}
              </FlexItem>
            </FlexContainer>
          )}
        />
      </FlexContainer>
    </>
  );
};

export default ConfirmStep;
