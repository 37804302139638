import { createSelector } from 'reselect';

import type { AppState } from '../../../store';
import type { TeamsState } from './interfaces';

const selectTeamsState = (state: AppState) => state.teams;

export const selectTeamsList = createSelector(
  [selectTeamsState],
  (teamsState: TeamsState) => teamsState.list,
);

export const selectImportActivetab = createSelector(
  [selectTeamsState],
  (teamsState: TeamsState) => teamsState.importActiveTab,
);

export const selectImportErrors = createSelector(
  [selectTeamsState],
  (teamsState: TeamsState) => teamsState.importFile?.importErrors,
);

export const selectImportFile = createSelector(
  [selectTeamsState],
  (teamsState: TeamsState) => teamsState.importFile,
);
