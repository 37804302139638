// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-card--loading {
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--pando-inky-blue-neutral-surface-100-dark, #1b1834);
  border-radius: var(--pando-radii-lg);
}
.product-card__loading-message {
  color: var(--pando-colors-text-secondary);
  font-size: var(--pando-font-sizes-lg);
}
.product-card__header {
  margin-bottom: var(--pando-spacing-4);
}
.product-card__header__title {
  height: 39px;
}
.product-card__header__legend {
  height: 29px;
}
.product-card__header__fallback-title {
  font-size: var(--pando-font-sizes-xl);
  font-weight: 600;
  color: var(--pando-colors-text-primary);
}
.product-card__content {
  margin-bottom: var(--pando-spacing-6);
  border-radius: var(--pando-radii-lg);
  background: var(--pando-inky-blue-neutral-surface-100-dark, #1b1834);
  padding: var(--pando-spacing-6) var(--pando-spacing-8);
}`, "",{"version":3,"sources":["webpack://./src/features/plan-settings/components/product-card/product-card.scss"],"names":[],"mappings":"AAEE;EACE,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,oEAAA;EACA,oCAAA;AADJ;AAIE;EACE,yCAAA;EACA,qCAAA;AAFJ;AAKE;EACE,qCAAA;AAHJ;AAKI;EACE,YAAA;AAHN;AAMI;EACE,YAAA;AAJN;AAOI;EACE,qCAAA;EACA,gBAAA;EACA,uCAAA;AALN;AASE;EACE,qCAAA;EACA,oCAAA;EACA,oEAAA;EACA,sDAAA;AAPJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.product-card {\n  &--loading {\n    min-height: 200px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background: var(--pando-inky-blue-neutral-surface-100-dark, #1b1834);\n    border-radius: var(--pando-radii-lg);\n  }\n\n  &__loading-message {\n    color: var(--pando-colors-text-secondary);\n    font-size: var(--pando-font-sizes-lg);\n  }\n\n  &__header {\n    margin-bottom: var(--pando-spacing-4);\n\n    &__title {\n      height: 39px;\n    }\n\n    &__legend {\n      height: 29px;\n    }\n\n    &__fallback-title {\n      font-size: var(--pando-font-sizes-xl);\n      font-weight: 600;\n      color: var(--pando-colors-text-primary);\n    }\n  }\n\n  &__content {\n    margin-bottom: var(--pando-spacing-6);\n    border-radius: var(--pando-radii-lg);\n    background: var(--pando-inky-blue-neutral-surface-100-dark, #1b1834);\n    padding: var(--pando-spacing-6) var(--pando-spacing-8);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
