import {
  Admonition,
  AdmonitionHeading,
  FlexContainer,
  FlexItem,
  Show,
  SwitchInput,
  SwitchInputLabel,
  TextLink,
  useConfirm,
  useToast,
} from '@pluralsight/react-ng';
import { useEffect, useState } from 'react';
import {
  PandoGapSm,
  PandoGapXl,
  PandoInkyBlueActionNavigationInitialDark,
} from '@pluralsight/design-tokens';
import { useDispatch, useSelector } from 'react-redux';
import { isBoolean } from 'lodash';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import './general-sso-settings.scss';

import {
  selectActiveSsoConnections,
  selectSsoConnections,
} from '../state/selectors';
import { selectOrganization } from '../../../../organization/state/selectors';
import { updateOrganization } from '../../../../organization/state/slice';

const GeneralSsoSettings = () => {
  const { t } = useTranslations();
  const dispatch = useDispatch();
  const activeSsoConnections = useSelector(selectActiveSsoConnections);
  const hasActiveConnections = activeSsoConnections?.length > 0;
  const ssoConnections = useSelector(selectSsoConnections);
  const organization = useSelector(selectOrganization);

  const [isAutoProvisioningEnabled, setIsAutoProvisioningEnabled] =
    useState(false);
  const [isSsoEnforcementEnabled, setIsSsoEnforcementEnabled] = useState(false);

  const { confirm } = useConfirm();
  const { show } = useToast();

  useEffect(() => {
    if (
      isBoolean(organization.ssoLicenseAutoProvisioningEnabled) &&
      isBoolean(organization.ssoEnforcementEnabled)
    ) {
      setIsAutoProvisioningEnabled(
        organization.ssoLicenseAutoProvisioningEnabled,
      );
      setIsSsoEnforcementEnabled(organization.ssoEnforcementEnabled);
    }
  }, [organization]);

  const handleAutoProvisioningToggle = async () => {
    const nextValue = !isAutoProvisioningEnabled;

    const heading = nextValue
      ? t('sso.ssoSettings.autoProvisioning.enableTitle')
      : t('sso.ssoSettings.autoProvisioning.disableTitle');

    const text = nextValue
      ? t('sso.ssoSettings.autoProvisioning.enableBody')
      : t('sso.ssoSettings.autoProvisioning.disableBody');

    const confirmText = nextValue
      ? t('sso.ssoSettings.autoProvisioning.enableConfirm')
      : t('sso.ssoSettings.autoProvisioning.disableConfirm');

    const cancelText = t('sso.ssoSettings.cancel');

    const confirmation = await confirm({
      heading,
      text,
      confirmText,
      cancelText,
    });

    if (!confirmation) return;

    setIsAutoProvisioningEnabled(nextValue);

    if (organization.id) {
      dispatch(
        updateOrganization({
          id: organization.id,
          ssoLicenseAutoProvisioningEnabled: nextValue,
        }),
      );

      const toastText = nextValue
        ? t('sso.ssoSettings.autoProvisioning.enableToast')
        : t('sso.ssoSettings.autoProvisioning.disableToast');

      show({
        text: toastText,
        palette: 'success',
        onClose: () => void {},
      });
    }
  };
  const handleSsoEnforcementToggle = async () => {
    const nextValue = !isSsoEnforcementEnabled;

    const heading = nextValue
      ? t('sso.ssoSettings.ssoEnforcement.enableTitle')
      : t('sso.ssoSettings.ssoEnforcement.disableTitle');

    const text = nextValue
      ? t('sso.ssoSettings.ssoEnforcement.enableBody')
      : t('sso.ssoSettings.ssoEnforcement.disableBody');

    const confirmText = nextValue
      ? t('sso.ssoSettings.ssoEnforcement.enableConfirm')
      : t('sso.ssoSettings.ssoEnforcement.disableConfirm');

    const cancelText = t('sso.ssoSettings.cancel');

    const confirmation = await confirm({
      heading,
      text,
      confirmText,
      cancelText,
    });

    if (!confirmation) return;

    setIsSsoEnforcementEnabled(nextValue);

    if (organization.id) {
      dispatch(
        updateOrganization({
          id: organization.id,
          ssoEnforcementEnabled: nextValue,
        }),
      );

      const toastText = nextValue
        ? t('sso.ssoSettings.ssoEnforcement.enableToast')
        : t('sso.ssoSettings.ssoEnforcement.disableToast');

      show({
        text: toastText,
        palette: 'success',
        onClose: () => void {},
      });
    }
  };

  return (
    <FlexContainer
      direction="col"
      className="general-sso-settings"
      gap={PandoGapXl}
    >
      <Show when={!hasActiveConnections}>
        <Admonition>
          <AdmonitionHeading>
            {ssoConnections.length > 0
              ? t('sso.ssoSettings.admonitionTitle.enable')
              : t('sso.ssoSettings.admonitionTitle.create')}
          </AdmonitionHeading>
        </Admonition>
      </Show>
      <FlexContainer justify="spaceBetween">
        <FlexContainer direction="col" className="fieldset" gap={PandoGapSm}>
          <SwitchInput
            className="fieldset__switch"
            labelPosition="end"
            switchSize="lg"
            disabled={!hasActiveConnections}
            onChange={() => void handleAutoProvisioningToggle()}
            checked={isAutoProvisioningEnabled}
          >
            <SwitchInputLabel
              className="fieldset__label"
              aria-label={t('sso.ssoSettings.autoProvisioning.title')}
            >
              {t('sso.ssoSettings.autoProvisioning.title')}
            </SwitchInputLabel>
          </SwitchInput>
          <p className="fieldset__note">
            {t('sso.ssoSettings.autoProvisioning.subtitle')}
          </p>
        </FlexContainer>
        <FlexItem basis="fitContent" className="fieldset__link">
          <TextLink
            href="https://app-dev.pluralsight.com/"
            style={{
              color: PandoInkyBlueActionNavigationInitialDark,
            }}
          >
            {t('sso.ssoSettings.helpDocumentation')}
          </TextLink>
        </FlexItem>
      </FlexContainer>
      <FlexContainer direction="col" className="fieldset" gap={PandoGapSm}>
        <SwitchInput
          className="fieldset__switch"
          labelPosition="end"
          switchSize="lg"
          disabled={!hasActiveConnections}
          onChange={() => void handleSsoEnforcementToggle()}
          checked={isSsoEnforcementEnabled}
        >
          <SwitchInputLabel
            className="fieldset__label"
            aria-label={t('sso.ssoSettings.ssoEnforcement.title')}
          >
            {t('sso.ssoSettings.ssoEnforcement.title')}
          </SwitchInputLabel>
        </SwitchInput>
        <p className="fieldset__note">
          {t('sso.ssoSettings.ssoEnforcement.subtitle')}
        </p>
      </FlexContainer>
    </FlexContainer>
  );
};

export default GeneralSsoSettings;
