export const EMAIL_PATTERN = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const UPPERCASE_LETTERS_REGEXP = /([A-Z])/g;

export enum USER_ROLE {
  SYSTEM_ADMIN = 'System Admin',
  SSO_ADMIN = 'SSO Admin',
  SUPER_ADMIN = 'Super Admin',
  PLAN_ADMIN = 'Plan Admin',

  MANAGER_FULL = 'Manager Full',
  MANAGER_LIMITED = 'Manager Limited',
  MANAGER_BASIC = 'Manager Basic',

  API_DEVELOPER = 'API developer',
  B2B_LEARNER = 'B2B learner',
  B2C_LEARNER = 'B2C learner',
}

export interface Permission {
  roleName: USER_ROLE;
  scope: 'organizationScope' | 'planScope' | 'teamScope';
}

export const deleteUsersRestrictedTo: Permission[] = [
  {
    roleName: USER_ROLE.SYSTEM_ADMIN,
    scope: 'organizationScope',
  },
  {
    roleName: USER_ROLE.SUPER_ADMIN,
    scope: 'planScope',
  },
  {
    roleName: USER_ROLE.SUPER_ADMIN,
    scope: 'organizationScope',
  },
  {
    roleName: USER_ROLE.PLAN_ADMIN,
    scope: 'planScope',
  },
];

export const ROUTES = {
  ACCOUNT_SETTINGS: '/account-settings',
  CREATE_ACCOUNT: '/create-account',
  HOME: '/',
  LOGIN: '/login',
  NOTIFICATIONS: '/notifications',
  PEOPLE: '/people',
  PLANS: '/plans',
  PROFILE: '/profile',
  SETTINGS: '/settings',
  SSO_CONFIGURATION: '/sso-configuration',
  TEAMS: '/teams',
  TEAM_DETAILS: '/teams/',
  INTERESTS: '/interests',
};

export const ROUTE_RESTRICTION_CONFIG: Record<string, Array<Permission>> = {
  [ROUTES.PEOPLE]: [
    {
      roleName: USER_ROLE.SUPER_ADMIN,
      scope: 'organizationScope',
    },
    {
      roleName: USER_ROLE.SYSTEM_ADMIN,
      scope: 'organizationScope',
    },
    {
      roleName: USER_ROLE.PLAN_ADMIN,
      scope: 'planScope',
    },
    {
      roleName: USER_ROLE.MANAGER_FULL,
      scope: 'teamScope',
    },
    {
      roleName: USER_ROLE.MANAGER_LIMITED,
      scope: 'teamScope',
    },
    {
      roleName: USER_ROLE.MANAGER_BASIC,
      scope: 'teamScope',
    },
  ],
  [ROUTES.TEAMS]: [
    {
      roleName: USER_ROLE.SUPER_ADMIN,
      scope: 'organizationScope',
    },
    {
      roleName: USER_ROLE.SYSTEM_ADMIN,
      scope: 'organizationScope',
    },
    {
      roleName: USER_ROLE.PLAN_ADMIN,
      scope: 'planScope',
    },
    {
      roleName: USER_ROLE.MANAGER_FULL,
      scope: 'teamScope',
    },
    {
      roleName: USER_ROLE.MANAGER_LIMITED,
      scope: 'teamScope',
    },
    {
      roleName: USER_ROLE.MANAGER_BASIC,
      scope: 'teamScope',
    },
  ],
  [ROUTES.SSO_CONFIGURATION]: [
    {
      roleName: USER_ROLE.SUPER_ADMIN,
      scope: 'organizationScope',
    },
    {
      roleName: USER_ROLE.SSO_ADMIN,
      scope: 'organizationScope',
    },
  ],
};

export const TABLES = {
  PAGINATION_OPTIONS: [100, 300, 500, 1000, 2000, 3000, 5000, 10000],
};

export enum TEAM_TABLE_KEYS {
  ID = 'id',
  NAME = 'name',
  MANAGERS_COUNT = 'managersCount',
  USERS = 'users',
  NESTED_TEAMS = 'nestedTeams',
  NESTED_USERS = 'nestedUsers',
}

export enum TEMPLATE_COLUMNS {
  ID = 'ID',
  TEAM_NAME = 'TeamName',
  PARENT_TEAM_NAME = 'ParentTeamName',
  ACTION = 'Action',
}
