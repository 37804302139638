import React from 'react';
import { CheckIcon, CloseIcon } from '@pluralsight/icons';
import { FlexContainer, FlexItem, For } from '@pluralsight/react-ng';
import { useSelector } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import { selectProducts } from '../../../products/state/selectors';
import './update-licenses.scss';
import useUpdateLicenses from '../../hooks/useUpdateLicenses';

const ConfirmStep = () => {
  const { t } = useTranslations();
  const products = useSelector(selectProducts);

  const { assigned, revoked } = useUpdateLicenses();

  const getProductName = (productToAdd: string) =>
    products.find((product) => product.productId === productToAdd)?.productName;

  return (
    <div className="update-licenses__container">
      {t('licenseDialog.titleConfirm')}
      {assigned.length ? (
        <>
          <div className="update-licenses__title">
            {t('licenseDialog.assigned')}
          </div>
          <For
            each={assigned}
            children={(productToAdd) => (
              <FlexContainer className="update-licenses__row">
                <CheckIcon width="14" className="update-licenses__check-icon" />
                <FlexItem>{getProductName(productToAdd)}</FlexItem>
              </FlexContainer>
            )}
          />
        </>
      ) : null}

      {revoked.length ? (
        <>
          <div className="update-licenses__title">
            {t('licenseDialog.revoked')}
          </div>
          <For
            each={revoked}
            children={(productToRemove) => (
              <FlexContainer className="update-licenses__row">
                <CloseIcon width="14" className="update-licenses__close-icon" />
                <FlexItem>{getProductName(productToRemove)}</FlexItem>
              </FlexContainer>
            )}
          />
        </>
      ) : null}
    </div>
  );
};

export default ConfirmStep;
