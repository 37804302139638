import { USER_ROLE } from '../../../../shared/constants';

export const roleNameMap: Record<string, string> = {
  [USER_ROLE.API_DEVELOPER]: 'invitation.roleNames.developer',
  [USER_ROLE.B2B_LEARNER]: 'invitation.roleNames.learner',
  [USER_ROLE.MANAGER_BASIC]: 'invitation.roleNames.managerBasic',
  [USER_ROLE.MANAGER_LIMITED]: 'invitation.roleNames.managerLimited',
  [USER_ROLE.MANAGER_FULL]: 'invitation.roleNames.managerFull',
  [USER_ROLE.PLAN_ADMIN]: 'invitation.roleNames.planAdmin',
  [USER_ROLE.SSO_ADMIN]: 'invitation.roleNames.ssoAdmin',
  [USER_ROLE.SUPER_ADMIN]: 'invitation.roleNames.superAdmin',
  [USER_ROLE.SYSTEM_ADMIN]: 'invitation.roleNames.systemAdmin',
};
