import type {
  TableColumn,
  UsersTableItem,
} from '../../../../shared/interfaces';

export const peopleTableColumns: TableColumn<UsersTableItem>[] = [
  {
    header: 'teamUsers.headerName',
    mapsTo: 'name',
    useAvatar: true,
    searchColumn: true,
  },
  { header: 'teamUsers.headerEmail', mapsTo: 'email', searchColumn: true },
  { header: 'teamUsers.headerRoles', mapsTo: 'roles' },
  {
    header: 'teamUsers.headerLicenses',
    mapsTo: 'licenses',
    searchColumn: true,
  },
  { header: 'teamUsers.headerTeams', mapsTo: 'teams', searchColumn: true },
  { header: 'teamUsers.headerLastLogin', mapsTo: 'lastLogin' },
  { header: 'teamUsers.headerNote', mapsTo: 'note', searchColumn: true },
];
