import { useMemo } from 'react';
import {
  FlexContainer,
  Button,
  GridContainer,
  GridCell,
} from '@pluralsight/react-ng';
import { differenceInDays } from 'date-fns';
import MediaQuery from 'react-responsive';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import './account-details.scss';

import { formatDate } from '../../shared/utils/date-utils';
import {
  SMALL_DEVICE_BREAKPOINT_MAX_WIDTH,
  MID_DEVICE_BREAKPOINT_MIN_WIDTH,
} from '../../shared/constants';

import type { FC } from 'react';
import type { IPlan } from '../../../plans/state/interfaces';

interface AccountDetailsProps {
  accountData: IPlan & { startDate: string; expireDate: string };
  isLoading: boolean;
}

const AccountDetails: FC<AccountDetailsProps> = ({
  accountData,
  isLoading,
}) => {
  const daysRemaining = useMemo(() => {
    if (!accountData.startDate || !accountData.expireDate) {
      return 0;
    }

    const startDate = new Date(accountData.startDate);
    const expireDate = new Date(accountData.expireDate);

    return differenceInDays(expireDate, startDate);
  }, [accountData.startDate, accountData.expireDate]);

  const { t } = useTranslations();

  const handleRenewPlan = () => {
    // TODO: Implement renewal logic
  };

  const renderRenewPlanButton = (extraClassName?: string) => (
    <Button
      palette="action"
      usage="filled"
      size="lg"
      onClick={handleRenewPlan}
      {...(extraClassName && { className: extraClassName })}
      aria-label={t('planSettings.accountDetails.renewPlan')}
    >
      {t('planSettings.accountDetails.renewPlan')}
    </Button>
  );

  if (isLoading) {
    return <div>{t('common.loading')}</div>;
  }

  return (
    <div className="account-details">
      <FlexContainer
        direction="row"
        align="stretch"
        justify="spaceBetween"
        style={{ marginBottom: 'var(--pando-spacing-6)' }}
      >
        <h3 className="account-details__title">
          {t('planSettings.accountDetails.title')}
        </h3>
        <MediaQuery minWidth={MID_DEVICE_BREAKPOINT_MIN_WIDTH}>
          {renderRenewPlanButton()}
        </MediaQuery>
      </FlexContainer>
      <GridContainer>
        <GridCell xsmall="12" small="12" medium="4">
          <FlexContainer direction="col">
            <span className="account-details__label">
              {t('planSettings.accountDetails.accountName')}
            </span>
            <span className="account-details__value account-details__value--bold">
              {accountData?.name}
            </span>
          </FlexContainer>
        </GridCell>
        <GridCell xsmall="12" small="12" medium="4">
          <FlexContainer direction="col">
            <span className="account-details__label">
              {t('planSettings.accountDetails.startDate')}
            </span>
            <span className="account-details__value">
              {formatDate(accountData.startDate, 'N/A')}
            </span>
          </FlexContainer>
        </GridCell>
        <GridCell xsmall="12" small="12" medium="4">
          <FlexContainer direction="col">
            <span className="account-details__label">
              {`${t('planSettings.accountDetails.renewalDate')} (${t(
                'planSettings.accountDetails.daysRemaining',
                {
                  days: daysRemaining,
                },
              )})`}
            </span>
            <span className="account-details__value">
              {formatDate(accountData.expireDate, 'N/A')}
            </span>
          </FlexContainer>
        </GridCell>
      </GridContainer>
      <MediaQuery maxWidth={SMALL_DEVICE_BREAKPOINT_MAX_WIDTH}>
        {renderRenewPlanButton('renew-btn--mobile')}
      </MediaQuery>
    </div>
  );
};

export default AccountDetails;
