import { createSlice } from '@reduxjs/toolkit';

import type { IOrganizationState, IOrganization } from './interfaces';
import type { PayloadAction } from '@reduxjs/toolkit';

const INITIAL_STATE: IOrganizationState = {
  organization: {} as IOrganization,
  fetch: {
    loading: false,
    success: null,
    error: null,
  },
  update: {
    loading: false,
    success: null,
    error: null,
  },
};

const organizationSlice = createSlice({
  name: 'organization',
  initialState: INITIAL_STATE,
  reducers: {
    fetchOrganization: (state, _action: PayloadAction<{ orgId: string }>) => {
      state.fetch = { loading: true, success: null, error: null };
    },
    fetchOrganizationSuccess: (state, action: PayloadAction<IOrganization>) => {
      state.organization = action.payload;
      state.fetch = { loading: false, success: true, error: null };
    },
    fetchOrganizationFailure: (state, action: PayloadAction<string>) => {
      state.fetch = { loading: false, success: false, error: action.payload };
    },
    updateOrganization: (state, _action: PayloadAction<IOrganization>) => {
      state.update = { loading: true, success: null, error: null };
    },
    updateOrganizationSuccess: (
      state,
      action: PayloadAction<IOrganization>,
    ) => {
      state.organization = action.payload;
      state.update = { loading: false, success: true, error: null };
    },
    updateOrganizationFailure: (state, action: PayloadAction<string>) => {
      state.update = { loading: false, success: false, error: action.payload };
    },
  },
});

export const {
  fetchOrganization,
  fetchOrganizationSuccess,
  fetchOrganizationFailure,
  updateOrganization,
  updateOrganizationSuccess,
  updateOrganizationFailure,
} = organizationSlice.actions;

export default organizationSlice.reducer;
