import i18n from 'i18next';

import { TABLES, UPPERCASE_LETTERS_REGEXP } from './constants';
import { SortingDirection } from './interfaces';

import type {
  AccessScope,
  PermissionsByScope,
  PermissionScope,
  User,
  UserPermission,
} from '../features/user/state/interfaces';
import type { USER_ROLE } from './constants';
import type { Sorting, ExpectedRole } from './interfaces';

export const calculateExpirationStatus = (startDate: Date): string => {
  const expirationDate = new Date(startDate);

  const today = new Date();

  const timeDifference = expirationDate.getTime() - today.getTime();
  const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  if (daysLeft > 0)
    return i18n.t('people.invitesTableContainer.daysLeft', {
      count: daysLeft,
    });

  return '';
};

export const createPaginationOptions = (listLength: number): number[] => {
  const paginationOptions = [];

  for (const paginationOption of TABLES.PAGINATION_OPTIONS) {
    if (paginationOption <= listLength) {
      paginationOptions.push(paginationOption);
    }
  }

  return paginationOptions;
};

export const sortArray = <T>(array: T[], sorting: Sorting<T>): T[] => {
  return array.sort((one, two) => {
    const _one = one[sorting.column as keyof T];
    const _two = two[sorting.column as keyof T];

    const getComparableValue = (value: unknown) => {
      if (Array.isArray(value)) {
        return value.map((item) => item.name || '').join(', ');
      }

      return value?.toString() || '';
    };

    const comparableOne = getComparableValue(_one);
    const comparableTwo = getComparableValue(_two);

    if (!comparableOne && !comparableTwo) return 0;

    if (!comparableOne)
      return sorting.direction === SortingDirection.ASC ? 1 : -1;

    if (!comparableTwo)
      return sorting.direction === SortingDirection.ASC ? -1 : 1;

    return sorting.direction === SortingDirection.ASC
      ? comparableOne.localeCompare(comparableTwo)
      : comparableTwo.localeCompare(comparableOne);
  });
};

export const convertCamelCaseToSnakeCase = (str: string): string => {
  return str.replace(UPPERCASE_LETTERS_REGEXP, '_$1').toLowerCase().trim();
};

export const groupPermissionsByScope = (
  permissionScope: PermissionScope[],
): PermissionsByScope => {
  const organizationScope: AccessScope[] = [];
  const planScope: AccessScope[] = [];
  const teamScope: AccessScope[] = [];

  for (const organization of permissionScope) {
    for (const role of organization.roles) {
      organizationScope.push({
        resourceId: organization.orgId,
        roleId: role.roleId,
        roleName: role.roleName,
      });
    }

    for (const plan of organization.plans) {
      for (const role of plan.roles) {
        planScope.push({
          resourceId: plan.planId,
          roleId: role.roleId,
          roleName: role.roleName,
        });
      }

      for (const team of plan.teams) {
        for (const role of team.roles) {
          teamScope.push({
            resourceId: team.teamId,
            roleId: role.roleId,
            roleName: role.roleName,
          });
        }
      }
    }
  }

  return { organizationScope, planScope, teamScope };
};

export const hasExpectedPermission = (
  user: User,
  expectedPermissions: string[],
) => {
  if (user.permissionScope.length && user.permissionScope[0].plans.length) {
    const userPermissions = user.permissionScope[0].plans[0].roles.reduce(
      (acc: UserPermission[], curr) => {
        return acc.concat(curr.permissions);
      },
      [],
    );

    for (const userPermission of userPermissions) {
      if (expectedPermissions.includes(userPermission.permissionName))
        return true;
    }
  }

  return false;
};

export const hasExpectedRole = (user: User, expectedRoles: USER_ROLE[]) => {
  if (user.permissionScope.length && user.permissionScope[0].plans.length) {
    for (const userRole of user.permissionScope[0].plans[0].roles) {
      if (expectedRoles.includes(userRole.roleName)) return true;
    }
  }

  return false;
};

export const hasExpectedRoleInScope = (
  user: User,
  expectedRoles: ExpectedRole[],
) => {
  for (const expectedRole of expectedRoles) {
    for (const permissionByScope of user.permissionsByScope[
      expectedRole.scope
    ]) {
      const isInRole = expectedRole.roleName === permissionByScope.roleName;
      const isInScope =
        !expectedRole.resourceId ||
        expectedRole.resourceId === permissionByScope.resourceId;

      if (isInRole && isInScope) {
        return true;
      }
    }
  }

  return false;
};

export const toUsername = (
  firstName?: string,
  lastName?: string,
): string | null => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }

  return firstName || lastName || null;
};

export const formatFileSize = (size?: number) => {
  if (!size) {
    return '';
  }

  if (size < 1024) {
    return `${size} B`;
  } else if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(2)} KB`;
  } else if (size < 1024 * 1024 * 1024) {
    return `${(size / (1024 * 1024)).toFixed(2)} MB`;
  } else {
    return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
  }
};

export const getDateFormat = (date: string) => {
  const dateFormat = new Date(date);

  return dateFormat
    .toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    })
    .replace(/\//g, '-');
};
