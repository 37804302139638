import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { Toast, ToastState } from './interfaces';

const INITIAL_STATE: ToastState = {
  toast: null,
};

const toastSlice = createSlice({
  name: 'toasts',
  initialState: INITIAL_STATE,
  reducers: {
    resetToast: (state) => {
      state.toast = null;
    },
    setToast: (state, action: PayloadAction<Toast>) => {
      state.toast = action.payload;
    },
  },
});

export const { resetToast, setToast } = toastSlice.actions;

export default toastSlice.reducer;
