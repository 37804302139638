import { PencilIcon } from '@pluralsight/icons';
import { FlexContainer, IconButton, useToast } from '@pluralsight/react-ng';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import RoleRestrictedComponent from '../../../../components/role-restricted-component/role-restricted-component';
import { USER_ROLE } from '../../../../shared/constants';
import { clearTeamName } from '../../../create-team/state/slice';
import { fetchTeams } from '../../../teams/state/slice';
import { selectToast } from '../../../toast/state/selectors';
import { selectTeamItem } from '../../state/selectors';
import { fetchTeam } from '../../state/slice';
import TeamNameDialog from '../team-name-dialog/team-name-dialog';

import type { Team } from '../../state/interfaces';
import './team-name.scss';

interface TeamNameProps {
  id: string;
}

const TeamName: React.FC<TeamNameProps> = ({ id }) => {
  const dispatch = useDispatch();
  const team: Team | null = useSelector(selectTeamItem);
  const toast = useSelector(selectToast);
  const { show } = useToast();
  const dialogRef = useRef<HTMLDialogElement>(null);
  const { t } = useTranslations();

  useEffect(() => {
    if (toast) {
      show({
        heading: toast.heading,
        palette: toast.palette,
        text: toast.text,
        onClose: () => {},
      });
    }
  }, [show, toast]);

  const showModal = () => {
    dispatch(fetchTeams());
    dispatch(fetchTeam(id));
    dialogRef.current?.showModal();
  };

  const closeModal = () => {
    dialogRef.current?.close();
    dispatch(clearTeamName());
  };

  return (
    <>
      <FlexContainer className="team-name" align="center">
        <h2 className="team-name__name">{team?.name || ''}</h2>
        <RoleRestrictedComponent
          restrictedTo={[
            {
              roleName: USER_ROLE.SUPER_ADMIN,
              scope: 'organizationScope',
            },
            {
              roleName: USER_ROLE.SYSTEM_ADMIN,
              scope: 'organizationScope',
            },
            {
              roleName: USER_ROLE.PLAN_ADMIN,
              scope: 'planScope',
            },
            {
              resourceId: id,
              roleName: USER_ROLE.MANAGER_FULL,
              scope: 'teamScope',
            },
            {
              resourceId: id,
              roleName: USER_ROLE.MANAGER_LIMITED,
              scope: 'teamScope',
            },
          ]}
        >
          <IconButton
            className="team-name__icon"
            palette="action"
            usage="text"
            size="sm"
            ariaLabel={t('team.editTeamNamePencil', {
              value: team?.name || '',
            })}
            onClick={showModal}
          >
            <PencilIcon />
          </IconButton>
        </RoleRestrictedComponent>
      </FlexContainer>
      <TeamNameDialog ref={dialogRef} id={id} closeModal={closeModal} />
    </>
  );
};

export default TeamName;
