import { TEAM_TABLE_KEYS } from '../../../../shared/constants';

import type { TableColumn } from '../../../../shared/interfaces';
import type { TeamsTableItem } from './teams-table';

export const columnsTeamTable: TableColumn<TeamsTableItem>[] = [
  {
    header: 'teams.teamsTable.teamColumn',
    mapsTo: TEAM_TABLE_KEYS.NAME,
    searchColumn: true,
    focusable: true,
  },
  {
    header: 'teams.teamsTable.managersColumn',
    mapsTo: TEAM_TABLE_KEYS.MANAGERS_COUNT,
  },
  { header: 'teams.teamsTable.usersColumn', mapsTo: TEAM_TABLE_KEYS.USERS },
  {
    header: 'teams.teamsTable.nestedTeamsColumn',
    mapsTo: TEAM_TABLE_KEYS.NESTED_TEAMS,
  },
  {
    header: 'teams.teamsTable.nestedUsersColumn',
    mapsTo: TEAM_TABLE_KEYS.NESTED_USERS,
  },
];
