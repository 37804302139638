import { Button, FlexContainer, FlexItem, For } from '@pluralsight/react-ng';
import { WarningTriangleFilledIcon } from '@pluralsight/icons';
import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import CustomDialog from '../../../../components/custom-dialog/custom-dialog';
import { selectExistingAccountEmails } from '../../state/selectors';
import bullet from '../../../../assets/list-bullet.svg';

import type {
  ContentType,
  HeaderType,
} from '../../../../components/custom-dialog/custom-dialog';
import './existing-account-dialog.scss';

interface IExistingAccountDialogProps {
  closeModal: () => void;
}

const ExistingAccountDialog = forwardRef<
  HTMLDialogElement | null,
  IExistingAccountDialogProps
>(({ closeModal }, ref) => {
  const { t } = useTranslations();
  const existingAccountEmails = useSelector(selectExistingAccountEmails);

  const dialogHeader: HeaderType = {
    1: (
      <FlexContainer>
        <WarningTriangleFilledIcon className="existing-account__warning-triangle" />
        {t('invitation.existingAccountDialog.header', {
          count: existingAccountEmails.length,
        })}
      </FlexContainer>
    ),
  };

  const dialogContent: ContentType = {
    1: (
      <FlexContainer direction="col">
        <FlexItem className="existing-account__emails-container">
          {t('invitation.existingAccountDialog.title', {
            count: existingAccountEmails.length,
          })}
        </FlexItem>

        <For
          each={existingAccountEmails}
          children={(email: string) => (
            <FlexContainer gap={12}>
              <img src={bullet} alt="list-bullet icon" />
              <FlexItem>{email}</FlexItem>
            </FlexContainer>
          )}
        />
      </FlexContainer>
    ),
  };

  const getActionButtons = (step: number): React.JSX.Element => {
    return step === 1 ? (
      <Button size="lg" palette="action" usage="filled" onClick={closeModal}>
        {t('invitation.existingAccountDialog.ok')}
      </Button>
    ) : (
      <></>
    );
  };

  return (
    <CustomDialog
      id="resend-invite"
      ref={ref}
      dialogHeader={dialogHeader}
      dialogContent={dialogContent}
      actionButtons={getActionButtons}
      multipleSteps={false}
      isWarning={false}
      closeModal={closeModal}
    />
  );
});

export default ExistingAccountDialog;
