import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseIconButton,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogHeading,
  Button,
  FlexContainer,
} from '@pluralsight/react-ng';
import React, { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import TeamNameInput from '../../../../components/team-name-input/team-name-input';
import { selectTeam } from '../../../create-team/state/selectors';
import { patchTeam } from '../../state/slice';
import './team-name-dialog.scss';

interface TeamNameDialogProps {
  id: string;
  closeModal: () => void;
  shouldRefetchTeams?: boolean;
}

const TeamNameDialog = forwardRef<
  HTMLDialogElement | null,
  TeamNameDialogProps
>(({ id, closeModal, shouldRefetchTeams = false }, ref) => {
  const dispatch = useDispatch();
  const { t } = useTranslations();
  const { name } = useSelector(selectTeam);

  const isActive = !!name.length;

  const handleUpdate = () => {
    dispatch(patchTeam({ id, name, shouldRefetchTeams }));
    closeModal();
  };

  return (
    <AlertDialog
      ref={ref}
      headingId="dialog-header"
      bodyId="dialog-body"
      className="team-name-dialog"
    >
      <AlertDialogCloseIconButton onClick={closeModal} />
      <AlertDialogHeader id="dialog-header">
        <AlertDialogHeading>{t('team.editTeamName')}</AlertDialogHeading>
      </AlertDialogHeader>

      <AlertDialogBody id="dialog-body">
        <p className="team-name-dialog__instructions">
          {t('team.updateOrEdit')}
        </p>
        <TeamNameInput />
      </AlertDialogBody>

      <AlertDialogFooter>
        <FlexContainer
          align="center"
          direction="row"
          justify="flexEnd"
          wrap="nowrap"
        >
          <Button
            className="action-buttons__button"
            size="lg"
            palette="action"
            usage="text"
            onClick={closeModal}
          >
            {t('common.actionButtons.cancel')}
          </Button>
          <Button
            size="lg"
            palette={isActive ? 'action' : 'neutral'}
            usage="filled"
            disabled={!isActive}
            onClick={handleUpdate}
          >
            {t('common.actionButtons.update')}
          </Button>
        </FlexContainer>
      </AlertDialogFooter>
    </AlertDialog>
  );
});

export default TeamNameDialog;
