import {
  EmptyState,
  EmptyStateDescription,
  EmptyStateHeader,
  EmptyStateIllustration,
  FlexContainer,
  useToast,
} from '@pluralsight/react-ng';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import ConnectionCard from '../connection-card/connection-card';
import ConnectionListHeader from '../connection-list-header/connection-list-header';
import {
  selectSsoConnectionDeleteSuccess,
  selectSsoConnections,
  selectSsoConnectionsError,
} from '../state/selectors';
import { fetchSsoConnections, resetDeleteSsoConnection } from '../state/slice';
import './connection-list.scss';
import ConnectionCardSM from '../connection-card/connection-card-sm';
import useScreenWidth from '../../../../../shared/hooks/useScreenWidth';
import { selectOrgId } from '../../../../plans/state/selectors';

const ConnectionList = () => {
  const dispatch = useDispatch();
  const connections = useSelector(selectSsoConnections);
  const connectionsError = useSelector(selectSsoConnectionsError);
  const deleteSsoConnectionSuccess = useSelector(
    selectSsoConnectionDeleteSuccess,
  );
  const orgId = useSelector(selectOrgId);
  const { show } = useToast();
  const { t } = useTranslations();
  const { isXS, isSM, isMD, isXL } = useScreenWidth();

  useEffect(() => {
    if (connectionsError) {
      show({
        text: t('sso.ssoToastMessages.ssoConnectionFetchingError'),
        palette: 'danger',
        onClose: () => {},
      });
    }
  }, [t, connectionsError, show]);

  useEffect(() => {
    if (deleteSsoConnectionSuccess) {
      show({
        text: t('sso.ssoToastMessages.ssoConnectionToastDeleteSuccess'),
        palette: 'success',
        onClose: () => {},
      });
      dispatch(resetDeleteSsoConnection());
    }
  }, [t, deleteSsoConnectionSuccess, show, dispatch]);

  useEffect(() => {
    if (orgId) {
      dispatch(fetchSsoConnections({ orgId }));
    }
  }, [dispatch, orgId]);

  return (
    <FlexContainer
      className="connection-list"
      direction="col"
      style={{
        marginTop: connections.length ? 'var(--pando-gap-md)' : '',
      }}
    >
      {connections.length ? (
        <table className="connection-list__table">
          {(isMD || isXL) && <ConnectionListHeader />}
          <tbody className="connection-list__table__body">
            {connections.map(({ id, name, updatedAt, strategy, status }) =>
              isXS || isSM ? (
                <ConnectionCardSM key={id} connectionId={id} name={name} />
              ) : (
                <ConnectionCard
                  key={id}
                  connectionId={id}
                  name={name}
                  status={status}
                  updatedAt={updatedAt}
                  protocol={strategy}
                />
              ),
            )}
          </tbody>
        </table>
      ) : (
        <EmptyState>
          <EmptyStateIllustration size="lg" />
          <EmptyStateHeader size="lg">
            {t('sso.ssoConnections.noConnections.title')}
          </EmptyStateHeader>
          <EmptyStateDescription>
            {t('sso.ssoConnections.noConnections.subTitle')}
          </EmptyStateDescription>
        </EmptyState>
      )}
    </FlexContainer>
  );
};

export default ConnectionList;
