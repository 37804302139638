import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { Role, RolesState } from './interfaces';

const INITIAL_STATE: RolesState = {
  list: [],
  loading: false,
  error: null,
};

const rolesSlice = createSlice({
  name: 'roles',
  initialState: INITIAL_STATE,
  reducers: {
    fetchRoles: (state, _action: PayloadAction<string>) => {
      state.error = null;
      state.loading = true;
    },
    fetchRolesSuccess: (state, action: PayloadAction<Role[]>) => {
      state.loading = false;
      state.list = action.payload;
    },
    fetchRolesFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { fetchRoles, fetchRolesSuccess, fetchRolesFailure } =
  rolesSlice.actions;

export default rolesSlice.reducer;
