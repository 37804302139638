import { ArrowRightIcon } from '@pluralsight/icons';
import { Button, FlexContainer } from '@pluralsight/react-ng';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import { selectTeam } from '../../state/selectors';
import { setDialogStep } from '../../../../shared/dialog/state/slice';
import './action-buttons.scss';

interface IActionButtonsProps {
  closeModal: () => void;
}

const TeamNameActionButtons: React.FC<IActionButtonsProps> = ({
  closeModal,
}) => {
  const { t } = useTranslations();
  const dispatch = useDispatch();
  const setNextStep = () => dispatch(setDialogStep(3)); // TODO (okyry) add step 2 to render nested team step (component is partially ready)

  const { name } = useSelector(selectTeam);

  const isActive = !!name.length;

  return (
    <FlexContainer
      align="center"
      direction="row"
      justify="flexEnd"
      wrap="nowrap"
    >
      <Button
        className="action-buttons__button"
        size="lg"
        palette="action"
        usage="text"
        onClick={closeModal}
      >
        {t('common.actionButtons.cancel')}
      </Button>
      <Button
        size="lg"
        palette={isActive ? 'action' : 'neutral'}
        usage="filled"
        disabled={!isActive}
        onClick={setNextStep}
      >
        {t('common.actionButtons.next')}
        <ArrowRightIcon width={20} />
      </Button>
    </FlexContainer>
  );
};

export default TeamNameActionButtons;
