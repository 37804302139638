import { createSelector } from 'reselect';

import type { RootState } from '../../../store';
import type { IOrganizationState } from './interfaces';

const selectOrganizationState = (state: RootState) => state.organization;

export const selectOrganization = createSelector(
  [selectOrganizationState],
  (organizationState: IOrganizationState) => organizationState.organization,
);
