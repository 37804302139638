import { toLower } from 'lodash';
import { useState, useEffect } from 'react';

interface ProductAssets {
  icon: string;
  legend: string;
}

const PRODUCT_ASSETS = {
  skills: {
    icon: () => import('../../assets/skills-logo-icon.svg'),
    legend: () => import('../../assets/skills-legend-image.svg'),
  },
  flow: {
    icon: () => import('../../assets/flow-logo-icon.svg'),
    legend: () => import('../../assets/flow-legend-image.svg'),
  },
} as const;

interface UseProductAssetsResult {
  productAssets: ProductAssets | null;
  isLoading: boolean;
}

export const useProductAssets = (
  productName: string,
): UseProductAssetsResult => {
  const [productAssets, setProductAssets] = useState<ProductAssets | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadAssets = async () => {
      try {
        setIsLoading(true);

        const assets =
          PRODUCT_ASSETS[toLower(productName) as keyof typeof PRODUCT_ASSETS];

        if (assets) {
          const [iconModule, legendModule] = await Promise.all([
            assets.icon(),
            assets.legend(),
          ]);

          setProductAssets({
            icon: iconModule.default,
            legend: legendModule.default,
          });
        } else {
          console.warn(`No assets found for product: ${productName}`);
        }
      } catch (error) {
        console.error('Failed to load product assets:', error);
      } finally {
        setIsLoading(false);
      }
    };

    void loadAssets();
  }, [productName]);

  return { productAssets, isLoading };
};

export { PRODUCT_ASSETS };
