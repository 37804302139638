import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import CustomDialog from '../../../../components/custom-dialog/custom-dialog';
import ImportStepActionButtons from '../action-buttons/import-step-action-buttons';
import ImportTeamsStep from '../import-teams-step/import-teams-step';
import ExportStepActionButtons from '../action-buttons/export-step-action-buttons';
import { ImportTabs } from '../../../../shared/interfaces';
import { selectImportActivetab } from '../../state/selectors';

import type {
  ContentType,
  HeaderType,
} from '../../../../components/custom-dialog/custom-dialog';
import './import-teams-dialog.scss';

interface ImportTeamsDialogProps {
  closeModal: () => void;
}

const dialogContent: ContentType = {
  1: <ImportTeamsStep />,
};

const ImportTeamsDialog = forwardRef<
  HTMLDialogElement | null,
  ImportTeamsDialogProps
>(({ closeModal }, ref) => {
  const { t } = useTranslations();
  const importActiveTab = useSelector(selectImportActivetab);

  const dialogHeader: HeaderType = {
    1: t('teams.importTeams.header'),
  };

  const getActionButtons = (step: number): React.JSX.Element => {
    switch (step) {
      case 1:
        return importActiveTab === ImportTabs.Import ? (
          <ImportStepActionButtons closeModal={closeModal} />
        ) : (
          <ExportStepActionButtons closeModal={closeModal} />
        );

      default:
        return <></>;
    }
  };

  return (
    <CustomDialog
      id="import-teams" /* id impacts nested tooltips, should be changed together */
      ref={ref}
      dialogHeader={dialogHeader}
      dialogContent={dialogContent}
      actionButtons={getActionButtons}
      closeModal={closeModal}
      multipleSteps={false}
    />
  );
});

export default ImportTeamsDialog;
