import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import './connection-list-header.scss';
import useScreenWidth from '../../../../../shared/hooks/useScreenWidth';

const ConnectionListHeader = () => {
  const { t } = useTranslations();
  const { isXL } = useScreenWidth();

  return (
    <thead>
      <tr className="connection-list-header">
        <th className="connection-list-header__col connection-list-header__col--1">
          {t('sso.ssoConnections.connectionListHeader.ssoConnectionName')}
        </th>
        <th className="connection-list-header__col connection-list-header__col--2">
          {t('sso.ssoConnections.connectionListHeader.protocol')}
        </th>
        <th className="connection-list-header__col connection-list-header__col--3">
          {t('sso.ssoConnections.connectionListHeader.lastUpdatedDate')}
        </th>
        <th className="connection-list-header__col connection-list-header__col--4">
          {isXL && (
            <>{t('sso.ssoConnections.connectionListHeader.lastUpdatedBy')}</>
          )}
        </th>
      </tr>
    </thead>
  );
};

export default ConnectionListHeader;
