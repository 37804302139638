import { useMemo } from 'react';
import {
  FlexContainer,
  GridCell,
  GridContainer,
  ProgressBar,
} from '@pluralsight/react-ng';
import { ListBulletIcon } from '@pluralsight/icons';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import type { LicensedProductWithUsage } from '../../../products/state/interfaces';

import './product-usage-widget.scss';

interface ProductUsageWidgetProps {
  product: LicensedProductWithUsage;
}

const BodyUsageInfoItem = ({
  label,
  value,
  name,
}: {
  label: string;
  value: number;
  name: string;
}) => (
  <FlexContainer
    direction="row"
    align="flexStart"
    justify="left"
    wrap="nowrap"
    className="product-usage-widget__body-usage-info-item"
    role="listitem"
  >
    <ListBulletIcon className={`icon-${name}`} aria-hidden="true" />
    <span
      className="product-usage-widget__body-usage-info-item_value"
      aria-label={`${value} ${label}`}
      aria-level={2}
    >
      {value}
    </span>
    <span
      className="product-usage-widget__body-usage-info-item_label"
      aria-hidden="true"
    >
      {label}
    </span>
  </FlexContainer>
);

const ProductUsageWidget = ({
  product: {
    productName,
    usedLicenses,
    totalLicenses,
    pendingLicenses,
    remainingLicenses,
  },
}: ProductUsageWidgetProps) => {
  const { t } = useTranslations();

  const infoItems = useMemo(
    () => [
      // key, value
      ['redeemed', usedLicenses - pendingLicenses],
      ['pending', pendingLicenses],
      ['available', remainingLicenses],
    ],
    [usedLicenses, pendingLicenses, remainingLicenses],
  );

  const usagePercentage = Math.round((usedLicenses / totalLicenses) * 100);

  return (
    <div className="product-usage-widget" aria-label={productName}>
      <FlexContainer
        direction="row"
        align="stretch"
        justify="spaceBetween"
        className="product-usage-widget__header"
      >
        <span className="product-usage-widget__header-title">
          {productName}
        </span>
        <span
          className="product-usage-widget__header-value"
          aria-level={2}
          aria-label={t('productUsageWidget.assignedWithCount', {
            used: usedLicenses,
            total: totalLicenses,
            delimiter: t('productUsageWidget.delimiters.outOf'),
          })}
        >
          {t('productUsageWidget.assignedWithCount', {
            used: usedLicenses,
            total: totalLicenses,
            delimiter: t('productUsageWidget.delimiters.slash'),
          })}
        </span>
      </FlexContainer>
      <div className="product-usage-widget__body">
        <ProgressBar
          min={0}
          usage="inset"
          ariaLabel={t('productUsageWidget.progressLicenseUsed', {
            count: usagePercentage,
          })}
          now={usedLicenses}
          max={totalLicenses}
          className="product-usage-widget__body-progress-bar"
        />
        <GridContainer className="product-usage-widget__body-usage-info">
          {infoItems.map(([key, value]) => (
            <GridCell key={key} medium="6" large="4">
              <BodyUsageInfoItem
                label={t(`productUsageWidget.${key}`)}
                value={value as number}
                name={key as string}
              />
            </GridCell>
          ))}
        </GridContainer>
      </div>
    </div>
  );
};

export default ProductUsageWidget;
