import {
  FieldMessage,
  FlexContainer,
  FormControlProvider,
  Label,
  Option,
  Select,
} from '@pluralsight/react-ng';
import { useState } from 'react';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import { CustomMenu } from '../custom-menu/custom-menu';
import useRoleOptions from '../../shared/hooks/useRoleOptions';
import './role-step.scss';

import type { IOption } from '../custom-menu/custom-menu';

type OptionType = {
  value: string;
  name: string;
  permissions: string;
} | null;

interface UserRoleStepProps {
  headerText?: string;
  assignedRoles: string[];
  optionSelected: (values: string[]) => void;
}

const RoleStep = ({
  headerText,
  assignedRoles,
  optionSelected,
}: UserRoleStepProps) => {
  const { t } = useTranslations();
  const {
    isAdmin,
    defaultManagerOption,
    defaultAdminOption,
    optionsByPermissions,
  } = useRoleOptions();

  const { managerOptions, adminOptions } = optionsByPermissions();

  const assignedManager =
    managerOptions.find((option) => assignedRoles.includes(option.value)) ||
    defaultManagerOption;

  const assignedAdmin =
    adminOptions.find((option) => assignedRoles.includes(option.value)) ||
    defaultAdminOption;

  const [selectedManager, setSelectedManager] = useState<OptionType>(null);

  const [selectedAdmin, setSelectedAdmin] = useState<OptionType>(null);

  const handleManagerSelect = (option: IOption) => {
    setSelectedManager(option);
    optionSelected([option.value, selectedAdmin?.value ?? assignedAdmin.value]);
  };

  const handleAdminSelect = (option: IOption) => {
    setSelectedAdmin(option);
    optionSelected([
      selectedManager?.value ?? assignedManager.value,
      option.value,
    ]);
  };

  return (
    <div className="role-step__container">
      <FormControlProvider>
        {headerText && <div>{headerText}</div>}

        <FlexContainer direction="col" className="role-step__select-role">
          <Label htmlFor="default-role">
            {t('common.roleStep.standardRoleLabel')}
          </Label>
          <Select id="default-role" name="default-role" size="lg" disabled>
            <Option value="0" name="Learner">
              {t('common.roleStep.standardRole')}
            </Option>
          </Select>
          <FieldMessage id="default-role">
            {t('common.roleStep.standardRolePermissions')}
          </FieldMessage>
        </FlexContainer>
      </FormControlProvider>
      {isAdmin && (
        <>
          <CustomMenu
            id="manager-role"
            label={t('common.roleStep.managerRoleLabel')}
            selectedOption={selectedManager ?? assignedManager}
            options={managerOptions}
            handleSelect={handleManagerSelect}
          />

          <CustomMenu
            id="admin-role"
            label={t('common.roleStep.adminRoleLabel')}
            selectedOption={selectedAdmin ?? assignedAdmin}
            options={adminOptions}
            handleSelect={handleAdminSelect}
          />
        </>
      )}
    </div>
  );
};

export default RoleStep;
