import { useCallback } from 'react';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import { toUsername } from '../../../shared/utils';
import { roleNameMap } from '../../../features/invitation/components/confirm-step/constants';

import type { Member } from '../../../shared/interfaces';

const useMapUsers = () => {
  const { t } = useTranslations();

  const toUserTableItems = useCallback(
    (people: Member[]) =>
      people.map((person) => ({
        id: person.id,
        name: toUsername(person.firstName, person.lastName),
        email: person.email,
        roles: person.roles.map((role) => t(roleNameMap[role.name])).join(', '),
        teams: person.teams
          ? person.teams.map((team) => team.name).join(', ')
          : '',
        teamsSummary: person.teams,
        licenses: person.products
          ? person.products
              .map((product) =>
                product.productName === 'Free product'
                  ? t('invitesTable.freeAccount')
                  : product.productName,
              )
              .join(', ')
          : null,
        lastLogin: person.lastSignIn,
        note: person.note,
        roleNames: person.roles.map((role) => role.name),
      })),
    [t],
  );

  return {
    toUserTableItems,
  };
};

export default useMapUsers;
