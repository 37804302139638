import { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import CustomDialog from '../../../../components/custom-dialog/custom-dialog';
import { selectEditUser, selectUserRoles } from '../../state/selectors';
import ConfirmRolesStepActionButtons from '../action-buttons/confirm-roles-step-action-button';
import UpdateRoleStepActionButtons from '../action-buttons/update-role-step-action-button';
import RoleConfirmationStep from '../role-confirmation-step/role-confirmation-step';
import RoleStep from '../../../../components/role-step/role-step';
import { assignUserRole } from '../../state/slice';
import { toUsername } from '../../../../shared/utils';

import type {
  ContentType,
  HeaderType,
} from '../../../../components/custom-dialog/custom-dialog';

interface IUpdateRolesDialogProps {
  closeModal: () => void;
}

const UpdateRolesDialog = forwardRef<
  HTMLDialogElement | null,
  IUpdateRolesDialogProps
>(({ closeModal }, ref) => {
  const dispatch = useDispatch();
  const { t } = useTranslations();
  const user = useSelector(selectEditUser);
  const assignedRoles = useSelector(selectUserRoles);
  const userName = toUsername(user?.firstName, user?.lastName);

  const dialogHeader: HeaderType = {
    1: t('people.updateRolesDialog.roleStepTitle', { user: userName }),
    2: t('people.updateRolesDialog.confirmationStepTitle', { user: userName }),
  };

  const onOptionChanged = (values: string[]) =>
    dispatch(assignUserRole(values));

  const dialogContent: ContentType = {
    1: user ? (
      <RoleStep
        assignedRoles={assignedRoles}
        optionSelected={onOptionChanged}
      />
    ) : null,
    2: <RoleConfirmationStep />,
  };

  const getActionButtons = (step: number): React.JSX.Element | null => {
    switch (step) {
      case 1:
        return <UpdateRoleStepActionButtons closeModal={closeModal} />;
      case 2:
        return <ConfirmRolesStepActionButtons closeModal={closeModal} />;
      default:
        return null;
    }
  };

  return (
    <CustomDialog
      id="update-roles"
      ref={ref}
      dialogHeader={dialogHeader}
      dialogContent={dialogContent}
      actionButtons={getActionButtons}
      multipleSteps={2}
      isWarning={false}
      closeModal={closeModal}
    />
  );
});

export default UpdateRolesDialog;
