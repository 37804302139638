import type { TSsoConnectionStatus } from '../../sso-conections/state/interfaces';

export enum SsoConfigurationFields {
  NAME = 'name',
  CONNECTION_NAME = 'connectionName',
  STATUS = 'status',
}

export enum OIDCFormFields {
  OPENID_CONNECT_TYPE = 'openidConnectType',
  OPENID_CONNECT_DISC_URL = 'openidConnectDiscUrl',
  COMMUNICATION_CHANNEL = 'communicationChannel',
  CLIENT_ID = 'clientId',
  CLIENT_SECRET = 'clientSecret',
  IS_PROFILE_SYNC_ENABLED = 'isProfileSyncEnabled',
  METADATA = 'metadata',
}

export enum SAMLFormFields {
  SIGN_SAML_REQUEST = 'signSamlRequest',
  SIGNATURE_ALGORITHM = 'signatureAlgorithm',
  DIGEST_ALGORITHM = 'digestAlgorithm',
  SIGN_IN_ENDPOINT = 'signInEndpoint',
  SIGN_OUT_ENDPOINT = 'signOutEndpoint',
  PROTOCOL_BINDING = 'protocolBinding',
  USER_ID_ATTRIBUTE = 'userIdAttribute',
  SIGNING_CERT = 'signingCert',
}

export interface ICommonConfigurationPayload {
  id: string;
  connectionName: string;
  status: string;
  orgId: string;
}

export interface IOidcConfigurationPayload extends ICommonConfigurationPayload {
  communicationChannel: CommunicationChannel;
  metadata?: File | object;
  clientId: string;
  clientSecret: string;
  openidConnectDiscUrl?: string;
}

export interface ISamlConfigurationPayload extends ICommonConfigurationPayload {
  signSamlRequest: 'on';
  signatureAlgorithm: SignatureAlgorithm;
  digestAlgorithm: DigestAlgorithm;
  signInEndpoint: string;
  signingCert: File | string;
  signOutEndpoint: string;
  protocolBinding: ProtocolBinding;
  userIdAttribute: string;
}

export type TSsoConfigurationPayload =
  | IOidcConfigurationPayload
  | ISamlConfigurationPayload;

export interface IStatusPayload {
  status: TSsoConnectionStatus;
  id: string;
}

export enum CommunicationChannel {
  BackChannel = 'back_channel',
  FrontChannel = 'front_channel',
}

export enum SsoProtocol {
  SAML = 'samlp',
  OIDC = 'oidc',
}

export const SsoProtocolMap: Record<SsoProtocol, string> = {
  [SsoProtocol.SAML]: 'saml',
  [SsoProtocol.OIDC]: 'oidc',
};

export enum OpenIdConnectType {
  DiscoveryUrl,
  MetadataFile,
}

export interface ISsoConfigurationState {
  isFormModifiedInEdit: boolean;
  loading: boolean;
  error: string | null;
  success: boolean | null;
}

export const SSO_CONF_TOAST_MESSAGES = {
  ERROR: 'SSO Configuration submission failed!',
  SUCCESS: 'SSO Configuration submission successfully!',
};

export const CLIENT_ID = 'aFlKYNe92AXFhsl5KiCP7lshKseV8E3R';

export const CLIENT_SECRET =
  'zWTfoyzU5fORQj1_Kbx6p9ByXByignJWYu6lwsZZEYPfcbRd9FlNufjYJfRmp5by';

export const DISCOVERY_URL =
  'https://lemur-10.cloud-iam.com/auth/realms/keyclock-test/.well-known/openid-configuration';

export enum SignatureAlgorithm {
  RSASHA1 = 'rsa-sha1',
  RSASHA256 = 'rsa-sha256',
}

export enum DigestAlgorithm {
  SHA1 = 'sha1',
  SHA256 = 'sha256',
}

export enum ProtocolBinding {
  HTTP_POST = 'urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST',
  HTTP_REDIRECT = 'urn:oasis:names:tc:SAML:2.0:bindings:HTTP-Redirect',
}

export enum ProtocolBindingField {
  HTTP_POST = 'httpPost',
  HTTP_REDIRECT = 'httpRedirect',
}

export const CERT_EXTENSIONS = ['cer', 'cert', 'crt', 'pem'] as const;
export const CERT_EXTENSIONS_WITH_DOT = CERT_EXTENSIONS.map((ext) => `.${ext}`);
