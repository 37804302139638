import './basic-information-form.style.scss';
import { EyeIcon } from '@pluralsight/icons';
import {
  Button,
  FlexContainer,
  FormControlProvider,
  Input,
  Label,
} from '@pluralsight/react-ng';
import { useCallback, useEffect, useState } from 'react';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import CopyButton from '../../shared/copy-button/copy-button';
import RightIconInput from '../../shared/right-icon-input/right-icon-input';
import {
  CLIENT_ID,
  CLIENT_SECRET,
  SsoConfigurationFields,
} from '../state/interfaces';

import type { ISsoConnection } from '../../sso-conections/state/interfaces';
import type { MouseEvent } from 'react';

interface IBasicInformationFormProps {
  connectionName?: ISsoConnection['name'];
  clientId?: ISsoConnection['id'];
  clientSecret?: string;
}
const AUTH0_DOMAIN = 'dev-q5mb8ryk0excju2y.us.auth0.com';

const BasicInformationForm = ({
  connectionName,
  clientId: id,
  clientSecret: secret,
}: IBasicInformationFormProps) => {
  const [name, setName] = useState<string>(connectionName ?? 'My web app');
  const [domain] = useState<string>(AUTH0_DOMAIN);
  const [clientId, setClientId] = useState<string>(CLIENT_ID);
  const [clientSecret, setClientSecret] = useState<string>(CLIENT_SECRET);
  const [isSecretVisible, setIsSecretVisible] = useState<boolean>(false);
  const { t } = useTranslations();

  useEffect(() => {
    if (connectionName) setName(connectionName);

    if (id) setClientId(id);

    if (secret) setClientSecret(secret);
  }, [connectionName, id, secret]);

  const toggleSecretVisibility = useCallback(
    (e: MouseEvent<HTMLButtonElement>): void => {
      e.preventDefault();
      setIsSecretVisible((prev) => !prev);
    },
    [],
  );

  const inputGroupContainer = (
    <FlexContainer direction="row" className="basic-information__icon-group">
      <Button
        className="basic-information__eye-icon"
        palette="action"
        size="md"
        usage="text"
        onClick={toggleSecretVisibility}
        startIcon={
          <EyeIcon
            aria-label={
              isSecretVisible
                ? t('sso.ssoConfiguration.hideClientSecret')
                : t('sso.ssoConfiguration.showClientSecret')
            }
          />
        }
      />
      <CopyButton
        copyText={clientSecret}
        aria-label={t('sso.ssoConfiguration.copyClientSecret')}
      />
    </FlexContainer>
  );

  return (
    <fieldset className="sso-config__fieldset fieldset basic-information">
      <FlexContainer
        direction="col"
        gap="var(--pando-gap-xs)"
        className="fieldset__header"
      >
        <h2 className="fieldset__title">
          {t('sso.ssoConfiguration.ssoConfigurationTitle')}
        </h2>
        <p className="fieldset__description">
          {t('sso.ssoConfiguration.viewAndCopyEssential')}
        </p>
      </FlexContainer>
      <FlexContainer
        direction="col"
        gap="var(--pando-gap-md)"
        className="fieldset__body"
      >
        <FlexContainer direction="col" className="fieldset__field">
          <FormControlProvider readOnly>
            <Label
              htmlFor="sso-basic-information-name"
              className="fieldset__label"
            >
              {t('sso.ssoConfiguration.nameLabel')}
            </Label>
            <Input
              disabled
              id="sso-basic-information-name"
              name={SsoConfigurationFields.NAME}
              placeholder={t('sso.ssoConfiguration.namePlaceholder')}
              size="lg"
              className="fieldset__input fieldset__input--special"
              value={name}
            />
          </FormControlProvider>
        </FlexContainer>

        <FlexContainer gap="0.5rem" direction="col" className="fieldset__field">
          <FormControlProvider readOnly>
            <Label
              htmlFor="sso-basic-information-domain"
              className="fieldset__label fieldset__note"
            >
              {t('sso.ssoConfiguration.domainLabel')}
            </Label>
            <RightIconInput
              disabled
              id="sso-basic-information-domain"
              name="domain"
              size="md"
              className="fieldset__input fieldset__input--special"
              value={domain}
              endIcon={
                <CopyButton
                  copyText={domain}
                  aria-label={t('sso.ssoConfiguration.copyDomainLabel')}
                />
              }
            />
          </FormControlProvider>
        </FlexContainer>

        <FlexContainer direction="col" className="fieldset__field">
          <FormControlProvider readOnly>
            <Label
              htmlFor="sso-basic-information-client-id"
              className="fieldset__label fieldset__note"
            >
              {t('sso.ssoConfiguration.clientIdLabel')}
            </Label>
            <RightIconInput
              disabled
              id="sso-basic-information-client-id"
              name="client-id"
              size="md"
              endIcon={
                <CopyButton
                  aria-label={t('sso.ssoConfiguration.copyClientId')}
                  copyText={clientId}
                />
              }
              className="fieldset__input fieldset__input--special"
              value={clientId}
            />
          </FormControlProvider>
        </FlexContainer>

        <FlexContainer
          direction="col"
          className="fieldset__field client-secret-field"
        >
          <FormControlProvider>
            <Label
              htmlFor="sso-basic-information-client-secret"
              className="fieldset__label fieldset__note"
            >
              {t('sso.ssoConfiguration.clientSecretLabel')}
            </Label>
            <RightIconInput
              disabled={!isSecretVisible}
              id="sso-basic-information-client-secret"
              name="client-secret"
              size="md"
              type={isSecretVisible ? 'text' : 'password'}
              endIcon={inputGroupContainer}
              className="fieldset__input fieldset__input--special fieldset__input--password"
              value={clientSecret}
            />
            <p className="fieldset__note fieldset__note--mt-sm">
              {t('sso.ssoConfiguration.clientSecretIsNotBase64')}
            </p>
          </FormControlProvider>
        </FlexContainer>
      </FlexContainer>
    </fieldset>
  );
};

export default BasicInformationForm;
