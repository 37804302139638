import { createSlice } from '@reduxjs/toolkit';

import type { Member } from '../../../shared/interfaces';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { Person } from '../../people/state/interfaces';
import type { TeamUsersState } from './interfaces';

const INITIAL_STATE: TeamUsersState = {
  error: null,
  list: [],
  loading: false,
  managers: [],
  teamsToAddIds: [],
  teamsToRemoveIds: [],
};

const teamUsersSlice = createSlice({
  name: 'team-users',
  initialState: INITIAL_STATE,
  reducers: {
    fetchTeamUsers: (state, _action) => {
      state.error = null;
      state.loading = true;
    },
    fetchTeamUsersSuccess: (state, action: PayloadAction<Member[]>) => {
      state.list = action.payload;
      state.loading = false;
    },
    fetchTeamUsersFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },

    addTeamMembers: (state) => {
      state.error = null;
      state.loading = true;
    },
    addTeamMembersSuccess: (state) => {
      state.loading = false;
    },
    addTeamMembersFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },

    addTeamManagers: (state) => {
      state.error = null;
      state.loading = true;
    },
    addTeamManagersSuccess: (state) => {
      state.loading = false;
    },
    addTeamManagersFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },

    assignManagers: (state, action: PayloadAction<Person>) => {
      state.managers = [...state.managers, action.payload];
    },
    clearAssignedManagers: (state) => {
      state.managers = [];
    },
    removeAssignedManagers: (state, action: PayloadAction<string>) => {
      state.managers = state.managers.filter(
        (manager) => manager.id !== action.payload,
      );
    },
    updateTeamSelection: (
      state,
      action: PayloadAction<{ teamId: string; wasPreviouslyAssigned: boolean }>,
    ) => {
      const { teamId, wasPreviouslyAssigned } = action.payload;

      if (wasPreviouslyAssigned) {
        if (state.teamsToRemoveIds.includes(teamId)) {
          state.teamsToRemoveIds = state.teamsToRemoveIds.filter(
            (id) => id !== teamId,
          );
          state.teamsToAddIds.push(teamId);
        } else {
          state.teamsToRemoveIds.push(teamId);
          state.teamsToAddIds = state.teamsToAddIds.filter(
            (id) => id !== teamId,
          );
        }
      } else {
        if (state.teamsToAddIds.includes(teamId)) {
          state.teamsToAddIds = state.teamsToAddIds.filter(
            (id) => id !== teamId,
          );
        } else {
          state.teamsToAddIds.push(teamId);
        }
      }
    },

    clearTeamSelections: (state) => {
      state.teamsToAddIds = [];
      state.teamsToRemoveIds = [];
    },

    moveTeamMembers: (state, _action) => {
      state.error = null;
      state.loading = true;
    },
    moveTeamMembersSuccess: (state) => {
      state.loading = false;
    },
    moveTeamMembersFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },

    removeTeamMembers: (state, _action) => {
      state.error = null;
      state.loading = true;
    },
    removeTeamMembersSuccess: (state) => {
      state.loading = false;
    },
    removeTeamMembersFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  fetchTeamUsers,
  fetchTeamUsersSuccess,
  fetchTeamUsersFailure,
  addTeamMembers,
  addTeamMembersSuccess,
  addTeamMembersFailure,
  addTeamManagers,
  addTeamManagersSuccess,
  addTeamManagersFailure,
  assignManagers,
  clearAssignedManagers,
  removeAssignedManagers,
  updateTeamSelection,
  clearTeamSelections,
  moveTeamMembers,
  moveTeamMembersSuccess,
  moveTeamMembersFailure,
  removeTeamMembers,
  removeTeamMembersSuccess,
  removeTeamMembersFailure,
} = teamUsersSlice.actions;

export default teamUsersSlice.reducer;
