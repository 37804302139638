import { useSelector } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import { selectUser } from '../../features/user/state/selectors';
import { USER_ROLE } from '../constants';
import { hasExpectedRole } from '../utils';
import { selectMappedRoles } from '../../features/roles/state/selectors';

const adminRoles = [
  USER_ROLE.SUPER_ADMIN,
  USER_ROLE.SSO_ADMIN,
  USER_ROLE.SYSTEM_ADMIN,
  USER_ROLE.PLAN_ADMIN,
];

const useRoleOptions = () => {
  const { t } = useTranslations();
  const user = useSelector(selectUser);
  const roles = useSelector(selectMappedRoles);

  const isAdmin = user && hasExpectedRole(user, adminRoles);
  const isPlanAdmin = user && hasExpectedRole(user, [USER_ROLE.PLAN_ADMIN]);
  const isSystemOrSuperAdmin =
    user &&
    hasExpectedRole(user, [USER_ROLE.SYSTEM_ADMIN, USER_ROLE.SUPER_ADMIN]);

  const defaultManagerOption = {
    value: '',
    name: t('common.roleStep.defaultManagerOption'),
    permissions: t('common.roleStep.defaultManagerOptionPermissions'),
  };

  const defaultAdminOption = {
    value: '',
    name: t('common.roleStep.defaultAdminOption'),
    permissions: t('common.roleStep.defaultAdminOptionPermissions'),
  };

  const findId = (enumValue: string): string => roles.get(enumValue) || '';

  const managerOptions = [
    { ...defaultManagerOption },
    {
      value: findId(USER_ROLE.MANAGER_BASIC),
      name: t('common.roleStep.managerRoleBasic'),
      permissions: t('common.roleStep.managerRoleBasicPermissions'),
    },
    {
      value: findId(USER_ROLE.MANAGER_LIMITED),
      name: t('common.roleStep.managerRoleLimited'),
      permissions: t('common.roleStep.managerRoleLimitedPermissions'),
    },
    {
      value: findId(USER_ROLE.MANAGER_FULL),
      name: t('common.roleStep.managerRoleFull'),
      permissions: t('common.roleStep.managerRoleFullPermissions'),
    },
  ];
  const adminOptions = [
    { ...defaultAdminOption },
    {
      value: findId(USER_ROLE.PLAN_ADMIN),
      name: t('common.roleStep.planAdminRole'),
      permissions: t('common.roleStep.planAdminRolePermissions'),
    },
    {
      value: findId(USER_ROLE.SUPER_ADMIN),
      name: t('common.roleStep.superAdminRole'),
      permissions: t('common.roleStep.superAdminRolePermissions'),
    },
  ];

  const optionsByPermissions = () => {
    switch (true) {
      case isSystemOrSuperAdmin:
        return { managerOptions, adminOptions };

      case isPlanAdmin:
        return { managerOptions, adminOptions: adminOptions.slice(0, 2) };

      default:
        return {
          managerOptions: [defaultManagerOption],
          adminOptions: [defaultAdminOption],
        };
    }
  };

  return {
    isAdmin,
    defaultAdminOption,
    defaultManagerOption,
    optionsByPermissions,
  };
};

export default useRoleOptions;
