import { FlexContainer, TextLink } from '@pluralsight/react-ng';
import { DownloadIcon } from '@pluralsight/icons';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import { generateCSV } from '../../../../shared/export-csv-data';
import { TEMPLATE_COLUMNS } from '../../../../shared/constants';

import './export-teams-container.scss';

const templateColumns = [
  TEMPLATE_COLUMNS.ID,
  TEMPLATE_COLUMNS.TEAM_NAME,
  TEMPLATE_COLUMNS.PARENT_TEAM_NAME,
  TEMPLATE_COLUMNS.ACTION,
];

const ExportTeamsContainer = () => {
  const { t } = useTranslations();

  const handleDownload = () => {
    generateCSV(templateColumns, [], 'CSV blank file.csv');
  };

  return (
    <FlexContainer direction="col" className="export-teams-container">
      <FlexContainer>
        <p>
          {t('teams.exportCsvFile.exportCsvInstructions')}{' '}
          <TextLink
            href="https://help.pluralsight.com/"
            className="export-teams-container__link"
            aria-label={t('teams.importTeams.helpCenter')}
          >
            {t('teams.importTeams.helpCenter')}
          </TextLink>
        </p>
      </FlexContainer>
      <FlexContainer
        direction="col"
        className="export-teams-container__section"
      >
        <h2 className="export-teams-container__subtitle">
          {t('people.exportCsvFile.exportOptions')}
        </h2>
        <FlexContainer direction="col">
          <div className="export-teams-container__action">
            <DownloadIcon
              width={20}
              height={20}
              className="export-teams-container__icon"
            />
            <TextLink
              onClick={() => handleDownload()}
              className="export-teams-container__link"
            >
              {t('teams.exportCsvFile.blankTemplate')}
            </TextLink>
          </div>
          <div className="export-teams-container__action">
            <DownloadIcon
              width={20}
              height={20}
              className="export-teams-container__icon"
            />
            <span className="export-teams-container__text">
              <TextLink className="export-teams-container__link">
                {t('teams.exportCsvFile.listOfTeams')}
              </TextLink>{' '}
              {t('teams.exportCsvFile.listOfTeamsDescription')}
            </span>
          </div>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default ExportTeamsContainer;
