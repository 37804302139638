import { FileIcon, TrashIcon, WarningTriangleIcon } from '@pluralsight/icons';
import {
  FileUploadItem,
  FileUploadItemDeleteTrigger,
  FileUploadItemPreview,
  FlexContainer,
  IconButton,
} from '@pluralsight/react-ng';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import { formatFileSize } from '../../shared/utils';

import type { UploadItem } from '../../shared/interfaces';

const UploadedItem = ({
  file,
  onFileDeleteHandler,
  error = null,
}: UploadItem) => {
  const { t } = useTranslations();
  const errors: { [key: string]: string } = {
    FILE_INVALID_TYPE: 'importFile.fileFormatError',
    FILE_TOO_LARGE: 'importFile.maxSizeError',
    FILE_INVALID_COLUMNS: 'importFile.columnsError',
    FILE_TOO_MANY_ROWS: 'importFile.maxRowsError',
  };

  const statusIcon = () =>
    error?.length ? (
      <WarningTriangleIcon className="fieldset__description__warn-icon" />
    ) : (
      <FileIcon className="fieldset__description__file-icon" />
    );

  const preview = () => (
    <FlexContainer gap="var(--pando-gap-md)" align="center">
      {statusIcon()}
      <FlexContainer direction="col">
        <span>{(file as File)?.name}</span>
        {error && (
          <span className="fieldset__description--error">
            {t(errors[error as string]) || t('importFile.genericError')}
          </span>
        )}
        {!error && (
          <span className="fieldset__description__size">
            {formatFileSize((file as File)?.size)}
          </span>
        )}
      </FlexContainer>
    </FlexContainer>
  );

  const renderPreview = () =>
    error ? (
      preview()
    ) : (
      <FileUploadItemPreview type=".*">{preview()}</FileUploadItemPreview>
    );

  return (
    <FileUploadItem
      key={(file as File)?.name || (file as File[])[0]?.name}
      file={file}
      className="fieldset__description"
    >
      {renderPreview()}
      <FileUploadItemDeleteTrigger asChild>
        <IconButton
          className="fieldset__description__trash-icon"
          palette="danger"
          size="sm"
          usage="text"
          onClick={onFileDeleteHandler}
          ariaLabel={t('importFile.deleteUploadedFile')}
        >
          <TrashIcon />
        </IconButton>
      </FileUploadItemDeleteTrigger>
    </FileUploadItem>
  );
};

export default UploadedItem;
