// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.connection-list {
  gap: var(--pando-gap-md);
  height: 100%;
}
.connection-list__table__body {
  display: flex;
  flex-direction: column;
  gap: var(--pando-gap-md);
}`, "",{"version":3,"sources":["webpack://./src/features/sso/components/sso-conections/connection-list/connection-list.scss"],"names":[],"mappings":"AACA;EACE,wBAAA;EACA,YAAA;AAAF;AAEE;EACE,aAAA;EACA,sBAAA;EACA,wBAAA;AAAJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.connection-list {\n  gap: var(--pando-gap-md);\n  height: 100%;\n\n  &__table__body {\n    display: flex;\n    flex-direction: column;\n    gap: var(--pando-gap-md);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
