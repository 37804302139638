export const validateConnectionName = (value: string): string | undefined => {
  if (value.length > 128) {
    return 'sso.ssoConfiguration.connectionNameTooLong';
  }

  if (!/^[A-Za-z0-9_]*$/.test(value)) {
    return 'sso.ssoConfiguration.invalidConnectionNameCharacters';
  }

  return undefined;
};

export const validateUrl = (url: string): string | undefined => {
  if (url.length > 2048) {
    return 'sso.ssoConfiguration.urlTooLong';
  }

  if (!/^https?:\/\//.test(url)) {
    return 'sso.ssoConfiguration.invalidUrlProtocol';
  }

  try {
    const parsedUrl = new URL(url);

    // This regex matches a dot followed by 2 to 63 letters (common TLD pattern).
    if (!/\.[a-zA-Z]{2,63}$/.test(parsedUrl.hostname)) {
      return 'sso.ssoConfiguration.invalidDomainEnding';
    }
  } catch {
    return 'sso.ssoConfiguration.invalidUrl';
  }

  return undefined;
};
