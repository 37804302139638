const convertToCSV = <T extends Record<string, string | null>>(
  data: T[],
  columns: Map<string, string> | string[],
) => {
  const isMap = columns instanceof Map;
  const header: string[] = isMap ? Array.from(columns.values()) : columns;
  const keys: string[] = isMap ? Array.from(columns.keys()) : columns;

  const csvRows = data.map((row) =>
    keys
      .map((key: string) => {
        let cellValue = row[key] ?? '';

        cellValue = String(cellValue).replace(/"/g, '""');

        if (/[",\n]/.test(cellValue)) {
          cellValue = `"${cellValue}"`;
        }

        return cellValue;
      })
      .join(','),
  );

  return [header, ...csvRows].join('\n');
};

const downloadCSV = (csvContent: string, filename = 'data.csv') => {
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  const link = document.createElement('a');

  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

const generateCSV = <T extends Record<string, string | null>>(
  columns: Map<string, string> | string[],
  data: T[],
  filename?: string,
) => {
  const content = convertToCSV(data, columns);

  downloadCSV(content, filename);
};

export { generateCSV };
