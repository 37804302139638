import { useDispatch } from 'react-redux';

import { setDialogBackStep, setDialogNextStep } from './state/slice';

const useDialogStep = () => {
  const dispatch = useDispatch();

  return {
    setStepBack: () => dispatch(setDialogBackStep()),
    setNextStep: () => dispatch(setDialogNextStep()),
  };
};

export default useDialogStep;
