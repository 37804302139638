import { DangerDiamondFilledIcon } from '@pluralsight/icons';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseIconButton,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogHeading,
  FlexContainer,
} from '@pluralsight/react-ng';
import React, { forwardRef, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { selectDialogStep } from '../../shared/dialog/state/selectors';
import './custom-dialog.scss';

export type HeaderType = { [key: string]: React.ReactNode };
export type ContentType = { [key: string]: React.ReactNode };

interface IDialogProps {
  id: string;
  dialogHeader: HeaderType;
  dialogContent: ContentType;
  isWarning?: boolean;
  multipleSteps: number | boolean;
  actionButtons: (step: number) => React.ReactNode;
  closeModal: () => void;
}

const CustomDialog = forwardRef<HTMLDialogElement | null, IDialogProps>(
  (
    {
      id,
      dialogHeader,
      dialogContent,
      isWarning,
      multipleSteps = true,
      actionButtons,
      closeModal,
    },
    ref,
  ) => {
    const headingRef = useRef<HTMLDivElement | null>(null);
    const step = useSelector(selectDialogStep);

    useEffect(() => {
      if (headingRef.current) {
        headingRef.current.focus();
      }
    }, [step]);

    return (
      <AlertDialog
        ref={ref}
        headingId="dialog-header"
        bodyId="dialog-body"
        className="custom-dialog"
      >
        <AlertDialogCloseIconButton onClick={closeModal} />
        <FlexContainer
          className="custom-dialog__status-section"
          align="center"
          direction="row"
          justify="spaceBetween"
        >
          {multipleSteps ? (
            <p className="custom-dialog__status-section-step">{`${step}/${multipleSteps}`}</p>
          ) : (
            <></>
          )}
        </FlexContainer>
        <AlertDialogHeader ref={headingRef} tabIndex={-1}>
          <AlertDialogHeading>
            <FlexContainer direction="row" gap={10}>
              {isWarning && (
                <div className="custom-dialog__warning">
                  <DangerDiamondFilledIcon />
                </div>
              )}
              {dialogHeader[step]}
            </FlexContainer>
          </AlertDialogHeading>
        </AlertDialogHeader>
        <AlertDialogBody id={id}>{dialogContent[step]}</AlertDialogBody>
        <AlertDialogFooter>{actionButtons(step)}</AlertDialogFooter>
      </AlertDialog>
    );
  },
);

export default CustomDialog;
