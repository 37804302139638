import { PlusIcon } from '@pluralsight/icons';
import {
  Button,
  Caption,
  FieldMessage,
  FlexContainer,
  FormControlProvider,
  IconButton,
  Input,
  Label,
  Show,
  Table,
  TBody,
  TD,
  THead,
  TR,
} from '@pluralsight/react-ng';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import PeopleIcon from '../../../../assets/people.svg';
import Placeholder from '../../../../components/placeholder/placeholder';
import { EMAIL_PATTERN } from '../../../../shared/constants';
import {
  selectEmailsList,
  selectInvalidEmailsList,
} from '../../state/selectors';
import {
  addInvalidEmails,
  addValidEmails,
  clearEmails,
} from '../../state/slice';
import EmailsList from '../emails-list/emails-list';

import './email-step.scss';

const MAX_INVITATIONS = 250;

const EmailStep = () => {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  const emails = useSelector(selectEmailsList);
  const invalidEmails = useSelector(selectInvalidEmailsList);

  const [enteredEmails, setEnteredEmails] = useState('');
  const [isValidLength, setIsValidLength] = useState(true);
  const [duplicates, setDuplicates] = useState(false);
  const [remainingLength, setRemainingLength] = useState(0);
  const { t } = useTranslations();

  const DIVIDER = /[\s,]+/; // a regular expression that matches: any whitespace (\s), a comma (,), ora any combination of these (e.g., ", " or " ,")

  const emailsList = enteredEmails
    .split(DIVIDER)
    .filter((email) => email !== '');

  const validateEmails = () => {
    const validEmails: string[] = [];
    const invalidFormat: string[] = [];
    const emailsSet = new Set([...emails]);
    const uniqueElements = new Set<string>();

    let hasDuplicates = false;

    emailsList.forEach((email) => {
      if (EMAIL_PATTERN.test(email)) {
        validEmails.push(email);
      } else {
        invalidFormat.push(email);
      }
    });

    validEmails.forEach((item) => {
      if (uniqueElements.has(item) || emailsSet.has(item)) {
        hasDuplicates = true;
      } else {
        uniqueElements.add(item);
      }
    });

    return {
      hasDuplicates,
      uniqueEmails: Array.from(uniqueElements),
      invalidFormat,
    };
  };

  const totalEmailsLength = emails.length + emailsList.length;

  const handleAddEmails = () => {
    const { invalidFormat, uniqueEmails, hasDuplicates } = validateEmails();

    const emailsToAdd = uniqueEmails.slice(0, MAX_INVITATIONS - emails.length);

    setRemainingLength(uniqueEmails.length - emailsToAdd.length);
    setIsValidLength(totalEmailsLength <= MAX_INVITATIONS);
    setDuplicates(hasDuplicates);

    dispatch(addValidEmails(emailsToAdd));
    dispatch(addInvalidEmails(invalidFormat));
    setEnteredEmails('');

    setTimeout(() => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollTop = 0;
      }
    }, 0);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleAddEmails();
    }
  };

  const handleEmailsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredEmails(event.target.value);
  };

  const clearValidatedEmails = () => dispatch(clearEmails());

  const errorMessage = () => {
    if (!invalidEmails.length && duplicates) {
      return t('invitation.emailsStep.duplicates');
    }

    if (invalidEmails.length && !duplicates) {
      return t('invitation.emailsStep.format', {
        count: invalidEmails.length,
        max: MAX_INVITATIONS,
      });
    }

    if (invalidEmails.length && duplicates) {
      return [
        t('invitation.emailsStep.format', {
          count: invalidEmails.length,
          max: MAX_INVITATIONS,
        }),
        t('invitation.emailsStep.duplicates'),
      ].join(' ');
    }

    return '';
  };

  return (
    <FormControlProvider>
      <Label className="email-step__input-label" htmlFor="email">
        {t('invitation.dialogEmailStep.addUpEmails')}
      </Label>
      <FlexContainer
        className="email-step__input-container"
        align="center"
        direction="row"
        justify="spaceBetween"
        wrap="nowrap"
      >
        <Input
          id="email"
          name="email"
          placeholder={t('invitation.dialogEmailStep.enterEmails')}
          size="md"
          value={enteredEmails}
          onChange={handleEmailsChange}
          onKeyDown={handleKeyDown}
        />
        <IconButton
          ariaLabel="add-emails"
          className="email-step__add-emails-button"
          size="lg"
          palette={enteredEmails ? 'action' : 'neutral'}
          usage="text"
          disabled={!enteredEmails || emails.length >= MAX_INVITATIONS}
          onClick={handleAddEmails}
        >
          <PlusIcon />
        </IconButton>
      </FlexContainer>

      {!isValidLength && (
        <FieldMessage id="emails-limit" className="email-step__error-message">
          {t('invitation.emailsStep.limit', {
            count: remainingLength,
            max: MAX_INVITATIONS,
          })}
        </FieldMessage>
      )}

      <FieldMessage id="invalid-format" className="email-step__error-message">
        {errorMessage()}
      </FieldMessage>

      <div className="email-step__table-container">
        <Table hover="highlight" type="base">
          <Caption type="base" className="caption">
            {t('invitation.dialogEmailStep.invitedUsersTable')}
          </Caption>
          <THead hover="highlight" type="base">
            <TR header hover="highlight" type="base">
              <TD hover="highlight" type="base">
                {`${t('invitation.dialogEmailStep.emailsAdded')} (${
                  emails.length
                }/250)`}
              </TD>
              <TD type="base">
                <FlexContainer justify="end">
                  <Button
                    size="md"
                    palette={
                      emails.length || invalidEmails.length
                        ? 'action'
                        : 'neutral'
                    }
                    usage="text"
                    disabled={!emails.length && !invalidEmails.length}
                    onClick={clearValidatedEmails}
                  >
                    {t('invitation.dialogEmailStep.removeAll')}
                  </Button>
                </FlexContainer>
              </TD>
            </TR>
          </THead>

          <Show when={!!(emails.length || invalidEmails.length)}>
            <TBody hover="highlight" type="base" className="tb">
              <FlexContainer
                ref={scrollContainerRef}
                direction="col"
                align="stretch"
                className="emails-list__scroll"
              >
                <EmailsList isValid={false} emailsList={invalidEmails} />
                <EmailsList isValid emailsList={emails} />
              </FlexContainer>
            </TBody>
          </Show>
        </Table>
      </div>

      <Show when={!emails.length && !invalidEmails.length}>
        <FlexContainer className="email-step__placeholder-container">
          <Placeholder icon={PeopleIcon}>
            <span className="email-step__placeholder-message">
              {t('invitation.dialogEmailStep.noEmailsHaveBeenAdded')}
            </span>
          </Placeholder>
        </FlexContainer>
      </Show>
    </FormControlProvider>
  );
};

export default EmailStep;
