import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';
import type {
  ISsoConnection,
  ISsoConnectionId,
  ISsoConnectionsState,
} from './interfaces';
import type { IStatusPayload } from '../../sso-configuration/state/interfaces';

const INITIAL_STATE: ISsoConnectionsState = {
  connections: [],
  singleConnection: null,
  fetch: {
    loading: false,
    success: null,
    error: null,
  },
  delete: {
    loading: false,
    success: null,
    error: null,
  },
  singleFetch: {
    loading: false,
    success: null,
    error: null,
  },
};

const ssoConnectionsSlice = createSlice({
  name: 'ssoConnections',
  initialState: INITIAL_STATE,
  reducers: {
    fetchSsoConnections: (state, _action: PayloadAction<{ orgId: string }>) => {
      state.fetch = { loading: true, success: null, error: null };
    },
    fetchSsoConnectionsSuccess: (
      state,
      action: PayloadAction<ISsoConnection[]>,
    ) => {
      state.connections = action.payload;
      state.fetch = { loading: false, success: true, error: null };
    },
    fetchSsoConnectionsFailure: (state, action: PayloadAction<string>) => {
      state.fetch = { loading: false, success: false, error: action.payload };
    },
    resetSsoConnection: (state) => {
      state.singleConnection = INITIAL_STATE.singleConnection;
      state.singleFetch = { ...INITIAL_STATE.singleFetch };
    },
    fetchSsoConnection: (state, _action: PayloadAction<ISsoConnectionId>) => {
      state.singleFetch = { loading: true, success: null, error: null };
    },
    fetchSsoConnectionSuccess: (
      state,
      action: PayloadAction<ISsoConnection>,
    ) => {
      state.singleConnection = action.payload;
      state.singleFetch = { loading: false, success: true, error: null };
    },
    fetchSsoConnectionFailure: (state, action: PayloadAction<string>) => {
      state.singleFetch = {
        loading: false,
        success: false,
        error: action.payload,
      };
    },
    deleteSsoConnection: (state) => {
      state.delete = { loading: true, success: null, error: null };
    },
    deleteSsoConnectionSuccess: (
      state,
      action: PayloadAction<ISsoConnectionId>,
    ) => {
      state.connections = state.connections.filter(
        (connection) => connection.id !== action.payload.id,
      );
      state.delete = { loading: false, success: true, error: null };
    },
    deleteSsoConnectionFailure: (state, action: PayloadAction<string>) => {
      state.delete = { loading: false, success: false, error: action.payload };
    },
    resetDeleteSsoConnection: (state) => {
      state.delete = { ...INITIAL_STATE.delete };
    },
    updateSingleSsoConnection: (
      state,
      action: PayloadAction<IStatusPayload>,
    ) => {
      const index = state.connections.findIndex(
        (conn) => conn.id === action.payload.id,
      );

      if (index !== -1) {
        state.connections[index].status = action.payload.status;
      }
    },
  },
});

export const {
  fetchSsoConnections,
  fetchSsoConnectionsSuccess,
  fetchSsoConnectionsFailure,
  deleteSsoConnection,
  deleteSsoConnectionSuccess,
  deleteSsoConnectionFailure,
  fetchSsoConnection,
  fetchSsoConnectionSuccess,
  fetchSsoConnectionFailure,
  resetSsoConnection,
  resetDeleteSsoConnection,
  updateSingleSsoConnection,
} = ssoConnectionsSlice.actions;

export default ssoConnectionsSlice.reducer;
