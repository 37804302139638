import { FlexContainer } from '@pluralsight/react-ng';
import React from 'react';
import './placeholder.scss';

interface PlaceholderProps {
  icon: string;
  children: React.ReactNode;
}

const Placeholder: React.FC<PlaceholderProps> = ({ icon, children }) => {
  return (
    <FlexContainer
      className="placeholder__container"
      align="center"
      direction="col"
      justify="center"
    >
      <img
        className="placeholder__icon"
        src={icon}
        alt="People Icon"
        aria-hidden
      />
      <div className="placeholder__content">{children}</div>
    </FlexContainer>
  );
};

export default Placeholder;
