// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.existing-account__emails-container {
  margin-bottom: var(--pando-spacing-2);
}
.existing-account__warning-triangle {
  margin-right: var(--pando-spacing-2);
  color: var(--pando-inky-blue-warning-text-200-dark);
}`, "",{"version":3,"sources":["webpack://./src/features/invitation/components/existing-account-dialog/existing-account-dialog.scss"],"names":[],"mappings":"AAEE;EACE,qCAAA;AADJ;AAIE;EACE,oCAAA;EACA,mDAAA;AAFJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.existing-account {\n  &__emails-container {\n    margin-bottom: var(--pando-spacing-2);\n  }\n\n  &__warning-triangle {\n    margin-right: var(--pando-spacing-2);\n    color: var(--pando-inky-blue-warning-text-200-dark);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
