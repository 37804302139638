import type { Invite } from '../features/people/state/interfaces';
import type { Member } from './interfaces';
import type { AxiosInstance } from 'axios';

const PLANS_PATH = '/v1/plans';

export class PlansService {
  private httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  fetchPlanMembers = async (planId: string): Promise<Member[]> => {
    try {
      const response = await this.httpClient.get<Member[]>(
        `${PLANS_PATH}/${planId}/members`,
      );

      return response.data;
    } catch (error) {
      console.error(error);

      throw error;
    }
  };

  fetchPlanInvites = async (planId: string): Promise<Invite[]> => {
    try {
      const response = await this.httpClient.get<Invite[]>(
        `${PLANS_PATH}/${planId}/invites`,
      );

      return response.data;
    } catch (error) {
      console.error(error);

      throw error;
    }
  };

  updateMemberNote = async (
    id: string,
    planId: string,
    note: string,
  ): Promise<Member> => {
    try {
      const response = await this.httpClient.patch<Member>(
        `${PLANS_PATH}/${planId}/members/${id}`,
        { note },
      );

      return response.data;
    } catch (error) {
      console.error('Error updating member note:', error);

      throw error;
    }
  };

  updateInviteNote = async (
    id: string,
    planId: string,
    note: string,
  ): Promise<Member> => {
    try {
      const response = await this.httpClient.patch<Member>(
        `${PLANS_PATH}/${planId}/invites`,
        [{ id, note }],
      );

      return response.data;
    } catch (error) {
      console.error('Error updating invite note:', error);

      throw error;
    }
  };
}
