import React from 'react';
import { toLower } from 'lodash';
import { Button, FlexContainer } from '@pluralsight/react-ng';
import MediaQuery from 'react-responsive';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import {
  MID_DEVICE_BREAKPOINT_MIN_WIDTH,
  SMALL_DEVICE_BREAKPOINT_MAX_WIDTH,
} from '../../shared/constants';
import Subscriptions from '../subscriptions/subscriptions';
import AddOns from '../add-ons/add-ons';

import { useProductAssets } from './use-product-assets';

import type { ProductAsset } from '../../../products/state/interfaces';
import './product-card.scss';

interface ProductCardProps {
  product: ProductAsset;
  isLoading: boolean;
  onAddUsers: (productId: string) => void;
}

const ProductCard: React.FC<ProductCardProps> = ({
  product,
  isLoading: isLoadingProp,
  onAddUsers,
}) => {
  const { t } = useTranslations();
  const { productAssets, isLoading: isLoadingAssets } = useProductAssets(
    product.name,
  );

  const renderAddUsersButton = () => (
    <Button
      palette="neutral"
      usage="filled"
      size="lg"
      onClick={() => onAddUsers(product.name)}
      aria-label={t('planSettings.addUsers')}
    >
      {t('planSettings.addUsers')}
    </Button>
  );

  if (isLoadingProp || isLoadingAssets) {
    return (
      <div className="product-card product-card--loading">
        <div className="product-card__loading-message">
          {t('common.loading')}
        </div>
      </div>
    );
  }

  return (
    <div className="product-card">
      <FlexContainer
        justify="spaceBetween"
        align="flexEnd"
        className="product-card__header"
      >
        <FlexContainer
          direction="row"
          gap="var(--pando-spacing-4)"
          align="center"
          style={{ height: '39px' }}
          className="product-card__header__title"
        >
          {productAssets ? (
            <>
              <img
                src={productAssets.icon}
                alt={t('planSettings.products.details.logo.label', {
                  name: t(
                    `planSettings.products.assets.${toLower(product.name)}`,
                  ),
                })}
                title={t('planSettings.products.details.logo.text', {
                  name: t(
                    `planSettings.products.assets.${toLower(product.name)}`,
                  ),
                })}
                aria-label={t('planSettings.products.details.logo.label', {
                  name: t(
                    `planSettings.products.assets.${toLower(product.name)}`,
                  ),
                })}
                role="img"
              />

              <img
                src={productAssets.legend}
                alt={t('planSettings.products.details.title.label', {
                  name: t(
                    `planSettings.products.assets.${toLower(product.name)}`,
                  ),
                })}
                title={t('planSettings.products.details.title.text', {
                  name: t(
                    `planSettings.products.assets.${toLower(product.name)}`,
                  ),
                })}
                aria-label={t('planSettings.products.details.title.label', {
                  name: t(
                    `planSettings.products.assets.${toLower(product.name)}`,
                  ),
                })}
                role="img"
                className="product-card__header__legend"
              />
            </>
          ) : (
            <h3 className="product-card__header__fallback-title">
              {/* This is a fallback title for products that are not in current scope. Those have no icon or legend imgs. */}
              {t(`planSettings.products.assets.${toLower(product.name)}`, {
                defaultValue: product.name,
              })}
            </h3>
          )}
        </FlexContainer>
        <MediaQuery minWidth={MID_DEVICE_BREAKPOINT_MIN_WIDTH}>
          {renderAddUsersButton()}
        </MediaQuery>
      </FlexContainer>
      <FlexContainer
        direction="col"
        gap="var(--pando-spacing-6)"
        className="product-card__content"
      >
        {product.subscriptions.length > 0 && (
          <Subscriptions
            subscriptionData={product.subscriptions}
            isLoading={isLoadingProp}
          />
        )}
        <MediaQuery maxWidth={SMALL_DEVICE_BREAKPOINT_MAX_WIDTH}>
          {renderAddUsersButton()}
        </MediaQuery>
        {product.addOns.length > 0 && (
          <AddOns addOnData={product.addOns} isLoading={isLoadingProp} />
        )}
      </FlexContainer>
    </div>
  );
};

export default ProductCard;
