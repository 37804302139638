import { useCallback } from 'react';

import type { TableColumn } from '../../../shared/interfaces';

const useSearchFilter = () => {
  const deepSearch = useCallback(
    (value: string | string[] | object, searchText: string): boolean => {
      const searchLower = searchText.toLowerCase();

      if (typeof value !== 'object' || value === null) {
        return String(value).toLowerCase().includes(searchLower);
      }

      if (Array.isArray(value)) {
        return value.some((item) => deepSearch(item, searchText));
      }

      return Object.values(value).some((val) => deepSearch(val, searchText));
    },
    [],
  );

  const filterData = useCallback(
    <T extends object, K>(
      items: T[],
      term: string,
      columns: TableColumn<K>[],
    ): T[] => {
      if (!term || term.trim() === '') return items;

      return items.filter((item: T) =>
        Object.entries(item)
          .filter(
            ([key]) => columns.find((col) => col.mapsTo === key)?.searchColumn,
          )
          .some(([_, value]) => deepSearch(value, term)),
      );
    },
    [deepSearch],
  );

  return {
    deepSearch,
    filterData,
  };
};

export default useSearchFilter;
