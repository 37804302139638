import type { Language } from '../../../../translations/interfaces';
import type { HeaderType } from '../../../../components/custom-dialog/custom-dialog';

export const dialogHeader_en: HeaderType = {
  1: 'What is this team’s name?',
  2: 'Choose where this new team will live in your plan’s team hierarchy',
  3: '(Optional) Select users to add to this team.',
  4: 'Review and confirm your new team',
};

export const dialogHeader_es: HeaderType = {
  1: '¿Cómo se llama este equipo?',
  2: 'Elige dónde estará este nuevo equipo en la jerarquía de equipos de tu plan',
  3: '(Opcional) Selecciona usuarios para añadir a este equipo.',
  4: 'Revisa y confirma tu nuevo equipo',
};

export const dialogHeader_fr: HeaderType = {
  1: 'Quel est le nom de cette équipe?',
  2: "Choisissez où cette nouvelle équipe s'insérera dans la hiérarchie des équipes de votre plan",
  3: '(Facultatif) Sélectionnez des utilisateurs à ajouter à cette équipe.',
  4: 'Examinez et confirmez votre nouvelle équipe',
};

export const dialogHeader_pt: HeaderType = {
  1: 'Qual é o nome desta equipe?',
  2: 'Escolha onde essa nova equipe ficará na hierarquia de equipes do seu plano',
  3: '(Opcional) Selecione usuários para adicionar a esta equipe.',
  4: 'Revise e confirme sua nova equipe',
};

export const dialogHeader_ge: HeaderType = {
  1: 'Wie heißt dieses Team?',
  2: 'Wählen Sie aus, wo sich dieses neue Team in der Teamhierarchie Ihres Plans befindet',
  3: '(Optional) Wählen Sie Benutzer aus, die diesem Team hinzugefügt werden sollen.',
  4: 'Überprüfen und bestätigen Sie Ihr neues Team',
};

export const dialogHeader_jp: HeaderType = {
  1: 'このチームの名前は何ですか？',
  2: 'この新しいチームをプランのチーム階層のどこに配置するかを選択してください',
  3: '（オプション）このチームに追加するユーザーを選択してください。',
  4: '新しいチームを確認して確定してください',
};

export const translationsPerDialog: Record<Language, HeaderType> = {
  en: dialogHeader_en,
  es: dialogHeader_es,
  fr: dialogHeader_fr,
  ge: dialogHeader_ge,
  jp: dialogHeader_jp,
  pt: dialogHeader_pt,
};
