// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subscriptions {
  border-radius: var(--pando-radii-lg);
  width: 100%;
}
.subscriptions__header {
  margin-bottom: var(--pando-spacing-6);
}
.subscriptions__title {
  font-size: var(--pando-font-sizes-2xl, 24px);
  font-style: normal;
  font-weight: 600;
  line-height: 125%; /* 30px */
}`, "",{"version":3,"sources":["webpack://./src/features/plan-settings/components/subscriptions/subscriptions.scss"],"names":[],"mappings":"AACA;EACE,oCAAA;EACA,WAAA;AAAF;AAEE;EACE,qCAAA;AAAJ;AAGE;EACE,4CAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;AADJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.subscriptions {\n  border-radius: var(--pando-radii-lg);\n  width: 100%;\n\n  &__header {\n    margin-bottom: var(--pando-spacing-6);\n  }\n\n  &__title {\n    font-size: var(--pando-font-sizes-2xl, 24px);\n    font-style: normal;\n    font-weight: 600;\n    line-height: 125%; /* 30px */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
