import { createSelector } from 'reselect';

import type { AppState } from '../../../store';
import type { InvitationState } from './interfaces';

const selectAddUsersState = (state: AppState) => state.invitations;

export const selectEmailsList = createSelector(
  [selectAddUsersState],
  (invitations: InvitationState) => invitations.emails,
);

export const selectInvalidEmailsList = createSelector(
  [selectAddUsersState],
  (invitations: InvitationState) => invitations.invalidEmails,
);

export const selectRoles = createSelector(
  [selectAddUsersState],
  (invitations: InvitationState) => invitations.roleIds,
);

export const selectTeamIds = createSelector(
  [selectAddUsersState],
  (invitations: InvitationState) => invitations.teamIds,
);

export const selectAssignedProducts = createSelector(
  [selectAddUsersState],
  (invitations: InvitationState) => invitations.productIds,
);

export const selectSendEmailsLoading = createSelector(
  [selectAddUsersState],
  (invitations: InvitationState) => invitations.loading,
);

export const selectSendEmailsError = createSelector(
  [selectAddUsersState],
  (invitations: InvitationState) => invitations.error,
);

export const selectExistingAccountEmails = createSelector(
  [selectAddUsersState],
  (invitations: InvitationState) => invitations.existingAccountEmails,
);

export const selectActionConfirm = createSelector(
  [selectAddUsersState],
  (invitations: InvitationState) => invitations.actionComplete,
);
