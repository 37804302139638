import { Button, FlexContainer } from '@pluralsight/react-ng';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';
import './action-buttons.scss';

import { selectImportFile } from '../../state/selectors';
import { importUsersFile } from '../../state/slice';

interface ActionButtonsProps {
  closeModal: () => void;
}

const ImportStepActionButtons: React.FC<ActionButtonsProps> = ({
  closeModal,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslations();
  const importFile = useSelector(selectImportFile);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    setIsDisabled(!importFile?.importErrors || !importFile?.file);
  }, [importFile]);

  const importUsers = () => {
    if (importFile?.file) dispatch(importUsersFile({ file: importFile.file }));

    closeModal();
  };

  return (
    <FlexContainer justify="flexEnd">
      <FlexContainer
        align="center"
        direction="row"
        justify="flexEnd"
        wrap="nowrap"
      >
        <Button
          className="action-buttons__button"
          size="lg"
          palette="action"
          usage="text"
          onClick={closeModal}
        >
          {t('people.peopleContainer.cancel')}
        </Button>
        <Button
          size="lg"
          palette="action"
          usage="filled"
          onClick={importUsers}
          disabled={isDisabled}
        >
          {t('people.peopleContainer.import')}
        </Button>
      </FlexContainer>
    </FlexContainer>
  );
};

export default ImportStepActionButtons;
