import { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import { updateFormModifiedStatus } from '../../state/slice';

import type { FileUploadFileChangeDetails } from '@pluralsight/react-ng';

export const useFileChangeHandler = () => {
  const { t } = useTranslations();
  const dispatch = useDispatch();
  const isFirstCallRef = useRef(true);
  const { connectionId } = useParams();

  const isEditMode = !!connectionId;

  const handleFileChange = useCallback(
    (fileChangeDetails: FileUploadFileChangeDetails) => {
      const file = fileChangeDetails.acceptedFiles[0];

      if (!isEditMode) return;

      if (isFirstCallRef.current) {
        isFirstCallRef.current = false;

        return;
      }

      if (!file?.isDummy) {
        dispatch(updateFormModifiedStatus(true));
      }
    },
    [dispatch, t, isEditMode],
  );

  return {
    handleFileChange,
  };
};
