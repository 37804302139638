import React, {
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';

import './options-dropdown.scss';
import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

interface HeaderOptionProps {
  button: React.ReactNode;
  isOpen: boolean;
  options: React.ReactNode;
  setIsOpen: () => void;
}

export interface OptionsDropdownRef {
  onClose: () => void;
  onMoreActionsButtonFocus: () => void;
}

const OptionsDropdown = forwardRef<OptionsDropdownRef, HeaderOptionProps>(
  ({ button, isOpen, options, setIsOpen }, ref) => {
    const dropdownRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const { t } = useTranslations();

    const handleTogleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setIsOpen();
    };

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target as Node)
        ) {
          setIsOpen();
        }
      };

      if (isOpen) {
        document.addEventListener('mousedown', handleClickOutside);
      }

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [isOpen, setIsOpen]);

    useImperativeHandle(ref, () => ({
      onClose: () => {
        setIsOpen();
      },
      onMoreActionsButtonFocus: () => {
        buttonRef.current?.focus();
      },
    }));

    return (
      <div
        ref={dropdownRef}
        className={
          isOpen ? 'common-options-dropdown open' : 'common-options-dropdown'
        }
      >
        <button
          ref={buttonRef}
          aria-haspopup="true"
          aria-label={t('common.actionButtons.moreActions')}
          aria-expanded={isOpen}
          className="options-dropdown-button"
          onClick={handleTogleButtonClick}
        >
          {button}
        </button>
        <div className="options-dropdown-pane">{options}</div>
      </div>
    );
  },
);

export default OptionsDropdown;
