// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.highlight-container--highlighted {
  font-style: italic;
  color: var(--pando-colors-action-text-100);
}`, "",{"version":3,"sources":["webpack://./src/features/teams/components/text-highlight/text-highlight.scss"],"names":[],"mappings":"AAEE;EACE,kBAAA;EACA,0CAAA;AADJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.highlight-container {\n  &--highlighted {\n    font-style: italic;\n    color: var(--pando-colors-action-text-100);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
