// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.team-container {
  display: flex;
  flex-direction: column;
  padding: var(--pando-spacing-6, 1rem);
}
.team-container__header {
  align-items: center;
  display: flex;
  height: 44px;
  justify-content: space-between;
  margin-bottom: 18px;
}
.team-container__header-title {
  font-size: var(--pando-font-sizes-4xl);
  font-weight: 600;
}
.team-container__back-to-teams {
  align-items: center;
  color: #a499e8;
  display: flex;
  font-size: var(--pando-font-sizes-sm);
  font-weight: 500;
  margin-bottom: 24px;
}
.team-container__back-to-teams-icon {
  color: #a499e8;
  height: 16px;
  margin: var(--pando-spacing-2);
  width: 16px;
}
.team-container__tabs {
  flex-direction: column;
  margin: var(--pando-spacing-6) 0;
}`, "",{"version":3,"sources":["webpack://./src/features/team/components/team-container/team-container.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,sBAAA;EACA,qCAAA;AAAF;AAEE;EACE,mBAAA;EACA,aAAA;EACA,YAAA;EACA,8BAAA;EACA,mBAAA;AAAJ;AAEI;EACE,sCAAA;EACA,gBAAA;AAAN;AAIE;EACE,mBAAA;EACA,cAAA;EACA,aAAA;EACA,qCAAA;EACA,gBAAA;EACA,mBAAA;AAFJ;AAII;EACE,cAAA;EACA,YAAA;EACA,8BAAA;EACA,WAAA;AAFN;AAME;EACE,sBAAA;EACA,gCAAA;AAJJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.team-container {\n  display: flex;\n  flex-direction: column;\n  padding: var(--pando-spacing-6, 1rem);\n\n  &__header {\n    align-items: center;\n    display: flex;\n    height: 44px;\n    justify-content: space-between;\n    margin-bottom: 18px;\n\n    &-title {\n      font-size: var(--pando-font-sizes-4xl);\n      font-weight: 600;\n    }\n  }\n\n  &__back-to-teams {\n    align-items: center;\n    color: #a499e8;\n    display: flex;\n    font-size: var(--pando-font-sizes-sm);\n    font-weight: 500;\n    margin-bottom: 24px;\n\n    &-icon {\n      color: #a499e8;\n      height: 16px;\n      margin: var(--pando-spacing-2);\n      width: 16px;\n    }\n  }\n\n  &__tabs {\n    flex-direction: column;\n    margin: var(--pando-spacing-6) 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
