import React, { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Show } from '@pluralsight/react-ng';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import { selectRoles } from '../../state/selectors';
import CustomDialog from '../../../../components/custom-dialog/custom-dialog';
import ConfirmStepActionButtons from '../action-buttons/confirm-step-action-buttons';
import EmailStepActionButtons from '../action-buttons/email-step-action-buttons';
import RoleStepActionButtons from '../action-buttons/role-step-action-buttons';
import TeamStepActionButtons from '../action-buttons/team-step-action-buttons';
import LicensesStepActionButtons from '../action-buttons/licenses-step-action-buttons';
import AssignTeamStep from '../assign-team-step/assign-team-step';
import DialogReviewStep from '../confirm-step/confirm-step';
import EmailStep from '../email-step/email-step';
import { assignRole } from '../../state/slice';
import RoleStep from '../../../../components/role-step/role-step';
import LicensesStep from '../licenses-step/licenses-step';
import { selectAccess } from '../../../user/state/selectors';

import type {
  ContentType,
  HeaderType,
} from '../../../../components/custom-dialog/custom-dialog';

import './add-users-dialog.scss';

interface IAddUsersDialogProps {
  closeModal: () => void;
}

const AddUsersDialog = forwardRef<
  HTMLDialogElement | null,
  IAddUsersDialogProps
>(({ closeModal }, ref) => {
  const { t } = useTranslations();
  const dispatch = useDispatch();
  const assignedRoles = useSelector(selectRoles);
  const isReducedAccess = useSelector(selectAccess);

  const onOptionChanged = (value: string[]) => dispatch(assignRole(value));

  const dialogHeader: HeaderType = {
    1: t('invitation.emailsStep.header'),
    2: t('common.roleStep.header'),
    3: t('invitation.assignTeamStep.header'),
    4: t('invitation.licensesStep.header'),
    5: t('invitation.dialogConfirmStep.header'),
  };

  const reducedStepsDialogHeader: HeaderType = {
    1: t('invitation.emailsStep.header'),
    2: t('invitation.assignTeamStep.header'),
    3: t('invitation.licensesStep.header'),
    4: t('invitation.dialogConfirmStep.header'),
  };

  const dialogContent: ContentType = {
    1: <EmailStep />,
    2: (
      <RoleStep
        headerText={t('common.roleStep.selectedRolesLabel')}
        assignedRoles={assignedRoles}
        optionSelected={onOptionChanged}
      />
    ),
    3: <AssignTeamStep />,
    4: <LicensesStep />,
    5: <DialogReviewStep />,
  };

  const reducedStepsDialogContent: ContentType = {
    1: <EmailStep />,
    2: <AssignTeamStep />,
    3: <LicensesStep />,
    4: <DialogReviewStep />,
  };

  const getActionButtons = (step: number): React.JSX.Element => {
    switch (step) {
      case 1:
        return <EmailStepActionButtons closeModal={closeModal} />;
      case 2:
        return <RoleStepActionButtons closeModal={closeModal} />;
      case 3:
        return <TeamStepActionButtons closeModal={closeModal} />;
      case 4:
        return <LicensesStepActionButtons closeModal={closeModal} />;
      case 5:
        return <ConfirmStepActionButtons closeModal={closeModal} />;

      default:
        return <></>;
    }
  };

  const getReducedStepsActionButtons = (step: number): React.JSX.Element => {
    switch (step) {
      case 1:
        return <EmailStepActionButtons closeModal={closeModal} />;
      case 2:
        return <TeamStepActionButtons closeModal={closeModal} />;
      case 3:
        return <LicensesStepActionButtons closeModal={closeModal} />;
      case 4:
        return <ConfirmStepActionButtons closeModal={closeModal} />;

      default:
        return <></>;
    }
  };

  return (
    <Show
      when={isReducedAccess}
      children={
        <CustomDialog
          id="add-users" /* id impacts nested tooltips, should be changed together */
          ref={ref}
          multipleSteps={4}
          dialogHeader={reducedStepsDialogHeader}
          dialogContent={reducedStepsDialogContent}
          actionButtons={getReducedStepsActionButtons}
          closeModal={closeModal}
        />
      }
      fallback={
        <CustomDialog
          id="add-users" /* id impacts nested tooltips, should be changed together */
          ref={ref}
          multipleSteps={5}
          dialogHeader={dialogHeader}
          dialogContent={dialogContent}
          actionButtons={getActionButtons}
          closeModal={closeModal}
        />
      }
    />
  );
});

export default AddUsersDialog;
