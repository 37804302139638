import { FlexContainer } from '@pluralsight/react-ng';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import magnifier from '../../../../assets/images/magnify.png';
import './no-results.scss';

const NoResults = () => {
  const { t } = useTranslations();

  return (
    <FlexContainer className="no-search-results-container" gap={24}>
      <img
        className="no-search-results-container__image"
        src={magnifier}
        alt="Magnify Icon"
        role="presentation"
        aria-hidden
      />
      <h3 className="no-search-results-container__title">
        {t('team.noResults')}
      </h3>
    </FlexContainer>
  );
};

export default NoResults;
