import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { IPlan, IPlanState } from './interfaces';

const INITIAL_STATE: IPlanState = {
  plan: {} as IPlan,
  fetch: {
    loading: false,
    success: null,
    error: null,
  },
};

const planSlice = createSlice({
  name: 'Plans',
  initialState: INITIAL_STATE,
  reducers: {
    fetchPlan: (state, _action: PayloadAction<string>) => {
      state.fetch = { loading: true, success: null, error: null };
    },
    fetchPlanSuccess: (state, action: PayloadAction<IPlan>) => {
      state.plan = action.payload;
      state.fetch = { loading: false, success: true, error: null };
    },
    fetchPlanFailure: (state, action: PayloadAction<string>) => {
      state.fetch = { loading: false, success: false, error: action.payload };
    },
  },
});

export const { fetchPlan, fetchPlanSuccess, fetchPlanFailure } =
  planSlice.actions;

export default planSlice.reducer;
