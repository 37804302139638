import { ArrowLeftIcon } from '@pluralsight/icons';
import {
  TabContent,
  TabsList,
  TabsRoot,
  TabTrigger,
  useToast,
} from '@pluralsight/react-ng';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import RoleRestrictedComponent from '../../../../components/role-restricted-component/role-restricted-component';
import { USER_ROLE } from '../../../../shared/constants';
import TeamPendingInvitesContainer from '../../../team-pending-invites/components/team-pending-invites-container/team-pending-invites-container';
import TeamManagersContainer from '../../../team-users/components/team-managers-container/team-managers-container';
import TeamUsersContainer from '../../../team-users/components/team-users-container/team-users-container';
import { selectToast } from '../../../toast/state/selectors';
import { selectUser } from '../../../user/state/selectors';
import { fetchTeam } from '../../state/slice';
import TeamName from '../team-name/team-name';

import type { ExpectedRole } from '../../../../shared/interfaces';

import './team-container.scss';

const TeamsContainer: React.FC = () => {
  const { show } = useToast();
  const { t } = useTranslations();

  const dispatch = useDispatch();
  const params = useParams();

  const toast = useSelector(selectToast);
  const user = useSelector(selectUser);

  const teamId = (params.teamId as unknown as string) || '';

  useEffect(() => {
    if (teamId) {
      dispatch(fetchTeam(teamId as any));
    }
  }, [dispatch, teamId, user]);

  useEffect(() => {
    if (toast) {
      show({
        heading: toast.heading,
        palette: toast.palette,
        text: toast.text,
        onClose: () => {},
      });
    }
  }, [show, toast]);

  const setTeamManagersRestrictedTo: ExpectedRole[] = [
    {
      roleName: USER_ROLE.SUPER_ADMIN,
      scope: 'organizationScope',
    },
    {
      roleName: USER_ROLE.SYSTEM_ADMIN,
      scope: 'organizationScope',
    },
    {
      roleName: USER_ROLE.PLAN_ADMIN,
      scope: 'planScope',
    },
  ];

  return (
    <div className="team-container">
      <div className="team-container__header">
        <h1 className="team-container__header-title">{t('team.teamsLabel')}</h1>
      </div>
      <Link to="/teams" className="team-container__back-to-teams">
        <ArrowLeftIcon className="team-container__back-to-teams-icon" />
        <span>{t('team.backToTeamsList')}</span>
      </Link>
      <TeamName id={teamId} />
      <TabsRoot defaultValue="users">
        <TabsList>
          <TabTrigger value="users" id="users-tab" tabIndex={0}>
            {t('team.usersLabel')}
          </TabTrigger>
          <RoleRestrictedComponent restrictedTo={setTeamManagersRestrictedTo}>
            <TabTrigger value="managers" id="managers-tab" tabIndex={0}>
              {t('team.managersLabel')}
            </TabTrigger>
          </RoleRestrictedComponent>
          <TabTrigger
            value="pending-invites"
            id="pending-invites-tab"
            tabIndex={0}
          >
            {t('team.pendingInvitesLabel')}
          </TabTrigger>
        </TabsList>
        <TabContent aria-labelledby="users-tab" value="users">
          <TeamUsersContainer teamId={teamId} />
        </TabContent>
        <RoleRestrictedComponent restrictedTo={setTeamManagersRestrictedTo}>
          <TabContent aria-labelledby="managers-tab" value="managers">
            <TeamManagersContainer teamId={teamId} />
          </TabContent>
        </RoleRestrictedComponent>
        <TabContent
          aria-labelledby="pending-invites-tab"
          value="pending-invites"
        >
          <TeamPendingInvitesContainer teamId={teamId} />
        </TabContent>
      </TabsRoot>
    </div>
  );
};

export default TeamsContainer;
