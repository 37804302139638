import type { Language } from '../../translations/interfaces';

export interface Widget {
  title: string;
  description: string;
  btnText?: string;
  linkText?: string;
  content?: {
    title: string;
    description: string;
    channels: string;
  }[];
}

export const widgets_en: Widget[] = [
  {
    title: 'Company Widget',
    description:
      'Your company learning is ready for you. Check our your company’s channels to get started.',
    btnText: 'View Company Channels',
  },
  {
    title: 'Priorities for Companyname',
    description: 'Focus on the skills that matter most to your organization.',
    linkText: 'View all',
    content: [
      {
        title: 'Priority 1 of 9',
        description: 'Agile ways of working',
        channels: '2 Channels',
      },
      {
        title: 'Priority 2 of 9',
        description:
          'Data science & environmental responsibility at Companyname',
        channels: '2 Channels',
      },
    ],
  },
];

export const widgets_es: Widget[] = [
  {
    title: 'Widget de la Empresa',
    description:
      'El aprendizaje de tu empresa está listo para ti. Consulta los canales de tu empresa para comenzar.',
    btnText: 'Ver canales de la empresa',
  },
  {
    title: 'Prioridades para Companyname',
    description:
      'Concéntrate en las habilidades que más importan a tu organización.',
    linkText: 'Ver todo',
    content: [
      {
        title: 'Prioridad 1 de 9',
        description: 'Formas ágiles de trabajar',
        channels: '2 canales',
      },
      {
        title: 'Prioridad 2 de 9',
        description:
          'Ciencia de datos y responsabilidad ambiental en Companyname',
        channels: '2 canales',
      },
    ],
  },
];

export const widgets_fr: Widget[] = [
  {
    title: "Widget de l'entreprise",
    description:
      "L'apprentissage de votre entreprise est prêt pour vous. Consultez les chaînes de votre entreprise pour commencer.",
    btnText: "Voir les chaînes de l'entreprise",
  },
  {
    title: 'Priorités pour Companyname',
    description:
      'Concentrez-vous sur les compétences les plus importantes pour votre organisation.',
    linkText: 'Voir tout',
    content: [
      {
        title: 'Priorité 1 sur 9',
        description: 'Méthodes agiles de travail',
        channels: '2 chaînes',
      },
      {
        title: 'Priorité 2 sur 9',
        description:
          'Science des données et responsabilité environnementale chez Companyname',
        channels: '2 chaînes',
      },
    ],
  },
];

export const widgets_jp: Widget[] = [
  {
    title: '会社ウィジェット',
    description:
      '会社の学習が利用可能です。会社のチャンネルを確認して始めてみましょう。',
    btnText: '会社のチャンネルを見る',
  },
  {
    title: 'Companyname の優先事項',
    description: '組織にとって最も重要なスキルに集中しましょう。',
    linkText: 'すべて見る',
    content: [
      {
        title: '優先事項 1/9',
        description: 'アジャイルな働き方',
        channels: '2 チャンネル',
      },
      {
        title: '優先事項 2/9',
        description: 'Companyname におけるデータサイエンスと環境責任',
        channels: '2 チャンネル',
      },
    ],
  },
];

export const widgets_ge: Widget[] = [
  {
    title: 'Unternehmens-Widget',
    description:
      'Das Lernen in Ihrem Unternehmen steht für Sie bereit. Sehen Sie sich die Kanäle Ihres Unternehmens an, um loszulegen.',
    btnText: 'Unternehmenskanäle anzeigen',
  },
  {
    title: 'Prioritäten für Companyname',
    description:
      'Konzentrieren Sie sich auf die Fähigkeiten, die für Ihre Organisation am wichtigsten sind.',
    linkText: 'Alle ansehen',
    content: [
      {
        title: 'Priorität 1 von 9',
        description: 'Agile Arbeitsweisen',
        channels: '2 Kanäle',
      },
      {
        title: 'Priorität 2 von 9',
        description:
          'Datenwissenschaft und Umweltverantwortung bei Companyname',
        channels: '2 Kanäle',
      },
    ],
  },
];

export const widgets_pt: Widget[] = [
  {
    title: 'Widget da Empresa',
    description:
      'O aprendizado da sua empresa está pronto para você. Verifique os canais da sua empresa para começar.',
    btnText: 'Ver Canais da Empresa',
  },
  {
    title: 'Prioridades para Companyname',
    description:
      'Concentre-se nas habilidades mais importantes para a sua organização.',
    linkText: 'Ver tudo',
    content: [
      {
        title: 'Prioridade 1 de 9',
        description: 'Métodos ágeis de trabalho',
        channels: '2 canais',
      },
      {
        title: 'Prioridade 2 de 9',
        description:
          'Ciência de dados e responsabilidade ambiental na Companyname',
        channels: '2 canais',
      },
    ],
  },
];

export const widgetsByLanguage: Record<Language, Widget[]> = {
  en: widgets_en,
  es: widgets_es,
  fr: widgets_fr,
  jp: widgets_jp,
  ge: widgets_ge,
  pt: widgets_pt,
};
