import { FlexContainer, Show } from '@pluralsight/react-ng';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import PeopleIcon from '../../../../assets/people.svg';
import InvitesTable from '../../../../components/invites-table/invites-table';
import Placeholder from '../../../../components/placeholder/placeholder';
import RoleRestrictedComponent from '../../../../components/role-restricted-component/role-restricted-component';
import { handleMenuKeyBoardNavigation } from '../../../../../src/shared/helpers/handle-menu-keyboard-navigation';
import { USER_ROLE } from '../../../../shared/constants';
import { selectUser } from '../../../user/state/selectors';
import { selectPendingInvitesList } from '../../state/selectors';
import { fetchInvites } from '../../state/slice';
import { NOTE_TYPE_ENUM } from '../../state/interfaces';
import UpdateNoteDialog from '../../../team-users/components/update-note-dialog/update-note-dialog';
import { setDialogStep } from '../../../../shared/dialog/state/slice';

import type { KeyBoardActions } from '../../../../../src/shared/helpers/handle-menu-keyboard-navigation';
import type { Invite } from '../../state/interfaces';

import './invites-table-container.scss';

interface InvitesTableContainerProps {
  openDialog: () => void;
  openRevokeDialog: (invite?: Invite) => void;
  openResendDialog: (invite?: Invite) => void;
}

const InvitesTableContainer: React.FC<InvitesTableContainerProps> = ({
  openDialog,
  openRevokeDialog = () => {},
  openResendDialog = () => {},
}) => {
  const [noteUser, setNoteUser] = useState<Invite | null>(null);
  const updateNoteDialogRef = useRef<HTMLDialogElement>(null);

  const { t } = useTranslations();
  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  const invites = useSelector(selectPendingInvitesList);

  useEffect(() => {
    dispatch(fetchInvites());
  }, [dispatch, user]);

  const handleResendInvite = (invite?: Invite) => {
    openResendDialog(invite);
  };

  const handleRevokeInvite = (invite?: Invite) => {
    openRevokeDialog(invite);
  };

  const showUpdateNoteDialog = (invite: Invite) => {
    setNoteUser(invite);
    dispatch(setDialogStep(1));
    updateNoteDialogRef.current?.showModal();
  };

  const onNoteModalClose = () => {
    setNoteUser(null);
    dispatch(setDialogStep(0));
    updateNoteDialogRef.current?.close();
  };

  const renderDropdownOptions = useCallback(
    (invite: Invite, actions: KeyBoardActions) => {
      return (
        <div
          role="menu"
          onKeyDown={handleMenuKeyBoardNavigation(
            'invite-dropdown-option',
            actions,
          )}
          tabIndex={0}
        >
          <div
            className="invite-dropdown-option"
            onClick={() => showUpdateNoteDialog(invite)}
            role="menuItem"
            tabIndex={0}
            ref={(el) => el?.focus()}
          >
            <span className="dropdown-option-span">
              {t('teamUsers.tableOptions.editNote')}
            </span>
          </div>
          <RoleRestrictedComponent
            restrictedTo={[
              {
                roleName: USER_ROLE.SUPER_ADMIN,
                scope: 'organizationScope',
              },
              {
                roleName: USER_ROLE.SUPER_ADMIN,
                scope: 'planScope',
              },
              {
                roleName: USER_ROLE.SYSTEM_ADMIN,
                scope: 'organizationScope',
              },
              {
                roleName: USER_ROLE.PLAN_ADMIN,
                scope: 'planScope',
              },
              {
                roleName: USER_ROLE.MANAGER_FULL,
                scope: 'planScope',
              },
            ]}
          >
            <div
              className="invite-dropdown-option"
              onClick={() => handleResendInvite(invite)}
              role="menuItem"
              tabIndex={0}
            >
              <span className="dropdown-option-span">
                {t('invitesTable.optionsDropdown.resendInvite')}
              </span>
              <span className="dropdown-option-description">
                {t('invitesTable.optionsDropdown.sentAnotherInvite')}
              </span>
            </div>
            <div
              className="invite-dropdown-option"
              onClick={() => handleRevokeInvite(invite)}
              role="menuItem"
              tabIndex={0}
            >
              <span className="dropdown-option-span">
                {t('invitesTable.optionsDropdown.deleteInvite')}
              </span>
              <span className="dropdown-option-description">
                {t('invitesTable.optionsDropdown.revokeTheAssignedLicense')}
              </span>
            </div>
          </RoleRestrictedComponent>
        </div>
      );
    },
    [],
  );

  return (
    <Show
      when={!!invites.length}
      fallback={
        <div className="users-table-container__placeholder">
          <Placeholder icon={PeopleIcon}>
            <div className="users-table-container__placeholder-message">
              {t('people.invitesTableContainer.looksLikeLabel')}
            </div>
            <FlexContainer justify="center">
              <RoleRestrictedComponent
                restrictedTo={[
                  {
                    roleName: USER_ROLE.SUPER_ADMIN,
                    scope: 'organizationScope',
                  },
                  {
                    roleName: USER_ROLE.SYSTEM_ADMIN,
                    scope: 'organizationScope',
                  },
                  {
                    roleName: USER_ROLE.PLAN_ADMIN,
                    scope: 'planScope',
                  },
                  {
                    roleName: USER_ROLE.MANAGER_FULL,
                    scope: 'planScope',
                  },
                ]}
              >
                <button
                  className="users-table-container__placeholder-button"
                  onClick={openDialog}
                >
                  {t('people.invitesTableContainer.addUsers')}
                </button>
              </RoleRestrictedComponent>
            </FlexContainer>
          </Placeholder>
        </div>
      }
    >
      <InvitesTable invites={invites} dropDownOptions={renderDropdownOptions} />
      <UpdateNoteDialog
        ref={updateNoteDialogRef}
        noteUser={noteUser as Invite}
        closeModal={onNoteModalClose}
        type={NOTE_TYPE_ENUM.INVITE}
      />
    </Show>
  );
};

export default InvitesTableContainer;
