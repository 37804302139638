import React, { useEffect, useRef, useState } from 'react';
import { SearchIcon } from '@pluralsight/icons';
import {
  FlexContainer,
  FormControlProvider,
  Input,
  Label,
  Show,
} from '@pluralsight/react-ng';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import PeopleIcon from '../../assets/people.svg';
import './people-select.scss';
import { selectTeam } from '../../features/create-team/state/selectors';
import {
  selectPendingInvitesList,
  selectPeopleList,
} from '../../features/people/state/selectors';
import { selectTeamPendingInvitesList } from '../../features/team-pending-invites/state/selectors';
import { fetchInvites, fetchPeople } from '../../features/people/state/slice';
import { selectTeamUsersList } from '../../features/team-users/state/selectors';
import UserSearchResult from '../people-search-result/people-search-result';
import Placeholder from '../placeholder/placeholder';
import UsersList from '../users-list/users-list';

import type { MethodsExport } from '../people-search-result/people-search-result';
import type { Member } from '../../shared/interfaces';
import type { FC } from 'react';

interface IPeopleSelectProps {
  isManagersFlow?: boolean;
  title: string;
  placeholder: string;
  hint?: string;
}

const PeopleSelect: FC<IPeopleSelectProps> = ({
  isManagersFlow = false,
  title,
  placeholder,
  hint = '',
}) => {
  const { t } = useTranslations();
  const dispatch = useDispatch();
  const users = useSelector(selectPeopleList);
  const pendingInvites = useSelector(selectPendingInvitesList);
  const teamPendingInvites = useSelector(selectTeamPendingInvitesList);
  const teamUsers: Member[] = useSelector(selectTeamUsersList);
  const { assignedMembers } = useSelector(selectTeam);

  const [searchUsers, setSearchUsers] = useState('');
  const childRef = useRef<MethodsExport | null>(null);

  useEffect(() => {
    dispatch(fetchPeople());
    dispatch(fetchInvites());
  }, [dispatch]);

  const cleanSearch = () => setSearchUsers('');

  const teamMemberIds = new Set(teamUsers.map((member) => member.id));
  const teamInviteMemberIds = new Set(
    teamPendingInvites.map((invite) => invite.psUserId),
  );

  const assignedMembersIds = new Set(
    assignedMembers.map((member) => member.id),
  );

  const usersList = users
    .filter((user) => !teamMemberIds.has(user.id))
    .filter((user) => !assignedMembersIds.has(user.id));

  const invitesList = pendingInvites
    .filter((invite) => !teamInviteMemberIds.has(invite.psUserId))
    .filter((invite) => !assignedMembersIds.has(invite.psUserId));

  const filteredUsers = usersList.filter(
    (user) =>
      user.username?.toLowerCase().includes(searchUsers?.toLowerCase()) ||
      user.email?.toLowerCase().includes(searchUsers?.toLowerCase()) ||
      user.firstName?.toLowerCase().includes(searchUsers?.toLowerCase()) ||
      user.lastName?.toLowerCase().includes(searchUsers?.toLowerCase()),
  );

  const filteredInvites = invitesList.filter((invite) =>
    invite.email.toLowerCase().includes(searchUsers.toLowerCase()),
  );

  const emptySearchInput = searchUsers.length === 0;
  const notFoundSearchResult =
    filteredUsers.length === 0 && filteredInvites.length === 0;

  const selectRecord = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'ArrowDown' && childRef.current) {
      childRef.current.focusFirstUser();
    }
  };

  const assignedUsersContent = (
    <Show
      when={assignedMembers.length > 0}
      children={<UsersList isManagersFlow={isManagersFlow} />}
      fallback={
        <FlexContainer
          className={`people-select__placeholder-container ${
            assignedMembers.length === 0 && 'empty-search'
          }`}
        >
          <Placeholder icon={PeopleIcon}>
            <div className="people-select__placeholder-message">
              {placeholder}
            </div>
            <div className="people-select__hint-message">{hint}</div>
          </Placeholder>
        </FlexContainer>
      }
    />
  );

  const renderContent = () => {
    switch (true) {
      case emptySearchInput:
        return assignedUsersContent;

      case notFoundSearchResult:
        return (
          <div className="people-select__scroll">
            <div className="people-select__no-result-message">
              {t('peopleSelect.noResultsFound')}
            </div>
            {assignedUsersContent}
          </div>
        );

      default:
        return (
          <UserSearchResult
            isAddingManagers={isManagersFlow}
            users={filteredUsers}
            filteredInvites={filteredInvites}
            cleanSearch={cleanSearch}
            ref={childRef}
          />
        );
    }
  };

  return (
    <FlexContainer direction="col" className="people-select">
      <FormControlProvider>
        <Label htmlFor="search-users" className="people-select__input-label">
          {title}
        </Label>
        <Input
          name="search-users"
          value={searchUsers}
          placeholder={t('peopleSelect.searchUsers')}
          startIcon={<SearchIcon aria-hidden height="1.5rem" width="1.5rem" />}
          onChange={(e) => setSearchUsers(e.target.value)}
          onKeyDown={(event) => selectRecord(event)}
        />
      </FormControlProvider>

      {renderContent()}
    </FlexContainer>
  );
};

export default PeopleSelect;
