// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.assigned-user__confirmation {
  margin: var(--pando-spacing-2) 0;
}
.assigned-user__confirmation img {
  margin-right: var(--pando-spacing-3);
}
.assigned-user__active {
  color: var(--pando-colors-neutral-text-300);
  /* Text/m */
  font-family: var(--font-family-body, "PS TT Commons");
  font-size: var(--font-size-md, 16px);
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
}
.assigned-user__pending {
  color: var(--pando-colors-neutral-text-200);
  /* Text/m */
  font-family: var(--font-family-body, "PS TT Commons");
  font-size: var(--font-size-md, 16px);
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
}
.assigned-user__email {
  color: var(--pando-colors-neutral-text-200);
  /* Text/s */
  font-family: var(--font-family-body, "PS TT Commons");
  font-size: var(--font-size-sm, 14px);
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}`, "",{"version":3,"sources":["webpack://./src/features/create-team/components/assigned-user-row/assigned-user.scss"],"names":[],"mappings":"AAEE;EACE,gCAAA;AADJ;AAGI;EACE,oCAAA;AADN;AAKE;EACE,2CAAA;EAEA,WAAA;EACA,qDAAA;EACA,oCAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;AAJJ;AAOE;EACE,2CAAA;EAEA,WAAA;EACA,qDAAA;EACA,oCAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;AANJ;AASE;EACE,2CAAA;EAEA,WAAA;EACA,qDAAA;EACA,oCAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AARJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.assigned-user {\n  &__confirmation {\n    margin: var(--pando-spacing-2) 0;\n\n    img {\n      margin-right: var(--pando-spacing-3);\n    }\n  }\n\n  &__active {\n    color: var(--pando-colors-neutral-text-300);\n\n    /* Text/m */\n    font-family: var(--font-family-body, \"PS TT Commons\");\n    font-size: var(--font-size-md, 16px);\n    font-style: normal;\n    font-weight: 500;\n    line-height: 150%; /* 24px */\n  }\n\n  &__pending {\n    color: var(--pando-colors-neutral-text-200);\n\n    /* Text/m */\n    font-family: var(--font-family-body, \"PS TT Commons\");\n    font-size: var(--font-size-md, 16px);\n    font-style: normal;\n    font-weight: 500;\n    line-height: 150%; /* 24px */\n  }\n\n  &__email {\n    color: var(--pando-colors-neutral-text-200);\n\n    /* Text/s */\n    font-family: var(--font-family-body, \"PS TT Commons\");\n    font-size: var(--font-size-sm, 14px);\n    font-style: normal;\n    font-weight: 500;\n    line-height: 150%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
