import type { AxiosInstance } from 'axios';
import type {
  ImportUploadUrlResponse,
  Invite,
  UpdateRolePayload,
  UsersResponseItem,
} from './interfaces';

const PLANS_PATH = '/v1/plans';

export class PeopleService {
  private httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  fetchPeople = async (planId: string): Promise<UsersResponseItem[]> => {
    try {
      // TODO (ferney-pena): Implement Api response type
      const response = await this.httpClient.get<UsersResponseItem[]>(
        `${PLANS_PATH}/${planId}/members`,
      );

      return response.data;
    } catch (error) {
      console.error('Error fetching people:', error);

      throw error;
    }
  };

  fetchInvites = async (planId: string): Promise<Invite[]> => {
    try {
      // TODO (ferney-pena): Implement Api response type
      const response = await this.httpClient.get<Invite[]>(
        `${PLANS_PATH}/${planId}/invites`,
      );

      return response.data;
    } catch (error) {
      console.error('Error fetching invites:', error);

      throw error;
    }
  };

  fetchUploadImporUserstUrl = async (
    planId: string,
  ): Promise<ImportUploadUrlResponse> => {
    try {
      const response = await this.httpClient.get(
        `${PLANS_PATH}/${planId}/members/upload/url`,
      );

      return response.data;
    } catch (error) {
      console.error('Error getting upload URL', error);

      throw error;
    }
  };

  uploadImportUsersFile = async (
    url: string,
    file: File,
  ): Promise<{ id: string }> => {
    try {
      const response = await this.httpClient.put(url, file, {
        headers: { 'Content-Type': file.type },
        transformRequest: [
          (data, headers) => {
            delete headers.Authorization;

            return data;
          },
        ],
      });

      return response.data;
    } catch (error) {
      console.error('Error getting upload URL', error);

      throw error;
    }
  };

  updateUserRoles = async (planId: string, data: UpdateRolePayload) => {
    try {
      const response = await this.httpClient.post<UsersResponseItem[]>(
        `${PLANS_PATH}/${planId}/members/roles`,
        data,
      );

      return response.data;
    } catch (error) {
      console.error('Error updating user roles:', error);

      throw error;
    }
  };

  deleteUser = async (planId: string, psUserId: string) => {
    try {
      await this.httpClient.delete(
        `${PLANS_PATH}/${planId}/members/${psUserId}`,
      );
    } catch (error) {
      console.error('Error deleting user from plan', error);

      throw error;
    }
  };
}
