import axios from 'axios';

import type { AxiosInstance, InternalAxiosRequestConfig } from 'axios';

const createHttpClient = (): AxiosInstance => {
  const httpClient = axios.create();

  httpClient.interceptors.request.use(
    (
      config: InternalAxiosRequestConfig,
    ): InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig> => {
      return { ...config, withCredentials: true };
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  return httpClient;
};

export default createHttpClient();
