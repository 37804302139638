// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.team-name {
  margin-bottom: var(--pando-spacing-2);
}
.team-name__name {
  font-size: var(--pando-font-sizes-3xl);
  font-weight: 600;
  line-height: 125%;
}
.team-name__icon {
  background-color: transparent;
  cursor: pointer;
  margin: 0 var(--pando-spacing-1);
  outline: none;
}
.team-name__icon:hover {
  background-color: var(--pando-inky-blue-action-background-100-hover-dark);
}`, "",{"version":3,"sources":["webpack://./src/features/team/components/team-name/team-name.scss"],"names":[],"mappings":"AACA;EACE,qCAAA;AAAF;AAEE;EACE,sCAAA;EACA,gBAAA;EACA,iBAAA;AAAJ;AAGE;EACE,6BAAA;EACA,eAAA;EACA,gCAAA;EACA,aAAA;AADJ;AAGI;EACE,yEAAA;AADN","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.team-name {\n  margin-bottom: var(--pando-spacing-2);\n\n  &__name {\n    font-size: var(--pando-font-sizes-3xl);\n    font-weight: 600;\n    line-height: 125%;\n  }\n\n  &__icon {\n    background-color: transparent;\n    cursor: pointer;\n    margin: 0 var(--pando-spacing-1);\n    outline: none;\n\n    &:hover {\n      background-color: var(--pando-inky-blue-action-background-100-hover-dark);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
