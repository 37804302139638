import { TrashIcon } from '@pluralsight/icons';
import { IconButton, usePrompt, useToast } from '@pluralsight/react-ng';
import { useDispatch } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import { deleteSsoConnection } from '../../state/slice';

import type { ISsoConnection } from '../../state/interfaces';

const DELETE_KEY = 'DELETE';

const DeleteSsoConnectionDialog = ({
  connectionId,
  connectionName,
}: {
  connectionId: ISsoConnection['id'];
  connectionName: string;
}) => {
  const { prompt } = usePrompt();
  const { show } = useToast();
  const dispatch = useDispatch();
  const { t } = useTranslations();

  const handleOnClick = async () => {
    try {
      const validation = await prompt({
        heading: t('sso.ssoConnections.deleteSsoConnection.heading'),
        text: t('sso.ssoConnections.deleteSsoConnection.actionUndone'),
        promptValidationKey: DELETE_KEY,
        confirmText: t('sso.ssoConnections.deleteSsoConnection.confirmText'),
        kind: 'destructive',
      });

      if (validation === DELETE_KEY) {
        dispatch(deleteSsoConnection({ id: connectionId } as any));
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      show({
        text: t('sso.ssoToastMessages.ssoConnectionToastDeleteError'),
        palette: 'danger',
        onClose: () => {},
      });
    }
  };

  return (
    <IconButton
      ariaLabel={t('sso.ssoConnections.removeConnection', {
        name: connectionName,
      })}
      onClick={() => void handleOnClick()}
      palette="danger"
      size="lg"
      usage="text"
      style={{ color: 'var(--pando-colors-danger-border-initial)' }}
    >
      <TrashIcon />
    </IconButton>
  );
};

export default DeleteSsoConnectionDialog;
