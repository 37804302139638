import { FormControlProvider, Input, Label } from '@pluralsight/react-ng';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import './update-note.scss';
import { NOTE_TYPE_ENUM } from '../../features/people/state/interfaces';

import type { UsersTableItem } from '../../shared/interfaces';
import type { Invite, NoteType } from '../../features/people/state/interfaces';

const UpdateNote = ({
  noteUser,
  note,
  type,
  handleNoteChange,
}: {
  noteUser: UsersTableItem | Invite;
  note: string;
  type: NoteType;
  handleNoteChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const { t } = useTranslations();
  const user = (noteUser as UsersTableItem)?.name || noteUser?.email;
  const userType =
    type === NOTE_TYPE_ENUM.MEMBER
      ? t('noteDialog.user.singular')
      : t('noteDialog.invite.singular');

  return (
    <>
      <FormControlProvider>
        <Label htmlFor="note">
          {t('noteDialog.updateForUser', {
            user,
            type: userType,
          })}
        </Label>
        <Input
          className="update-note__input"
          id="note"
          name="note"
          value={note || ''}
          maxLength={200}
          onChange={handleNoteChange}
        />
      </FormControlProvider>
    </>
  );
};

export default UpdateNote;
