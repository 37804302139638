import { ArrowLeftIcon } from '@pluralsight/icons';
import { Button, FlexContainer, FlexItem } from '@pluralsight/react-ng';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import { updateUserRoles } from '../../state/slice';
import { selectEditUser, selectUserRoles } from '../../state/selectors';
import { USER_ROLE } from '../../../../shared/constants';
import {
  selectAllRoles,
  selectMappedRoles,
} from '../../../roles/state/selectors';
import useScreenWidth from '../../../../shared/hooks/useScreenWidth';
import useDialogStep from '../../../../shared/dialog/useDialogStep';

import type { Member } from '../../../../shared/interfaces';
import type { Role } from '../../../roles/state/interfaces';

interface IActionButtonsProps {
  closeModal: () => void;
}

const ConfirmRolesStepActionButtons: React.FC<IActionButtonsProps> = ({
  closeModal,
}) => {
  const { isXS } = useScreenWidth();
  const newRoles = useSelector(selectUserRoles);
  const user = useSelector(selectEditUser);
  const mappedRoles = useSelector(selectMappedRoles);
  const roles = useSelector(selectAllRoles);

  const { t } = useTranslations();
  const dispatch = useDispatch();
  const { setStepBack } = useDialogStep();

  const B2CLearnerId = mappedRoles.get(USER_ROLE.B2C_LEARNER);
  const B2BLearnerId = mappedRoles.get(USER_ROLE.B2B_LEARNER);

  const createPayload = () => {
    const psUserId = user?.id as string;
    const currentRoles = Array.from(user?.roles || []);

    const assignedRolesIds = currentRoles.map((role) => role.id);

    const mapToPayload = (roleId: string) => ({ psUserId, roleId });
    const filterIfLearner = (roleId: string) =>
      ![B2CLearnerId, B2BLearnerId].includes(roleId);

    const newSet = new Set(newRoles);
    const oldSet = new Set(assignedRolesIds);

    const toAdd = Array.from(newSet).filter((id) => !oldSet.has(id));
    const toRemove = Array.from(oldSet)
      .filter((id) => !newSet.has(id))
      .filter(filterIfLearner);

    const mappedNewRoles = toAdd
      .map((id) => roles.find((role) => role.id === id))
      .filter(Boolean) as Role[];

    const filteredPrevRoles = currentRoles.filter(
      (role) => !toRemove.includes(role.id),
    );

    const newUserRoles = [...mappedNewRoles, ...filteredPrevRoles];
    const rolesToRemove = toRemove.map(mapToPayload);
    const rolesToAdd = toAdd.map(mapToPayload);

    return {
      user: user as Member,
      rolesToAdd,
      rolesToRemove,
      newUserRoles,
    };
  };

  const onConfirm = () => {
    const payload = createPayload();

    dispatch(updateUserRoles(payload));
    closeModal();
  };

  return (
    <FlexContainer justify="spaceBetween">
      <FlexItem>
        <Button
          className="action-buttons__button"
          size="lg"
          palette="neutral"
          usage="filled"
          role="button"
          aria-label={t('common.actionButtons.back')}
          onClick={setStepBack}
        >
          <ArrowLeftIcon aria-hidden="true" width={20} />
          {!isXS && t('common.actionButtons.back')}
        </Button>
      </FlexItem>

      <FlexContainer
        align="center"
        direction="row"
        justify="flexEnd"
        wrap="nowrap"
      >
        <Button
          className="action-buttons__button"
          size="lg"
          palette="action"
          usage="text"
          role="button"
          aria-label={t('common.actionButtons.cancel')}
          onClick={closeModal}
        >
          {t('common.actionButtons.cancel')}
        </Button>
        <Button
          size="lg"
          palette="action"
          usage="filled"
          role="button"
          aria-label={t('people.updateRolesDialog.update')}
          onClick={onConfirm}
        >
          {isXS
            ? t('people.updateRolesDialog.update')
            : t('people.updateRolesDialog.updateRoles')}
        </Button>
      </FlexContainer>
    </FlexContainer>
  );
};

export default ConfirmRolesStepActionButtons;
