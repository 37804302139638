import { Input } from '@pluralsight/react-ng';

import './right-icon-input.scss';
import type { InputProps } from '@pluralsight/react-ng';
import type { ReactNode } from 'react';

interface RightIconInputProps extends InputProps {
  endIcon?: ReactNode;
}

const RightIconInput = ({ endIcon, ...attr }: RightIconInputProps) => (
  <div className="right-icon-input-container">
    <Input {...attr} />
    <div className="right-icon-input-container__icon">{endIcon}</div>
  </div>
);

export default RightIconInput;
