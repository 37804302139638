import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@pluralsight/react-ng';
import { useRef, useState } from 'react';

import { useHandlePopoverAccessibility } from './useHandlePopoverAccessibility';

import type { ReactNode } from 'react';

import './hint-popover.scss';

interface HintPopoverProps {
  body: string;
  children: ReactNode;
  emptyDataChar?: string;
  id: string;
}

const HintPopover = ({
  body,
  emptyDataChar = '-',
  children,
  id,
}: HintPopoverProps) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const isEmpty = !body || body === emptyDataChar;

  useHandlePopoverAccessibility({ isOpen, setIsOpen, id, body });

  return isEmpty ? (
    <>{children}</>
  ) : (
    <Popover
      ref={ref}
      open={isOpen}
      placement="top"
      onOpenChange={(change) => setIsOpen(change)}
    >
      <PopoverTrigger onClick={() => setIsOpen(!isOpen)} role="button">
        <div className="hint-popover__trigger">{children}</div>
      </PopoverTrigger>
      <PopoverContent className="hint-popover__content" id={`hint-${id}`}>
        <PopoverBody>{body}</PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

HintPopover.displayName = 'HintPopover';

export default HintPopover;
