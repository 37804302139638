import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { updateFormModifiedStatus } from '../../state/slice';

export const useFieldTracker = (initialValue: any, currentValue: any): void => {
  const dispatch = useDispatch();
  const { connectionId } = useParams();

  const isEditMode = !!connectionId;

  // We store the initial value in a ref so it's not overwritten too soon.
  const stableInitialValue = useRef(initialValue);

  useEffect(() => {
    stableInitialValue.current = initialValue;
  }, [initialValue]);

  useEffect(() => {
    if (stableInitialValue.current !== undefined && isEditMode) {
      const isModified = currentValue !== stableInitialValue.current;

      dispatch(updateFormModifiedStatus(isModified));
    }
  }, [isEditMode, currentValue, dispatch]);
};
