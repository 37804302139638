import {
  SortArrowAscendingIcon,
  SortColumnIcon,
  SortDescendingIcon,
} from '@pluralsight/icons';
import { IconButton, Show } from '@pluralsight/react-ng';
import React from 'react';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import { SortingDirection } from '../../shared/interfaces';

import type { Sorting } from '../../shared/interfaces';

interface SortingIconButtonProps<T> {
  column: keyof T;
  sorting: Sorting<T>;
  onChangeSorting: (column: keyof T) => void;
}

const SortingIconButton = <T,>({
  column,
  sorting,
  onChangeSorting,
}: SortingIconButtonProps<T>) => {
  const isSortedColumn = sorting.column === column;
  const { t } = useTranslations();

  const columnName = String(column);
  const ariaLabel = isSortedColumn
    ? t(
        sorting.direction === SortingDirection.ASC
          ? 'common.table.sortedAsc'
          : 'common.table.sortedDesc',
        { column: columnName },
      )
    : t('common.table.sortBy', { column: columnName });

  return (
    <IconButton
      className="invites-table__th-icon"
      palette="action"
      usage="text"
      size="sm"
      ariaLabel={ariaLabel}
      onClick={() => onChangeSorting(column)}
    >
      <Show when={!isSortedColumn}>
        <SortColumnIcon />
      </Show>
      <Show when={isSortedColumn}>
        {sorting.direction === SortingDirection.ASC ? (
          <SortArrowAscendingIcon />
        ) : (
          <SortDescendingIcon />
        )}
      </Show>
    </IconButton>
  );
};

export default SortingIconButton;
