import enLang from './locales/en.json';
import esLang from './locales/es.json';
import frLang from './locales/fr.json';
import geLang from './locales/ge.json';
import jpLang from './locales/jp.json';
import ptLang from './locales/pt.json';

export const resources = {
  en: {
    translation: enLang,
  },
  fr: {
    translation: frLang,
  },
  es: {
    translation: esLang,
  },
  ge: {
    translation: geLang,
  },
  jp: {
    translation: jpLang,
  },
  pt: {
    translation: ptLang,
  },
};
