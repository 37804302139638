// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.connection-list-header {
  max-width: 66rem;
  min-width: 42rem;
  padding: var(--pando-padding-md);
  display: flex;
  gap: var(--pando-gap-md);
}
.connection-list-header__col {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
}
.connection-list-header__col--1 {
  flex: 2 2 40%;
  min-width: 16rem;
}
.connection-list-header__col--2 {
  flex: 1 1 14%;
}
.connection-list-header__col--3 {
  flex: 1 1 19%;
}
.connection-list-header__col--4 {
  flex: 1 0 30%;
  min-width: 16rem;
}
@media screen and (max-width: 767px) {
  .connection-list-header__col--4 {
    min-width: auto;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .connection-list-header__col--4 {
    flex: 1 1 0%;
    min-width: 4rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/features/sso/components/sso-conections/connection-list-header/connection-list-header.scss"],"names":[],"mappings":"AACA;EACE,gBAAA;EACA,gBAAA;EACA,gCAAA;EACA,aAAA;EACA,wBAAA;AAAF;AAEE;EACE,aAAA;EACA,mBAAA;EACA,cAAA;AAAJ;AAEI;EACE,aAAA;EACA,gBAAA;AAAN;AAGI;EACE,aAAA;AADN;AAII;EACE,aAAA;AAFN;AAKI;EACE,aAAA;EACA,gBAAA;AAHN;AAIM;EAHF;IAII,eAAA;EADN;AACF;AAGM;EAPF;IAQI,YAAA;IACA,eAAA;EAAN;AACF","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.connection-list-header {\n  max-width: 66rem;\n  min-width: 42rem;\n  padding: var(--pando-padding-md);\n  display: flex;\n  gap: var(--pando-gap-md);\n\n  &__col {\n    display: flex;\n    align-items: center;\n    flex: 1 1 auto;\n\n    &--1 {\n      flex: 2 2 40%;\n      min-width: 16rem;\n    }\n\n    &--2 {\n      flex: 1 1 14%;\n    }\n\n    &--3 {\n      flex: 1 1 19%;\n    }\n\n    &--4 {\n      flex: 1 0 30%;\n      min-width: 16rem;\n      @media screen and (max-width: 767px) {\n        min-width: auto;\n      }\n\n      @media screen and (min-width: 768px) and (max-width: 1023px) {\n        flex: 1 1 0%;\n        min-width: 4rem;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
