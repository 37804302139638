import { createSlice } from '@reduxjs/toolkit';

import type { LicensedProductWithUsage, ProductsState } from './interfaces';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState: ProductsState = {
  loading: false,
  error: null,
  products: [],
};

const productsSlice = createSlice({
  name: 'planSettings',
  initialState,
  reducers: {
    fetchProducts: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchProductsSuccess: (
      state,
      action: PayloadAction<LicensedProductWithUsage[]>,
    ) => {
      state.loading = false;
      state.error = null;
      state.products = action.payload;
    },
    fetchProductsFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchProducts, fetchProductsSuccess, fetchProductsFailure } =
  productsSlice.actions;

export default productsSlice.reducer;
