import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectUser } from '../../features/user/state/selectors';
import { hasExpectedRoleInScope } from '../../shared/utils';

import type { ExpectedRole } from '../../shared/interfaces';
import type { FC, ReactNode } from 'react';

interface RoleRestrictedComponentProps {
  children: ReactNode;
  restrictedTo: ExpectedRole[];
}

const RoleRestrictedComponent: FC<RoleRestrictedComponentProps> = ({
  children,
  restrictedTo,
}) => {
  const [isPermitted, setIsPermitted] = useState(true);

  const user = useSelector(selectUser);

  useEffect(() => {
    if (user) setIsPermitted(hasExpectedRoleInScope(user, restrictedTo));
  }, [user]);

  if (!user || !isPermitted) return <></>;
  else return children;
};

export default RoleRestrictedComponent;
