import './connection-card.scss';
import { PencilIcon } from '@pluralsight/icons';
import {
  Avatar,
  Badge,
  FlexContainer,
  FlexItem,
  IconButton,
  SwitchInput,
  SwitchInputLabel,
  useConfirm,
} from '@pluralsight/react-ng';
import cs from 'classnames';
import { format } from 'date-fns';
import { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';
import { ROUTES } from '@ps-fe-plan-management/shared/constants';
import { selectOrganization } from '@ps-fe-plan-management/features/organization/state/selectors';

import {
  SsoProtocol,
  SsoProtocolMap,
} from '../../sso-configuration/state/interfaces';
import { updateSsoConnectionStatus } from '../../sso-configuration/state/slice';
import useScreenWidth from '../../../../../shared/hooks/useScreenWidth';
import { selectActiveSsoConnections } from '../state/selectors';

import DeleteSsoConnectionDialog from './delete-sso-connection-dialog/delete-sso-connection-dialog';

import type { TSsoConnectionStatus } from '../state/interfaces';
import type { IStatusPayload } from '../../sso-configuration/state/interfaces';

interface IConnectionCardProps {
  connectionId: string;
  status: TSsoConnectionStatus;
  name: string;
  protocol: SsoProtocol;
  updatedAt: string | Date;
  lastUpdatedBy?: string;
}

const dialogConfigs: Record<
  string,
  { heading: string; text: string; confirmText: string }
> = {
  'true-true': {
    heading: 'sso.ssoSettings.disableSsoDialog.title',
    text: 'sso.ssoSettings.disableSsoDialog.body',
    confirmText: 'sso.ssoSettings.disableSsoDialog.confirm',
  },
  'true-false': {
    heading: 'sso.ssoSettings.disableSsoAndAutoProvisioningDialog.title',
    text: 'sso.ssoSettings.disableSsoAndAutoProvisioningDialog.body',
    confirmText: 'sso.ssoSettings.disableSsoAndAutoProvisioningDialog.confirm',
  },
  'false-true': {
    heading: 'sso.ssoSettings.disableSsoAndAutoEnforcementDialog.title',
    text: 'sso.ssoSettings.disableSsoAndAutoEnforcementDialog.body',
    confirmText: 'sso.ssoSettings.disableSsoAndAutoEnforcementDialog.confirm',
  },
};

const ConnectionCard = ({
  connectionId = 'fa935d20-5d90-4415-bb15-91035872f344',
  status,
  name,
  protocol,
  updatedAt,
  lastUpdatedBy = 'Johnathan Doe',
}: IConnectionCardProps) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const activeSsoConnections = useSelector(selectActiveSsoConnections);
  const organization = useSelector(selectOrganization);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formatedUpdatedAt = useMemo(
    () => format(updatedAt, 'MM/dd/yyyy'),
    [updatedAt],
  );
  const { isXL } = useScreenWidth();
  const { confirm } = useConfirm();
  const { t } = useTranslations();

  const isLastActiveConnection = isActive && activeSsoConnections?.length === 1;

  const handleConfirm = async () => {
    const key = `${Boolean(organization?.ssoLicenseAutoProvisioningEnabled)}-${Boolean(organization?.ssoEnforcementEnabled)}`;
    const config = dialogConfigs[key];

    return config
      ? await confirm({
          heading: t(`${config.heading}`),
          text: t(`${config.text}`),
          confirmText: t(`${config.confirmText}`),
          cancelText: t('sso.ssoSettings.cancel'),
        })
      : true;
  };

  const handleSsoStatusToggle = async () => {
    const nextValue = !isActive;

    // If disabling, and it's the last active connection, show confirm dialog.
    if (!nextValue && isLastActiveConnection) {
      // Apply pre-wrap custom styling for proper text formatting in the pando confirm dialog.
      const pTag = document.querySelector(
        '.pando-alertDialog__root p',
      ) as HTMLElement | null;

      if (pTag) pTag.style.whiteSpace = 'pre-wrap';

      const confirmation = await handleConfirm();

      if (pTag) pTag.style.whiteSpace = '';

      if (!confirmation) return;
    }

    setIsActive(nextValue);

    if (connectionId) {
      const statusPayload = {
        status: nextValue ? 'ACTIVE' : 'INACTIVE',
        id: connectionId,
      } as IStatusPayload;

      dispatch(updateSsoConnectionStatus(statusPayload));
    }
  };

  useEffect(() => {
    if (status) setIsActive(status === 'ACTIVE');
  }, [status]);

  return (
    <tr className="connection-card">
      <td className="connection-card__col connection-card__col--1">
        <FlexContainer gap="var(--pando-gap-lg)" align="center">
          <SwitchInput
            checked={isActive}
            onChange={() => void handleSsoStatusToggle()}
          >
            <SwitchInputLabel className="connection-name">
              {name}
            </SwitchInputLabel>
          </SwitchInput>
        </FlexContainer>
      </td>
      <td className="connection-card__col connection-card__col--2">
        <Badge
          className={cs('connection-card__badge', {
            isSaml: protocol === SsoProtocol.SAML,
          })}
        >
          {SsoProtocolMap[protocol]}
        </Badge>
      </td>
      <td className="connection-card__col connection-card__col--3">
        <span className="connection-card__date">{formatedUpdatedAt}</span>
      </td>
      <td className="connection-card__col connection-card__col--4">
        <FlexContainer
          gap="var(--pando-gap-md)"
          align="center"
          justify="spaceBetween"
        >
          {isXL && (
            <FlexItem>
              <FlexContainer gap="var(--pando-gap-sm)" align="center">
                <Avatar label={lastUpdatedBy} size="xs" />
                <span>{lastUpdatedBy}</span>
              </FlexContainer>
            </FlexItem>
          )}
          <FlexItem shrink="1">
            <FlexContainer align="center" justify="flexEnd">
              <IconButton
                role="link"
                ariaLabel={t('sso.ssoConnections.editConnection', { name })}
                onClick={() =>
                  navigate(`${ROUTES.SSO_CONFIGURATION}/${connectionId}`)
                }
                palette="action"
                size="lg"
                usage="text"
                style={{
                  color: 'var(--pando-inky-blue-action-text-initial-dark)',
                }}
              >
                <PencilIcon />
              </IconButton>
              {isXL && (
                <DeleteSsoConnectionDialog
                  connectionId={connectionId}
                  connectionName={name}
                />
              )}
            </FlexContainer>
          </FlexItem>
        </FlexContainer>
      </td>
    </tr>
  );
};

export default memo(ConnectionCard);
