// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.import-teams-container__section {
  padding-top: var(--pando-spacing-4);
}
.import-teams-container__subtitle {
  color: var(--pando-colors-neutral-text-300);
  font-size: var(--pando-font-sizes-md);
  font-weight: var(--pando-font-weights-bold);
  font-variation-settings: var(--pando-text-styles-display-md-font-variation-settings);
}
.import-teams-container__form {
  width: 100%;
}
.import-teams-container__link {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/features/teams/components/import-teams-container/import-teams-container.scss"],"names":[],"mappings":"AAEE;EACE,mCAAA;AADJ;AAGE;EACE,2CAAA;EACA,qCAAA;EACA,2CAAA;EACA,oFAAA;AADJ;AAME;EACE,WAAA;AAJJ;AAOE;EACE,eAAA;AALJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.import-teams-container {\n  &__section {\n    padding-top: var(--pando-spacing-4);\n  }\n  &__subtitle {\n    color: var(--pando-colors-neutral-text-300);\n    font-size: var(--pando-font-sizes-md);\n    font-weight: var(--pando-font-weights-bold);\n    font-variation-settings: var(\n      --pando-text-styles-display-md-font-variation-settings\n    );\n  }\n\n  &__form {\n    width: 100%;\n  }\n\n  &__link {\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
