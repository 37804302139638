import { WarningTriangleFilledIcon } from '@pluralsight/icons';
import { Button, FlexContainer, For, TD, TR } from '@pluralsight/react-ng';
import React from 'react';
import { useDispatch } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import { deleteEmail } from '../../state/slice';

import type { FC } from 'react';
import './emails-list.scss';

interface EmailsListProps {
  isValid?: boolean;
  emailsList: string[];
}

const EmailsList: FC<EmailsListProps> = ({ isValid = true, emailsList }) => {
  const dispatch = useDispatch();
  const { t } = useTranslations();

  return (
    <For
      each={emailsList}
      children={(email: string) => {
        const handleDelete = () => dispatch(deleteEmail(email));

        return (
          <TR
            type="base"
            className={`emails-list__row${!isValid ? ' invalid' : ''}`}
          >
            <TD type="base">
              <FlexContainer direction="row">
                {!isValid && (
                  <WarningTriangleFilledIcon className="emails-list__warning-triangle" />
                )}
                {email}
              </FlexContainer>
            </TD>
            <TD type="base">
              <Button
                size="md"
                palette="action"
                usage="text"
                onClick={handleDelete}
                aria-label={`${t('invitation.emailsList.remove')} ${email}`}
              >
                {t('invitation.emailsList.remove')}
              </Button>
            </TD>
          </TR>
        );
      }}
    />
  );
};

export default EmailsList;
