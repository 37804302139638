import { createSelector } from 'reselect';

import { toUsername } from '../../../shared/utils';

import type { AppState } from '../../../store';
import type { LicensesState } from './interfaces';

const selectUpdateLicensesState = (state: AppState) => state.memberLicenses;

export const selectMemberIds = createSelector(
  [selectUpdateLicensesState],
  (licenses: LicensesState) =>
    licenses.currentMember ? [licenses.currentMember.id] : [],
);

export const selectMemberName = createSelector(
  [selectUpdateLicensesState],
  (licenses: LicensesState) => {
    const { firstName, lastName } = licenses.currentMember || {};

    return toUsername(firstName, lastName);
  },
);

export const selectMemberProducts = createSelector(
  [selectUpdateLicensesState],
  (licenses: LicensesState) =>
    licenses.currentMember?.products
      ? licenses.currentMember.products.map((product) => product.productId)
      : [],
);

export const selectProductsToAdd = createSelector(
  [selectUpdateLicensesState],
  (licenses: LicensesState) => licenses.productsToAdd,
);

export const selectProductsToRemove = createSelector(
  [selectUpdateLicensesState],
  (licenses: LicensesState) => licenses.productsToRemove,
);

export const selectProductIds = createSelector(
  [selectUpdateLicensesState],
  (licenses: LicensesState) => licenses.productIds,
);
