// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-on-item {
  border: 1px solid var(--pando-inky-blue-neutral-border-100-dark, #2d2d55);
  background: var(--pando-inky-blue-neutral-surface-200-dark, #242145);
  border-radius: var(--pando-radii-md);
  padding: var(--pando-spacing-4);
  width: 100%;
  height: 100%;
}
.add-on-item__edit-icon {
  cursor: pointer;
}
.add-on-item__header {
  margin-bottom: var(--pando-spacing-4);
}
.add-on-item__title {
  color: var(--pando-colors-neutral-text-300, #f3f3f8);
  font-size: var(--pando-font-sizes-md);
  font-weight: 700;
  line-height: 125%;
}
.add-on-item__details {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--pando-spacing-4);
}
.add-on-item__field {
  display: flex;
  flex-direction: column;
}
.add-on-item__label {
  color: var(--pando-colors-neutral-text-300, #f3f3f8);
  font-size: var(--pando-font-sizes-sm);
  line-height: 150%;
  font-weight: 500;
}
.add-on-item__value {
  color: var(--pando-colors-neutral-text-200, #a5aacf);
  font-size: var(--pando-font-sizes-lg);
  font-weight: 700;
  line-height: 150%;
}`, "",{"version":3,"sources":["webpack://./src/features/plan-settings/components/add-ons/add-on-item/add-on-item.scss"],"names":[],"mappings":"AACA;EACE,yEAAA;EACA,oEAAA;EACA,oCAAA;EACA,+BAAA;EACA,WAAA;EACA,YAAA;AAAF;AAEE;EACE,eAAA;AAAJ;AAGE;EACE,qCAAA;AADJ;AAIE;EACE,oDAAA;EACA,qCAAA;EACA,gBAAA;EACA,iBAAA;AAFJ;AAKE;EACE,aAAA;EACA,qCAAA;EACA,2BAAA;AAHJ;AAME;EACE,aAAA;EACA,sBAAA;AAJJ;AAOE;EACE,oDAAA;EACA,qCAAA;EACA,iBAAA;EACA,gBAAA;AALJ;AAQE;EACE,oDAAA;EACA,qCAAA;EACA,gBAAA;EACA,iBAAA;AANJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.add-on-item {\n  border: 1px solid var(--pando-inky-blue-neutral-border-100-dark, #2d2d55);\n  background: var(--pando-inky-blue-neutral-surface-200-dark, #242145);\n  border-radius: var(--pando-radii-md);\n  padding: var(--pando-spacing-4);\n  width: 100%;\n  height: 100%;\n\n  &__edit-icon {\n    cursor: pointer;\n  }\n\n  &__header {\n    margin-bottom: var(--pando-spacing-4);\n  }\n\n  &__title {\n    color: var(--pando-colors-neutral-text-300, #f3f3f8);\n    font-size: var(--pando-font-sizes-md);\n    font-weight: 700;\n    line-height: 125%;\n  }\n\n  &__details {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    gap: var(--pando-spacing-4);\n  }\n\n  &__field {\n    display: flex;\n    flex-direction: column;\n  }\n\n  &__label {\n    color: var(--pando-colors-neutral-text-300, #f3f3f8);\n    font-size: var(--pando-font-sizes-sm);\n    line-height: 150%;\n    font-weight: 500;\n  }\n\n  &__value {\n    color: var(--pando-colors-neutral-text-200, #a5aacf);\n    font-size: var(--pando-font-sizes-lg);\n    font-weight: 700;\n    line-height: 150%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
