import React from 'react';

const Divider = () => {
  return (
    <hr
      style={{
        borderBottom: '1px solid',
        borderColor: 'var(--pando-colors-neutral-border-100)',
        height: 1,
        margin: 0,
      }}
      aria-hidden="true" // Indicates this is purely decorative
    />
  );
};

export default Divider;
