import { ChevronRightIcon, SearchIcon } from '@pluralsight/icons';
import {
  Checkbox,
  FlexContainer,
  For,
  FormControlProvider,
  Input,
  Radio,
} from '@pluralsight/react-ng';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import { selectTeamsList } from '../../../teams/state/selectors';

import type { Team } from '../../../team/state/interfaces';
import './nested-team-step.scss';

const NestedTeamStep = () => {
  const [notNested, setNotNested] = useState(true);
  const [searchTeam, setSearchTeam] = useState('');
  const [options, setOptions] = useState('');
  const teams = useSelector(selectTeamsList);
  const { t } = useTranslations();

  const handleSetOptions = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOptions(event.target.value);
  };

  const handleCheckboxChange = () => {
    setNotNested((prev) => !prev);
    setOptions('');
  };

  return (
    <FlexContainer direction="col" className="nested-team-step">
      <div>{t('createTeam.nestedTeamStep.byDefaultMsg')}</div>
      <FormControlProvider>
        <div className="nested-team-step__checkbox">
          <Checkbox
            id="is-nested-team"
            name="is-nested-team"
            checked={notNested}
            onChange={handleCheckboxChange}
          >
            {t('createTeam.nestedTeamStep.notNestedMsg')}
          </Checkbox>
        </div>
      </FormControlProvider>

      <FormControlProvider>
        <Input
          name="nested-team"
          value={searchTeam}
          placeholder={t('createTeam.nestedTeamStep.searchTeams')}
          startIcon={
            <SearchIcon aria-hidden height="1.25rem" width="1.25rem" />
          }
          onChange={(e) => setSearchTeam(e.target.value)}
        />
      </FormControlProvider>

      <FormControlProvider>
        <FlexContainer
          className="nested-team-step__radio-container"
          direction="col"
        >
          <For
            each={teams}
            children={(team: Team) => (
              <FlexContainer className="nested-team-step__radio" align="center">
                <ChevronRightIcon />
                <Radio
                  disabled={notNested}
                  id={team.id}
                  value={team.id}
                  name={`nested-team-${team.id}`}
                  checked={options === team.id}
                  onChange={handleSetOptions}
                >
                  <div className="nested-team-step__team">{team.name}</div>
                </Radio>
              </FlexContainer>
            )}
          />
        </FlexContainer>
      </FormControlProvider>
    </FlexContainer>
  );
};

export default NestedTeamStep;
