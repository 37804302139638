import { useCallback } from 'react';

import type { FileUploadFileChangeDetails } from '@pluralsight/react-ng';

export const useFileChangeHandler = (clearRejectedFiles: () => void) => {
  const handleFileChange = useCallback(
    (fileChangeDetails: FileUploadFileChangeDetails) => {
      if (
        fileChangeDetails.acceptedFiles.length &&
        fileChangeDetails.rejectedFiles.length
      ) {
        clearRejectedFiles();
      }

      if (fileChangeDetails.rejectedFiles.length) {
        const validFileOnMultipleIndex =
          fileChangeDetails.rejectedFiles.findIndex(
            (file: { errors: string[] }) =>
              file.errors.some((err: string) => err === 'TOO_MANY_FILES'),
          );

        if (validFileOnMultipleIndex > -1) {
          fileChangeDetails.acceptedFiles.push(
            fileChangeDetails.rejectedFiles[validFileOnMultipleIndex].file,
          );
          clearRejectedFiles();
        }
      }
    },
    [clearRejectedFiles],
  );

  return {
    handleFileChange,
  };
};
