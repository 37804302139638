import { all } from 'redux-saga/effects';

import createTeamSagas from '../features/create-team/state/sagas';
import invitationSagas from '../features/invitation/state/sagas';
import peopleSagas from '../features/people/state/sagas';
import plansSagas from '../features/plans/state/sagas';
import productsSagas from '../features/products/state/sagas';
import rolesSagas from '../features/roles/state/sagas';
import teamPendingInvitesSagas from '../features/team-pending-invites/state/sagas';
import teamUsersSagas from '../features/team-users/state/sagas';
import teamSagas from '../features/team/state/sagas';
import teamsSagas from '../features/teams/state/sagas';
import toastSagas from '../features/toast/state/sagas';
import userSagas from '../features/user/state/sagas';
import updateLicensesSagas from '../features/update-licenses/state/sagas';
import organizationSagas from '../features/organization/state/sagas';
import ssoConfigurationSagas from '../features/sso/components/sso-configuration/state/sagas';
import ssoConnectionsSagas from '../features/sso/components/sso-conections/state/sagas';

export default function* rootSaga() {
  yield all([
    createTeamSagas(),
    invitationSagas(),
    peopleSagas(),
    plansSagas(),
    productsSagas(),
    rolesSagas(),
    teamSagas(),
    teamPendingInvitesSagas(),
    teamUsersSagas(),
    teamsSagas(),
    toastSagas(),
    userSagas(),
    updateLicensesSagas(),
    organizationSagas(),
    ssoConfigurationSagas(),
    ssoConnectionsSagas(),
  ]);
}
