import { FlexContainer } from '@pluralsight/react-ng';
import React from 'react';

import './dashboard.scss';
import DashboardSidebar from '../dashboard-sidebar/dashboard-sidebar';
import HandsOnContent from '../hands-on-content/hands-on-content';
import PopularCourses from '../popular-courses/popular-courses';
import Welcome from '../welcome/welcome';

const Dashboard: React.FC = () => {
  return (
    <div className="dashboard">
      <div className="dashboard__header">
        <Welcome />
      </div>
      <FlexContainer className="dashboard__container">
        <div
          className="dashboard__content"
          style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}
        >
          <HandsOnContent />
          <PopularCourses />
        </div>
        <div className="dashboard__sidebar">
          <DashboardSidebar />
        </div>
      </FlexContainer>
    </div>
  );
};

export default Dashboard;
