import {
  FlexContainer,
  TabContent,
  TabsList,
  TabsRoot,
  TabTrigger,
} from '@pluralsight/react-ng';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';
import './import-teams-step.scss';

import ImportTeamsContainer from '../import-teams-container/import-teams-container';
import ExportTeamsContainer from '../export-teams-container/export-teams-container';
import { selectImportActivetab } from '../../state/selectors';
import { setImportActiveTab } from '../../state/slice';
import { ImportTabs } from '../../../../shared/interfaces';

enum KeyboardEventPeoplePage {
  ArrowRight = 'ArrowRight',
  ArrowLeft = 'ArrowLeft',
}

const ImportTeamsStep = () => {
  const { t } = useTranslations();
  const dispatch = useDispatch();
  const tabRefs = useRef<HTMLButtonElement[]>([]);
  const importActiveTab = useSelector(selectImportActivetab);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (tabRefs.current.length === 0) return;

    if (
      event.code === KeyboardEventPeoplePage.ArrowRight ||
      event.code === KeyboardEventPeoplePage.ArrowLeft
    ) {
      event.preventDefault();

      const newActiveTab =
        event.key === KeyboardEventPeoplePage.ArrowRight
          ? ImportTabs.Import
          : ImportTabs.Export;

      setImportActiveTab(newActiveTab);
    }
  };

  return (
    <FlexContainer direction="col" className="import-teams-step">
      <div className="teams-container__tabs">
        <TabsRoot
          value={importActiveTab}
          onValueChange={(event) => {
            dispatch(setImportActiveTab(event.value as ImportTabs));
          }}
        >
          <TabsList>
            <TabTrigger
              value={ImportTabs.Import}
              id="import-tab"
              ref={(element) =>
                (tabRefs.current[0] = element as HTMLButtonElement)
              }
            >
              {t('teams.importTeams.importCsv')}
            </TabTrigger>
            <TabTrigger
              value={ImportTabs.Export}
              id="export-tab"
              ref={(element) =>
                (tabRefs.current[1] = element as HTMLButtonElement)
              }
            >
              {t('teams.importTeams.exportCsv')}
            </TabTrigger>
          </TabsList>
          <TabContent
            aria-labelledby={t('teams.importTeams.importCsv')}
            value={ImportTabs.Import}
          >
            <ImportTeamsContainer />
          </TabContent>
          <TabContent
            aria-labelledby={t('teams.importTeams.exportCsv')}
            value={ImportTabs.Export}
          >
            <ExportTeamsContainer />
          </TabContent>
        </TabsRoot>
      </div>
    </FlexContainer>
  );
};

export default ImportTeamsStep;
