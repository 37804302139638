import './copy-button.scss';
import { CopyIcon } from '@pluralsight/icons';
import { Button, useToast } from '@pluralsight/react-ng';
import React from 'react';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import type { ButtonSize } from '@pluralsight/react-ng';

interface ICopyButtonProps {
  copyText: string;
  size?: ButtonSize;
}

const CopyButton = ({ copyText, size, ...attr }: ICopyButtonProps) => {
  const { show } = useToast();
  const { t } = useTranslations();

  const copyToClipboard = (text: string): void => {
    if (!text) return;

    navigator.clipboard.writeText(text).then(
      () => {
        show({
          text: t('copyButton.copyMsjSucessfully'),
          palette: 'success',
          onClose: () => {},
        });
      },
      () => {
        show({
          text: t('copyButton.copyMsjUnsucessfully'),
          palette: 'danger',
          onClose: () => {},
        });
      },
    );
  };

  return (
    <Button
      className="copy-button"
      palette="action"
      usage="text"
      size={size ?? 'md'}
      {...attr}
      onClick={(e) => {
        e.preventDefault();
        copyToClipboard(copyText);
      }}
      startIcon={<CopyIcon />}
    />
  );
};

export default CopyButton;
