import type { AxiosInstance, AxiosResponse } from 'axios';
import type { ApiResponse, Member } from '../../../shared/interfaces';

const PLANS_PATH = '/v1/plans';

export class TeamMembersService {
  private httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  fetchTeamMembers = async (
    planId: string,
    teamId: string,
  ): Promise<Member[]> => {
    try {
      const response = await this.httpClient.get<Member[]>( // Should we change it to Member type?
        `${PLANS_PATH}/${planId}/members`,
        {
          params: {
            teamId,
          },
        },
      );

      return [...response.data]; // TODO (okyry) remove mock when NGP-369 is ready
    } catch (error) {
      console.error('Error fetching team users:', error);

      throw error;
    }
  };

  addTeamMembers = async (users: string[], teamId: string, planId: string) => {
    const data = {
      teamsToAdd: {
        memberIds: users,
        teamIds: [teamId],
      },
    };

    try {
      const response = await this.httpClient.post<
        ApiResponse<AxiosResponse<undefined>>
      >(`${PLANS_PATH}/${planId}/members/teams`, data);

      return response.data;
    } catch (error) {
      console.error('Error adding team members:', error);

      throw error;
    }
  };

  moveTeamMembers = async (
    users: string[],
    teamsToAddIds: string[],
    teamsToRemoveIds: string[],
    planId: string,
  ) => {
    const data = {
      teamsToAdd: {
        memberIds: users,
        teamIds: teamsToAddIds,
      },
      teamsToRemove: {
        memberIds: users,
        teamIds: teamsToRemoveIds,
      },
    };

    try {
      const response = await this.httpClient.post<
        ApiResponse<AxiosResponse<undefined>>
      >(`${PLANS_PATH}/${planId}/members/teams`, data);

      return response.data;
    } catch (error) {
      console.error('Error moving team members:', error);

      throw error;
    }
  };

  removeTeamMembers = async (
    users: string[],
    teamId: string,
    planId: string,
  ) => {
    const data = {
      teamsToRemove: {
        memberIds: users,
        teamIds: [teamId],
      },
    };

    try {
      const response = await this.httpClient.post<
        ApiResponse<AxiosResponse<undefined>>
      >(`${PLANS_PATH}/${planId}/members/teams`, data);

      return response.data;
    } catch (error) {
      console.error('Error removing team members:', error);

      throw error;
    }
  };
}
