export const dialogHeaderTranslations = (email: string) => ({
  en: `Delete
  invite to
  ${email}
  from
  the
  plan
  ?`,
  es: `¿Eliminar la invitación a ${email} del plan?`,
  fr: `Supprimer l'invitation à ${email} du plan ?`,
  pt: `Excluir o convite para ${email} do plano?`,
  ge: `Einladung an ${email} aus dem Plan löschen?`,
  jp: `${email} の招待をプランから削除しますか？`,
});
