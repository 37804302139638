import type { AxiosInstance, AxiosResponse } from 'axios';
import type { ApiResponse } from '../../../shared/interfaces';
import type { Team, TeamPatch, TeamResponseItem } from './interfaces';

const PLANS_PATH = '/v1/plans';

export class TeamService {
  private httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  fetchTeam = async (
    planId: string,
    teamId: string,
  ): Promise<TeamResponseItem> => {
    try {
      const response = await this.httpClient.get<TeamResponseItem>(
        `${PLANS_PATH}/${planId}/teams/${teamId}`,
      );

      return response.data;
    } catch (error) {
      console.error('Error fetching team:', error);

      throw error;
    }
  };

  patchTeam = async (planId: string, teamId: string, patch: TeamPatch) => {
    try {
      const response = await this.httpClient.patch<
        ApiResponse<AxiosResponse<Team[]>>
      >(`${PLANS_PATH}/${planId}/teams/${teamId}`, patch);

      return response.data;
    } catch (error) {
      console.error('Error patching team:', error);

      throw error;
    }
  };
}
