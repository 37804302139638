import React, { useEffect, useRef, useState } from 'react';
import './invites-table.scss';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisVerticalIcon,
} from '@pluralsight/icons';
import {
  Caption,
  Checkbox,
  FlexContainer,
  For,
  FormControlProvider,
  IconButton,
  Option,
  Select,
  Table,
  TBody,
  TD,
  TH,
  THead,
  TR,
} from '@pluralsight/react-ng';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import { InviteStatus } from '../../features/people/state/interfaces';
import { TABLES, USER_ROLE } from '../../shared/constants';
import { SortingDirection } from '../../shared/interfaces';
import {
  calculateExpirationStatus,
  createPaginationOptions,
  sortArray,
} from '../../shared/utils';
import OptionsDropdown from '../options-dropdown/options-dropdown';
import RoleRestrictedComponent from '../role-restricted-component/role-restricted-component';
import SortingIconButton from '../table/sorting-icon-button';
import useIndividualMenuStatuses from '../../shared/hooks/useIndividualMenuStatuses';
import HintPopover from '../../components/hint-popover/hint-popover';
import { roleNameMap } from '../../features/invitation/components/confirm-step/constants';

import type { KeyBoardActions } from '../../shared/helpers/handle-menu-keyboard-navigation';
import type { OptionsDropdownRef } from '../options-dropdown/options-dropdown';
import type { Sorting } from '../../shared/interfaces';
import type { Invite } from '../../features/people/state/interfaces';

interface InvitesTableProps {
  invites: Invite[];
  dropDownOptions: (invite: Invite, actions: KeyBoardActions) => JSX.Element;
}

// TODO (przem-wierzbicki): REFACTOR!!! Use generic Customizable Table Component instead.
const InvitesTable: React.FC<InvitesTableProps> = React.memo(
  ({ invites, dropDownOptions }) => {
    const [pagination, setPagination] = useState({
      currPage: 0,
      pageSize: TABLES.PAGINATION_OPTIONS[0],
    });
    const { t } = useTranslations();
    const optionsDropdownRefs = useRef<
      Record<string, OptionsDropdownRef | null>
    >({});

    const [sorting, setSorting] = useState<Sorting<Invite>>({
      column: 'email',
      direction: SortingDirection.DESC,
    });

    const { individualMenuStatuses, toggleMenuStatuses } =
      useIndividualMenuStatuses(invites);

    const paginated = (invites: Invite[]) => {
      return invites.slice(
        pagination.currPage * pagination.pageSize,
        (pagination.currPage + 1) * pagination.pageSize,
      );
    };

    const [_invites, _setInvites] = useState<Invite[]>(() =>
      paginated(sortArray<Invite>([...invites], sorting)),
    );

    const onChangeSorting = (column: keyof Invite) => {
      const isSortingDirectionDesc =
        sorting.column === column &&
        sorting.direction === SortingDirection.DESC;
      const direction = isSortingDirectionDesc
        ? SortingDirection.ASC
        : SortingDirection.DESC;

      setSorting({ column, direction });
    };

    const onChangePageSize = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const currPage = 0;
      const pageSize = parseInt(e.target.value);

      setPagination({ ...pagination, currPage, pageSize });
    };

    const onChangePage = (pageChange: number) => {
      const currPage = pagination.currPage + pageChange;

      if (currPage < 0 || currPage > invites.length / pagination.pageSize)
        return;

      setPagination({ ...pagination, currPage });
    };

    const handleDropdownClose = (inviteId: string) => {
      const dropdown = optionsDropdownRefs.current[inviteId];

      dropdown?.onClose();
      dropdown?.onMoreActionsButtonFocus();
    };

    useEffect(() => {
      _setInvites(paginated(sortArray<Invite>([...invites], sorting)));
    }, [invites, sorting, pagination]);

    const currFrom = pagination.currPage * pagination.pageSize + 1;
    const currTo =
      (pagination.currPage + 1) * pagination.pageSize <= invites.length
        ? (pagination.currPage + 1) * pagination.pageSize
        : invites.length;

    const isPaginationDisabled = invites.length <= 100;
    const paginationOptions = createPaginationOptions(invites.length);

    const getValueOrDash = (value: string | undefined | null) => value || '-';

    return (
      <div className="invites-table">
        <Caption type="base" className="invites-table__caption">
          {t('invitesTable.caption')}
        </Caption>
        <FormControlProvider>
          <Table className="invites-table__table" hover="highlight" type="base">
            <THead
              className="invites-table__head"
              hover="highlight"
              type="base"
            >
              <TR header hover="highlight" type="base">
                <TH hover="highlight" type="condensed" />
                <TH hover="highlight" type="base">
                  <FlexContainer className="invites-table__th">
                    <span>{t('invitesTable.email')}</span>
                    <span className="invites-table__th-separator" />
                    <SortingIconButton<Invite>
                      column="email"
                      sorting={sorting}
                      onChangeSorting={onChangeSorting}
                    />
                  </FlexContainer>
                </TH>
                <TH hover="highlight" type="base">
                  <FlexContainer className="invites-table__th">
                    <span>{t('invitesTable.roles')}</span>
                    <span className="invites-table__th-separator" />
                    <SortingIconButton<Invite>
                      column="roles"
                      sorting={sorting}
                      onChangeSorting={onChangeSorting}
                    />
                  </FlexContainer>
                </TH>
                <TH hover="highlight" type="base">
                  <FlexContainer className="invites-table__th">
                    <span>{t('invitesTable.licenses')}</span>
                    <span className="invites-table__th-separator" />
                    <SortingIconButton<Invite>
                      column="products"
                      sorting={sorting}
                      onChangeSorting={onChangeSorting}
                    />
                  </FlexContainer>
                </TH>
                <TH hover="highlight" type="base">
                  <FlexContainer className="invites-table__th">
                    <span>{t('invitesTable.teams')}</span>
                    <span className="invites-table__th-separator" />
                    <SortingIconButton<Invite>
                      column="teams"
                      sorting={sorting}
                      onChangeSorting={onChangeSorting}
                    />
                  </FlexContainer>
                </TH>
                <TH hover="highlight" type="base">
                  <FlexContainer className="invites-table__th">
                    <span>{t('invitesTable.invitationStatus')}</span>
                    <span className="invites-table__th-separator" />
                    <SortingIconButton<Invite>
                      column="status"
                      sorting={sorting}
                      onChangeSorting={onChangeSorting}
                    />
                  </FlexContainer>
                </TH>
                <TH hover="highlight" type="base">
                  <FlexContainer className="invites-table__th">
                    <span>{t('invitesTable.note')}</span>
                    <span className="invites-table__th-separator" />
                    <SortingIconButton<Invite>
                      column="note"
                      sorting={sorting}
                      onChangeSorting={onChangeSorting}
                    />
                  </FlexContainer>
                </TH>
                <TH hover="highlight" type="condensed" />
              </TR>
            </THead>
            <TBody hover="highlight" type="base" style={{ textAlign: 'left' }}>
              <For
                each={_invites}
                children={(invite: Invite) => {
                  const email = getValueOrDash(invite.email);
                  const roles =
                    invite.roles
                      .map((role) => t(roleNameMap[role.name]))
                      .join(', ') || '-';
                  const teamNames = getValueOrDash(
                    invite.teams?.map((team) => team.name).join(', '),
                  );
                  const licenses = getValueOrDash(
                    invite.products
                      ?.map((product) =>
                        product.name === 'Free product'
                          ? t('invitesTable.freeAccount')
                          : product.name,
                      )
                      ?.join(', '),
                  );

                  return (
                    <TR key={invite.id} hover="highlight" type="base">
                      <TD hover="highlight" type="base" tabIndex={0}>
                        <Checkbox id={invite.id} name={invite.id} disabled />
                      </TD>
                      <TD
                        hover="highlight"
                        type="base"
                        tabIndex={0}
                        id={`${invite.id}-1`}
                      >
                        <HintPopover body={email} id={`${invite.id}-1`}>
                          {email}
                        </HintPopover>
                      </TD>
                      <TD
                        hover="highlight"
                        type="base"
                        tabIndex={0}
                        id={`${invite.id}-2`}
                      >
                        <HintPopover body={roles} id={`${invite.id}-2`}>
                          {roles}
                        </HintPopover>
                      </TD>
                      <TD
                        hover="highlight"
                        type="base"
                        tabIndex={0}
                        id={`${invite.id}-3`}
                      >
                        <HintPopover
                          body={licenses || '-'}
                          id={`${invite.id}-3`}
                        >
                          {licenses}
                        </HintPopover>
                      </TD>
                      <TD
                        hover="highlight"
                        type="base"
                        tabIndex={0}
                        id={`${invite.id}-4`}
                      >
                        <HintPopover body={teamNames} id={`${invite.id}-4`}>
                          {teamNames}
                        </HintPopover>
                      </TD>
                      <TD hover="highlight" type="base" tabIndex={0}>
                        <span
                          className={`invites-table__invitation-status ${
                            invite.status === InviteStatus.Expired
                              ? 'expired'
                              : ''
                          }`}
                        >
                          {t(
                            `invitesTable.inviteStatus.${invite.status.toLowerCase()}`,
                          )}
                        </span>
                        <span className="invites-table__expires_on">
                          {invite.status !== InviteStatus.Expired
                            ? calculateExpirationStatus(invite.expiresOn)
                            : ''}
                        </span>
                      </TD>
                      <TD
                        hover="highlight"
                        type="base"
                        tabIndex={0}
                        id={`${invite.id}-5`}
                      >
                        <HintPopover
                          body={invite.note || ''}
                          id={`${invite.id}-5`}
                        >
                          <span>{invite.note || '-'}</span>
                        </HintPopover>
                      </TD>
                      <TD hover="highlight" type="base">
                        <RoleRestrictedComponent
                          restrictedTo={[
                            {
                              roleName: USER_ROLE.SUPER_ADMIN,
                              scope: 'organizationScope',
                            },
                            {
                              roleName: USER_ROLE.SUPER_ADMIN,
                              scope: 'planScope',
                            },
                            {
                              roleName: USER_ROLE.SYSTEM_ADMIN,
                              scope: 'organizationScope',
                            },
                            {
                              roleName: USER_ROLE.PLAN_ADMIN,
                              scope: 'planScope',
                            },
                            {
                              roleName: USER_ROLE.MANAGER_FULL,
                              scope: 'planScope',
                            },
                            {
                              roleName: USER_ROLE.MANAGER_LIMITED,
                              scope: 'planScope',
                            },
                          ]}
                        >
                          <OptionsDropdown
                            ref={(el) => {
                              optionsDropdownRefs.current[invite.id] = el;
                            }}
                            button={
                              <EllipsisVerticalIcon
                                aria-label={t(
                                  'invitesTable.elipsisMessageEmail',
                                  {
                                    value: invite.email,
                                  },
                                )}
                              />
                            }
                            isOpen={individualMenuStatuses[invite.id]}
                            options={dropDownOptions(invite, {
                              Escape: () => handleDropdownClose(invite.id),
                              Tab: () => handleDropdownClose(invite.id),
                              ShiftTab: () => handleDropdownClose(invite.id),
                            })}
                            setIsOpen={() => {
                              toggleMenuStatuses(invite.id);
                            }}
                          />
                        </RoleRestrictedComponent>
                      </TD>
                    </TR>
                  );
                }}
              />
            </TBody>
          </Table>
          <div className="invites-table__pagination">
            <FlexContainer align="center" justify="flexEnd">
              <span className="invites-table__pagination-status">
                <span className="emphasis">
                  {currFrom}-{currTo}{' '}
                </span>
                {t('invitesTable.of')} {invites.length}
              </span>
            </FlexContainer>
            <FlexContainer align="center" justify="center">
              <Select
                aria-label={t('pagination.itemsPerPage')}
                id="name"
                name="name"
                size="md"
                onChange={onChangePageSize}
                disabled={isPaginationDisabled}
              >
                <React.Fragment key=".0">
                  <For
                    each={paginationOptions}
                    children={(paginationOption) => {
                      return (
                        <Option
                          name={paginationOption.toString()}
                          value={paginationOption.toString()}
                        >
                          {paginationOption}
                        </Option>
                      );
                    }}
                  />
                </React.Fragment>
              </Select>
            </FlexContainer>
            <FlexContainer align="center" justify="flexStart">
              <IconButton
                ariaLabel={t('pagination.pageDown')}
                className="invites-table__pagination-icon"
                palette="action"
                usage="text"
                size="sm"
                disabled={pagination.currPage <= 0 || isPaginationDisabled}
                onClick={() => onChangePage(-1)}
              >
                <ChevronLeftIcon />
              </IconButton>
              <IconButton
                ariaLabel={t('pagination.pageUp')}
                className="invites-table__pagination-icon"
                palette="action"
                usage="text"
                size="sm"
                disabled={
                  pagination.currPage >=
                    invites.length / pagination.pageSize - 1 ||
                  isPaginationDisabled
                }
                onClick={() => onChangePage(1)}
              >
                <ChevronRightIcon />
              </IconButton>
            </FlexContainer>
          </div>
        </FormControlProvider>
      </div>
    );
  },
);

export default InvitesTable;
