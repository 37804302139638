import { EyeIcon } from '@pluralsight/icons';
import {
  Button,
  FlexContainer,
  FlexItem,
  FormControlProvider,
  InlineCode,
  Label,
  Radio,
} from '@pluralsight/react-ng';
import { useCallback, useEffect, useState } from 'react';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import CopyButton from '../../../../shared/copy-button/copy-button';
import RightIconInput from '../../../../shared/right-icon-input/right-icon-input';
import {
  CommunicationChannel,
  OIDCFormFields,
} from '../../../state/interfaces';
import { useFieldTracker } from '../../hooks/useFieldTracker';

import type { ChangeEvent, MouseEvent } from 'react';

interface ICommunicationChannelFieldProps {
  communicationChannel?: CommunicationChannel;
  clientId?: string;
  clientSecret?: string;
}

const CommunicationChannelField = ({
  clientId: id,
  clientSecret: secret,
  communicationChannel: channel,
}: ICommunicationChannelFieldProps) => {
  const [communicationChannel, setCommunicationChannel] =
    useState<CommunicationChannel>(CommunicationChannel.BackChannel);
  const [clientId, setClientId] = useState<string>('');
  const [clientSecret, setClientSecret] = useState<string>('');
  const [isSecretVisible, setIsSecretVisible] = useState<boolean>(false);
  const { t } = useTranslations();

  useEffect(() => {
    if (id) setClientId(id);

    if (secret) setClientSecret(secret);

    if (channel) setCommunicationChannel(channel);
  }, [id, secret, channel]);

  useFieldTracker(channel, communicationChannel);
  useFieldTracker(secret, clientSecret);
  useFieldTracker(id, clientId);

  const handleClientIdChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      setClientId(e.target.value);
    },
    [],
  );
  const handleClientSecretChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      setClientSecret(e.target.value);
    },
    [],
  );

  const toggleSecretVisibility = useCallback(
    (e: MouseEvent<HTMLButtonElement>): void => {
      e.preventDefault();
      setIsSecretVisible((prev) => !prev);
    },
    [],
  );

  const inputGroupContainer = (
    <FlexContainer direction="row" className="basic-information__icon-group">
      <Button
        className="basic-information__eye-icon"
        palette="action"
        size="md"
        usage="text"
        onClick={toggleSecretVisibility}
        startIcon={
          <EyeIcon
            aria-label={
              isSecretVisible
                ? t('sso.ssoConfiguration.hideClientSecret')
                : t('sso.ssoConfiguration.showClientSecret')
            }
          />
        }
      />
      <CopyButton
        copyText={clientSecret}
        aria-label={t('sso.ssoConfiguration.copyClientSecret')}
      />
    </FlexContainer>
  );

  return (
    <FlexContainer
      direction="col"
      gap="var(--pando-gap-md)"
      className="fieldset__body"
    >
      <fieldset className="fieldset__field">
        <FormControlProvider>
          <legend className="fieldset__label fieldset__label--mb-sm">
            {t('sso.ssoConfiguration.communicationChannel')}
          </legend>
          <FlexContainer direction="col" gap="var(--pando-gap-md)">
            <FlexContainer direction="col">
              <Radio
                checked={
                  communicationChannel === CommunicationChannel.BackChannel
                }
                id="sso-configuration-comm-channel-back"
                name={OIDCFormFields.COMMUNICATION_CHANNEL}
                onChange={() =>
                  setCommunicationChannel(CommunicationChannel.BackChannel)
                }
                value={CommunicationChannel.BackChannel}
              >
                {t('sso.ssoConfiguration.backChannel')}
              </Radio>
              <p className="fieldset__note fieldset__note--pl-md">
                {t('sso.ssoConfiguration.usesWord')}{' '}
                <InlineCode className="fieldset__code">
                  response_type=id_token
                </InlineCode>
              </p>
            </FlexContainer>
            <FlexContainer direction="col">
              <Radio
                checked={
                  communicationChannel === CommunicationChannel.FrontChannel
                }
                id="sso-configuration-comm-channel-front"
                name={OIDCFormFields.COMMUNICATION_CHANNEL}
                onChange={() =>
                  setCommunicationChannel(CommunicationChannel.FrontChannel)
                }
                value={CommunicationChannel.FrontChannel}
              >
                {t('sso.ssoConfiguration.frontChannel')}
              </Radio>
              <p className="fieldset__note fieldset__note--pl-md">
                {t('sso.ssoConfiguration.usesWord')}{' '}
                <InlineCode className="fieldset__code">
                  response_mode=form_post
                </InlineCode>{' '}
                {t('sso.ssoConfiguration.andWord')}{' '}
                <InlineCode className="fieldset__code">
                  response_type=id_token
                </InlineCode>
              </p>
            </FlexContainer>
          </FlexContainer>
        </FormControlProvider>
      </fieldset>

      <FlexItem className="fieldset__field">
        <FormControlProvider required>
          <Label
            htmlFor="sso-configuration-client-id"
            usage="default"
            className="fieldset__label"
          >
            {t('sso.ssoConfiguration.clientIdLabel')}
          </Label>
          <RightIconInput
            id="sso-configuration-client-id"
            name={OIDCFormFields.CLIENT_ID}
            size="lg"
            endIcon={
              <CopyButton
                aria-label={t('sso.ssoConfiguration.copyClientId')}
                copyText={clientId}
              />
            }
            className="fieldset__input"
            value={clientId}
            onChange={handleClientIdChange}
          />
          <p className="fieldset__note">
            {t('sso.ssoConfiguration.obtainingClientIdDiffers')}
          </p>
        </FormControlProvider>
      </FlexItem>
      {communicationChannel === CommunicationChannel.BackChannel && (
        <FlexItem className="fieldset__field">
          <FormControlProvider required>
            <Label
              htmlFor="sso-configuration-client-secret"
              className="fieldset__label"
            >
              {t('sso.ssoConfiguration.clientSecretLabel')}
            </Label>
            <RightIconInput
              id="sso-configuration-client-secret"
              name={OIDCFormFields.CLIENT_SECRET}
              size="lg"
              type={isSecretVisible ? 'text' : 'password'}
              className="fieldset__input fieldset__input--password"
              endIcon={inputGroupContainer}
              value={clientSecret}
              onChange={handleClientSecretChange}
            />
            <p className="fieldset__note">
              {t('sso.ssoConfiguration.obtainingClientIdDiffers')}
            </p>
          </FormControlProvider>
        </FlexItem>
      )}
    </FlexContainer>
  );
};

export default CommunicationChannelField;
