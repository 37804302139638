import { createSelector } from 'reselect';

import type { Member } from '../../../shared/interfaces';
import type { AppState } from '../../../store';
import type { TeamUsersState } from './interfaces';

const selectTeamUsersState = (state: AppState) => state.teamUsers;

export const selectTeamUsersList = createSelector(
  [selectTeamUsersState],
  (teamUsersState: TeamUsersState): Member[] => teamUsersState.list,
);

export const selectTeamsToAddIds = createSelector(
  [selectTeamUsersState],
  (teamUsersState: TeamUsersState) => teamUsersState.teamsToAddIds,
);

export const selectTeamsToRemoveIds = createSelector(
  [selectTeamUsersState],
  (teamUsersState: TeamUsersState) => teamUsersState.teamsToRemoveIds,
);
