// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-details {
  border-radius: var(--pando-radii-lg);
  background: var(---pando-inky-blue-neutral-surface-100-dark, #1b1834);
  padding: var(--pando-spacing-6);
}
.account-details .renew-btn--mobile {
  margin-top: var(--pando-spacing-6);
}
.account-details__title {
  font-size: var(--pando-font-sizes-xl);
  font-weight: 600;
  margin-bottom: var(--pando-spacing-2);
  line-height: 125%;
}
.account-details__label {
  color: var(--pando-colors-neutral-text-300, #f3f3f8);
  font-size: var(--pando-font-sizes-sm);
  font-weight: 500;
}
.account-details__value {
  color: var(--pando-colors-neutral-text-200, #a5aacf);
  font-size: var(--pando-font-sizes-lg);
  font-weight: 700;
  line-height: 150%;
}
.account-details__value--bold {
  color: var(--pando-colors-neutral-text-300, #f3f3f8);
  font-size: var(--pando-font-sizes-2xl);
}
@media (max-width: 768px) {
  .account-details {
    /* pando overrides */
  }
  .account-details .pando-gridContainer {
    gap: var(--pando-gap-lg);
  }
}`, "",{"version":3,"sources":["webpack://./src/features/plan-settings/components/account-details/account-details.scss"],"names":[],"mappings":"AACA;EACE,oCAAA;EACA,qEAAA;EACA,+BAAA;AAAF;AAEE;EACE,kCAAA;AAAJ;AAGE;EACE,qCAAA;EACA,gBAAA;EACA,qCAAA;EACA,iBAAA;AADJ;AAIE;EACE,oDAAA;EACA,qCAAA;EACA,gBAAA;AAFJ;AAKE;EACE,oDAAA;EACA,qCAAA;EACA,gBAAA;EACA,iBAAA;AAHJ;AAKI;EACE,oDAAA;EACA,sCAAA;AAHN;AAOE;EAlCF;IAmCI,oBAAA;EAJF;EAKE;IACE,wBAAA;EAHJ;AACF","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.account-details {\n  border-radius: var(--pando-radii-lg);\n  background: var(---pando-inky-blue-neutral-surface-100-dark, #1b1834);\n  padding: var(--pando-spacing-6);\n\n  & .renew-btn--mobile {\n    margin-top: var(--pando-spacing-6);\n  }\n\n  &__title {\n    font-size: var(--pando-font-sizes-xl);\n    font-weight: 600;\n    margin-bottom: var(--pando-spacing-2);\n    line-height: 125%;\n  }\n\n  &__label {\n    color: var(--pando-colors-neutral-text-300, #f3f3f8);\n    font-size: var(--pando-font-sizes-sm);\n    font-weight: 500;\n  }\n\n  &__value {\n    color: var(--pando-colors-neutral-text-200, #a5aacf);\n    font-size: var(--pando-font-sizes-lg);\n    font-weight: 700;\n    line-height: 150%;\n\n    &--bold {\n      color: var(--pando-colors-neutral-text-300, #f3f3f8);\n      font-size: var(--pando-font-sizes-2xl);\n    }\n  }\n\n  @media (max-width: 768px) {\n    /* pando overrides */\n    .pando-gridContainer {\n      gap: var(--pando-gap-lg);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
