import './connection-card.scss';
import { PencilIcon } from '@pluralsight/icons';
import { FlexContainer, FlexItem, IconButton } from '@pluralsight/react-ng';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '@ps-fe-plan-management/shared/constants';

interface IConnectionCardProps {
  connectionId: string;
  name: string;
}

const ConnectionCardSM = ({
  connectionId = 'fa935d20-5d90-4415-bb15-91035872f344',
  name,
}: IConnectionCardProps) => {
  const navigate = useNavigate();

  return (
    <tr className="connection-card">
      <td className="connection-card__col connection-card__col--1">
        <span className="connection-name" aria-label={name}>
          {name}
        </span>
      </td>
      <td className="connection-card__col connection-card__col--4">
        <FlexContainer
          gap="var(--pando-gap-md)"
          align="center"
          justify="spaceBetween"
        >
          <FlexItem shrink="1">
            <FlexContainer align="center" justify="flexEnd">
              <IconButton
                role="link"
                ariaLabel="pencil icon"
                onClick={() =>
                  navigate(`${ROUTES.SSO_CONFIGURATION}/${connectionId}`)
                }
                palette="action"
                size="lg"
                usage="text"
                className="connection-card__edit-button"
              >
                <PencilIcon />
              </IconButton>
            </FlexContainer>
          </FlexItem>
        </FlexContainer>
      </td>
    </tr>
  );
};

export default memo(ConnectionCardSM);
