import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import SelectLicenses from '../../../../components/select-licenses/select-licenses';
import {
  assignSelectedLicenses,
  revokeLicenses,
  setProductIds,
} from '../../state/slice';
import { selectMemberProducts, selectProductIds } from '../../state/selectors';
import {
  selectAddOnProducts,
  selectProducts,
} from '../../../products/state/selectors';

import './update-licenses.scss';

const UpdateLicensesStep = () => {
  const { t } = useTranslations();
  const dispatch = useDispatch();
  const products = useSelector(selectProducts);
  const addOnProducts = useSelector(selectAddOnProducts);
  const currentMemberProducts = useSelector(selectMemberProducts);
  const productIds = useSelector(selectProductIds);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const productsToAdd = !currentMemberProducts.includes(event.target.value);
    const productsToRemove = currentMemberProducts.includes(event.target.value);

    if (productsToAdd) dispatch(assignSelectedLicenses(event.target.value));

    if (productsToRemove) dispatch(revokeLicenses(event.target.value));

    dispatch(setProductIds(event.target.value));
  };

  return (
    <div
      className={cn('update-licenses__container', {
        scroll: products.length > 1,
      })}
    >
      {t('licenseDialog.titleSelect', { count: addOnProducts.length })}
      <SelectLicenses productIds={productIds} onChange={onChange} />
    </div>
  );
};

export default UpdateLicensesStep;
