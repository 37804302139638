// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirmation-card {
  display: flex;
  flex-direction: column;
}
.confirmation-card__title {
  font-weight: var(--pando-font-weights-bold);
  font-size: var(--pando-font-sizes-sm);
}
.confirmation-card__container {
  border-radius: var(--pando-radii-md);
  background-color: var(--pando-colors-neutral-surface-300);
}
.confirmation-card__container__head {
  padding: var(--pando-spacing-2) var(--pando-spacing-4);
  font-size: var(--pando-font-sizes-lg);
  font-weight: var(--pando-font-weights-medium);
}
.confirmation-card__container__body {
  background-color: var(--pando-colors-neutral-surface-200);
  padding: var(--pando-spacing-2) var(--pando-spacing-4) var(--pando-spacing-4);
  border-bottom-left-radius: var(--pando-radii-md);
  border-bottom-right-radius: var(--pando-radii-md);
  color: var(--pando-colors-neutral-text-initial);
  font-size: var(--pando-font-sizes-sm);
  font-weight: var(--pando-font-weights-medium);
}
.confirmation-card__container__body__description {
  padding-bottom: var(--pando-spacing-2);
}
.confirmation-card__container__body__warn {
  font-size: var(--pando-font-sizes-sm);
  display: flex;
  color: var(--pando-colors-warning-text-200);
  gap: 5px;
}`, "",{"version":3,"sources":["webpack://./src/features/people/components/confirmation-card/confirmation-card.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,sBAAA;AAAF;AAEE;EACE,2CAAA;EACA,qCAAA;AAAJ;AAGE;EACE,oCAAA;EACA,yDAAA;AADJ;AAGI;EACE,sDAAA;EACA,qCAAA;EACA,6CAAA;AADN;AAII;EACE,yDAAA;EACA,6EAAA;EAEA,gDAAA;EACA,iDAAA;EACA,+CAAA;EACA,qCAAA;EACA,6CAAA;AAHN;AAKM;EACE,sCAAA;AAHR;AAMM;EACE,qCAAA;EACA,aAAA;EACA,2CAAA;EACA,QAAA;AAJR","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.confirmation-card {\n  display: flex;\n  flex-direction: column;\n\n  &__title {\n    font-weight: var(--pando-font-weights-bold);\n    font-size: var(--pando-font-sizes-sm);\n  }\n\n  &__container {\n    border-radius: var(--pando-radii-md);\n    background-color: var(--pando-colors-neutral-surface-300);\n\n    &__head {\n      padding: var(--pando-spacing-2) var(--pando-spacing-4);\n      font-size: var(--pando-font-sizes-lg);\n      font-weight: var(--pando-font-weights-medium);\n    }\n\n    &__body {\n      background-color: var(--pando-colors-neutral-surface-200);\n      padding: var(--pando-spacing-2) var(--pando-spacing-4)\n        var(--pando-spacing-4);\n      border-bottom-left-radius: var(--pando-radii-md);\n      border-bottom-right-radius: var(--pando-radii-md);\n      color: var(--pando-colors-neutral-text-initial);\n      font-size: var(--pando-font-sizes-sm);\n      font-weight: var(--pando-font-weights-medium);\n\n      &__description {\n        padding-bottom: var(--pando-spacing-2);\n      }\n\n      &__warn {\n        font-size: var(--pando-font-sizes-sm);\n        display: flex;\n        color: var(--pando-colors-warning-text-200);\n        gap: 5px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
