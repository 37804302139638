import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { Invite } from '../../people/state/interfaces';
import type { TeamPendingInvitesState } from './interfaces';

const INITIAL_STATE: TeamPendingInvitesState = {
  error: null,
  list: [],
  loading: false,
};

const teamPendingInvitesSlice = createSlice({
  name: 'team-pending-invites',
  initialState: INITIAL_STATE,
  reducers: {
    fetchTeamPendingInvites: (state) => {
      state.error = null;
      state.loading = true;
    },
    fetchTeamPendingInvitesSuccess: (
      state,
      action: PayloadAction<Invite[]>,
    ) => {
      state.list = action.payload;
      state.loading = false;
    },
    fetchTeamPendingInvitesFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  fetchTeamPendingInvites,
  fetchTeamPendingInvitesSuccess,
  fetchTeamPendingInvitesFailure,
} = teamPendingInvitesSlice.actions;

export default teamPendingInvitesSlice.reducer;
