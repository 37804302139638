import { createSlice } from '@reduxjs/toolkit';

import { ImportTabs } from '../../../shared/interfaces';

import type { Invite, PeopleState, UpdateRole } from './interfaces';
import type {
  ImportFile,
  Member,
  UsersTableItem,
} from '../../../shared/interfaces';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { Role } from '../../roles/state/interfaces';

const INITIAL_STATE: PeopleState = {
  error: null,
  list: [],
  inviteList: [],
  editUser: null,
  roles: [],
  loading: false,
  importFile: null,
  importActiveTab: ImportTabs.Import,
};

const peopleSlice = createSlice({
  name: 'people',
  initialState: INITIAL_STATE,
  reducers: {
    fetchPeople: (state) => {
      state.error = null;
      state.loading = true;
    },
    fetchPeopleSuccess: (state, action: PayloadAction<Member[]>) => {
      state.list = action.payload;
      state.loading = false;
    },
    fetchPeopleFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    fetchInvites: (state) => {
      state.error = null;
      state.loading = true;
    },
    fetchInvitesSuccess: (state, action: PayloadAction<Invite[]>) => {
      state.inviteList = action.payload;
      state.loading = false;
    },
    fetchInvitesFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    updateNote: (
      state,
      _action: PayloadAction<{
        id: string;
        note: string;
        type: 'MEMBER' | 'INVITE';
      }>,
    ) => {
      state.error = null;
      state.loading = true;
    },
    updateMemberNoteSuccess: (
      state,
      action: PayloadAction<{ id: string; note: string }>,
    ) => {
      state.error = null;
      state.loading = false;
      state.list = state.list.map((user) =>
        user.id === action.payload.id
          ? { ...user, note: action.payload.note }
          : user,
      );
    },
    updateMemberNoteFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    updateInviteNoteSuccess: (
      state,
      action: PayloadAction<{ id: string; note: string }>,
    ) => {
      state.error = null;
      state.loading = false;
      state.inviteList = state.inviteList.map((user) =>
        user.id === action.payload.id
          ? { ...user, note: action.payload.note }
          : user,
      );
    },
    updateInviteNoteFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    setImportActiveTab: (state, action: PayloadAction<ImportTabs>) => {
      state.importActiveTab = action.payload;
    },
    setImportFile: (state, action: PayloadAction<ImportFile | null>) => {
      state.importFile = action.payload;
    },
    fetchUploadUsersUrl: (state) => {
      state.error = null;
      state.loading = true;
    },
    fetchUploadUsersUrlSuccess: (
      state,
      _action: PayloadAction<{ url: string }>,
    ) => {
      state.error = null;
      state.loading = false;
    },
    fetchUploadUsersUrlFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    setImportErrors: (state, action: PayloadAction<string[] | null>) => {
      if (state.importFile) {
        state.importFile.importErrors = action.payload;
      } else {
        state.importFile = {
          id: null,
          file: null,
          objectKey: null,
          importErrors: action.payload,
        };
      }
    },
    importUsersFile: (state, _action: PayloadAction<{ file: File }>) => {
      state.error = null;
      state.loading = true;
    },
    importUsersFileSuccess: (state, _action) => {
      state.error = null;
      state.loading = true;
    },
    importUsersFileFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = true;
    },
    setEditUser: (state, action: PayloadAction<string>) => {
      state.editUser =
        state.list.find((user) => action.payload === user.id) || null;
      state.roles = state.editUser?.roles.map((role) => role.id) || [];
    },
    clearEditUser: (state) => {
      state.editUser = null;
      state.roles = [];
    },
    assignUserRole: (state, action: PayloadAction<string[]>) => {
      state.roles = action.payload.filter(Boolean);
    },
    updateUserRoles: (state, _action: PayloadAction<UpdateRole>) => {
      state.error = null;
      state.loading = true;
    },
    updateUserRolesSuccess: (
      state,
      action: PayloadAction<{ userId: string; roles: Role[] }>,
    ) => {
      state.list = state.list.map((usr) =>
        usr.id === action.payload.userId
          ? { ...usr, roles: action.payload.roles }
          : usr,
      );
      state.editUser = null;
      state.roles = [];
    },
    updateUserRolesFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    deleteUser: (state, _action: PayloadAction<UsersTableItem>) => {
      state.error = null;
      state.loading = true;
    },
    deleteUserSuccess: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.list = state.list.filter((usr) => usr.id !== action.payload);
      state.editUser = null;
    },
    deleteUserFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  fetchPeople,
  fetchPeopleSuccess,
  fetchPeopleFailure,
  fetchInvites,
  fetchInvitesSuccess,
  fetchInvitesFailure,
  updateNote,
  updateMemberNoteSuccess,
  updateInviteNoteSuccess,
  updateMemberNoteFailure,
  updateInviteNoteFailure,
  setImportActiveTab,
  setImportFile,
  fetchUploadUsersUrl,
  fetchUploadUsersUrlSuccess,
  fetchUploadUsersUrlFailure,
  setImportErrors,
  importUsersFile,
  importUsersFileSuccess,
  importUsersFileFailure,
  setEditUser,
  clearEditUser,
  assignUserRole,
  updateUserRoles,
  updateUserRolesSuccess,
  updateUserRolesFailure,
  deleteUser,
  deleteUserSuccess,
  deleteUserFailure,
} = peopleSlice.actions;

export default peopleSlice.reducer;
