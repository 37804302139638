import { createSlice } from '@reduxjs/toolkit';

import { ImportTabs } from '../../../shared/interfaces';

import type { ImportFile } from '../../../shared/interfaces';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { Team } from '../../team/state/interfaces';
import type { TeamsState } from './interfaces';

const INITIAL_STATE: TeamsState = {
  error: null,
  list: [],
  loading: false,
  importFile: null,
  importActiveTab: ImportTabs.Import,
};

const teamsSlice = createSlice({
  name: 'teams',
  initialState: INITIAL_STATE,
  reducers: {
    fetchTeams: (state) => {
      state.error = null;
      state.loading = true;
    },
    fetchTeamsSuccess: (state, action: PayloadAction<Team[]>) => {
      state.list = action.payload;
      state.loading = false;
    },
    fetchTeamsFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    setImportActiveTab: (state, action: PayloadAction<ImportTabs>) => {
      state.importActiveTab = action.payload;
    },
    setImportFile: (state, action: PayloadAction<ImportFile | null>) => {
      state.importFile = action.payload;
    },
    fetchUploadTeamsUrl: (state) => {
      state.error = null;
      state.loading = true;
    },
    fetchUploadTeamsUrlSuccess: (
      state,
      _action: PayloadAction<{ url: string }>,
    ) => {
      state.error = null;
      state.loading = false;
    },
    fetchUploadTeamsUrlFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    setImportErrors: (state, action: PayloadAction<string[] | null>) => {
      if (state.importFile) {
        state.importFile.importErrors = action.payload;
      } else {
        state.importFile = {
          id: null,
          file: null,
          objectKey: null,
          importErrors: action.payload,
        };
      }
    },
    importTeamsFile: (state, _action: PayloadAction<{ file: File }>) => {
      state.error = null;
      state.loading = true;
    },
    importTeamsFileSuccess: (state, _action) => {
      state.error = null;
      state.loading = true;
    },
    importTeamsFileFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = true;
    },
  },
});

export const {
  fetchTeams,
  fetchTeamsSuccess,
  fetchTeamsFailure,
  setImportActiveTab,
  setImportFile,
  fetchUploadTeamsUrl,
  fetchUploadTeamsUrlSuccess,
  fetchUploadTeamsUrlFailure,
  setImportErrors,
  importTeamsFile,
  importTeamsFileSuccess,
  importTeamsFileFailure,
} = teamsSlice.actions;

export default teamsSlice.reducer;
