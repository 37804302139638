import { FlexContainer, FlexItem, For } from '@pluralsight/react-ng';
import React from 'react';
import { useSelector } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import bullet from '../../../../assets/list-bullet.svg';
import {
  selectAssignedInvites,
  selectAssignedUsers,
  selectTeam,
} from '../../state/selectors';
import AssignedUserRow from '../assigned-user-row/assigned-user';

import type { Person } from '../../../people/state/interfaces';
import './confirmation-step.scss';

const ConfirmationStep = () => {
  const { name } = useSelector(selectTeam);
  const { t } = useTranslations();
  const assignedUsers = useSelector(selectAssignedUsers);
  const assignedInvites = useSelector(selectAssignedInvites);

  return (
    <>
      <div className="confirmation-step__team-name">
        <div className="confirmation-step__title team-name">
          {t('createTeam.confirmationStep.teamName')}
        </div>
        <FlexContainer gap={12}>
          <img src={bullet} alt="list-bullet icon" />
          <FlexItem className="confirm-emails-list__email">{name}</FlexItem>
        </FlexContainer>
      </div>

      {/* TODO (okyry) uncomment in scope of adding nested team*/}
      {/* <div className="confirmation-step__title">Team nesting</div>*/}

      <FlexContainer
        gap={8}
        align="center"
        className="confirmation-step__users"
      >
        <FlexItem className="confirmation-step__title">
          {t('createTeam.confirmationStep.usersAssigned')}
        </FlexItem>
        <FlexItem className="confirmation-step__length">{`(${
          assignedUsers.length + assignedInvites.length
        })`}</FlexItem>
      </FlexContainer>

      <For
        each={[...assignedUsers, ...assignedInvites]}
        children={(user: Person) => (
          <AssignedUserRow user={user} isConfirmationStep />
        )}
      />
    </>
  );
};

export default ConfirmationStep;
