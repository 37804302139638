// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.team-users-container__placeholder {
  margin-top: var(--pando-spacing-4);
  background-color: var(--pando-colors-neutral-surface-200);
  border-radius: var(--pando-radii-lg-border-radius);
}
.team-users-container__placeholder-message {
  color: var(--neutral-text-300, #f3f3f8);
  font-family: var(--font-family-title), "PS TT Commons", sans-serif;
  font-size: var(--pando-font-size-2xl-font-size, 24px);
  font-weight: 600;
  line-height: 125%;
  margin-bottom: var(--pando-spacing-4);
  text-align: center;
}
.team-users-container__placeholder-button {
  align-items: center;
  color: var(--pando-inky-blue-action-text-100-dark);
  cursor: pointer;
  display: flex;
  font-family: var(--font-family-title), "PS TT Commons", sans-serif;
  font-size: var(--pando-font-sizes-sm, 14px);
  font-weight: 500;
  height: var(--pando-spacing-8);
  line-height: 150%;
  padding: 0 var(--pando-spacing-4);
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/features/team-users/components/team-users-container/team-users-container.scss"],"names":[],"mappings":"AAEE;EACE,kCAAA;EACA,yDAAA;EACA,kDAAA;AADJ;AAIE;EACE,uCAAA;EACA,kEAAA;EACA,qDAAA;EACA,gBAAA;EACA,iBAAA;EACA,qCAAA;EACA,kBAAA;AAFJ;AAKE;EACE,mBAAA;EACA,kDAAA;EACA,eAAA;EACA,aAAA;EACA,kEAAA;EACA,2CAAA;EACA,gBAAA;EACA,8BAAA;EACA,iBAAA;EACA,iCAAA;EACA,kBAAA;AAHJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.team-users-container {\n  &__placeholder {\n    margin-top: var(--pando-spacing-4);\n    background-color: var(--pando-colors-neutral-surface-200);\n    border-radius: var(--pando-radii-lg-border-radius);\n  }\n\n  &__placeholder-message {\n    color: var(--neutral-text-300, #f3f3f8);\n    font-family: var(--font-family-title), \"PS TT Commons\", sans-serif;\n    font-size: var(--pando-font-size-2xl-font-size, 24px);\n    font-weight: 600;\n    line-height: 125%;\n    margin-bottom: var(--pando-spacing-4);\n    text-align: center;\n  }\n\n  &__placeholder-button {\n    align-items: center;\n    color: var(--pando-inky-blue-action-text-100-dark);\n    cursor: pointer;\n    display: flex;\n    font-family: var(--font-family-title), \"PS TT Commons\", sans-serif;\n    font-size: var(--pando-font-sizes-sm, 14px);\n    font-weight: 500;\n    height: var(--pando-spacing-8);\n    line-height: 150%;\n    padding: 0 var(--pando-spacing-4);\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
