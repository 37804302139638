import React, { forwardRef } from 'react';
import { Button } from '@pluralsight/react-ng';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import CustomDialog from '../../../../components/custom-dialog/custom-dialog';
import { selectTeam } from '../../../create-team/state/selectors';
import SelectTeamMembers from '../../../invitation/components/select-team-members/select-team-members';
import { addTeamMembers } from '../../state/slice';

import type {
  ContentType,
  HeaderType,
} from '../../../../components/custom-dialog/custom-dialog';

interface IAddTeamMembersDialogProps {
  teamIdSelected?: string;
  closeModal: () => void;
  shouldFetchTeams?: boolean;
}

const dialogHeader = (t: any): HeaderType => ({
  1: t('teamUsers.selectTheUsers'),
});

const AddTeamMembersDialog = forwardRef<
  HTMLDialogElement | null,
  IAddTeamMembersDialogProps
>(({ teamIdSelected, closeModal, shouldFetchTeams = false }, ref) => {
  const dispatch = useDispatch();
  const { assignedMembers } = useSelector(selectTeam);
  const { teamId } = useParams();
  const { t } = useTranslations();

  const dialogContent: ContentType = {
    1: <SelectTeamMembers />,
  };

  const addUsers = () => {
    const payload = {
      users: assignedMembers.map((member) => member.id),
      teamId: teamIdSelected || teamId || '',
      shouldFetchTeams,
    };

    dispatch(addTeamMembers(payload));
    closeModal();
  };

  const actionButtons = () => (
    <>
      <Button size="lg" palette="action" usage="text" onClick={closeModal}>
        {t('common.actionButtons.cancel')}
      </Button>
      <Button
        size="lg"
        disabled={!assignedMembers.length}
        palette={assignedMembers.length ? 'action' : 'neutral'}
        usage="filled"
        onClick={addUsers}
      >
        {t('teamUsers.addUsers')}
      </Button>
    </>
  );

  return (
    <CustomDialog
      id="add-members"
      ref={ref}
      dialogHeader={dialogHeader(t)}
      dialogContent={dialogContent}
      actionButtons={actionButtons}
      multipleSteps={false}
      isWarning={false}
      closeModal={closeModal}
    />
  );
});

export default AddTeamMembersDialog;
