// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.placeholder__container {
  height: 100%;
  padding: var(--pando-spacing-6) var(--pando-spacing-6) var(--pando-spacing-8);
  width: 100%;
}
.placeholder__icon {
  margin-bottom: var(--pando-spacing-6);
}`, "",{"version":3,"sources":["webpack://./src/components/placeholder/placeholder.scss"],"names":[],"mappings":"AAEE;EACE,YAAA;EACA,6EAAA;EAEA,WAAA;AAFJ;AAKE;EACE,qCAAA;AAHJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.placeholder {\n  &__container {\n    height: 100%;\n    padding: var(--pando-spacing-6) var(--pando-spacing-6)\n      var(--pando-spacing-8);\n    width: 100%;\n  }\n\n  &__icon {\n    margin-bottom: var(--pando-spacing-6);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
