export enum LicenseCategories {
  SUBSCRIPTION = 'Base Products',
  ADD_ON = 'Add On Services',
}

export interface LicensedProductWithUsage {
  productId: string;
  productName: string;
  productType: string;
  productCategory: LicenseCategories;
  startDate: string;
  expireDate: string;
  validFrom: string;
  validTo: string;
  isDefault: boolean;
  isUserAssignable: boolean;
  featureIds: string[];
  features?: string[];
  totalLicenses: number;
  usedLicenses: number;
  pendingLicenses: number;
  remainingLicenses: number;
}

export interface Subscription extends LicensedProductWithUsage {
  category: LicenseCategories.SUBSCRIPTION;
}

export interface AddOn extends LicensedProductWithUsage {
  category: LicenseCategories.ADD_ON;
}

export interface ProductAsset {
  name: string;
  subscriptions: Subscription[];
  addOns: AddOn[];
}

export interface ProductsState {
  loading: boolean;
  error: null | string;
  products: LicensedProductWithUsage[];
}
