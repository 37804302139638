// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirm-emails-list {
  border: 1px solid red;
}
.confirm-emails-list__scroll {
  width: 676px;
  height: 503px;
  overflow: auto;
}
.confirm-emails-list__email {
  color: var(--neutral-text-300, #f3f3f8);
  line-height: 170%;
}
.confirm-emails-list__title {
  margin-top: var(--pando-spacing-4);
  color: var(--pando-colors-neutral-text-300);
  /* Text/s bold */
  font-family: var(--font-family-body, "PS TT Commons");
  font-size: var(--font-size-sm, 14px);
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 21px */
}`, "",{"version":3,"sources":["webpack://./src/features/invitation/components/confirm-step/confirm-step.scss"],"names":[],"mappings":"AACA;EACE,qBAAA;AAAF;AAEE;EACE,YAAA;EACA,aAAA;EACA,cAAA;AAAJ;AAGE;EACE,uCAAA;EACA,iBAAA;AADJ;AAIE;EACE,kCAAA;EACA,2CAAA;EAEA,gBAAA;EACA,qDAAA;EACA,oCAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;AAHJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.confirm-emails-list {\n  border: 1px solid red;\n\n  &__scroll {\n    width: 676px;\n    height: 503px;\n    overflow: auto;\n  }\n\n  &__email {\n    color: var(--neutral-text-300, #f3f3f8);\n    line-height: 170%;\n  }\n\n  &__title {\n    margin-top: var(--pando-spacing-4);\n    color: var(--pando-colors-neutral-text-300);\n\n    /* Text/s bold */\n    font-family: var(--font-family-body, \"PS TT Commons\");\n    font-size: var(--font-size-sm, 14px);\n    font-style: normal;\n    font-weight: 700;\n    line-height: 150%; /* 21px */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
