import React from 'react';
import { FlexContainer, IconButton } from '@pluralsight/react-ng';
import { PencilIcon } from '@pluralsight/icons';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import { formatDate } from '../../../shared/utils/date-utils';

import type { AddOn } from '../../../../products/state/interfaces';

import './add-on-item.scss';

interface AddOnItemProps {
  addon: AddOn;
  onEdit?: (addon: AddOn) => void;
}

const AddOnItem: React.FC<AddOnItemProps> = ({ addon, onEdit }) => {
  const { t } = useTranslations();

  const handleEdit = () => {
    onEdit?.(addon);
  };

  return (
    <div className="add-on-item">
      <FlexContainer align="stretch" justify="spaceBetween" direction="row">
        <div className="add-on-item__header">
          <h4 className="add-on-item__title">{addon.productName}</h4>
        </div>
        <IconButton
          ariaLabel={t('planSettings.addOns.edit')}
          onClick={handleEdit}
          size="sm"
          usage="text"
          palette="action"
          className="add-on-item__edit-icon"
        >
          <PencilIcon />
        </IconButton>
      </FlexContainer>
      <div className="add-on-item__details">
        <div className="add-on-item__field">
          <span className="add-on-item__label">
            {t('planSettings.addOns.licenses')}
          </span>
          <span className="add-on-item__value">{addon.totalLicenses}</span>
        </div>
        <div className="add-on-item__field">
          <span className="add-on-item__label">
            {t('planSettings.addOns.renewalDate')}
          </span>
          <span className="add-on-item__value">
            {formatDate(addon.expireDate)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default AddOnItem;
