import {
  Button,
  Caption,
  FlexContainer,
  For,
  Table,
  TBody,
  TD,
  THead,
  TR,
} from '@pluralsight/react-ng';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import AssignedUserRow from '../../features/create-team/components/assigned-user-row/assigned-user';
import { selectAssignedMembers } from '../../features/create-team/state/selectors';
import {
  clearAssignedMembers,
  removeAssignedUser,
} from '../../features/create-team/state/slice';
import { clearAssignedManagers } from '../../features/team-users/state/slice';
import './users-list.scss';

const UsersList = ({ isManagersFlow = false }) => {
  const dispatch = useDispatch();
  const assignedMembers = useSelector(selectAssignedMembers);
  const { t } = useTranslations();

  const handleRemoveAll = () =>
    dispatch(isManagersFlow ? clearAssignedManagers() : clearAssignedMembers());

  return (
    <Table hover="highlight" type="base">
      <Caption type="base" className="caption">
        {t('usersList.assignedUsersTable')}
      </Caption>
      <THead hover="highlight" type="base">
        <TR header hover="highlight" type="base">
          <TD type="base">
            {t('usersList.addedUsers', { value: assignedMembers.length })}
          </TD>
          <TD type="base">
            <FlexContainer justify="end">
              <Button
                size="md"
                palette="action"
                usage="text"
                onClick={handleRemoveAll}
              >
                {t('usersList.removeAll')}
              </Button>
            </FlexContainer>
          </TD>
        </TR>
      </THead>
      <TBody hover="highlight" type="base" className="tb">
        <FlexContainer
          direction="col"
          align="stretch"
          className="users-list__scroll"
        >
          <For
            each={assignedMembers}
            children={(user) => {
              const handleDelete = () => dispatch(removeAssignedUser(user.id));

              return (
                <TR type="base" className="users-list__row">
                  <TD type="base">
                    <AssignedUserRow user={user} />
                  </TD>
                  <TD type="base">
                    <Button
                      size="md"
                      palette="action"
                      usage="text"
                      onClick={handleDelete}
                    >
                      {t('usersList.remove')}
                    </Button>
                  </TD>
                </TR>
              );
            }}
          />
        </FlexContainer>
      </TBody>
    </Table>
  );
};

export default UsersList;
