import { FlexContainer } from '@pluralsight/react-ng';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '@ps-fe-plan-management/shared/constants';

import AccountDetails from '../account-details/account-details';
import ProductCard from '../product-card/product-card';
import { fetchProducts } from '../../../products/state/slice';
import {
  selectProductAssets,
  selectProductDateBoundaries,
  selectProductsLoading,
} from '../../../products/state/selectors';
import { selectPlan, selectPlanLoading } from '../../../plans/state/selectors';
import { selectUser, selectUserPlanId } from '../../../user/state/selectors';
import { fetchPlan } from '../../../plans/state/slice';

import type { PeopleLocationState } from '../../../people/types/navigation';

import './plan-settings-container.scss';

const PlanSettingsContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(selectUser);
  const plan = useSelector(selectPlan);
  const planId = useSelector(selectUserPlanId);
  const products = useSelector(selectProductAssets);
  const isPlanLoading = useSelector(selectPlanLoading);
  const isProductsLoading = useSelector(selectProductsLoading);
  const dateBoundaries = useSelector(selectProductDateBoundaries);
  const accountData = useMemo(
    () => ({
      ...plan,
      ...dateBoundaries,
    }),
    [plan, dateBoundaries],
  );

  useEffect(() => {
    if (user) {
      if (!plan && planId) {
        dispatch(fetchPlan(planId));
      } else if (plan) {
        dispatch(fetchProducts());
      }
    }
  }, [dispatch, plan, planId, user]);

  const handleAddUsers = (productId: string) => {
    const navigationState: PeopleLocationState = {
      shouldOpenInviteModal: true,
      source: 'product-card',
      productId,
    };

    navigate(ROUTES.PEOPLE, { state: navigationState });
  };

  return (
    <div className="plan-settings-container">
      <FlexContainer direction="col" gap="var(--pando-spacing-9)">
        <AccountDetails accountData={accountData} isLoading={isPlanLoading} />
        <FlexContainer direction="col" gap="var(--pando-spacing-6)">
          {products.map((product) => (
            <ProductCard
              key={product.name}
              product={product}
              isLoading={isPlanLoading || isProductsLoading}
              onAddUsers={handleAddUsers}
            />
          ))}
        </FlexContainer>
      </FlexContainer>
    </div>
  );
};

export default PlanSettingsContainer;
