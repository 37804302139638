import { ArrowLeftIcon } from '@pluralsight/icons';
import { Button, FlexContainer, FlexItem } from '@pluralsight/react-ng';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import { selectTeam } from '../../state/selectors';
import { createTeam } from '../../state/slice';
import useDialogStep from '../../../../shared/dialog/useDialogStep';
import './action-buttons.scss';

interface IActionButtonsProps {
  closeModal: () => void;
}

const ConfirmStepActionButtons: React.FC<IActionButtonsProps> = ({
  closeModal,
}) => {
  const { t } = useTranslations();
  const dispatch = useDispatch();
  const team = useSelector(selectTeam);

  const { setStepBack } = useDialogStep();

  const submitCreateTeam = () => {
    closeModal();
    dispatch(createTeam(team));
  };

  return (
    <FlexContainer justify="spaceBetween">
      <FlexItem>
        <Button
          className="action-buttons__button"
          size="lg"
          palette="neutral"
          usage="filled"
          onClick={setStepBack}
        >
          <ArrowLeftIcon width={20} />
          {t('common.actionButtons.back')}
        </Button>
      </FlexItem>

      <FlexContainer
        align="center"
        direction="row"
        justify="flexEnd"
        wrap="nowrap"
      >
        <Button
          className="action-buttons__button"
          size="lg"
          palette="action"
          usage="text"
          onClick={closeModal}
        >
          {t('common.actionButtons.cancel')}
        </Button>
        <Button
          size="lg"
          palette="action"
          usage="filled"
          onClick={submitCreateTeam}
        >
          {t('createTeam.actionButtons.createTeam')}
        </Button>
      </FlexContainer>
    </FlexContainer>
  );
};

export default ConfirmStepActionButtons;
