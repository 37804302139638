import { FlexContainer, FlexItem } from '@pluralsight/react-ng';
import { WarningTriangleIcon } from '@pluralsight/icons';

import './confirmation-card.scss';

interface ConfirmationCardProps {
  title: string;
  role: string;
  description: string;
  warn?: string | null;
}

const ConfirmationCard = ({
  title,
  role,
  description,
  warn,
}: ConfirmationCardProps) => (
  <FlexContainer className="confirmation-card" gap={8}>
    <h2 className="confirmation-card__title">{title}</h2>
    <FlexItem className="confirmation-card__container">
      <h3 className="confirmation-card__container__head">{role}</h3>
      <FlexItem className="confirmation-card__container__body">
        <span className="confirmation-card__container__body__description">
          {description}
        </span>
        {warn && (
          <span
            aria-label="Warning"
            className="confirmation-card__container__body__warn"
          >
            <WarningTriangleIcon />
            {warn}
          </span>
        )}
      </FlexItem>
    </FlexItem>
  </FlexContainer>
);

export default ConfirmationCard;
