import { createSelector } from 'reselect';

import type { AppState } from '../../../store';
import type { Toast, ToastState } from './interfaces';

const selectToastState = (state: AppState) => state.toast;

export const selectToast = createSelector(
  [selectToastState],
  (toastState: ToastState): Toast | null => toastState.toast,
);
