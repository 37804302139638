import { FlexContainer, FlexItem, Show } from '@pluralsight/react-ng';
import React from 'react';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';

import bullet from '../../../../assets/list-bullet.svg';

import type { FC } from 'react';
import type { Person } from '../../../people/state/interfaces';
import './assigned-user.scss';

interface IAssignedUser {
  user: Person;
  isConfirmationStep?: boolean;
}

const AssignedUserRowComponent: FC<IAssignedUser> = ({
  user,
  isConfirmationStep = false,
}) => {
  const { t } = useTranslations();
  const className = `assigned-user__${user.active ? 'active' : 'pending'}`;
  const containerClassName = `assigned-user${
    isConfirmationStep ? '__confirmation' : ''
  }`;
  const name = user.active
    ? `${user.firstName} ${user.lastName}`
    : t('createTeam.assignedUsedRow.pendingInvite');

  return (
    <FlexContainer
      gap={3}
      align="center"
      className={containerClassName}
      tabIndex={0}
    >
      <Show
        when={isConfirmationStep}
        children={<img src={bullet} alt="list-bullet icon" />}
      />
      <FlexItem className={className}>{name}</FlexItem>
      <FlexItem className="assigned-user__email">{`(${user.email})`}</FlexItem>
    </FlexContainer>
  );
};

export default AssignedUserRowComponent;
