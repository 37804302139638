// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sso-connections {
  height: 100%;
}
.sso-connections .text-center {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/features/sso/components/sso-conections/sso-connections.scss"],"names":[],"mappings":"AACA;EACE,YAAA;AAAF;AAEE;EACE,kBAAA;AAAJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.sso-connections {\n  height: 100%;\n\n  .text-center {\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
