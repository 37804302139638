import type { AxiosInstance } from 'axios';
import type { ISsoConnection, ISsoConnectionId } from './interfaces';

const SSO_CONNECTION_PATH = '/v1/sso-connections';

export class SsoConnectionsService {
  private httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  fetchSsoConnections = async (
    organizationId: string,
  ): Promise<ISsoConnection[]> => {
    try {
      const response = await this.httpClient.get<ISsoConnection[]>(
        SSO_CONNECTION_PATH,
        {
          params: { organizationId },
        },
      );

      return response.data;
    } catch (error) {
      console.error('Error fetching SSO Connections:', error);

      throw error;
    }
  };

  fetchSsoConnection = async (id: string): Promise<ISsoConnection> => {
    try {
      const response = await this.httpClient.get<ISsoConnection>(
        `${SSO_CONNECTION_PATH}/${id}`,
      );

      return response.data;
    } catch (error) {
      console.error(`Error fetching SSO Connection with ID ${id}:`, error);

      throw error;
    }
  };

  deleteSsoConnection = async (payload: ISsoConnectionId): Promise<void> => {
    try {
      await this.httpClient.delete(`${SSO_CONNECTION_PATH}/${payload.id}`);
    } catch (error) {
      console.error('Error deleting SSO Connection:', error);

      throw error;
    }
  };
}
