import httpClient from '../http-client';

import type {
  CurrentUserData,
  UserAppData,
} from '@ps-fe-plan-management/types';

const API_VERSION = 'v1';
const USERS_PATH = '/users';

export const getAppUserData: () => Promise<UserAppData | null> = async () => {
  try {
    const { data } = await httpClient.get<CurrentUserData>(
      `${API_VERSION}${USERS_PATH}/current`,
    );

    const appMetadata = {
      country: data.country,
      psId: data.psUserId,
      terms_accepted: data.isTermsAccepted,
      terms_accepted_created_at: data.acceptedTermsOn,
    };

    return {
      userId: data.psUserId,
      username: `${data.firstName} ${data.lastName}`,
      email: data.email,
      appMetadata,
    };
  } catch (err) {
    console.log(err);

    return null;
  }
};
