import React from 'react';

import ProvidersLayer from '@ps-fe-plan-management/components/ProvidersLayer';

import App from './components/app/app';

import type { AppConfig } from '@ps-fe-plan-management/contexts/AppConfigContext';

const PlanManagementRootComponent: React.FC = () => {
  const config = (window as unknown as { NEXTGEN_CONFIG: AppConfig })
    .NEXTGEN_CONFIG;

  return (
    <ProvidersLayer
      initialUser={null}
      initialLanguage={'en'}
      appConfig={config}
    >
      <App />
    </ProvidersLayer>
  );
};

export default PlanManagementRootComponent;
