import { CheckCircleIcon, TrashIcon } from '@pluralsight/icons';
import {
  Button,
  FileUploadContext,
  FileUploadDropzone,
  FileUploadHiddenInput,
  FileUploadItem,
  FileUploadItemDeleteTrigger,
  FileUploadItemGroup,
  FileUploadItemName,
  FileUploadItemPreview,
  FileUploadLabel,
  FileUploadRootProvider,
  FileUploadTrigger,
  FlexContainer,
  FlexItem,
  FormControlProvider,
  IconButton,
  Input,
  Label,
  Option,
  Select,
  Show,
  SwitchInput,
  SwitchInputLabel,
  useFileUpload,
} from '@pluralsight/react-ng';
import { PandoInkyBlueDangerBorderInitialDark } from '@pluralsight/design-tokens';
import { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import cs from 'classnames';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';
import { validateUrl } from '@ps-fe-plan-management/features/sso/components/sso-configuration/sso-configuration-form/utils/validation';
import FieldValidationProvider from '@ps-fe-plan-management/features/sso/components/shared/field-validation/field-validation-provider';
import { errorSAMLFileUploadKeys } from '@ps-fe-plan-management/features/sso/components/sso-configuration/sso-configuration-form/saml/constants';

import { selectSingleSsoConnectionConfigurations } from '../../../sso-conections/state/selectors';
import {
  CERT_EXTENSIONS_WITH_DOT,
  DigestAlgorithm,
  ProtocolBinding,
  ProtocolBindingField,
  SAMLFormFields,
  SignatureAlgorithm,
} from '../../state/interfaces';
import { useFileChangeHandler } from '../hooks/useFileChangeHandler';
import { useFieldTracker } from '../hooks/useFieldTracker';

import type { ISsoConfigurationSAML } from '../../../sso-conections/state/interfaces';
import type { ChangeEvent } from 'react';
import type { FileUploadFileChangeDetails } from '@pluralsight/react-ng';
import type { FileError, FileRejection } from '@zag-js/file-upload';

const requestTemplate = `
  <samlp:AuthRequest xmlns:samlp="urn:oasis:names:tc:SAML:">
    @@AssertServiceURLAndDestination@@
    ID="@@ID@@"
    IssueInstant="@@IssueInstant@@"
    ProtocolBinding="@@ProtocolBinding@@" Version="2.0">
    <saml:Issuer xmlns:samlp="urn:oasis:names:tc:SAML:2.0:">
    </saml:Issuer>
  </samlp:AuthRequest>
`;

const fileUploadConfig = {
  accept: { 'application/*': CERT_EXTENSIONS_WITH_DOT },
  maxFiles: 1,
  maxFileSize: 1024 * 1024, // 1MB
};
const initEnableSignOut = true;
const initDebugMode = false;

const Saml = () => {
  const { connectionId } = useParams();
  const [isEditMode, setIsEditMode] = useState<boolean>(!!connectionId);
  const {
    signInEndpoint,
    signOutEndpoint,
    userIdAttribute,
    signSamlRequest,
    signingCert,
    protocolBinding,
    signatureAlgorithm,
    digestAlgorithm,
  } = useSelector(
    selectSingleSsoConnectionConfigurations,
  ) as ISsoConfigurationSAML;
  const { t } = useTranslations();
  const [singInUrl, setSingInUrl] = useState('');
  const [signOutUrl, setSingOutUrl] = useState('');
  const [userIdAttr, setUserIdAttr] = useState('');
  const [enableSignOut, setEnableSignOut] =
    useState<boolean>(initEnableSignOut);
  const [debugMode, setDebugMode] = useState<boolean>(initDebugMode);
  const [signRequest, setSignRequest] = useState<boolean>(true);
  const [sigAlg, setSigAlg] = useState(
    signatureAlgorithm || SignatureAlgorithm.RSASHA1,
  );
  const [digestAlg, setDigestAlg] = useState(
    digestAlgorithm || DigestAlgorithm.SHA1,
  );
  const [protocolBind, setProtocolBind] = useState(
    protocolBinding || ProtocolBinding.HTTP_POST,
  );
  const { handleFileChange } = useFileChangeHandler();

  const fileUpload = useFileUpload({
    ...fileUploadConfig,
    onFileChange: handleFileChange,
  });

  const hasRejectedFiles = !!fileUpload?.rejectedFiles?.length;

  const handleEnableSignOutToggle = useCallback(() => {
    setEnableSignOut((prev) => !prev);
  }, []);

  const handleDebugModeToggle = useCallback(() => {
    setDebugMode((prev) => !prev);
  }, []);

  const handleSignRequestToggle = useCallback(() => {
    setSignRequest((prev) => !prev);
  }, []);

  const handleSignItUrl = (
    e: ChangeEvent<HTMLInputElement>,
    validateField: (inputValue: string) => void,
  ): void => {
    validateField(e.target.value);
    setSingInUrl(e.target.value);
  };

  const handleSignOutUrl = (
    e: ChangeEvent<HTMLInputElement>,
    validateField: (inputValue: string) => void,
  ): void => {
    validateField(e.target.value);
    setSingOutUrl(e.target.value);
  };

  const handleUserIdAttr = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      setUserIdAttr(e.target.value);
    },
    [],
  );

  const handleSigAlgChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      setSigAlg(e.target.value as SignatureAlgorithm);
    },
    [],
  );

  const handleDigestAlgChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      setDigestAlg(e.target.value as DigestAlgorithm);
    },
    [],
  );

  const handleProtocolBindChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      setProtocolBind(e.target.value as ProtocolBinding);
    },
    [],
  );

  const getFileUploadErrorText = (error: FileError) =>
    t(
      errorSAMLFileUploadKeys[error] ||
        'sso.ssoConfiguration.anErrorOccurredWhileUploading',
    );

  useEffect(() => {
    if (signInEndpoint) setSingInUrl(signInEndpoint);

    if (signOutEndpoint) setSingOutUrl(signOutEndpoint);

    if (!signOutEndpoint && isEditMode) setEnableSignOut(!initEnableSignOut);

    if (signInEndpoint) setSingInUrl(signInEndpoint);

    if (userIdAttribute) setUserIdAttr(userIdAttribute);

    if (signSamlRequest !== undefined) setSignRequest(signSamlRequest);

    if (signatureAlgorithm) setSigAlg(signatureAlgorithm);

    if (digestAlgorithm) setDigestAlg(digestAlgorithm);

    if (protocolBinding) setProtocolBind(protocolBinding);

    if (signingCert) {
      const file = createDummyPemFile();

      fileUpload.setFiles([file]);
    }
  }, [
    signInEndpoint,
    signOutEndpoint,
    userIdAttribute,
    signSamlRequest,
    signatureAlgorithm,
    digestAlgorithm,
    protocolBinding,
    signingCert,
  ]);

  useFieldTracker(signInEndpoint, singInUrl);
  useFieldTracker(
    !isEditMode ? initEnableSignOut : Boolean(signOutEndpoint),
    enableSignOut,
  );
  useFieldTracker(signOutEndpoint, signOutUrl);
  useFieldTracker(userIdAttribute, userIdAttr);
  useFieldTracker(initDebugMode, debugMode);
  useFieldTracker(signSamlRequest, signRequest);
  useFieldTracker(signatureAlgorithm, sigAlg);
  useFieldTracker(digestAlgorithm, digestAlg);
  useFieldTracker(protocolBinding, protocolBind);

  return (
    <FlexContainer direction="col" gap="var(--pando-gap-lg)">
      <FlexItem className="fieldset__field">
        <FieldValidationProvider validate={validateUrl}>
          {({ error, validateField }) => (
            <FormControlProvider required invalid={!!error}>
              <Label
                htmlFor="saml-sing-in-url"
                className={cs('fieldset__label', {
                  'fieldset__label--error': !!error,
                })}
              >
                {t('sso.ssoConfiguration.signInUrl')}
              </Label>
              <Input
                id="saml-sing-in-url"
                name={SAMLFormFields.SIGN_IN_ENDPOINT}
                placeholder="https://samlp.example.com/login"
                size="lg"
                className="fieldset__input"
                value={singInUrl}
                onChange={(e) => handleSignItUrl(e, validateField)}
              />
            </FormControlProvider>
          )}
        </FieldValidationProvider>
      </FlexItem>
      <FlexItem className="fieldset__field">
        <h4
          className={cs('fieldset__label', {
            'fieldset__label--error': hasRejectedFiles,
          })}
        >
          {t('sso.ssoConfiguration.x509SigningCertificate')}
        </h4>
        <FileUploadRootProvider
          value={fileUpload}
          style={{ marginTop: 'var(--pando-spacing-2)' }}
        >
          <Show when={!fileUpload?.acceptedFiles?.length}>
            <FileUploadDropzone
              style={{
                borderColor: hasRejectedFiles
                  ? PandoInkyBlueDangerBorderInitialDark
                  : undefined,
              }}
            >
              <FileUploadTrigger asChild>
                <Button palette="action" size="md">
                  {t('sso.ssoConfiguration.chooseFile')}
                </Button>
              </FileUploadTrigger>
              <FileUploadLabel>
                {t('sso.ssoConfiguration.chooseAFileOrDrag')}
              </FileUploadLabel>
            </FileUploadDropzone>
          </Show>
          <FileUploadItemGroup>
            <FileUploadContext>
              {({ acceptedFiles }: FileUploadFileChangeDetails) =>
                acceptedFiles.map((file: File) => (
                  <FileUploadItem
                    key={file.name}
                    file={file}
                    className="fieldset__description"
                    style={{
                      color: 'var(--pando-inky-blue-neutral-text-initial-dark)',
                    }}
                  >
                    <FileUploadItemPreview type=".*">
                      <FlexContainer gap="var(--pando-gap-md)" align="center">
                        <CheckCircleIcon
                          style={{
                            color:
                              'var(--pando-inky-blue-success-border-initial-dark)',
                          }}
                        />
                        <FlexContainer direction="col">
                          <FileUploadItemName>
                            {t('sso.ssoConfiguration.yourCertificate')}
                          </FileUploadItemName>
                        </FlexContainer>
                      </FlexContainer>
                    </FileUploadItemPreview>
                    <FileUploadItemDeleteTrigger asChild>
                      <IconButton
                        ariaLabel="trash icon"
                        palette="danger"
                        size="sm"
                        usage="text"
                        style={{
                          color: 'var(--pando-colors-danger-border-initial)',
                        }}
                        onClick={() => setIsEditMode(false)} // after this action, upload is required
                      >
                        <TrashIcon />
                      </IconButton>
                    </FileUploadItemDeleteTrigger>
                  </FileUploadItem>
                ))
              }
            </FileUploadContext>
          </FileUploadItemGroup>
          <FileUploadContext>
            {({ rejectedFiles }: FileUploadFileChangeDetails) =>
              rejectedFiles.map(({ errors }: FileRejection) => (
                <p className="field-validation-error-message">
                  {getFileUploadErrorText(errors[0])}
                </p>
              ))
            }
          </FileUploadContext>
          <FileUploadHiddenInput
            name={!isEditMode ? SAMLFormFields.SIGNING_CERT : undefined}
            required={!isEditMode}
          />
        </FileUploadRootProvider>
        <p className="fieldset__note">
          {t('sso.ssoConfiguration.samlpServerPublic')}
        </p>
      </FlexItem>
      <FlexItem>
        <SwitchInput
          className="fieldset__switch"
          labelPosition="start"
          switchSize="sm"
          onChange={handleEnableSignOutToggle}
          checked={enableSignOut}
        >
          <SwitchInputLabel
            className="fieldset__switch-label"
            aria-label={t('sso.ssoConfiguration.enableSignOut')}
          >
            {t('sso.ssoConfiguration.enableSignOut')}
          </SwitchInputLabel>
        </SwitchInput>
      </FlexItem>
      <Show when={enableSignOut}>
        <FlexItem className="fieldset__field">
          <FieldValidationProvider validate={validateUrl}>
            {({ error, validateField }) => (
              <FormControlProvider required invalid={!!error}>
                <Label
                  htmlFor="saml-sing-out-url"
                  className={cs('fieldset__label', {
                    'fieldset__label--error': !!error,
                  })}
                >
                  {t('sso.ssoConfiguration.signOutUrl')}
                </Label>
                <Input
                  id="saml-sing-out-url"
                  name={SAMLFormFields.SIGN_OUT_ENDPOINT}
                  placeholder="https://samlp.example.com/login"
                  size="lg"
                  className="fieldset__input"
                  value={signOutUrl}
                  onChange={(e) => handleSignOutUrl(e, validateField)}
                />
              </FormControlProvider>
            )}
          </FieldValidationProvider>
          <p className="fieldset__note">
            {t('sso.ssoConfiguration.whenEmptyThisField')}
          </p>
        </FlexItem>
      </Show>
      <FlexItem className="fieldset__field">
        <FormControlProvider>
          <Label htmlFor="saml-user-id-attr" className="fieldset__label">
            {t('sso.ssoConfiguration.userIdAttribute')}
          </Label>
          <Input
            id="saml-user-id-attr"
            name={SAMLFormFields.USER_ID_ATTRIBUTE}
            placeholder="http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
            size="lg"
            className="fieldset__input"
            value={userIdAttr}
            onChange={handleUserIdAttr}
          />
          <p className="fieldset__note">
            {t('sso.ssoConfiguration.thisIsTheAttributeInSaml')}
          </p>
        </FormControlProvider>
      </FlexItem>
      <FlexContainer direction="col">
        <SwitchInput
          className="fieldset__switch"
          labelPosition="start"
          switchSize="sm"
          onChange={handleDebugModeToggle}
          checked={debugMode}
        >
          <SwitchInputLabel
            className="fieldset__switch-label"
            aria-label={t('sso.ssoConfiguration.debugMode')}
          >
            {t('sso.ssoConfiguration.debugMode')}
          </SwitchInputLabel>
        </SwitchInput>
        <p className="fieldset__note">
          {t('sso.ssoConfiguration.includeMoreVerbose')}
        </p>
      </FlexContainer>
      <FlexContainer direction="col">
        <SwitchInput
          className="fieldset__switch"
          labelPosition="start"
          switchSize="sm"
          onChange={handleSignRequestToggle}
          checked={signRequest}
          name={SAMLFormFields.SIGN_SAML_REQUEST}
        >
          <SwitchInputLabel
            className="fieldset__switch-label"
            aria-label={t('sso.ssoConfiguration.signRequest')}
          >
            {t('sso.ssoConfiguration.signRequest')}
          </SwitchInputLabel>
        </SwitchInput>
        <p className="fieldset__note">
          {t('sso.ssoConfiguration.whenEnabled')}{' '}
          <a
            href="https://dev-yzfbhwn6h7f0pk28.eu.auth0.com/pem?cert=connection"
            className="fieldset__link"
          >
            {t('sso.ssoConfiguration.certificate')}
          </a>{' '}
          {t('sso.ssoConfiguration.andGiveItToSaml')}
        </p>
      </FlexContainer>
      <FlexItem>
        <FormControlProvider>
          <Label htmlFor="saml-sign-req-alg" className="fieldset__label">
            {t('sso.ssoConfiguration.signRequestAlgoritmn')}
          </Label>
          <Select
            id="saml-sign-req-alg"
            name={SAMLFormFields.SIGNATURE_ALGORITHM}
            size="lg"
            style={{ textTransform: 'uppercase' }}
            value={sigAlg}
            onChange={handleSigAlgChange}
          >
            <>
              <Option
                name={SignatureAlgorithm.RSASHA1}
                value={SignatureAlgorithm.RSASHA1}
              >
                {SignatureAlgorithm.RSASHA1}
              </Option>
              <Option
                name={SignatureAlgorithm.RSASHA256}
                value={SignatureAlgorithm.RSASHA256}
              >
                {SignatureAlgorithm.RSASHA256}
              </Option>
            </>
          </Select>
        </FormControlProvider>
      </FlexItem>
      <FlexItem>
        <FormControlProvider>
          <Label htmlFor="saml-sign-req-alg-digest" className="fieldset__label">
            {t('sso.ssoConfiguration.signRequestAlgorithmDigest')}
          </Label>
          <Select
            id="saml-sign-req-alg-digest"
            name={SAMLFormFields.DIGEST_ALGORITHM}
            size="lg"
            style={{ textTransform: 'uppercase' }}
            value={digestAlg}
            onChange={handleDigestAlgChange}
          >
            <>
              <Option name={DigestAlgorithm.SHA1} value={DigestAlgorithm.SHA1}>
                {DigestAlgorithm.SHA1}
              </Option>
              <Option
                name={DigestAlgorithm.SHA256}
                value={DigestAlgorithm.SHA256}
              >
                {DigestAlgorithm.SHA256}
              </Option>
            </>
          </Select>
        </FormControlProvider>
      </FlexItem>
      <FlexItem className="fieldset__field">
        <FormControlProvider>
          <Label
            htmlFor="saml-protocol-binding"
            className="fieldset__label fieldset__label--mb-negative"
          >
            {t('sso.ssoConfiguration.protocolBinding')}
          </Label>
          <Select
            id="saml-protocol-binding"
            name={SAMLFormFields.PROTOCOL_BINDING}
            size="lg"
            style={{ textTransform: 'uppercase' }}
            value={protocolBind}
            onChange={handleProtocolBindChange}
          >
            <>
              <Option
                name={ProtocolBinding.HTTP_POST}
                value={ProtocolBinding.HTTP_POST}
              >
                {ProtocolBindingField.HTTP_POST}
              </Option>
              <Option
                name={ProtocolBinding.HTTP_REDIRECT}
                value={ProtocolBinding.HTTP_REDIRECT}
              >
                {ProtocolBindingField.HTTP_REDIRECT}
              </Option>
            </>
          </Select>
          <p className="fieldset__note">
            {t('sso.ssoConfiguration.appliesOnlyToSaml')}
          </p>
        </FormControlProvider>
      </FlexItem>
      <FlexItem>
        <h4 className="fieldset__label">
          {t('sso.ssoConfiguration.requestTemplate')}
        </h4>
        <pre>{requestTemplate}</pre>
      </FlexItem>
    </FlexContainer>
  );
};

const createDummyPemFile = (): File => {
  const fileContent = 'This is a dummy certificate file.';
  const blob = new Blob([fileContent], { type: 'application/x-x509-ca-cert' });

  const file = new File([blob], 'dummy.pem', {
    type: 'application/x-x509-ca-cert',
  });

  Object.defineProperty(file, 'isDummy', {
    value: true,
  });

  return file;
};

export default memo(Saml);
