import axios from 'axios';

import { CERT_EXTENSIONS, SsoProtocol } from './interfaces';

import type { AxiosInstance } from 'axios';
import type {
  IConnectionSettings,
  ISsoConfigurationOIDC,
  ISsoConfigurationSAML,
  ISsoConnection,
  TSsoConnectionStatus,
} from '../../sso-conections/state/interfaces';
import type {
  IOidcConfigurationPayload,
  ISamlConfigurationPayload,
  IStatusPayload,
  TSsoConfigurationPayload,
} from './interfaces';

const SSO_CONNECTION_PATH = '/v1/sso-connections';
// Constants for hardcoded values
const SCOPE = 'openid profile email';
const SCHEMA_VERSION = 'oidc-V4';
const ATTRIBUTE_MAP = {};
const CONNECTION_SETTINGS: IConnectionSettings = {
  pkce: 'auto',
};

export class SsoConfigurationService {
  private httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  submitSsoConfiguration = async (
    configData: TSsoConfigurationPayload,
  ): Promise<ISsoConnection> => {
    try {
      const payload = await transformConfigData(configData);
      const response = await this.httpClient.post<ISsoConnection>(
        `${SSO_CONNECTION_PATH}`,
        payload,
      );

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        let errorMessage =
          error.response?.data?.message || 'Unknown Axios error';

        // Remove the "Auth0Service:" prefix used for internal BE debugging; do not show it on the UI.
        errorMessage = errorMessage.replace(/^Auth0Service:\s*/, '');

        console.error(errorMessage);

        throw errorMessage;
      } else {
        console.error('Unexpected error submitting SSO Configuration:', error);

        throw error;
      }
    }
  };

  updateSsoConnection = async (
    configData: TSsoConfigurationPayload,
  ): Promise<ISsoConnection> => {
    const connectionId = configData.id;

    if (!connectionId) {
      throw new Error('Connection ID is required to update SSO Connection');
    }

    try {
      const payload = await transformConfigData(configData);
      const response = await this.httpClient.put<ISsoConnection>(
        `${SSO_CONNECTION_PATH}/${connectionId}`,
        payload,
      );

      return response.data;
    } catch (error) {
      console.error('Error updating SSO Connection:', error);

      throw error;
    }
  };

  updateSsoConnectionStatus = async ({
    status,
    id: connectionId,
  }: IStatusPayload) => {
    if (!connectionId) {
      throw new Error('Connection ID is required to update SSO Connection');
    }

    try {
      const response = await this.httpClient.patch<{
        status: TSsoConnectionStatus;
      }>(`${SSO_CONNECTION_PATH}/${connectionId}`, { status });

      return response.data;
    } catch (error) {
      console.error('Error updating SSO Connection:', error);

      throw error;
    }
  };
}

export const transformConfigData = async (
  configData: TSsoConfigurationPayload,
): Promise<Partial<ISsoConnection>> => {
  if (!configData.connectionName) {
    throw new Error("Missing SSO configuration field 'name'");
  }

  if (!configData.orgId) {
    throw new Error("Missing SSO configuration field 'orgId'");
  }

  const configurations = isOidc(configData)
    ? await mapOidcConfig(configData as IOidcConfigurationPayload)
    : await mapSamlConfig(configData as ISamlConfigurationPayload);

  return {
    name: configData.connectionName,
    organizationId: configData.orgId,
    strategy: isOidc(configData) ? SsoProtocol.OIDC : SsoProtocol.SAML,
    configurations,
  };
};

const mapSamlConfig = async (
  configData: ISamlConfigurationPayload,
): Promise<ISsoConfigurationSAML> => {
  if (!configData.signingCert) {
    throw new Error("Missing SSO configuration field 'signingCert'");
  }

  let signingCert = configData.signingCert;

  if (configData.signingCert instanceof File) {
    signingCert = (await processFile(configData.signingCert)) as string;
  }

  return {
    signSamlRequest: configData.signSamlRequest === 'on',
    signatureAlgorithm: configData.signatureAlgorithm,
    digestAlgorithm: configData.digestAlgorithm,
    signingCert: signingCert as string,
    signInEndpoint: configData.signInEndpoint,
    signOutEndpoint: configData.signOutEndpoint,
    userIdAttribute: configData.userIdAttribute,
    protocolBinding: configData.protocolBinding,
  };
};

const mapOidcConfig = async (
  configData: IOidcConfigurationPayload,
): Promise<ISsoConfigurationOIDC> => {
  const communicationChannel = configData.communicationChannel;
  let oidcMetadata: ISsoConfigurationOIDC['oidcMetadata'];

  if (!configData.openidConnectDiscUrl) {
    oidcMetadata = configData.metadata;

    if (configData.metadata instanceof File) {
      oidcMetadata = await processFile(configData.metadata);
    }
  }

  return {
    scope: SCOPE,
    type: communicationChannel,
    discoveryUrl: configData.openidConnectDiscUrl,
    oidcMetadata,
    clientId: configData.clientId,
    clientSecret: configData.clientSecret,
    schemaVersion: SCHEMA_VERSION,
    attributeMap: ATTRIBUTE_MAP,
    connectionSettings: CONNECTION_SETTINGS,
  };
};

const isOidc = (
  configData: TSsoConfigurationPayload,
): configData is IOidcConfigurationPayload =>
  'communicationChannel' in configData && 'clientId' in configData;

const processFile = (file: File): Promise<unknown> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      // OIDC Metadata: .json file
      const extension = file.name.split('.').pop()?.toLowerCase();

      if (file.type === 'application/json') {
        try {
          const jsonContent = JSON.parse(reader.result as string) as unknown;

          resolve(jsonContent);
        } catch (err) {
          reject(err);
        }
      } // SAMLP SigningCert: .pem, cer, cert, crt
      else if (
        extension &&
        CERT_EXTENSIONS.includes(extension as (typeof CERT_EXTENSIONS)[number])
      ) {
        resolve(reader.result);
      } // Default case for unsupported file types
      else {
        resolve(undefined);
      }
    };

    reader.onerror = () => {
      console.error('Error reading file:', reader.error);
      reject(reader.error);
    };

    reader.readAsText(file);
  });
