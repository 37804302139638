import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { User, UserState } from './interfaces';

const INITIAL_STATE: UserState = {
  error: null,
  item: null,
  loading: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState: INITIAL_STATE,
  reducers: {
    fetchUser: (state, _action: PayloadAction<string>) => {
      state.error = null;
      state.loading = true;
    },
    fetchUserSuccess: (state, action: PayloadAction<User>) => {
      state.item = action.payload;
      state.loading = false;
    },
    fetchUserFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    patchUser: (state) => {
      state.error = null;
      state.loading = true;
    },
    patchUserSuccess: (state, action: PayloadAction<User>) => {
      state.item = action.payload;
      state.loading = false;
    },
    patchUserFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  fetchUser,
  fetchUserSuccess,
  fetchUserFailure,
  patchUser,
  patchUserSuccess,
  patchUserFailure,
} = userSlice.actions;

export default userSlice.reducer;
