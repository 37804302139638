import { useEffect, useState } from 'react';

interface Props {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  body: string;
  id: string;
}

export const useHandlePopoverAccessibility = ({
  isOpen,
  setIsOpen,
  body,
  id,
}: Props) => {
  const [prevActiveElement, setPrevActiveElement] = useState<string | null>(
    null,
  );

  // Accessibility
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        event.key === 'Enter' &&
        !isOpen &&
        document.activeElement?.id &&
        document.activeElement?.id === id
      ) {
        setPrevActiveElement(document.activeElement?.id);
        setIsOpen(true);
      }

      if (
        (!event.key || event.key === 'Enter') &&
        isOpen &&
        document.activeElement?.id === `hint-${id}`
      ) {
        setIsOpen(false);
      }

      if (
        event.key === 'Tab' &&
        isOpen &&
        document.activeElement?.id === `hint-${id}`
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      if (prevActiveElement) {
        const el = document.getElementById(prevActiveElement);

        el?.focus();
      }

      return document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, setIsOpen, id]);

  // Accessibility - copy
  useEffect(() => {
    const handleCopy = (event: ClipboardEvent) => {
      if (isOpen) {
        event.preventDefault();
        void navigator.clipboard.writeText(body);
      }
    };

    document.addEventListener('copy', handleCopy);

    return () => document.removeEventListener('copy', handleCopy);
  }, [isOpen, body]);
};
