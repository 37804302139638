// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-buttons__button {
  margin-right: var(--pando-spacing-3);
}`, "",{"version":3,"sources":["webpack://./src/features/people/components/action-buttons/action-buttons.scss"],"names":[],"mappings":"AAEE;EACE,oCAAA;AADJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.action-buttons {\n  &__button {\n    margin-right: var(--pando-spacing-3);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
