import { createSlice } from '@reduxjs/toolkit';

import type { Member } from '../../../shared/interfaces';
import type { LicensesState, UpdateLicensesResponse } from './interfaces';
import type { PayloadAction } from '@reduxjs/toolkit';

const INITIAL_STATE: LicensesState = {
  currentMember: null,
  productsToAdd: [],
  productsToRemove: [],
  productIds: [],
  loading: false,
  error: null,
};

const memberLicensesSlice = createSlice({
  name: 'member-licenses',
  initialState: INITIAL_STATE,
  reducers: {
    updateLicenses: (
      state,
      _action: PayloadAction<{
        productsToAdd: string[];
        productsToRemove: string[];
      }>,
    ) => {
      state.error = null;
      state.loading = true;
    },
    updateLicensesSuccess: (
      state,
      _action: PayloadAction<UpdateLicensesResponse>,
    ) => {
      state.loading = false;
    },
    updateLicensesFailure: (
      state,
      action: PayloadAction<{
        response?: UpdateLicensesResponse;
        error: string;
      }>,
    ) => {
      state.loading = false;
      state.error = action.payload.error;
    },
    setCurrentMember: (state, action: PayloadAction<Member | null>) => {
      state.currentMember = action.payload;
      state.productIds =
        action.payload?.products.map((product) => product.productId) || [];
    },
    clearCurrentMember: (state) => {
      state.currentMember = null;
      state.productIds = [];
      state.productsToAdd = [];
      state.productsToRemove = [];
    },
    assignSelectedLicenses: (state, action: PayloadAction<string>) => {
      if (state.productsToAdd.includes(action.payload)) {
        state.productsToAdd = state.productsToAdd.filter(
          (product) => product !== action.payload,
        );
      } else {
        state.productsToAdd.push(action.payload);
      }
    },
    revokeLicenses: (state, action: PayloadAction<string>) => {
      if (state.productsToRemove.includes(action.payload)) {
        state.productsToRemove = state.productsToRemove.filter(
          (product) => product !== action.payload,
        );
      } else {
        state.productsToRemove.push(action.payload);
      }
    },

    setProductIds: (state, action: PayloadAction<string>) => {
      const index = state.productIds.indexOf(action.payload);

      state.productIds = state.productIds.includes(action.payload)
        ? [
            ...state.productIds.slice(0, index),
            ...state.productIds.slice(index + 1),
          ]
        : [...state.productIds, action.payload];
    },
  },
});

export const {
  updateLicenses,
  updateLicensesSuccess,
  updateLicensesFailure,
  setCurrentMember,
  clearCurrentMember,
  assignSelectedLicenses,
  revokeLicenses,
  setProductIds,
} = memberLicensesSlice.actions;

export default memberLicensesSlice.reducer;
