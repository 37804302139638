import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { Team, TeamState } from './interfaces';

const INITIAL_STATE: TeamState = {
  error: null,
  item: null,
  loading: false,
};

const teamSlice = createSlice({
  name: 'team',
  initialState: INITIAL_STATE,
  reducers: {
    fetchTeam: (state, _action) => {
      state.error = null;
      state.loading = true;
    },
    fetchTeamSuccess: (state, action: PayloadAction<Team>) => {
      state.item = action.payload;
      state.loading = false;
    },
    fetchTeamFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    patchTeam: (state) => {
      state.error = null;
      state.loading = true;
    },
    patchTeamSuccess: (state, action: PayloadAction<Team>) => {
      state.item = action.payload;
      state.loading = false;
    },
    patchTeamFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    resetTeamDetails: (state) => {
      state.item = null;
    },
  },
});

export const {
  fetchTeam,
  fetchTeamSuccess,
  fetchTeamFailure,
  patchTeam,
  patchTeamSuccess,
  patchTeamFailure,
  resetTeamDetails,
} = teamSlice.actions;

export default teamSlice.reducer;
