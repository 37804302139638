import { Button, FlexContainer } from '@pluralsight/react-ng';
import React from 'react';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';
import './action-buttons.scss';

interface ActionButtonsProps {
  closeModal: () => void;
}

const ExportStepActionButtons: React.FC<ActionButtonsProps> = ({
  closeModal,
}) => {
  const { t } = useTranslations();

  return (
    <FlexContainer justify="flexEnd">
      <FlexContainer
        align="center"
        direction="row"
        justify="flexEnd"
        wrap="nowrap"
      >
        <Button size="lg" palette="action" usage="filled" onClick={closeModal}>
          {t('teams.exportCsvFile.okay')}
        </Button>
      </FlexContainer>
    </FlexContainer>
  );
};

export default ExportStepActionButtons;
