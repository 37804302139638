import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import PeopleContainer from '../../features/people/components/people-container/people-container';
import { fetchPlan } from '../../features/plans/state/slice';
import TeamContainer from '../../features/team/components/team-container/team-container';
import TeamsContainer from '../../features/teams/components/teams-container/teams-container';
import { selectUser } from '../../features/user/state/selectors';
import { fetchUser } from '../../features/user/state/slice';
import { ROUTE_RESTRICTION_CONFIG, ROUTES } from '../../shared/constants';
import Dashboard from '../dashboard/dashboard';
import RoleRestrictedRoute from '../role-restricted-route/role-restricted-route';
import AccountSettingsPage from '../../pages/account-settings/account-settings';
import SsoConfiguration from '../../features/sso/components/sso-configuration/sso-configuration';
import { selectOrgId } from '../../features/plans/state/selectors';
import { fetchOrganization } from '../../features/organization/state/slice';

const App = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const orgId = useSelector(selectOrgId);

  useEffect(() => {
    const storedUser = sessionStorage.getItem('userLoggedIn');

    if (storedUser) {
      const userLoggedIn = JSON.parse(storedUser) as { userId: string };

      dispatch(fetchUser(userLoggedIn.userId));
    }
  }, []);

  useEffect(() => {
    const planId = user?.permissionScope[0]?.plans[0]?.planId;

    if (planId) {
      dispatch(fetchPlan(planId));
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (orgId) {
      dispatch(fetchOrganization({ orgId }));
    }
  }, [orgId, dispatch]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path={ROUTES.HOME} element={<Dashboard />} />
        <Route
          path={ROUTES.PEOPLE}
          element={
            <RoleRestrictedRoute
              restrictedTo={ROUTE_RESTRICTION_CONFIG[ROUTES.PEOPLE]}
            >
              <PeopleContainer />
            </RoleRestrictedRoute>
          }
        />
        <Route
          path={ROUTES.ACCOUNT_SETTINGS}
          element={<AccountSettingsPage />}
        />
        <Route
          path={ROUTES.TEAMS}
          element={
            <RoleRestrictedRoute
              restrictedTo={ROUTE_RESTRICTION_CONFIG[ROUTES.TEAMS]}
            >
              <TeamsContainer />
            </RoleRestrictedRoute>
          }
        />
        <Route
          path={`${ROUTES.TEAMS}/:teamId`}
          element={
            <RoleRestrictedRoute
              restrictedTo={ROUTE_RESTRICTION_CONFIG[ROUTES.TEAMS]}
            >
              <TeamContainer />
            </RoleRestrictedRoute>
          }
        />
        <Route
          path={`${ROUTES.SSO_CONFIGURATION}/:connectionId?`}
          element={
            <RoleRestrictedRoute
              restrictedTo={ROUTE_RESTRICTION_CONFIG[ROUTES.SSO_CONFIGURATION]}
            >
              <SsoConfiguration />
            </RoleRestrictedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
