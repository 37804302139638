import { InfoCircleIcon } from '@pluralsight/icons';
import {
  Button,
  FlexContainer,
  FormControlProvider,
  Label,
  Show,
  SwitchInput,
  SwitchInputLabel,
  usePrompt,
  useToast,
} from '@pluralsight/react-ng';
import cs from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { useTranslations } from '@ps-fe-plan-management/contexts/TranslationsContext';
import { ROUTES } from '@ps-fe-plan-management/shared/constants';
import FieldValidationProvider from '@ps-fe-plan-management/features/sso/components/shared/field-validation/field-validation-provider';
import { validateConnectionName } from '@ps-fe-plan-management/features/sso/components/sso-configuration/sso-configuration-form/utils/validation';

import CopyButton from '../../shared/copy-button/copy-button';
import RightIconInput from '../../shared/right-icon-input/right-icon-input';
import Divider from '../../shared/divider/divider';
import { selectSingleSsoConnection } from '../../sso-conections/state/selectors';
import { deleteSsoConnection } from '../../sso-conections/state/slice';
import { SsoConfigurationFields, SsoProtocol } from '../state/interfaces';
import { selectIsFormModifiedInEdit } from '../state/selectors';
import { Tabs } from '../../../../../pages/account-settings/account-settings';

import Oidc from './oidc/oidc';
import Saml from './saml/saml';
import { useFieldTracker } from './hooks/useFieldTracker';

import type { ChangeEvent, MouseEvent } from 'react';

const DELETE_KEY = 'DELETE';

const SsoConfigurationForm = () => {
  const { t } = useTranslations();
  const { connectionId } = useParams();
  const { prompt } = usePrompt();
  const { show } = useToast();
  const dispatch = useDispatch();
  const [isUserSsoEnabled, setIsUserSsoEnabled] = useState(false);
  const [name, setName] = useState<string>('');
  const [strategy, setStrategy] = useState<SsoProtocol>(SsoProtocol.OIDC);

  const singleSsoConnection = useSelector(selectSingleSsoConnection);
  const isFormModifiedInEdit = useSelector(selectIsFormModifiedInEdit);

  const samlButtonRef = useRef<HTMLButtonElement>(null);
  const oidcButtonRef = useRef<HTMLButtonElement>(null);

  const {
    name: connectionName,
    strategy: ssoProtocol,
    status,
  } = singleSsoConnection ?? {};

  const isEditMode = !!connectionId;
  const isSaml = strategy === SsoProtocol.SAML;

  const navigate = useNavigate();

  useEffect(() => {
    if (status) setIsUserSsoEnabled(status === 'ACTIVE');

    if (connectionName) setName(connectionName);

    if (ssoProtocol) setStrategy(ssoProtocol);
  }, [status, connectionName, ssoProtocol]);

  useFieldTracker(status ? status === 'ACTIVE' : undefined, isUserSsoEnabled);

  const handleUserSsoToggle = useCallback(() => {
    const nextValue = !isUserSsoEnabled;

    setIsUserSsoEnabled(nextValue);
  }, [isUserSsoEnabled]);

  const navigateToAccountSettingsSsoTab = useCallback(() => {
    navigate(ROUTES.ACCOUNT_SETTINGS, {
      state: { currentTab: Tabs.SSO },
      replace: true,
    });
  }, [navigate]);

  const handleConnectionNameChange = (
    e: ChangeEvent<HTMLInputElement>,
    validateField: (inputValue: string) => void,
  ): void => {
    validateField(e.target.value);
    setName(e.target.value);
  };

  const handleDeleteClick = useCallback(
    async (e: MouseEvent) => {
      e.preventDefault();

      if (!connectionId) return;

      try {
        const validation = await prompt({
          heading: t('sso.ssoConfiguration.deleteSsoConnection'),
          text: t('sso.ssoConfiguration.thisActionCannotBeUndone'),
          promptValidationKey: DELETE_KEY,
          confirmText: 'Delete',
          kind: 'destructive',
        });

        if (validation === DELETE_KEY) {
          dispatch(deleteSsoConnection({ id: connectionId } as any));
          navigateToAccountSettingsSsoTab();
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (error) {
        show({
          text: t('sso.ssoToastMessages.ssoConnectionToastDeleteError'),
          palette: 'danger',
          onClose: () => {},
        });
      }
    },
    [t, navigateToAccountSettingsSsoTab, connectionId, dispatch, show, prompt],
  );

  const radioOptions = [
    { value: SsoProtocol.SAML, ref: samlButtonRef },
    { value: SsoProtocol.OIDC, ref: oidcButtonRef },
  ];

  const handleRadioKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLButtonElement>) => {
      const target = e.currentTarget;
      const currentIndex = radioOptions.findIndex(
        (option: {
          value: SsoProtocol;
          ref: React.RefObject<HTMLButtonElement>;
        }) => option.ref.current === target,
      );

      if (currentIndex === -1) return;

      const moveFocus = (newIndex: number) => {
        radioOptions[newIndex].ref.current?.focus();
      };

      switch (e.key) {
        case 'ArrowRight':
        case 'ArrowDown': {
          e.preventDefault();

          const newIndex = (currentIndex + 1) % radioOptions.length;

          moveFocus(newIndex);

          break;
        }
        case 'ArrowLeft':
        case 'ArrowUp': {
          e.preventDefault();

          const newIndex =
            (currentIndex - 1 + radioOptions.length) % radioOptions.length;

          moveFocus(newIndex);

          break;
        }
        case ' ':
        case 'Enter': {
          e.preventDefault();

          const currentOption = radioOptions[currentIndex];

          if (strategy !== currentOption.value) {
            setStrategy(currentOption.value);
          }

          break;
        }
        default:
          break;
      }
    },
    [radioOptions, strategy],
  );

  return (
    <fieldset className="sso-config__fieldset fieldset">
      <FlexContainer
        direction="col"
        gap="var(--pando-gap-xs)"
        className="fieldset__header"
      >
        <h2 className="fieldset__title">
          {t('sso.ssoConfiguration.ssoSetupDetails')}
        </h2>
        <p className="fieldset__description">
          {t('sso.ssoConfiguration.fillOutTheRequiredFields')}
        </p>
      </FlexContainer>
      <Show when={isEditMode}>
        <FlexContainer direction="col">
          <SwitchInput
            className="fieldset__switch"
            labelPosition="start"
            switchSize="sm"
            onChange={handleUserSsoToggle}
            checked={isUserSsoEnabled}
            name={SsoConfigurationFields.STATUS}
          >
            <SwitchInputLabel
              className="fieldset__switch-label"
              aria-label={t('sso.ssoConfiguration.enableUserSso')}
            >
              {t('sso.ssoConfiguration.enableUserSso')}
            </SwitchInputLabel>
          </SwitchInput>
          <p className="fieldset__note">
            {t('sso.ssoConfiguration.ifYouActivateSso')}
          </p>
        </FlexContainer>
      </Show>
      <FlexContainer
        align="center"
        gap="var(--pando-gap-md)"
        className="fieldset__group"
      >
        <FlexContainer
          role="radiogroup"
          aria-label={t('sso.ssoConfiguration.selectProtocol')}
          align="center"
          className="fieldset__button-group"
          gap="var(--pando-gap-md)"
        >
          <Show when={!isEditMode || isSaml}>
            <Button
              ref={samlButtonRef}
              tabIndex={isSaml ? 0 : -1}
              palette="action"
              size="md"
              usage={isSaml ? 'filled' : 'text'}
              onClick={(e: MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                setStrategy(SsoProtocol.SAML);
              }}
              onKeyDown={handleRadioKeyDown}
              role="radio"
              aria-checked={isSaml}
            >
              SAML
            </Button>
          </Show>
          <Show when={!isEditMode || !isSaml}>
            <Button
              ref={oidcButtonRef}
              tabIndex={!isSaml ? 0 : -1}
              onClick={(e: MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                setStrategy(SsoProtocol.OIDC);
              }}
              onKeyDown={handleRadioKeyDown}
              palette="action"
              size="md"
              usage={!isSaml ? 'filled' : 'text'}
              role="radio"
              aria-checked={!isSaml}
            >
              {t('sso.ssoConfiguration.openIdConnect')}
            </Button>
          </Show>
        </FlexContainer>
        <Show when={isEditMode}>
          <p className="fieldset__note">
            <FlexContainer align="center" gap="var(--pando-gap-sm)">
              <InfoCircleIcon />
              {t('sso.ssoConfiguration.protocolUnchangeable')}
            </FlexContainer>
          </p>
        </Show>
      </FlexContainer>
      <FlexContainer direction="col">
        <h3>{t('sso.ssoConfiguration.general')}</h3>
        <Divider />
      </FlexContainer>
      <FlexContainer
        direction="col"
        gap="var(--pando-gap-lg)"
        className="fieldset__body"
      >
        <FlexContainer direction="col" className="fieldset__field">
          <FieldValidationProvider validate={validateConnectionName}>
            {({ error, validateField }) => (
              <FormControlProvider
                required
                disabled={isEditMode}
                invalid={!!error}
              >
                <Label
                  htmlFor="sso-basic-information-name"
                  className={cs('fieldset__label', {
                    'fieldset__label--error': !!error,
                  })}
                >
                  {t('sso.ssoConfiguration.connectionName')}
                </Label>
                <RightIconInput
                  id="sso-basic-information--connection-name"
                  name={SsoConfigurationFields.CONNECTION_NAME}
                  placeholder={t('sso.ssoConfiguration.connectionName')}
                  size="lg"
                  endIcon={
                    !error && (
                      <CopyButton
                        copyText={name}
                        aria-label={t(
                          'sso.ssoConfiguration.copyConnectionName',
                        )}
                      />
                    )
                  }
                  className={cs('fieldset__input', {
                    'fieldset__input--special': isEditMode,
                  })}
                  value={name}
                  onChange={(e) => handleConnectionNameChange(e, validateField)}
                />
              </FormControlProvider>
            )}
          </FieldValidationProvider>
          <p className="fieldset__note">
            {t('sso.ssoConfiguration.thisIsALogicalIdentifier')}
          </p>
        </FlexContainer>
        {strategy === SsoProtocol.SAML ? <Saml /> : <Oidc />}
      </FlexContainer>
      <FlexContainer gap="var(--pando-gap-md)" className="fieldset__footer">
        <Button
          role="link"
          palette="action"
          size="lg"
          usage="outline"
          onClick={navigateToAccountSettingsSsoTab}
        >
          {t('sso.ssoConfiguration.cancel')}
        </Button>
        <Button
          palette="action"
          size="lg"
          usage="filled"
          type="submit"
          disabled={isEditMode && !isFormModifiedInEdit}
        >
          {t('sso.ssoConfiguration.saveConfiguration')}
        </Button>

        {isEditMode && (
          <Button
            palette="danger"
            size="lg"
            usage="filled"
            onClick={(e) => {
              void handleDeleteClick(e);
            }}
          >
            {t('sso.ssoConfiguration.deleteConnection')}
          </Button>
        )}
      </FlexContainer>
    </fieldset>
  );
};

export default SsoConfigurationForm;
