// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.people-search__scroll {
  border: 1px solid var(--pando-inky-blue-neutral-border-initial-dark);
  border-radius: 6px;
  height: 327px;
  margin-top: 4px;
  overflow: auto;
  width: 676px;
}
.people-search__scroll td {
  border: none;
}
.people-search__username {
  text-overflow: ellipsis;
  white-space: nowrap;
  /* Text/m */
  font-family: var(--font-family-body, "PS TT Commons");
  font-size: var(--font-size-md, 16px);
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
}
.people-search__email {
  color: var(--neutral-text-medium, var(--pando-colors-neutral-text-200));
  /* text-sm/lineHeight-5/font-normal */
  font-family: "PS TT Commons";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}`, "",{"version":3,"sources":["webpack://./src/components/people-search-result/people-search-result.scss"],"names":[],"mappings":"AAEE;EACE,oEAAA;EACA,kBAAA;EACA,aAAA;EACA,eAAA;EACA,cAAA;EACA,YAAA;AADJ;AAGI;EACE,YAAA;AADN;AAKE;EACE,uBAAA;EACA,mBAAA;EAEA,WAAA;EACA,qDAAA;EACA,oCAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;AAJJ;AAOE;EACE,uEAAA;EAEA,qCAAA;EACA,4BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,aAAA;AANJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.people-search {\n  &__scroll {\n    border: 1px solid var(--pando-inky-blue-neutral-border-initial-dark);\n    border-radius: 6px;\n    height: 327px;\n    margin-top: 4px;\n    overflow: auto;\n    width: 676px;\n\n    td {\n      border: none;\n    }\n  }\n\n  &__username {\n    text-overflow: ellipsis;\n    white-space: nowrap;\n\n    /* Text/m */\n    font-family: var(--font-family-body, \"PS TT Commons\");\n    font-size: var(--font-size-md, 16px);\n    font-style: normal;\n    font-weight: 500;\n    line-height: 150%; /* 24px */\n  }\n\n  &__email {\n    color: var(--neutral-text-medium, var(--pando-colors-neutral-text-200));\n\n    /* text-sm/lineHeight-5/font-normal */\n    font-family: \"PS TT Commons\";\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 20px; /* 142.857% */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
