import { createSelector } from 'reselect';

import { InviteStatus } from './interfaces';

import type { AppState } from '../../../store';
import type { PeopleState } from './interfaces';

const selectPeopleState = (state: AppState) => state.people;

export const selectPeopleList = createSelector(
  [selectPeopleState],
  (peopleState: PeopleState) => peopleState.list,
);

export const selectPendingInvitesList = createSelector(
  [selectPeopleState],
  (peopleState: PeopleState) =>
    peopleState.inviteList.filter(
      (invite) => invite.status !== InviteStatus.Accepted,
    ),
);

export const selectImportActivetab = createSelector(
  [selectPeopleState],
  (peopleState: PeopleState) => peopleState.importActiveTab,
);

export const selectImportErrors = createSelector(
  [selectPeopleState],
  (peopleState: PeopleState) => peopleState.importFile?.importErrors,
);

export const selectImportFile = createSelector(
  [selectPeopleState],
  (peopleState: PeopleState) => peopleState.importFile,
);

export const selectUserRoles = createSelector(
  [selectPeopleState],
  (people: PeopleState) => people.roles,
);

export const selectEditUser = createSelector(
  [selectPeopleState],
  (people: PeopleState) => people.editUser,
);
