import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { ROUTES } from '@ps-fe-plan-management/shared/constants';

import { selectUser } from '../../features/user/state/selectors';
import { hasExpectedRoleInScope } from '../../shared/utils';

import type { ExpectedRole } from '../../shared/interfaces';
import type { FC, ReactNode } from 'react';

interface RoleRestrictedRouteProps {
  children: ReactNode;
  restrictedTo: ExpectedRole[];
}

const RoleRestrictedRoute: FC<RoleRestrictedRouteProps> = ({
  children,
  restrictedTo,
}) => {
  const [isPermitted, setIsPermitted] = useState(true);

  const user = useSelector(selectUser);

  useEffect(() => {
    if (user) setIsPermitted(hasExpectedRoleInScope(user, restrictedTo));
  }, [user]);

  if (!user) return <></>;
  else if (user && !isPermitted) return <Navigate to={ROUTES.HOME} />;
  else return children;
};

export default RoleRestrictedRoute;
